import * as R from 'ramda';
import { createSelector } from 'reselect';

import getRation from '../getRation';

export default createSelector(
  getRation,

  R.compose(
    R.not,
    R.propOr(false, 'custom_не_назначен'),
  ),
);

import * as R from 'ramda';
import { createSelector } from 'reselect';
import createOrmSelector from 'redux/utils/createOrmSelector';

import getMealsAll from 'redux/selectors/getMealsAll';
import getUserType from 'redux/selectors/getUserType';
import { getSelect } from "redux/modules/products";

import getDietanamnezKindsFood from '../getDietanamnezKindsFood';
import getDietanamnezItems from '../getDietanamnezItems';
import getDietanamnezTiles from '../getDietanamnezTiles';
import getDietanamnezType from '../getDietanamnezType';

const getTilesHidden = createOrmSelector(
  getDietanamnezKindsFood,
  getDietanamnezType,
  getUserType,

  (session, kindsFood, daType, userType) => {
    const { DietanamnezTiles } = session;
    const excluder = (item) => {
      const { custom_специфические_исключения } = item;

      return R.compose(
        R.isEmpty,
        R.intersection(custom_специфические_исключения),
      )(kindsFood);
    };

    const excludedByGroups = DietanamnezTiles
      .filter({
        ___parent: null,
      })
      .filter(({ custom_формат }) => !custom_формат || custom_формат === daType)
      .filter(({ custom_версия_приложения }) => !custom_версия_приложения || custom_версия_приложения === userType)
      .exclude(excluder)
      .toModelArray()
      .map(item => item.items.toRefArray());

    const excludedByTiles = DietanamnezTiles
      .exclude({
        ___parent: null,
      })
      .filter(({ custom_формат }) => !custom_формат || custom_формат === daType)
      .filter(({ custom_версия_приложения }) => !custom_версия_приложения || custom_версия_приложения === userType)
      .exclude(excluder)
      .toRefArray();

    return R.compose(
      R.concat(excludedByTiles),
      R.unnest,
    )(excludedByGroups);
  },
);

const getSupplement = createSelector(
  (state) => state,

  (state,) => getSelect(null, "supplement", state)
);

export default createSelector(
  getDietanamnezItems,
  getTilesHidden,
  getMealsAll,
  getSupplement,
  getDietanamnezTiles,

  (items, hiddenTiles, mealsArray, supplementsarray, tiles) => {
    const meals = R.indexBy(R.prop('key'), mealsArray);
    const supplements = R.indexBy(R.prop('key'), supplementsarray);
    const excludedKeys = R.pluck('key', hiddenTiles);
    const excludedTiles = R.map(item => ({
      key: item.key,
      label: item.custom_наименование,
      dish: item.key,
      byKindsFood: true,
      byItem: false,
      value: 2,
    }), hiddenTiles);
    const getIsProduct = R.contains('t4r');
    const getIsSupplement = R.contains('t157r');

    return R.compose(
      R.sort(R.comparator((a, b) => {
        if (a.byItem === b.byItem) {
          return a.label < b.label;
        }

        return a.byItem < b.byItem;
      })),
      R.concat(excludedTiles),
      R.reject(item => item.value === 1 && R.contains(item.custom_блюдо, excludedKeys)),
      R.map((item) => {
        const isProduct = getIsProduct(item.custom_блюдо);
        const isSupplement = getIsSupplement(item.custom_блюдо);
        let label = item.custom_блюдо

        if (isProduct) {
          label = R.pathOr(null, [item.custom_блюдо, 'custom_название'], meals)
        } else if (isSupplement) {
          label = R.pathOr(null, [item.custom_блюдо, 'custom_наименование'], supplements)
        } else {
          label = R.compose(
            R.propOr(null, 'custom_наименование'),
            R.find(R.propEq('key', item.custom_блюдо)),
          )(tiles)
        }

        return {
          key: item.key,
          label: item.custom_строка_поиска || label || item.custom_блюдо,
          dish: R.isEmpty(item.custom_список_блюд) ? [item.custom_блюдо] : item.custom_список_блюд,
          byItem: isProduct,
          value: item.custom_добавить_исключить,
        };
      }),
    )(items);
  },
);

import { attr, Model } from 'redux-orm';

import generator from 'redux/hor/orm-module';
import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,
  fetch,
} = generator({
  name: 'Vitamin',
  tableId: 6,
});

export default class Vitamin extends Model {
  static modelName = 'Vitamin';

  static reducer = reducer;

  static options = {
    idAttribute: 'key',
  }

  static fields = {
    custom_наименование: attr(),
    custom_рекомендации: attr(),
  }
}

export const fetchList = fetchWithView(fetch, 'ts5c6cs9r12', {
  useLS: true,
});

export {
  fetch,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'utils/react-redux';
import Helmet from 'react-helmet';

import { head } from 'config';
import withFetcher from 'decorators/withFetcher';

import { ping } from 'redux/modules/ping';
import { setParams } from 'redux/modules/routeParams';
import Modal from 'components/Modal';
import ServerUnderMaintenance from 'components/ServerUnderMaintenance';

const mapStateToProps = state => ({
  serverUnderMaintenance: state.ping.serverUnderMaintenance,
});

const mapDispatchToProps = {
  setParams,
};

class AppContainer extends PureComponent {
  static propTypes = {
    children: PropTypes.object.isRequired,
    setParams: PropTypes.func.isRequired,
    serverUnderMaintenance: PropTypes.bool,
    params: PropTypes.object.isRequired,
  };

  static defaultProps = {
    children: {},
    setParams: () => {},
    serverUnderMaintenance: false,
    router: {},
    params: {},
  };

  componentWillReceiveProps(nextProps) {
    this.props.setParams(nextProps.params);
  }

  renderServerMaintenance(serverUnderMaintenance) {
    return (
      <Modal
        ariaHideApp={false}
        contentLabel='Сервер в обслуживании'
        isOpen={serverUnderMaintenance}
      >
        <ServerUnderMaintenance />
      </Modal>
    );
  }

  render() {
    return (
      <div>
        <Helmet {...head} />
        {this.props.children}
        {this.renderServerMaintenance(this.props.serverUnderMaintenance)}
      </div>
    );
  }
}

export default R.compose(
  withFetcher([
    params => setParams(params),
    () => ping(),
  ]),
  connect(mapStateToProps, mapDispatchToProps),
)(AppContainer);

import { request } from 'utils/api';

import { appKey } from 'config';

export async function getVariable<T>(name: string): Promise<T> {
  const response = await request.post(`/${appKey}t155r/json/v2`, {
    parameters: {
      name,
    },
  }, {
    headers: {
      Function: 'getAppVariable',
    },
  })

  const { fields } = response.data;

  const data = fields.custom_значение || fields.custom_json_value as T;

  return data;
}
import generator from 'redux/hor/module';

import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,
  fetch,
} = generator({
  name: 'rationMode',
  tableId: 37,
});

export const fetchList = fetchWithView(fetch, 'ts5c37cs9r73', {
  useLS: true,
});

export {
  fetch,
};

export {
  getSelectValues,
} from './selectors';

export default reducer;

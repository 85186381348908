import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';

import styles from './Page.module.css';

const cx = classNames.bind(styles);

export default class Page extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    bgColor: PropTypes.string,
  }

  static defaultProps = {
    children: undefined,
    bgColor: undefined,
  }

  render() {
    const { bgColor } = this.props;

    return (
      <main
        className={cx({
          page: true,
          [`page_bg_${bgColor}`]: bgColor,
        })}
      >
        {this.props.children}
      </main>
    );
  }
}

import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import * as R from 'ramda';
import axios from 'axios';

import Icon from 'components/Icon';

import iconList from './icons';

import styles from './LoaderIcons.module.css';

const cx = classNames.bind(styles);

const baseIcons = [
  'beef',
  'carrot',
  'fish',
  'fruit',
  'soup',
];

export default class LoaderIcons extends PureComponent {
  state = {
    left: 0,
    right: 0,
    icons: [],
  }

  componentWillMount() {
    this.handleAnimation('left')();
    this.handleAnimation('right')();
  }

  componentDidMount() {
    const selected = R.compose(
      R.map(index => iconList[index]),
      R.map(() => this.getRandomIndex(iconList.length)),
      R.range,
    )(0, 10);

    this.loadIcons(selected);
  }

  handleAnimation = icon => () => {
    this.setState(({ left, right }) => {
      const current = [left, right];
      const list = this.getList();

      return {
        [icon]: this.getRandomIndex(list.length, current),
      };
    });
  }

  getRandomIndex(items, exclude = []) {
    const index = Math.round(Math.random() * (items - 1));

    if (R.contains(index, exclude) && items > 2) {
      return this.getRandomIndex(items, exclude);
    }

    return index;
  }

  getList() {
    const { icons } = this.state;

    return [...baseIcons, ...R.values(icons)];
  }

  loadIcons = async (icons) => {
    await icons.reduce(async (acc, item) => {
      await acc;

      return this.loadIcon(item);
    }, Promise.resolve());
  }

  loadIcon = async (src) => {
    const response = await axios.get(src);
    const icon = response.data;

    this.setState(state => ({
      icons: state.icons.concat(icon),
    }))
  }

  renderIcon(index) {
    const list = this.getList();
    const icon = list[index];

    return (
      <Icon
        icon={icon}
        iconContent={R.contains(icon, baseIcons) ? undefined : icon}
      />
    );
  }

  render() {
    const { left, right } = this.state;

    return (
      <div className={cx('icons')}>
        <div onAnimationIteration={this.handleAnimation('left')} className={cx('icon')}>
          {this.renderIcon(left)}
        </div>
        <div onAnimationIteration={this.handleAnimation('right')} className={cx('icon')}>
          {this.renderIcon(right)}
        </div>
      </div>
    );
  }
}

import * as R from 'ramda';
import { fk, attr, Model } from 'redux-orm';

import generator from 'redux/hor/orm-module';
import convertFromServerTimeZoneToCurrentTimeZone
  from 'utils/convertFromServerTimeZoneToCurrentTimeZone';

const {
  reducer,

  fetchSuccess,
  save,
} = generator({
  name: 'InstitutionSubscription',
  tableId: 115,
  parseItem: R.evolve({
    ___parent: R.propOr(null, 'key'),
    custom_действующий_тарифный_план: R.propOr(null, 'key'),
    custom_текущий_вариант_подписки: R.propOr(null, 'key'),
    custom_оплачено: R.propOr(null, 'key'),
    custom_дата_начала_подписки: convertFromServerTimeZoneToCurrentTimeZone,
    custom_время_окончания_подписки: convertFromServerTimeZoneToCurrentTimeZone,
  }),
});

export default class InstitutionSubscription extends Model {
  static modelName = 'InstitutionSubscription';

  static options = {
    idAttribute: 'key',
  }

  static fields = {
    custom_активных_пользователей: attr(),
    custom_время_окончания_подписки: attr(),
    custom_дата_начала_подписки: attr(),
    custom_действующий_тарифный_план: attr(), // fk
    custom_количество_пользователей: attr(),
    custom_оплачено_пользователей: attr(),
    custom_оплачено: fk('SubscriptionDay', 'futurePayments'),
    custom_текущий_вариант_подписки: fk('SubscriptionDay', 'payments'),
    custom_оплачено_дней: attr(),
    custom_остаток_бонусных_дней: attr(),
    custom_стоимость_текущей_подписки: attr(),
  }

  static reducer = reducer;
}

export {
  fetchSuccess,
  save,
};

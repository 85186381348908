import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getItemsArray } from 'redux/hor/module/selectors';

export const getPatientAllergen = createSelector(
  R.identity,
  (_, state) => getItemsArray(state),

  R.uncurryN(2, key => R.compose(
    R.pluck('custom_блюдо'),
    R.filter(R.propEq('custom_пациент', key)),
  )),
);

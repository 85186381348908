import * as R from 'ramda';

import { getItemsArrayByParent, getItemWithAllFields } from 'redux/hor/module/selectors';

export const getItems = R.compose(
  R.map(R.evolve({
    custom_текст_поиска: (value) => {
      if (!value) {
        return value;
      }

      const [first, ...rest] = value;


      return first.toUpperCase() + rest.join('').toLowerCase();
    },
  })),
  R.map(getItemWithAllFields),
  getItemsArrayByParent,
);

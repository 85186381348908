import * as R from 'ramda';
import { contextKey } from 'config';

import publicLogin from 'helpers/publicLogin';
import { request } from 'utils/api';

export default function getPublicToken(hasStrictDomain) {
  const { email, password, remember } = publicLogin;
  const rememberHeader = remember ? { Remember: 'true' } : {};

  return request.post('/authenticate/json/v2', undefined, {
    hasStrictDomain,
    auth: {
      username: email,
      password,
    },
    headers: {
      context: contextKey,
      ...rememberHeader,
    },
  }).then(R.path(['headers', 'token']));
}

function getPublicAuth() {
  const { email, password, remember } = publicLogin;
  const rememberHeader = remember ? { Remember: 'true' } : {};

  return request.post('/authenticate/json/v2', undefined, {
    auth: {
      username: email,
      password,
    },
    headers: {
      context: contextKey,
      ...rememberHeader,
    },
  }).then(res => res);
}

export {
  getPublicAuth,
  getPublicToken
}

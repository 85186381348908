import * as R from 'ramda';
import { createSelector } from 'reselect';

import getCatalogGroups from '../getCatalogGroups';

export default createSelector(
  getCatalogGroups,
  (state, props) => Number(props.groupId),

  (items, groupId) => R.find(R.propEq('id', groupId), items),
);

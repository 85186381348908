import { connect as connectSource } from 'react-redux';

export const connect = (mapStateToProps, mapDispatchToProps, mergeProps, options) => {
  return connectSource(mapStateToProps, mapDispatchToProps, mergeProps, {
    areStatesEqual: (next, prev) => {
      if (next.loading.isLoading && prev.loading.isLoading) {
        return true
      }

      return next === prev;
    },
    ...options,
  });
}

import * as R from 'ramda';
import { createSelector } from 'reselect';

import isLocalKey from 'utils/isLocalKey';
import getPatientId from '../getPatientId';
import getPatientsList from '../getPatientsList';
import getUserIsLight from '../getUserIsLight';
import getUserIsPartner from '../getUserIsPartner';
import { getDietanamnezIdWithotNotEnded } from 'redux/selectors';

const tooltip = 'Введите все регистрационные данные, обязательные для заполнения.';

const getProPatientMenu = createSelector(
  getPatientId,
  getPatientsList,
  getUserIsPartner,

  (patientId, list, isPartner) => {
    const newItem = R.find(R.propSatisfies(isLocalKey, 'key'), list);
    const isNew = patientId === 'new';
    const mainPage = {
      to: `/patients/${patientId}`,
      title: 'Регистрационные данные',
      onlyActiveOnIndex: true,
      visible: true,
    };
    const restPage = [
      {
        to: `/patients/${patientId}/diagnosis`,
        title: 'Диагноз',
        visible: !isPartner,
      },
      {
        to: `/patients/${patientId}/anthropometry`,
        title: 'Антропометрия и состав тела',
        visible: true,
      },
      {
        to: `/patients/${patientId}/energy`,
        title: 'Энергетический обмен',
        visible: true,
      },
      {
        to: `/patients/${patientId}/analyses`,
        title: 'Анализы',
        visible: !isPartner,
      },
      {
        to: `/patients/${patientId}/dietanamnez`,
        title: 'Диетанамнез',
        visible: !isPartner,
      },
      {
        to: `/patients/${patientId}/rations`,
        title: 'Рацион',
        visible: true,
      },
    ];

    return R.compose(
      R.filter(item => item.visible),
      R.concat([mainPage]),
      R.map(R.set(R.lensProp('disabled'), isNew)),
      R.map(R.set(R.lensProp('tooltip'), (isNew && !newItem) ? tooltip : null)),
      R.map(R.over(R.lensProp('to'), val => isNew ? '' : val)),
    )(restPage);
  },
);

const getLightPatientMenu = createSelector(
  getPatientId,
  getPatientsList,
  getDietanamnezIdWithotNotEnded,
  (patientId, list, dietanamnezId) => {
    const newItem = R.find(R.propSatisfies(isLocalKey, 'key'), list);
    const isNew = patientId === 'new';
    const mainPage = {
      to: `/patients/${patientId}`,
      label: 'Регистрация',
      onlyActiveOnIndex: true,
    };
    const restPage = [
      {
        to: `/patients/${patientId}/status`,
        label: 'Пищевой статус',
      },
      {
        to: `/patients/${patientId}/dietanamnez/${dietanamnezId || ''}`,
        label: 'Фактическое питание',
      },
      {
        to: `/patients/${patientId}/rations`,
        label: 'Оптимизация питания',
      },
    ];

    return R.compose(
      R.concat([mainPage]),
      R.map(R.set(R.lensProp('disabled'), isNew)),
      R.map(R.set(R.lensProp('tooltip'), (isNew && !newItem) ? tooltip : null)),
      R.map(R.over(R.lensProp('to'), val => isNew ? null : val)),
    )(restPage);
  },
);

export default createSelector(
  getUserIsLight,
  getProPatientMenu,
  getLightPatientMenu,

  (isLight, proMenu, lightMenu) => isLight ? lightMenu : proMenu,
);

import * as R from 'ramda';
import { createSelector } from 'reselect';

import {
  getSpecificConditions,
} from 'modules/Patient/redux/disease';

export default createSelector(
  R.path(['Patient', 'disease']),

  getSpecificConditions,
);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import ReactModal from 'react-modal';

import styles from './Modal.module.css';

const cx = classNames.bind(styles);

export default class Modal extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    isOpen: PropTypes.bool, // eslint-disable-line
    withClose: PropTypes.bool,
    onAfterClose: PropTypes.func,
    onRequestClose: PropTypes.func,
  };

  static defaultProps = {
    children: undefined,
    isOpen: false,
    withClose: false,
    onAfterClose: () => {},
    onRequestClose: () => {},
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isOpen && !nextProps.isOpen) {
      setTimeout(() => {
        this.props.onAfterClose();
      }, 350);
    }
  }

  render() {
    const { onAfterClose, ...props } = this.props

    return (
      <div>
        <ReactModal
          {...props}
          overlayClassName={cx('modal')}
          className={cx('content')}
          closeTimeoutMS={350}
          shouldCloseOnOverlayClick={false}
        >
          {this.props.withClose &&
            <button
              type='button'
              onClick={this.props.onRequestClose}
              className={cx('close')}
            />
          }

          {this.props.children}
        </ReactModal>
      </div>
    );
  }
}

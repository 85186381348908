import generator from 'redux/hor/module';

const {
  reducer,
  fetch,
} = generator({
  name: 'UserNotifications',
  tableId: 141,
});

export {
  fetch,
};

export {
  getItems,
} from './selectors';

export default reducer;

import generator from 'redux/hor/module';
import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,
  fetch,
} = generator({
  name: 'SupplementGroups',
  tableId: 159,
});

export const fetchList = fetchWithView(fetch, 'ts5c159cs9r567');

export {
  getKeyById,
  getItemById,
  getItem,
} from 'redux/hor/module/selectors';

export {
  getCatalogGroups,
  getSelectData,
} from './selectors';

export default reducer;

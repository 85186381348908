import * as R from 'ramda';
import createCachedOrmSelector from 'redux/utils/createCachedOrmSelector';
import createOrmSelector from 'redux/utils/createOrmSelector';

import { appKey } from 'config';

import getUserIsLight from '../../selectors/getUserIsLight';

export const getElementsList = createOrmSelector(
  getUserIsLight,

  (session, isLight) => {
    const { Elements } = session;
    const filter = item => {
      return isLight ? item.custom_light : item.custom_used_in_pro
    };

    return Elements
      .filter(filter)
      .toRefArray()
      .concat(isLight ? {
        key: `${appKey}t28r0`,
        ___parent: null,
        custom_для_сортировки: 15,
      } : [])
      .map((item) => {

        if (!isLight) {
          return item;
        }

        if (item.key === `${appKey}t28r51554960` || item.key === `${appKey}t28r51554961`) {
          return R.compose(
            R.assoc('___parent', `${appKey}t28r3620`),
            R.assoc('custom_для_сортировки', 25),
          )(item);
        }

        if (item.key === `${appKey}t28r2038859`) {
          return R.compose(
            R.assoc('___parent', `${appKey}t28r3620`),
            R.assoc('custom_для_сортировки', 35),
          )(item);
        }

        if (item.key === `${appKey}t28r225`) {
          return R.compose(
            R.assoc('___parent', `${appKey}t28r3620`),
            R.assoc('custom_для_сортировки', 50),
          )(item);
        }

        if (item.key === `${appKey}t28r236`) {
          return R.compose(
            R.assoc('___parent', `${appKey}t28r3620`),
            R.assoc('custom_для_сортировки', 60),
          )(item);
        }

        const needChangeParent = R.propSatisfies(
          value => R.includes(value, [`${appKey}t28r221`, `${appKey}t28r222`, `${appKey}t28r223`]),
          '___parent',
          item,
        );

        if (needChangeParent) {
          const indexes = {
            [`${appKey}t28r12493202`]: 1,
            [`${appKey}t28r12493207`]: 2,
            [`${appKey}t28r12523083`]: 3,
            [`${appKey}t28r12497601`]: 4,
            [`${appKey}t28r12497603`]: 5,
            [`${appKey}t28r12523027`]: 6,
            [`${appKey}t28r12520074`]: 7,
            [`${appKey}t28r3582`]: 10,
            [`${appKey}t28r3583`]: 20,
            [`${appKey}t28r2038901`]: 25,
            [`${appKey}t28r228`]: 30,
            [`${appKey}t28r231`]: 40,
            [`${appKey}t28r233`]: 50,
            [`${appKey}t28r11374694`]: 60,
          };

          return R.compose(
            R.assoc('custom_для_сортировки', indexes[item.key]),
            R.assoc('___parent', `${appKey}t28r0`),
          )(item);
        }

        return item;
      });
  },
);

export const getValues = createCachedOrmSelector(
  (state, props) => props.chartId || 'common',
  (state, props) => props.data,
  (state, props) => props.includeFieldName,
  (state, props) => props.useShortName,
  getElementsList,
  getUserIsLight,

  (session, chartId, data = {}, includeFieldName, useShortName, items, isLight) => {
    const groups = R.filter(R.propEq('___parent', null), items);

    const parseItems = (level, i) => R.compose(
      R.unnest,
      R.map((item) => {
        const subitems = R.filter(R.propEq('___parent', item.key), items);
        const parsedSubitems = parseItems(level + 1, subitems);
        const label = useShortName ?
          item.custom_сокращенное_наименование || item.custom_наименование :
          item.custom_наименование;

        const value = {
          ...(data || {})[item.key],
          element: item.key,
          label,
          size: R.prop('label', item.custom_ед_изм),
          nameField: includeFieldName ? item.key : undefined,
          sources: item.custom_источники,
          influence: item.custom_влияние,
        };

        if (level === 2 && isLight) {
          return [
            value,
            ...parsedSubitems,
          ];
        }

        return {
          ...value,
          subitems: parsedSubitems,
        };
      }),
      R.sortBy(R.prop('custom_для_сортировки')),
    )(i);

    return R.compose(
      R.reject(item => R.isEmpty(item.subitems)),
      parseItems
    )(1, groups);
  },
);

export const getVisibleList = createOrmSelector(
  getElementsList,

  (session, items) => {
    const groups = R.compose(
      R.sortBy(R.prop('custom_для_сортировки')),
      R.filter(R.propEq('___parent', null)),
    )(items);

    const result = R.compose(
      R.unnest,
      R.unnest,
      R.map(item => {
        return R.compose(
          R.map(i => [i, ...R.filter(R.propEq('___parent', i.key), items)]),
          R.filter(R.propEq('___parent', item.key))
        )(items)
      }),
    )(groups);

    return result
  },
);

export const getMineralsAndVitamins = createOrmSelector(
  (session) => {
    const { Elements } = session;

    const items = Elements
      .filter({
        ___parent: null,
      })
      .filter((item) => {
        const name = item.custom_наименование;

        return R.contains(name, ['Минералы', 'Витамины']);
      })
      .toModelArray()
      .reduce((m, i) => {
        const val = R.map(j => ({
          value: j.key,
          label: j.custom_наименование,
        }), i.items.toRefArray() || []);

        m[i.custom_наименование] = val; // eslint-disable-line no-param-reassign

        return m;
      }, {});

    return items;
  },
);

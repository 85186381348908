import createCachedOrmSelector from 'redux/utils/createCachedOrmSelector';
import memoizeByResult from 'utils/memoizeByResult';

import getDayKey from '../getDayKey';

export default createCachedOrmSelector(
  getDayKey,
  (state, props) => props.groupKey,

  memoizeByResult(
    (session, dayKey, key) => {
      const { Day } = session;

      return Day
        .withId(dayKey)
        .groups[key]
        .группы_альтернатив
    },
    (_, dayKey, key) => `${dayKey}_${key}`,
  ),
);

import * as R from 'ramda';
import memoizee from 'memoizee';

import { getItemsArray } from 'redux/hor/module/selectors';

export const getItemsByCountry = memoizee(
  (city, state) => R.compose(
    R.map(item => ({
      label: item.custom_название,
      value: item.key,
    })),
    R.filter(R.propEq('custom_страна', city)),
    getItemsArray,
  )(state),
  { max: 1 },
);

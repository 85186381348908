import * as R from 'ramda';
import { createAction } from 'redux-act';
import { attr, fk, Model } from 'redux-orm';

import { appKey } from 'config';

import getKeyInfo from 'utils/getKeyInfo';

import generator from 'redux/hor/orm-module';
import fetchWithView from 'redux/utils/fetchWithView';
import convertFromServerTimeZoneToCurrentTimeZone from 'utils/convertFromServerTimeZoneToCurrentTimeZone';

const {
  reducer,
  fetch,
  fetchSuccess,
  create,
  save,
  saveSuccess,
  remove,
  removeWithUndo,
} = generator({
  name: 'Dietanamnezes',
  tableId: 140,
  parseItem: R.evolve({
    ___parent: R.propOr(null, 'key'),
    custom_специалист: R.propOr(null, 'key'),
    custom_тип_да: R.propOr(null, 'value'),
    custom_специфические_виды_питания: R.compose(
      R.pluck('key'),
      R.defaultTo([]),
    ),
    custom_профиль_потребления_веществ: R.compose(
      R.map(R.evolve({
        элемент_состава: R.replace('cXaX', appKey)
      })),
      R.defaultTo([]),
    ),
    custom_профиль_потребления_продуктов: R.compose(
      R.map(R.evolve({
        продукт: R.replace('cXaX', appKey)
      })),
      R.defaultTo([]),
    ),
    custom_частота_потребления_групп_продуктов: R.compose(
      R.map(R.evolve({
        группа_продуктов: R.replace('cXaX', appKey)
      })),
      R.defaultTo([]),
    ),
    custom_дата_назначения: convertFromServerTimeZoneToCurrentTimeZone,
  }),
});

export default class Dietanamnez extends Model {
  static modelName = 'Dietanamnez';

  static reducer = reducer;

  static options = {
    idAttribute: 'key',
  }

  static fields = {
    ___parent: attr(),
    custom_специалист: fk('Worker', 'dietanamnez'),
    custom_фио_специалиста: attr(),
    custom_специфические_виды_питания: attr(),
    custom_дата_назначения: attr(),
    custom_диетанамнез_заключение: attr(),
    custom_заключение_недостаток: attr(),
    custom_заключение_избыток: attr(),
    custom_селфтест_до: attr(),
    custom_тип_да: attr(),
    custom_профиль_потребления_веществ: attr(),
    custom_профиль_потребления_продуктов: attr(),
    custom_частота_потребления_групп_продуктов: attr(),
  }

  get id() {
    return getKeyInfo(this.key).record;
  }
}

export const fetchForm = fetchWithView(fetch, 'with_staff_name');
export const fetchReport = fetchWithView(fetch, 'report');
export const calcDietanamnezReport = createAction('DIETANAMNEZ__CALC_REPORT');
export const assign = createAction('DIETANAMNEZ__ASSIGN');
export const assignSuccess = createAction('DIETANAMNEZ__ASSIGN_SUCCESS');
export const switchType = createAction('DIETANAMNEZ__SWITCH_TYPE');

export {
  fetchSuccess,
  create,
  save,
  saveSuccess,
  remove,
  removeWithUndo,
};

import { createSelector } from 'reselect';

import getPatientKey from '../getPatientKey';
import getAbstractNorm from '../getAbstractNorm';
import getPatientNorm from '../getPatientNorm';

export default createSelector(
  getPatientKey,
  getAbstractNorm,
  getPatientNorm,

  (patientKey, abstractNorm, patientNorm) => patientKey ? patientNorm : abstractNorm,
);

import generator from 'redux/hor/module';
import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,
  fetch,
} = generator({
  name: 'specificEatingTypes',
  tableId: 18,
});

export const fetchList = fetchWithView(fetch, 'ts5c18cs9r356', {
  useLS: true,
});

export {
  getItem,
} from 'redux/hor/module/selectors';

export {
  getSelectData,
  getItems,
} from './selectors';

export default reducer;

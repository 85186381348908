import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import Link from 'components/Link';
import Text from 'components/Text';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import styles from './More.module.css';

const cx = classNames.bind(styles);

const OPEN_MAX_HEIGHT = 2000;
const TRANSITION_DELAY = 300;

export const shouldShowButton = self =>
  (!self.textNode || self.textNode.scrollHeight > self.textNode.offsetHeight);

export default class More extends PureComponent {
  static propTypes = {
    backgroundColor: PropTypes.oneOf([
      'dayWhite',
    ]),
    lineHeight: PropTypes.number,
    lines: PropTypes.number,
    text: PropTypes.string,
  }

  static defaultProps = {
    backgroundColor: undefined,
    lineHeight: 19.6,
    lines: 3,
    text: '',
  }

  state = {
    open: false,
    showButton: false,
  }

  componentDidMount() {
    window.addEventListener('resize', this.setShowButtonDelayed);
    this.setShowButton();
  }

  componentDidUpdate() {
    this.setShowButtonDelayed();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setShowButtonDelayed);
  }

  handleClick = () => {
    if (this.state.open) {
      this.setState({
        open: false,
      });
    } else {
      this.setState({
        open: true,
      });
    }
  }

  setShowButton = () => {
    this.setState({
      showButton: shouldShowButton(this),
    });
  }

  setShowButtonDelayed = () => {
    if (!this.state.open) {
      setTimeout(this.setShowButton, TRANSITION_DELAY);
    }
  }

  setNode = (textNode) => {
    this.textNode = textNode;
  };

  render() {
    const buttonText = !this.state.open ? 'Развернуть' : 'Свернуть';
    const {
      backgroundColor,
      lines,
      lineHeight,
    } = this.props;
    const hasBackground = !R.isNil(backgroundColor);
    const showButton = this.props.text.length > 0 && this.state.showButton;
    const height = lines * lineHeight;

    return (<div
      className={cx('more')}
    >
      <div
        ref={this.setNode}
        className={cx({
          text: true,
          text_open: this.state.open,
          [`text_${backgroundColor}`]: hasBackground,
        })}
        style={{
          maxHeight: !this.state.open ? height : OPEN_MAX_HEIGHT,
        }}
      >
        <Text color='gray'>
          {this.props.text}
        </Text>
      </div>
      <div
        className={cx({
          button: true,
          button_open: this.state.open,
          [`button_${backgroundColor}`]: hasBackground,
        })}
      >
        {showButton &&
          <Link
            block
            color='blue'
            onClick={this.handleClick}
          >
            {buttonText}
          </Link>
        }
      </div>
    </div>);
  }
}

import * as R from 'ramda';
import { appKey } from 'config';

import { getItemsArrayByParent } from 'redux/hor/module/selectors';

const firstIngestionKey = `${appKey}t36r1495`;

export const getFirstIngestionTime = (key, state) => {
  const items = getItemsArrayByParent(key, state);

  const time = R.compose(
    R.propOr('', 'custom_время'),
    R.find(R.propEq('custom_схема_питания', firstIngestionKey)),
  )(items);

  return time;
};

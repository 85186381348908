import * as R from 'ramda';
import { createSelector } from 'reselect';
import moment from 'moment';

import declOfNums from 'utils/declOfNums';

import { ReactComponent as Support } from 'icons/support.svg';
import { ReactComponent as Time } from 'icons/time.svg';
import { ReactComponent as Folder } from 'icons/folder.svg';
import { ReactComponent as Calendar } from 'icons/calendar.svg';
import { ReactComponent as User } from 'icons/user.svg';
import { ReactComponent as Location } from 'icons/location.svg';
import { ReactComponent as Personal } from 'icons/personal.svg';
import { ReactComponent as Camera } from 'icons/v-camera.svg';
import { ReactComponent as Link } from 'icons/link.svg';
import { ReactComponent as Export } from 'icons/export.svg';

import getUserIsLight from '../getUserIsLight';
import getUserIsPartner from '../getUserIsPartner';
import getInstitutionTariffName from '../getInstitutionTariffName';
import getInstitutionSubscription from '../getInstitutionSubscription';
import getInstitutionSubscriptionEnd from '../getInstitutionSubscriptionEnd';
import getInstitutionSubscriptionDurationTotal from '../getInstitutionSubscriptionDurationTotal';
import getUserIsSupport from '../getUserIsSupport';
import getPartner from '../getPartner';
import getSubscriptionUrl from '../getSubscriptionUrl';
import { getUserRight } from '../getUserRights';

const getHour = declOfNums(['час', 'часа', 'часов']);

export default createSelector(
  getUserIsLight,
  getUserIsPartner,
  getInstitutionTariffName,
  getInstitutionSubscription,
  getInstitutionSubscriptionEnd,
  getInstitutionSubscriptionDurationTotal,
  getUserIsSupport,
  state => getUserRight('INSTITUTION_ADMINISTER', state),
  getPartner,
  state => process.env.REACT_APP_API === 'dd' ? {} : getSubscriptionUrl(state),

  (
    isLight,
    isPartner,
    tariffName,
    subscription,
    end,
    duration,
    isSupport,
    canEditSubscription,
    partner,
    subscriptionUrl,
  ) => {
    const { isCurrentSubscriptionOneDay } = subscription;
    const daysDuration = duration >= 0 ? `${tariffName}, еще ${duration} дней` : null;
    const dailyHourDuration = Math.ceil(moment(end).diff(moment(), 'hours', true));
    const hourDuration = `Осталось ${dailyHourDuration} ${getHour(dailyHourDuration)}`;
    const daysError = duration < 0 ? 'Требует продления' : null;

    const items = [
      {
        ...subscriptionUrl,
        disabled: partner && !subscriptionUrl,
        title: 'Подписка',
        subtitle: partner ? null : isCurrentSubscriptionOneDay ? hourDuration : daysDuration,
        error: partner ? null : isCurrentSubscriptionOneDay ? null : daysError,
        inMenu: canEditSubscription,
      },
      {
        icon: Camera,
        to: '/webinars',
        title: 'Онлайн-обучение',
        inMenu: isLight,
      },
      {
        icon: Support,
        to: '/user/support',
        title: 'Поддержка',
        inMenu: isSupport,
      },
      {
        icon: Link,
        to: '/user/links',
        title: 'Ссылка саморегистрации',
        inMenu: !isPartner,
      },
      {
        icon: Time,
        to: '/user/journal',
        title: 'Журнал действий',
        inMenu: !isLight,
      },
      {
        icon: Folder,
        to: '/statistic',
        title: 'Группа',
        inMenu: isLight,
      },
      {
        icon: Calendar,
        to: '/user/calendar',
        title: 'План приемов',
        inMenu: !isLight,
      },
      {
        icon: Export,
        to: '/user/export',
        title: 'Экспорт контактов пациентов',
        inMenu: !isLight,
      },
      {
        icon: User,
        to: '/user/profile',
        title: 'Профиль',
      },
      {
        icon: Location,
        to: '/user/institution',
        title: 'Учреждение',
        inMenu: !isLight && !isPartner,
      },
      {
        icon: Personal,
        to: '/user/staff',
        title: 'Персонал',
        inMenu: !isLight && !isPartner,
      },
    ];

    return R.reject(R.propEq('inMenu', false), items);
  },
);

import { all, takeEvery, call, put } from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';

import { appKey } from 'config';

import { request } from 'utils/api';

import {
  fetchItem
} from 'redux/modules/patients'

import {
  loadId,
  loadIdSuccess,
  loadIdFailure,
  loadInbodyReport,
  loadInbodyReportSuccess,
  loadInbodyReportFailure,
} from './index';

function* loadInbodyReportFlow({ payload }) {
  const key = payload.key
  const inbodyid = payload.inbodyid

  try {
    if (payload.form) {
      yield put(startSubmit(payload.form));
    }

    const response = yield call(request.post, `/${appKey}t179r/json/v2`, {
      parameters: {
        patient_key: key,
        inbodyid,
      },
    }, {
      headers: {
        Function: 'getInbodyMetering',
      }
    })

    if (response.data.error) {
      if (payload.form) {
        yield put(stopSubmit(payload.form, {
          id: 'Отчет с таким InBodyId не найден. Проверьте номер и повторите попытку.',
        }))
      }
      yield put(loadInbodyReportFailure())
      return
    }

    yield put(fetchItem({
      key,
    }))

    yield put(loadInbodyReportSuccess({
      ...response.data.data,
    }))
    yield put(stopSubmit(payload.form))
  } catch (error) {
    yield put(loadInbodyReportFailure(error))
    yield put(stopSubmit(payload.form, {
      id: 'Отчет с таким InBodyId не найден. Проверьте номер и повторите попытку.',
    }))
  }
}


function* loadIdFlow(action) {
  const key = action.payload.key

  try {
    const response = yield call(request.post, `/${appKey}t179r/json/v2`, {
      parameters: {
        patient_key: key,
      },
    }, {
      headers: {
        Function: 'getInbodyID',
      }
    })

    const id = response.data.data.inbodyid

    yield put(loadIdSuccess(id))
  } catch (error) {
    yield put(loadIdFailure({
      ...action.payload,
      error,
    }))
  }
}

export default function* saga() {
  yield all([
    takeEvery(loadId, loadIdFlow),
    takeEvery(loadInbodyReport, loadInbodyReportFlow),
  ]);
}

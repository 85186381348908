import * as R from 'ramda';

import { getItemsArray } from 'redux/hor/module/selectors';

export const getItems = R.compose(
  R.map(item => ({
    label: item.custom_название,
    value: item.key,
  })),
  getItemsArray,
);

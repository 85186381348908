import { createSelector } from "reselect";
import * as R from "ramda";

import getUser from "../getUser";

export default createSelector(
  getUser,

  R.propOr("c105a902t104r5087852", "custom_страна")
);

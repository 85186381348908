import * as R from "ramda";
import { createAction } from "redux-act";
import moment from "moment";
import { attr, Model } from "redux-orm";
import fetchWithView from "redux/utils/fetchWithView";

import generator from "redux/hor/orm-module";

const { reducer, fetch, fetchSuccess, create, save, saveSuccess } = generator({
  name: "staff",
  tableId: 47,
  parseItem: R.evolve({
    ___territory: R.propOr(null, "key"),
    custom_состояние: R.propOr(null, "value"),
    custom_sexint: R.propOr(null, "value"),
    custom_role: R.propOr(null, "key"),
    custom_user: R.propOr(null, "key"),
    custom_страна: R.propOr(null, "key"),
    custom_город: R.propOr(null, "key"),
    custom_профиль: R.propOr(null, "key"),
    custom_партнер: R.propOr(null, "key"),
    custom_производители_бад: R.compose(R.pluck("key"), R.defaultTo([])),
    custom_dob: (date) => (date ? moment(date) : undefined),
  }),
});

export default class Worker extends Model {
  static modelName = "Worker";

  static options = {
    idAttribute: "key",
  };

  static fields = {
    ___createddate: attr(),
    ___territory: attr(),
    custom_состояние: attr(),
    custom_role: attr(),
    custom_user: attr(),
    custom_страна: attr(),
    custom_город: attr(),
    custom_dob: attr(),
    custom_firstname: attr(),
    custom_is_support: attr(),
    custom_lastname: attr(),
    custom_login: attr(),
    custom_midname: attr(),
    custom_sexint: attr(),
    custom_должность: attr(),
    custom_разрешен_вход_по_пользователем_до: attr(),
    custom_ред_сис_шаблонов: attr(),
    custom_редактирование_общих_блюд: attr(),
    custom_фото: attr(),
    custom_ключ_системного_пользователяold: attr(),
    custom_избранный: attr(),
    custom_хеш_пользователя: attr(),
    custom_мобильный_телефон: attr(),
    custom_verify_phone: attr(),
    custom_производители_бад: attr(),
    custom_профиль: attr(),
    custom_партнер: attr(),
    custom_inbody_user: attr(),
    custom_номер_нпа: attr(),
    custom_состоит_в_группе: attr(),
  };

  static reducer = reducer;

  get fullname() {
    return R.compose(
      R.join(" "),
      R.values,
      R.pick(["custom_firstname", "custom_midname", "custom_lastname"])
    )(this);
  }

  toString() {
    return `Worker: ${this.key}`;
  }
}

export const changePassword = createAction("STAFF__CHANGE_PASSWORD");
export const invite = createAction("STAFF__INVITE");
export const inviteLight = createAction("STAFF__INVITE_GRP");
export const saveProfile = createAction("STAFF__SAVE_PROFILE");
export const fetchMe = fetchWithView(fetch, "ts5c47cs9r173");
export const allowLogin = createAction("STAFF__ALLOW_LOGIN");
export const setRole = createAction("STAFF__CHANGE_ROLE");
export const setStatus = createAction("STAFF__SET_STATUS");
export const getMobilePhoneStatus = createAction("STAFF__GET_MOBILE_PHONE");

export { fetch, fetchSuccess, save, saveSuccess, create };

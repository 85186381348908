import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';

import Title from 'components/Title';
import Text from 'components/Text';
import Button from 'components/Button';
import Error from 'components/Error';
import Info from 'components/Info';

import styles from './ModalForm.module.css';

const cx = classNames.bind(styles);

export default class ModalForm extends PureComponent {
  state = {
    type: 'main'
  }
  static propTypes = {
    align: PropTypes.oneOf([
      'center',
      'right',
    ]),
    cancelBtn: PropTypes.string,
    children: PropTypes.node,
    deleteBtn: PropTypes.string,
    error: PropTypes.string,
    submitBtn: PropTypes.string,
    additionSubmitBtn: PropTypes.string,
    submitting: PropTypes.bool,
    theme: PropTypes.string,
    title: PropTypes.node,
    valid: PropTypes.bool,
    validDelete: PropTypes.bool,
    size: PropTypes.oneOf([
      'small',
      'wide',
    ]),
    noPadding: PropTypes.bool,
    onCancel: PropTypes.func,
    changeType: PropTypes.func,
    onDelete: PropTypes.func,
    onSubmit: PropTypes.func,
    deleteTooltip: PropTypes.string,
    submitInvalidTooltip: PropTypes.string,
  }

  static defaultProps = {
    cancelBtn: 'Отменить',
    children: undefined,
    deleteBtn: undefined,
    error: undefined,
    submitBtn: 'Продолжить',
    additionSubmitBtn: null,
    submitting: false,
    theme: undefined,
    title: undefined,
    valid: false,
    validDelete: true,
    size: undefined,
    noPadding: false,
    changeType: () => {},
    onCancel: () => { },
    onDelete: () => { },
    onSubmit: () => { },
    deleteTooltip: null,
    submitInvalidTooltip: null,
  }

  renderAdditionalSubmitBtn = () => {
    const {
      valid,
      submitting,
      theme,
      additionSubmitBtn,
      changeType
    } = this.props;
    const handleOnClick = () => {
      changeType('additional');
    }
    return (
      <Button
        upper={this.props.align === 'center' || this.props.align === 'right'}
        onClick={handleOnClick}
        type='submit'
        block
        theme={theme}
        disabled={!valid || submitting}
        isLoading={submitting}
      >
        {additionSubmitBtn}
      </Button>
    );
  }

  renderSubmitBtn = () => {
    const {
      submitBtn,
      valid,
      submitting,
      theme,
      submitInvalidTooltip
    } = this.props;

    const button = (
      <Button
        upper={this.props.align === 'center' || this.props.align === 'right'}
        type='submit'
        block
        theme={theme}
        disabled={!valid || submitting}
        isLoading={submitting}
      >
        {submitBtn}
      </Button>
    );

    if (!submitInvalidTooltip || valid) {
      return button;
    }

    return (
      <Info toggler={button} alwaysTop width={330}>
        <Text>
          {submitInvalidTooltip}
        </Text>
      </Info>
    )
  }

  renderDeleteBnt = () => {
    const {
      deleteBtn,
      valid,
      validDelete,
      deleteTooltip,
      submitting,
      theme,
      onDelete,
    } = this.props;

    const button = (
      <Button
        block
        theme={theme}
        disabled={!valid || submitting || !validDelete}
        isLoading={submitting}
        onClick={onDelete}
      >
        {deleteBtn}
      </Button>
    );

    if (!deleteTooltip) {
      return button;
    }

    return (
      <Info toggler={button} togglerFullSize>
        <Text>
          {this.props.deleteTooltip}
        </Text>
      </Info>
    );
  }

  render() {
    const {
      title,
      children,
      cancelBtn,
      deleteBtn,
      theme,
      onSubmit,
      onCancel,
      error,
      align,
      size,
      noPadding,
    } = this.props;

    return (
      <form
        onSubmit={onSubmit}
        className={cx({
          modalForm: true,
          modalForm_empty: !children && !error,
          [`modalForm_size_${size}`]: size,
          [`modalForm_align_${align}`]: align,
        })}
      >
        <div className={cx({
          body: true,
          body_no_padding: noPadding,
        })}
        >
          {title && (
            <header className={cx('header')}>
              {typeof title === 'string' ? (
                <Title size='medium'>
                  {title}
                </Title>
              ) : (
                  title
                )}
            </header>
          )}
          {children && React.Children.map(children, child => child && (
            <div className={cx('row')}>
              {child}
            </div>
          ))}
        </div>

        {error &&
          <div className={cx('error')}>
            <Error error={error} />
          </div>
        }

        {(this.props.submitBtn || this.props.cancelBtn) &&
          <footer className={cx('footer')}>
            <div className={cx('footerInner')}>
              {
                this.props.additionSubmitBtn &&
                <div className={cx('button', 'button__with-margin')}>
                  {this.renderAdditionalSubmitBtn()}
                </div>
              }
              {this.props.submitBtn &&
                <div className={cx('button')}>
                  {this.renderSubmitBtn()}
                </div>
              }
              {deleteBtn &&
                <div className={cx('button')}>
                  {this.renderDeleteBnt()}
                </div>
              }
              {this.props.cancelBtn &&
                <div className={cx('button')}>
                  <Button
                    upper={this.props.align === 'center' || this.props.align === 'right'}
                    onClick={onCancel}
                    block
                    theme={theme}
                    bordered
                  >
                    {cancelBtn}
                  </Button>
                </div>
              }
            </div>
          </footer>
        }
      </form>
    );
  }
}

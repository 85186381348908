import * as R from 'ramda';
import { createSelector } from 'reselect';

import getTariffReal from '../getTariffReal';

export default createSelector(
  getTariffReal,

  R.prop('custom_спецтариф'),
);

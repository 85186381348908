import { createSelector } from 'reselect';
import * as R from 'ramda';

import getUserCanEditSystemTemplates from 'redux/selectors/getUserCanEditSystemTemplates';

import getRation from '../getRation';

const getSourceRation = createSelector(
  getRation,
  state => state,

  (ration, state) => {
    if (!ration || !ration.custom_debuginfo) {
      return null;
    }

    return getRation(state, {
      rationKey: ration.custom_debuginfo,
    });
  },
);

export default createSelector(
  getSourceRation,
  getUserCanEditSystemTemplates,

  (sourceRation, isChosen) => {
    if (!isChosen || !sourceRation) {
      return null;
    }

    return R.propOr(null, 'custom_название', sourceRation);
  },
);

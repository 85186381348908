import { createSelector } from 'reselect';

import getKeyInfo from 'utils/getKeyInfo';

import getPatientRationNewKey from '../getPatientRationNewKey';

export default createSelector(
  getPatientRationNewKey,

  (key) => {
    if (!key) {
      return null;
    }

    return getKeyInfo(key).record;
  },
);

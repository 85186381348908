import * as R from 'ramda';
import { put } from 'redux-saga/effects';

import getKeyInfo from 'utils/getKeyInfo';
import transformVirtualCategory from '../transformVirtualCategory';

const tableIdByLabel = {
  'Блюда-аллергены пациента': 99,
};

export default function getSubitemsEffect(response, subitems = {}) {
  return R.compose(
    R.reject(R.isNil),
    R.values,
    R.mapObjIndexed((group, id) => {
      const action = subitems[id];

      if (!action) {
        return null;
      }

      if (typeof action !== 'function') {
        throw new Error('Subitems config should contain function');
      }

      const parents = R.map(R.path(['fields', '___parent', 'key']), group.list);

      return put(action({
        response: group,
        parents: R.compose(
          R.reject(R.isNil),
          R.uniq,
        )([...parents, response.key]),
      }));
    }),
    R.reduce((acc, group) => {
      R.forEach((transformedGroup) => {
        const key = R.pathOr(null, ['list', 0, 'key'], transformedGroup);
        const table = key ? getKeyInfo(key).table : tableIdByLabel[group.label];

        if (!acc[table]) {
          acc[table] = transformedGroup; // eslint-disable-line no-param-reassign
          return;
        }

        acc[table] = { // eslint-disable-line no-param-reassign
          ...acc[table],
          list: [
            ...acc[table].list,
            ...transformedGroup.list,
          ],
        };
      }, transformVirtualCategory(group));

      return acc;
    }, {}),
    R.pathOr([], ['related', 'list']),
  )(response);
}

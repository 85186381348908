import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import * as R from 'ramda';

import BarChart from './Barchart';

import {
  getGroupedChart,
  getStandartChart,
  getWithTwoBoundariesOfNorm,
  getOnePercent,
  getColorStatus,
} from './helpers';

import OneRect from './chemical/OneRect';
import HighertLimit from './chemical/HighertLimit';
import NormRangeLine from './chemical/NormRangeLine';
import VerticalLine from './chemical/VerticalLine';

const propsByType = {
  chemical: {
    OneRect,
    HighertLimit,
    NormRangeLine,
    VerticalLine,
  },
};

class BarChartDefault extends PureComponent {
  static propTypes = {
    type: PropTypes.string,
    data: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.array,
    ]),
    norm: PropTypes.number,
    normMin: PropTypes.number,
    normMax: PropTypes.number,
    width: PropTypes.number,
    color: PropTypes.string,
  };

  static defaultProps = {
    width: 0,
    height: 0,
    multiplier: 1,
  };

  getPercent = () => {
    const { data, normMin, normMax } = this.props;
    const sum = Array.isArray(data) ? R.sum(data || [0]) : data;

    if (sum < normMin) {
      return Math.floor(((sum - normMin) * (100 / normMin)));
    } else if (sum > normMax) {
      return Math.ceil(sum * (100 / normMax));
    }
    return 100;
  }

  getDataCenterChart() {
    return this.props.norm || R.sum([].concat(this.props.data));
  }

  getChemicalDataCenterChart() {
    const { normMin, normMax } = this.props;

    if (!normMin && !normMax) {
      return 50;
    }

    return (getOnePercent(normMin, normMax) || 0) * 50;
  }

  getData() {
    if (this.props.type === 'chemical') {
      const { data, normMin, normMax, width } = this.props;

      const dataInCenter = this.getChemicalDataCenterChart();
      const dataChart = getWithTwoBoundariesOfNorm(data, normMin, normMax, width);
      const valueInPercent = this.getPercent();
      const gradientColor = getColorStatus(valueInPercent, this.props.color);
      return {
        dataInCenter,
        dataChart,
        gradientColor,
      };
    }

    const { data } = this.props;
    const dataInCenter = this.getDataCenterChart();
    const isGrouped = Array.isArray(data);
    const dataChart = isGrouped ? getGroupedChart(data) : getStandartChart(data);

    return {
      dataInCenter,
      isGrouped,
      dataChart,
    };
  }

  render() {
    const { type } = this.props;
    return (
      <BarChart
        {...propsByType[type]}
        {...this.props}
        {...this.getData()}
      />
    );
  }
}


export default BarChartDefault;

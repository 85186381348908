import * as R from "ramda";
import memoizee from "memoizee";

import { superuserRoleKey } from "helpers/constants";

import createOrmSelector from "redux/utils/createOrmSelector";

import constructFile from "utils/constructFile";
import capitalize from "utils/capitalize";
import getKeyInfo from "utils/getKeyInfo";
import ageOptions from "utils/ageOptions";

import {
  getUser,
  getCatalogGroups,
  getApplicationUser,
  getUserCountry,
} from "redux/selectors";
import { getItem as getCookingTechnique } from "redux/modules/cookingTechniques";
import { getItem as getPortion } from "redux/modules/portions";
import { getItem as getMealType } from "redux/modules/mealTypes";
import { getItem as getEatingType } from "redux/modules/specificEatingTypes";
import { getItem as getDisease } from "modules/Patient/redux/disease";
import * as meals from "redux/modules/meals";
import * as ingredients from "redux/modules/ingredients";
import * as supplement from "redux/modules/supplement";
import { sexOptions } from "modules/Catalog/components/ProductFormSupplement/ProductFormIngredient";

const selectors = {
  meals,
  ingredients,
  supplement,
};

const groupFieldByType = {
  meals: "custom_группа",
  ingredients: "custom_группа",
  supplement: "custom_производитель",
};

export const getItemById = (type, id, state) =>
  selectors[type].getItemById(id, state[type]);

export const getKeyById = (type, id, state) =>
  selectors[type].getKeyById(id, state[type]);

export const getItem = (type, id, state) =>
  selectors[type].getItem(id, state[type]);

export const getImage = (type, key, state) => {
  const item = selectors[type].getItem(key, state[type]);
  const base64Image = constructFile(R.propOr(null, ["custom_картинка"], item));
  const imageUrl = R.path(["custom_картинка", "url"], item);
  const imageUrlField = R.path(["custom_картинка_url"], item);

  const image = imageUrlField || base64Image || imageUrl || null;
  return image;
};

const arrayToString = R.compose(capitalize, R.toLower, R.join(", "));

export const getFeatures = (type, key, state) => {
  const item = selectors[type].getItem(key, state[type]);
  const portionKey = R.propOr(null, "custom_порция", item);
  const portion = R.propOr(
    null,
    "custom_название",
    getPortion(portionKey, state.portions)
  );
  const sexId = R.propOr(null, "custom_пол")(item);
  const sexName = R.compose(
    R.prop("label"),
    R.head,
    R.filter(({ value }) => value === sexId)
  )(sexOptions);

  const groupField = groupFieldByType[type];
  const groupKey = R.propOr(null, groupField, item);
  const groups = getCatalogGroups(state);
  const group = R.find(R.propEq("key", groupKey), groups);

  const cookingTechniqueKey = R.propOr(
    null,
    "custom_способ_приготовления",
    item
  );
  const cookingTechnique = R.propOr(
    null,
    "custom_полное_наименование",
    getCookingTechnique(cookingTechniqueKey, state.cookingTechniques)
  );

  const ingestion = R.compose(
    arrayToString,
    R.pluck("custom_наименование"),
    R.reject(R.isNil),
    R.map((mealTypeKey) => getMealType(mealTypeKey, state.mealTypes)),
    R.propOr([], "custom_применимость_в_схеме_питания")
  )(item);

  const specificEatingTypes = R.compose(
    arrayToString,
    R.pluck("custom_наименование"),
    R.reject(R.isNil),
    R.map((typeKey) => getEatingType(typeKey, state.specificEatingTypes)),
    R.defaultTo([]),
    R.propOr([], "custom_специфические_виды_питания")
  )(item);

  const features = [
    {
      name: "Упаковка",
      value: item.custom_расширенная_форма,
    },
    {
      name: "Порция",
      value: portion,
    },
    {
      name: type === "supplement" ? "Производитель" : "Группа",
      value: group.title,
    },
    {
      name: "Способ приготовления",
      value: cookingTechnique,
    },
    {
      name: "Исключить для (виды питания)",
      value: specificEatingTypes,
    },
    {
      name: "Предпочтительный прием",
      value: ingestion,
    },
    {
      name: "Пол",
      value: sexName,
    },
    {
      name: "Возрастная группа",
      value:
        ageOptions.slice(1).length ===
        (item.custom_возрастнаягруппа || []).length
          ? ageOptions[0].text
          : (item.custom_возрастнаягруппа || []).join(", "),
    },
  ];

  return R.reject(
    R.propSatisfies(R.either(R.isNil, R.isEmpty), "value"),
    features
  );
};

export const getSource = createOrmSelector(
  (state, props) =>
    R.compose(
      R.propOr([], "custom_продукт_повышает"),
      selectors[props.type].getItem
    )(props.key, state[props.type]),
  (session, sourceKeys) => {
    const { Elements } = session;

    return R.map((elementKey) => {
      const element = Elements.withId(elementKey);

      return element.custom_наименование;
    }, sourceKeys);
  }
);

export const getDiseases = (type, key, state) => {
  const item = selectors[type].getItem(key, state[type]);

  return R.compose(
    R.map((typeKey) => {
      const disease = getDisease(typeKey, state.Patient.disease);
      return disease.custom_наименование;
    }),
    R.defaultTo([]),
    R.propOr([], "custom_запрещено_к_употреблению_при")
  )(item);
};

export const getDescription = (type, key, state) => {
  const item = selectors[type].getItem(key, state[type]);

  return R.propOr(null, "custom_описание", item);
};

export const getRecipe = (type, key, state) => {
  const item = selectors[type].getItem(key, state[type]);

  return R.propOr(null, "custom_рецепт", item);
};

const pathIsNotEmpty = R.compose(R.not, R.isNil, R.path);

const BASE = "base";
const PRIVATE = "private";
const INSTITUTION = "institution";

export const getCaption = (type, itemId, state) => {
  const item = selectors[type].getItemById(itemId, state[type]);
  const hasOwner = pathIsNotEmpty(["___owner"], item);
  const hasTerritory = pathIsNotEmpty(["___territory"], item);
  const hasShowInInstitution = R.pathEq(
    ["custom_показывать_в_рамках_учреждения"],
    true,
    item
  );

  if (!hasOwner && !hasTerritory) {
    return BASE;
  }

  return hasShowInInstitution ? INSTITUTION : PRIVATE;
};

const getItemsArray = (userKey, type, state) => {
  const country = userKey ? getUserCountry(state) : null;
  const items = selectors[type].getItemsList(state[type], country);

  return R.compose(
    memoizee(
      R.map((item) => {
        const id = getKeyInfo(item.key).record;
        return {
          ...item,
          id,
          type,
          value: item.key,
          key: item.key,
          caption: getCaption(type, id, state),
          label: item.custom_наименование || item.custom_название,
          title: item.custom_наименование || item.custom_название,
        };
      })
    ),
    memoizee(R.reject(R.propOr(null, "custom_черновик")))
  )(items);
};

const rejectByUser = (userKey, type, state) =>
  memoizee(
    R.reject((item) => {
      if (!userKey) {
        return false;
      }

      const isCurrentUserItem = R.propEq("___owner", userKey, item);
      const id = getKeyInfo(item.key).record;
      const caption = getCaption(type, id, state);

      return caption === PRIVATE && !isCurrentUserItem;
    })
  );

export const getSelect = (userKey, type, state) => {
  if (!type) {
    return [];
  }

  return R.compose(
    rejectByUser(userKey, type, state),
    R.filter((item) => !userKey || !item.custom_скрытый),
    R.filter((item) => {
      if (!userKey) {
        return true;
      }

      if (type === "supplement") {
        return !item.custom_неактивен;
      }

      return true;
    }),
    getItemsArray
  )(userKey, type, state);
};

export const getProductsByGroup = (userKey, type, groupKey, age, state) =>
  R.compose(
    R.filter(R.propOr(null, "title")),
    R.sortBy(R.propOr(null, "title")),
    R.map((item) => {
      const id = getKeyInfo(item.key).record;
      const caption = getCaption(type, id, state);

      return {
        id,
        title: item.title,
        key: item.key,
        caption,
      };
    }),
    R.filter((item) => {
      const itemAge = item.custom_возрастнаягруппа || [];
      const anyAge = R.contains(ageOptions[0].value);

      if (R.isEmpty(age) || anyAge(itemAge) || anyAge(age)) {
        return true;
      }

      return R.intersection(itemAge, age).length !== 0;
    }),
    R.filter((item) => {
      if (!userKey) {
        return true;
      }

      if (type === "supplement") {
        return !item.custom_неактивен;
      }

      return true;
    }),
    type === "supplement" ? R.identity : rejectByUser(userKey, type, state),
    R.reject(R.propOr(null, "custom_скрытый")),
    groupKey
      ? R.filter(R.propEq(groupFieldByType[type], groupKey))
      : R.identity,
    getItemsArray
  )(userKey, type, state);

export const getRigths = (type, itemId, state) => {
  const userItem = getUser(state);
  const userKey = getApplicationUser(state);
  const caption = getCaption(type, itemId, state);
  const isEditableBase = R.propOr(
    false,
    "custom_редактирование_общих_блюд",
    userItem
  );

  if (caption === BASE && isEditableBase) {
    return {
      isEditable: true,
      isDeletable: type === "supplement",
    };
  }

  const item = selectors[type].getItemById(itemId, state[type]);
  const productOwnerKey = R.propOr(null, "___owner", item);
  const isSuperUser =
    R.propOr("", "custom_role", userItem) === superuserRoleKey;
  const isRight =
    (isSuperUser && caption === INSTITUTION) || userKey === productOwnerKey;

  return {
    isEditable: isRight,
    isDeletable: isRight,
  };
};

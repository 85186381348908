import * as R from 'ramda';
import { createSelector } from 'reselect';

import getUserIsLight from '../getUserIsLight';

export default createSelector(
  getUserIsLight,

  (isLight) => {
    if (isLight) {
      return 1;
    }

    return 2;
  },
);

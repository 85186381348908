import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { isWebUri } from 'valid-url';

import constructFile from 'utils/constructFile';

import Button from 'components/Button';

import styles from './ImagePicker.module.css';

const cx = classNames.bind(styles);

export default class ImagePicker extends PureComponent {
  static propTypes = {
    error: PropTypes.string,
    fileTitle: PropTypes.string,
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    title: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
    onChange: PropTypes.func,
  }

  static defaultProps = {
    error: undefined,
    fileTitle: 'Изменить',
    id: undefined,
    title: 'Выбрать',
    value: {},
    onChange: () => {},
  }

  handleChange = (e) => {
    const reader = new FileReader();
    const name = R.last(e.target.value.split('\\'));
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    reader.onload = (event) => {
      const result = event.target.result.split(/[:;,]/);
      const mime = result[1];
      const base64 = result[3];

      this.props.onChange({
        mime,
        base64,
        name,
      });
    };

    reader.readAsDataURL(e.target.files[0]);
  }

  fileRef = (file) => { this.file = file; };

  render() {
    const { id, title, fileTitle, value } = this.props;
    const withFile = !R.isEmpty(value);
    const src = isWebUri(value.url) ? value.url : constructFile(value);

    return (
      <label htmlFor={id} className={cx('imagePicker')}>
        <input
          className={cx('input')}
          type='file'
          accept='image/*'
          id={id}
          onChange={this.handleChange}
          ref={this.fileRef}
        />
        <div className={cx('container')}>
          <div className={cx('avatar')}>
            {withFile &&
              <img
                className={cx('image')}
                src={src}
                alt='presentation'
              />
            }
          </div>
          <div className={cx('button')}>
            <Button
              size='small'
              bordered
              component='span'
            >
              {withFile && fileTitle ? fileTitle : title}
            </Button>
          </div>
        </div>
        <div className={cx('error')}>
          {this.props.error}
        </div>
      </label>
    );
  }
}

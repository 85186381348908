const environments = {
  develop: {
    apiUrl: 'https://dev.nutrilogic.ru',
    appKey: 'c105a902',
    liteApiUrl: 'https://dev.nutrilogic.ru',
    proApiUrl: 'https://dev.nutrilogic.ru',
    contextKey: 'c105ts1r902',
    oneDayKey: 'c105a902t119r10698526',
    yearKey: 'c105a902t119r5371410',
    liteMonthKey: 'c105a902t119r5370654',
    sentry: 'https://11cf05e55e96416fa7bac61d83a4f504@sentry.io/179036',
  },
  master: {
    apiUrl: 'https://master1.nutrilogic.ru',
    appKey: 'c105a902',
    liteApiUrl: 'https://master1.nutrilogic.ru',
    proApiUrl: 'https://master2.nutrilogic.ru',
    contextKey: 'c105ts1r902',
    oneDayKey: 'c105a902t119r10698526',
    yearKey: 'c105a902t119r5371410',
    liteMonthKey: 'c105a902t119r5370654',
    sentry: 'https://11cf05e55e96416fa7bac61d83a4f504@sentry.io/179036',
  },
  production: {
    apiUrl: 'https://core1.nutrilogic.ru',
    appKey: 'c105a902',
    liteApiUrl: 'https://core1.nutrilogic.ru',
    proApiUrl: 'https://core2.nutrilogic.ru',
    contextKey: 'c105ts1r902',
    oneDayKey: 'c105a902t119r10698526',
    yearKey: 'c105a902t119r5371410',
    liteMonthKey: 'c105a902t119r5370654',
    sentry: 'https://05b1206a90754cd4986f835fd8151083@sentry.io/174935',
  },
  dd: {
    apiUrl: 'https://core.xn--80adffhqmpddacw4o.xn--p1ai',
    appKey: 'c105a902',
    liteApiUrl: 'https://core.xn--80adffhqmpddacw4o.xn--p1ai',
    proApiUrl: 'https://core.xn--80adffhqmpddacw4o.xn--p1ai',
    contextKey: 'c105ts1r902',
    oneDayKey: 'c105a902t119r10698526',
    yearKey: 'c105a902t119r5371410',
    liteMonthKey: 'c105a902t119r5370654',
    sentry: 'https://05b1206a90754cd4986f835fd8151083@sentry.io/174935',
  },
};

const env = environments[process.env.REACT_APP_API || 'develop'];

export const apiUrl = env.apiUrl;
export const liteApiUrl = env.liteApiUrl;
export const proApiUrl = env.proApiUrl;
export const appKey = env.appKey;
export const contextKey = env.contextKey;
export const oneDayKey = env.oneDayKey;
export const sentry = env.sentry;
export const yearKey = env.yearKey;
export const liteMonthKey = env.liteMonthKey;
export const version = process.env.REACT_APP_VERSION || 'VERSION';
export const head = {
  titleTemplate: process.env.REACT_APP_API === 'dd' ? '%s - Доступная Диетология' : '%s - Nutrilogic',
  meta: [
    {
      charset: 'utf-8',
    },
  ],
}

import createOrmSelector from 'redux/utils/createOrmSelector';

import getApplicationUser from '../getApplicationUser';

export default createOrmSelector(
  getApplicationUser,

  (session, key) => {
    const { Worker } = session;

    const user = Worker
      .filter({
        custom_user: key,
      })
      .first();

    if (user) {
      return user.ref;
    }

    return undefined;
  },
);

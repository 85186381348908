import * as R from 'ramda';
import { attr, fk, Model } from 'redux-orm';

import generator from 'redux/hor/orm-module';

const {
  reducer,
  fetchSuccess,
  create,
  save,
  remove,
} = generator({
  name: 'DietanamnezItem',
  tableId: 65,
  parseItem: R.evolve({
    ___parent: R.propOr(null, 'key'),
    custom_добавить_исключить: R.propOr(null, 'value'),
    custom_блюдо: R.propOr(null, 'key'),
    custom_частота_употребления: R.propOr(null, 'key'),
    custom_эталонное_блюдо_для_фронта: R.propOr(null, 'key'),
    custom_список_блюд: R.compose(
      R.pluck('key'),
      R.defaultTo([]),
    ),
  }),
});

export default class DietanamnezItem extends Model {
  static modelName = 'DietanamnezItem';

  static reducer = reducer;

  static options = {
    idAttribute: 'key',
  }

  static fields = {
    ___parent: fk('Dietanamnez', 'items'),
    custom_добавить_исключить: attr(),
    custom_блюдо: attr(),
    custom_количество: attr(),
    custom_список_блюд: attr(),
    custom_частота_употребления: attr(),
    custom_строка_поиска: attr(),
    custom_эталонное_блюдо_для_фронта: attr(),
  }
}

export {
  fetchSuccess,
  create,
  save,
  remove,
};

export { default as saga } from './saga.js';

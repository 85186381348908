import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'utils/react-redux';
import { reduxForm } from 'redux-form';

import createValidate from 'utils/validation';
import options from 'utils/validation/defaultOptions';
import Modal from 'components/Modal';

import PasswordRecovery from '../../components/PasswordRecovery';
import { resetPassword } from '../../redux/actions';

const form = {
  form: 'passwordRecovery',
  validate: createValidate({
    email: options.email,
  }),
  fields: [
    'email',
  ],
};

const mapDispatchToProps = {
  resetPassword,
};

class PublicPasswordRecoveryPage extends PureComponent {
  static propTypes = {
    error: PropTypes.string,
    resetPassword: PropTypes.func,
    form: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    router: PropTypes.object,
    submitting: PropTypes.bool,
    submitSucceeded: PropTypes.bool,
    valid: PropTypes.bool,
  }

  static defaultProps = {
    error: undefined,
    resetPassword: () => {},
    form: undefined,
    handleSubmit: () => {},
    router: {},
    submitting: false,
    submitSucceeded: false,
    valid: false,
  }

  state = {
    isOpen: true,
  };

  handleRequestClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  handleAfterClose = () => {
    const { router, location } = this.props;

    router.push(location.pathname.split('/').slice(0, -1).join('/'));
  };

  handleSubmit = this.props.handleSubmit(({ email }) => {
    this.setState({
      email,
    });

    this.props.resetPassword({
      form: this.props.form,
      email,
    });
  })

  render() {
    const { submitting, error } = this.props;

    return (
      <div>
        <Helmet title='Восстановление пароля' />

        <Modal
          ariaHideApp={false}
          contentLabel='Восстановление пароля'
          isOpen={this.state.isOpen}
          onRequestClose={this.handleRequestClose}
          onAfterClose={this.handleAfterClose}
        >
          <PasswordRecovery
            onSubmit={this.handleSubmit}
            onCancel={this.handleRequestClose}
            success={!submitting && !error && this.state.email}
            email={this.state.email}
            valid={this.props.valid}
            submitting={this.props.submitting}
            error={this.props.error}
          />
        </Modal>
      </div>
    );
  }
}

export default R.compose(
  reduxForm(form),
  connect(null, mapDispatchToProps),
)(PublicPasswordRecoveryPage);

import * as R from 'ramda';
import { call, all, takeEvery, put, select } from 'redux-saga/effects';

import { request } from 'utils/api';
import dispatchAndWait from 'redux/utils/dispatchAndWait';

import { appKey } from 'config';

import getRationKey from '../../selectors/getRationKey';
import getDayKey from '../../selectors/getDayKey';
import getRationDays from '../../selectors/getRationDays';

import {
  fetchBase as fetchRation,
  fetchRationComposition,
} from '../ration';

import {
  copy,
  fetchItem,
  save,
  sort,
  fillFromTemplate,
  fillFromTemplateSuccess,
  fillFromTemplateFailure,
  addGroup,
  removeGroup,
  updateGroupTime,
  sortGroupItems,
  removeDishGroup,
  removeDishGroupDish,
  addDishGroupDish,
  changeDishGroupDishAmount,
  changeDishGroupActive,
} from './index';

function* fillFromTemplateFlow(action) {
  const { payload, meta } = action;

  try {
    yield put(save({
      key: payload.key,
      data: {
        isFilling: true,
      },
      isLocal: true,
    }));
    yield call(request.post, `/${payload.key}/json/v2`, {
      parameters: {
        key_: payload.templateKey,
      },
    }, {
      headers: {
        Function: 'fillFromTemplate',
      },
    });

    yield dispatchAndWait(fetchRationComposition({
      key: meta.entity.custom_рацион_пациента,
      num: payload.key,
    }));

    yield dispatchAndWait(fetchItem({
      key: payload.key,
    }));


    yield put(fillFromTemplateSuccess({
      key: payload.key,
    }));
  } catch (error) {
    yield put(fillFromTemplateFailure({
      ...action.payload,
      error,
    }));
  } finally {
    yield put(save({
      key: payload.key,
      data: {
        isFilling: false,
      },
      isLocal: true,
    }));
  }
}

function* copyFlow(action) {
  const { payload } = action;

  try {
    const rationKey = yield select(getRationKey);
    const dayKey = yield select(getDayKey);
    yield put(save({
      key: dayKey,
      data: {
        isCopying: true,
      },
      isLocal: true,
    }));
    yield call(request.post, `/${appKey}t155r/json/v2`, {
      parameters: {
        source_day_key: payload.key,
        day_numbers_to: payload.dates,
        meal_times: payload.group,
      },
    }, {
      headers: {
        Function: 'copy_day_of_ration_v2',
      },
    });
    yield put(fetchRation({
      key: rationKey,
    }));
  } catch (error) {
    console.error(error);
  } finally {
    const dayKey = yield select(getDayKey);

    yield put(save({
      key: dayKey,
      data: {
        isCopying: false,
      },
      isLocal: true,
    }));
  }
}

function* sortFlow(action) {
  const { payload } = action;

  const days = yield select(state => getRationDays(state, {
    rationKey: payload.key,
  }));
  const day = R.find(R.propEq('custom_номер', payload.to), days);

  yield call(request.post, `/${day.key}/json/v2`, {
    parameters: {
      new_number: payload.to,
    },
  }, {
    headers: {
      Function: 'changeNumberDay',
    },
  });
}

function* triggerSave({ payload }) {
  if (payload.isLocal) {
    return;
  }

  yield put(save({
    key: payload.key,
    isSilent: true,
  }))
}

export default function* saga() {
  yield all([
    takeEvery(fillFromTemplate, fillFromTemplateFlow),
    takeEvery(copy, copyFlow),
    takeEvery(sort, sortFlow),
    takeEvery(addGroup, triggerSave),
    takeEvery(removeGroup, triggerSave),
    takeEvery(updateGroupTime, triggerSave),
    takeEvery(sortGroupItems, triggerSave),
    takeEvery(removeDishGroup, triggerSave),
    takeEvery(removeDishGroupDish, triggerSave),
    takeEvery(changeDishGroupDishAmount, triggerSave),
    takeEvery(addDishGroupDish, triggerSave),
    takeEvery(changeDishGroupActive, triggerSave),
  ]);
}

import * as R from 'ramda';
import { createSelector } from 'reselect';

import {
  getItems as getIllnessItems,
} from 'modules/Patient/redux/illness';

import getDiseaseList from '../getDiseaseList';

export default createSelector(
  getDiseaseList,
  R.path(['Patient', 'illness']),

  getIllnessItems,
);

import * as R from 'ramda';
import { createSelector } from 'reselect';

import getDietanamnez from '../getDietanamnez';


export default createSelector(
  getDietanamnez,

  R.compose(
    R.defaultTo([]),
    R.propOr([], 'custom_специфические_виды_питания'),
  ),
);

import { put, all, takeEvery } from 'redux-saga/effects';

import { setParams, setParamsSuccess } from './reducer';

function* setParamsFlow() {
  yield put(setParamsSuccess());
}

export default function* saga() {
  yield all([
    takeEvery(setParams, setParamsFlow),
  ]);
}

import { createAction, createReducer } from 'redux-act';

const initialState = {};

export const checkPromo = createAction('SUBSCRIPTION_PROMOCODE_CHECK_PROMO');
export const setPromo = createAction('SUBSCRIPTION_PROMOCODE_SET_PROMO');
export const resetPromo = createAction('SUBSCRIPTION_PROMOCODE_RESET_PROMO');

const handleSetPromo = (state, payload) => ({
  ...state,
  ...payload,
});

const handleResetPromo = () => initialState;

export default createReducer({
  [setPromo]: handleSetPromo,
  [resetPromo]: handleResetPromo,
}, initialState);

import generator from 'redux/hor/module';

import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,
  fetch,
} = generator({
  name: 'rationCorrection',
  tableId: 106,
});

export const fetchList = fetchWithView(fetch, 'ts5c106cs9r318', {
  useLS: true,
});

export {
  fetch,
};

export {
  getCorrectionValuesByWeight,
} from './selectors';

export default reducer;

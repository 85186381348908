import createOrmSelector from 'redux/utils/createOrmSelector';

import getDietanamnezKey from '../getDietanamnezKey';

export default createOrmSelector(
  getDietanamnezKey,

  (session, key) => {
    const { DietanamnezItem } = session;

    try {
      const items = DietanamnezItem
        .filter({
          ___parent: key,
        })
        .exclude({
          isDeleted: true,
        })
        .toRefArray();

      return items;
    } catch (error) {
      return [];
    }
  },
);

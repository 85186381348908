import createOrmSelector from 'redux/utils/createOrmSelector';

import isLocalKey from 'utils/isLocalKey';

import getDietanamnezId from '../getDietanamnezId';

export default createOrmSelector(
  getDietanamnezId,
  (state, props = {}) => props.dietanamnezKey,

  (session, id, itemKey) => {
    const { Dietanamnez } = session;

    try {
      const key =  Dietanamnez
        .filter(item => item.key === id || item.oldKey === id || (itemKey && (item.key === itemKey || item.oldKey === itemKey)))
        .first()
        .ref
        .key;

      return key
    } catch (error) {
      if (!isLocalKey(id)) {
        const item = Dietanamnez
          .all()
          .toModelArray()
          .filter(i => i.id === Number(id))[0]

        return item ? item.key : null;
      }
    }
  },
);

import * as R from 'ramda';
import { createSelector } from 'reselect';

import getKeyInfo from 'utils/getKeyInfo';

import { getItemsArray } from 'redux/hor/module/selectors';
import { defaultIcon } from 'helpers/constants';

export const getSelectData = createSelector(
  getItemsArray,
  (state, groups) => groups,

  (items, groups) => R.compose(
    R.sortBy(R.prop('label')),
    R.map(item => ({
      value: item.key,
      label: item.custom_наименование,
    })),
    R.filter(item => R.isEmpty(groups) || R.contains(item.key, groups)),
  )(items),
);

export const getCatalogGroups = createSelector(
  getItemsArray,
  (state, groups) => groups,

  (items, groups) => R.compose(
    R.sortBy(R.prop('title')),
    R.map(item => ({
      key: item.key,
      id: getKeyInfo(item.key).record,
      title: item.custom_наименование,
      custom_наименование: item.custom_наименование,
      custom_картинка_url: item.custom_картинка_url,
      image: item.custom_картинка_url,
      icon: defaultIcon,
    })),
    R.filter(item => R.isEmpty(groups) || R.contains(item.key, groups)),
  )(items),
);

import React, { PureComponent } from 'react';
import { connect } from 'utils/react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import InstituteForm from '../../containers/InstituteForm';
import { setInstitution } from '../../redux/signup';

const mapDispatchToProps = {
  setInstitution,
};

class PublicRegisterInstituteContainer extends PureComponent {
  static propTypes = {
    setInstitution: PropTypes.func.isRequired,
  }

  static defaultProps = {
    setInstitution: () => {},
  }

  handleSubmit = (data) => {
    this.props.setInstitution({
      data,
    });
  }

  render() {
    return (
      <InstituteForm
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export default R.compose(
  connect(null, mapDispatchToProps),
)(PublicRegisterInstituteContainer);

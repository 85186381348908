import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import scaleLinearImport from 'd3-scale/src/linear';
import memoizee from 'memoizee';

import Defs from './Defs';

import TooltipDefault from './Tooltip';
import OneRectDefault from './default/OneRect';
import HighertLimitDefault from './default/HighertLimit';
import NormRangeLineDefault from './default/NormRangeLine';
import VerticalLineDefault from './default/VerticalLine';

import styles from './Barchart.module.css';

const cx = classNames.bind(styles);

const scaleLinear = scaleLinearImport.default || scaleLinearImport;

export default class Barchart extends PureComponent {
  static propTypes = {
    data: PropTypes.oneOfType([
      PropTypes.number,
    ]),
    norm: PropTypes.number,
    dataChart: PropTypes.array,
    dataInCenter: PropTypes.number,
    normMin: PropTypes.number,
    normMax: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    id: PropTypes.string,
    multiplier: PropTypes.number,
    color: PropTypes.oneOf(['sapphirine']),
    gradientColor: PropTypes.string,
    OneRect: PropTypes.func,
    HighertLimit: PropTypes.func,
    NormRangeLine: PropTypes.func,
    VerticalLine: PropTypes.func,
    Tooltip: PropTypes.func,
    extraWidth: PropTypes.number,
    noNorm: PropTypes.bool,
    isEmpty: PropTypes.bool,
    size: PropTypes.string,
  };

  static defaultProps = {
    dataChart: [],
    width: 0,
    height: 0,
    multiplier: 1,
    OneRect: OneRectDefault,
    HighertLimit: HighertLimitDefault,
    NormRangeLine: NormRangeLineDefault,
    VerticalLine: VerticalLineDefault,
    Tooltip: TooltipDefault,
    extraWidth: 15,
  };

  state = {
    toolTipX: 0,
    toolTipY: 0,
    toolTipOnChart: false,
    toolTipShow: false,
    toolTipText: null,
  };


  handlePositionMouse = ({ x, y }) => {
    if (this.state.toolTipOnChart) {
      this.setState({
        toolTipX: x,
        toolTipY: y,
        toolTipShow: true,
      });
    }
  };

  handleMouseMove = (event) => {
    const x = event.clientX;
    const y = event.clientY;

    if (!this.props.noNorm) {
      this.setState({
        toolTipShow: false,
      });

      this.handleDebounceMouseMove({ x, y });
    } else {
      this.handlePositionMouse({ x, y });
    }
  };

  handleDebounceMouseMove = debounce(event => this.handlePositionMouse(event), 200);

  handleMouseLeave = () => {
    this.setState({
      toolTipShow: false,
      toolTipOnChart: false,
    });
  };

  handleMouseEnter = memoizee((text, color) => (event) => {
    this.setState({
      toolTipX: event.clientX,
      toolTipY: event.clientY,
      toolTipColor: color,
      toolTipOnChart: true,
      toolTipShow: true,
      toolTipText: `${text}%`,
    });
  });


  renderMainChart(data, x, y) {
    if (!this.props.data) {
      return null;
    }

    const { OneRect } = this.props;
    return data.map((rect, index, list) => (
      <OneRect
        rectStart={rect.start}
        rectEnd={rect.end}
        index={index}
        key={index}
        total={list.length}
        dataInCenter={this.props.dataInCenter}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
        onMouseLeave={this.handleMouseLeave}
        norm={this.props.norm}
        normMin={this.props.normMin}
        normMax={this.props.normMax}
        id={this.props.id}
        multiplier={this.props.multiplier}
        color={this.props.color}
        data={this.props.data}
        x={x(rect.start)}
        height={y(this.props.height - 10)}
        width={x((rect.end / this.props.multiplier) - rect.start)}
      />
    ));
  }


  renderBarChart() {
    const {
      data,
      width,
      extraWidth,
      height,
      color,
      id,
      multiplier,
      dataInCenter,
      dataChart,
      normMin,
      norm,
      normMax,
      gradientColor,
    } = this.props;

    const x = scaleLinear().range([0, width]);
    const y = scaleLinear().range([0, height]);

    x.domain([0, dataInCenter * 2]);
    y.domain([0, height]);

    const { HighertLimit, NormRangeLine, VerticalLine } = this.props;

    return (
      <svg
        className={cx('chart')}
        viewBox={`0 0 ${width + extraWidth} ${height}`}
        preserveAspectRatio='none slice'
      >
        <Defs
          width={width}
          height={height}
          id={id}
          gradientColor={gradientColor}
          extraWidth={extraWidth}
        />

        <g clipPath={`url(#chartClip${id})`}>
          <VerticalLine
            height={height}
            width={width}
            x={x}
            y={y}
            normMin={normMin}
            normMax={normMax}
          />

          {this.renderMainChart(dataChart, x, y)}

          <HighertLimit
            height={height}
            data={data}
            dataChart={dataChart}
            norm={norm}
            normMin={normMin}
            normMax={normMax}
            dataInCenter={dataInCenter}
            multiplier={multiplier}
            x={x}
            y={y}
            color={color}
            id={id}
          />

          {this.props.noNorm ||
            <g clipPath={`url(#normClip${id})`}>
              <NormRangeLine
                norm={norm}
                normMin={normMin}
                normMax={normMax}
                height={height}
                multiplier={multiplier}
                x={x}
                y={y}
              />
            </g>
          }
        </g>
      </svg>
    );
  }

  render() {
    const { Tooltip, size, isEmpty } = this.props;
    const addHandler = size && !isEmpty;

    return (
      <div
        className={cx('barchart', {
          [`barchart_size_${this.props.size}`]: this.props.size,
        })}
        onMouseEnter={addHandler ? this.handleMouseEnter() : null}
        onMouseMove={addHandler ? this.handleMouseMove : null}
        onMouseLeave={addHandler ? this.handleMouseLeave : null}
      >
        {this.renderBarChart()}

        {this.state.toolTipShow &&
          <Tooltip
            {...this.props}
            toolTipX={this.state.toolTipX}
            toolTipY={this.state.toolTipY}
            toolTipColor={this.state.toolTipColor}
            toolTipText={this.state.toolTipText}
          />
        }
      </div>
    );
  }
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';

import Text from 'components/Text';

import styles from './Checkbox.module.css';

const cx = classNames.bind(styles);

export default class Checkbox extends PureComponent {
  static propTypes = {
    align: PropTypes.string,
    checked: PropTypes.bool,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    block: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    noWrap: PropTypes.bool,
    sizeCheck: PropTypes.oneOf([
      'medium',
      'small',
    ]),
    size: PropTypes.oneOf([
      'big',
      'medium',
      'small',
    ]),
    theme: PropTypes.oneOf([
      'bright',
      'tag',
      'disabled',
      'sapphirine',
      'sapphirine-hover',
      'dark',
      'thin',
    ]),
    value: PropTypes.string,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    align: undefined,
    checked: false,
    children: undefined,
    disabled: false,
    id: undefined,
    name: undefined,
    noWrap: false,
    size: undefined,
    sizeCheck: undefined,
    theme: undefined,
    value: undefined,
    onChange: () => { },
  }

  handleChange = (event) => {
    this.props.onChange(event.target.checked, this.props.value, event);
  }

  render() {
    const {
      name,
      id,
      checked,

      children,
      size,
      sizeCheck,
      theme,
      noWrap,
      align,
      value,
      disabled,
      block,
    } = this.props;
    return (
      <label
        className={cx({
          checkbox: true,
          [`checkbox_theme_${theme}`]: theme,
          checkbox_disabled: disabled,
          checkbox_block: block,
        })}
        htmlFor={id}
      >
        <input
          className={cx('input')}
          type='checkbox'
          name={name}
          id={id}
          value={value}
          checked={checked}
          onChange={this.handleChange}
          disabled={disabled}
        />
        <span
          className={cx({
            label: true,
            [`label_align_${align}`]: align,
          })}
          htmlFor={id}
        >
          <div
            className={cx({
              check: true,
              [`check_${sizeCheck}`]: sizeCheck,
            })}
          />
          {children &&
            (noWrap ? children : <div className={cx('text')}>
              <Text inherit size={size} color='inherit'>
                {children}
              </Text>
            </div>)
          }
        </span>
      </label>
    );
  }
}

import { createSelector } from 'reselect';

import declOfNums from 'utils/declOfNums';
import getInstitutionSubscriptionDurationTotal from '../getInstitutionSubscriptionDurationTotal';
import getSubscriptionUrl from '../getSubscriptionUrl';
import getUserIsPartner from '../getUserIsPartner';

const getDay = declOfNums(['день', 'дня', 'дней']);

export default createSelector(
  getInstitutionSubscriptionDurationTotal,
  getSubscriptionUrl,
  getUserIsPartner,

  (duration, subscriptionUrl, isPartner) => {
    if (duration > 7 || duration <= 0 || isPartner || process.env.REACT_APP_API === 'dd') {
      return {};
    }

    return {
      title: 'Внимание!',
      text: `Внимание! До конца оплаченного периода подписки осталось ${duration} ${getDay(duration)}.`,
      button: 'Продлить',
      ...subscriptionUrl,
    };
  },
);

export default function getKeyInfo(key) {
  if (!key) {
    return {};
  }

  const [company, app, table, record] = key.split(/[catr]/).slice(1).map(item => parseInt(item, 10));

  return {
    company,
    app,
    table,
    record,
  };
}

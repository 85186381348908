import { all, takeEvery, call, put } from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';

import { appKey } from 'config';

import { request } from 'utils/api';

import {
	fetchItem
} from 'redux/modules/patients'

import {
	loadFiles,
	loadFilesSuccess,
	loadFilesFailure,
	loadMedassReport,
	loadMedassReportSuccess,
	loadMedassReportFailure,
} from './index';

function* loadMedassReportFlow({ payload }) {
	const key = payload.key;

	try {
		if (payload.form) {
			yield put(startSubmit(payload.form));
		}

		const response = yield call(request.put, `/${key}/json/v2`, {
			parameters: {
				patient_key: key,
			},
			data: payload.imageFile ?
				{
					custom_медасс_csv: payload.csvFile,
					custom_медасс_jpg: payload.imageFile,
				} :
				{
					custom_медасс_csv: payload.csvFile,
				}
		})

		if (response.data.error) {
			if (payload.form) {
				yield put(stopSubmit(payload.form))
			}
			yield put(loadMedassReportFailure())
			return
		}

		yield put(fetchItem({
			key,
		}))

		yield put(loadMedassReportSuccess({
			...response.data.data,
		}))
		yield put(stopSubmit(payload.form))
	} catch (error) {
		yield put(loadMedassReportFailure(error))
		yield put(stopSubmit(payload.form))
	}
}


function* loadFilesFlow(action) {
	const key = action.payload.key

	try {
		const response = yield call(request.post, `/${key}/json/v2`, {
			parameters: {
				patient_key: key,
			},
		})

		const { csvFile, imageFile } = response.data.data;

		yield put(loadFilesSuccess(csvFile, imageFile))
	} catch (error) {
		yield put(loadFilesFailure({
			...action.payload,
			error,
		}))
	}
}

export default function* saga() {
	yield all([
		takeEvery(loadFiles, loadFilesFlow),
		takeEvery(loadMedassReport, loadMedassReportFlow),
	]);
}

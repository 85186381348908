import { createAction, createReducer } from 'redux-act';

const initialState = {
  isLoading: false,
};

export const loadingStart = createAction('LOADING_START');
export const loadingFinish = createAction('LOADING_FINISH');

export default createReducer({
  [loadingStart]: () => ({
    isLoading: true,
  }),
  [loadingFinish]: () => ({
    isLoading: false,
  }),
}, initialState);

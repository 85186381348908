import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import RowsTable from '../../components/RowsTable';

const tableTitles = ['Ген', 'Генотип', 'Влияние'];

export default class AnalysisGenes extends PureComponent {
  static propTypes = {
    data: PropTypes.array,
    itemSelectDictionary: PropTypes.array,
  }

  render() {
    const {
      data,
      itemSelectDictionary,
    } = this.props;

    return (
      <RowsTable
        rows={data}
        titles={tableTitles}
        selectList={itemSelectDictionary}
      />
    );
  }
}

import getPatientKey from 'redux/selectors/getPatientKey';

import createOrmSelector from 'redux/utils/createOrmSelector';

import getKeyInfo from 'utils/getKeyInfo';

export default createOrmSelector(
  getPatientKey,

  (session, patientKey) => {
    const { Dietanamnez } = session;
    const item = Dietanamnez
      .exclude({
        isDeleted: true,
        custom_селфтест_до: null,
      })
      .filter({
        ___parent: patientKey,
        custom_дата_назначения: null,
      })
      .orderBy('custom_дата_назначения')
      .last();

    if (!item) {
      return null;
    }

    return getKeyInfo(item.key).record;
  },
);

import * as R from 'ramda';

const CHART_PADDING_TOP = 5;

const baseColors = [
  {
    valid: '#4cc770',
    invalid: '#fc6b53',
  },
];

const chemicalColors = {
  first: 'fc6b53',
  second: '801300',
};


const groupedColors = [
  {
    valid: '#16b49c',
  },
  {
    valid: '#56e0bb',
  },
  {
    valid: '#97f1e8',
  },
  {
    valid: '#56bee0',
  },
  {
    valid: '#1679b4',
  },
  {
    valid: '#003b5e',
  },
];

const propColorMap = {
  sapphirine: '#8ec5d0',
};


const getPercent = (normWeight, value) => Math.round(value / (normWeight / 100));


const getOnePercent = (normMin, normMax) => {
  if (!normMax && !normMin) {
    return 1;
  }
  if (normMax < Infinity) {
    return (normMax / 65);
  }
  return (normMin / 35);
};

const TYPE_NUMBER_HEX = 16;

const hex = (x) => {
  const x16 = x.toString(TYPE_NUMBER_HEX);
  return (x16.length === 1) ? `0${x16}` : x16;
};

const calculatePartRgb = (ratio, color2, color1, startStr, endStr) => (
  Math.ceil(
    (parseInt(color1.substring(startStr, endStr), TYPE_NUMBER_HEX) * ratio) +
    (parseInt(color2.substring(startStr, endStr), TYPE_NUMBER_HEX) * (1 - ratio)),
  )
);

const getColorChemical = (ratio, color2, color1) => {
  const r = calculatePartRgb(ratio, color2, color1, 0, 2);
  const g = calculatePartRgb(ratio, color2, color1, 2, 4);
  const b = calculatePartRgb(ratio, color2, color1, 4, 6);
  return `#${hex(r)}${hex(g)}${hex(b)}`;
};

const getStandartChart = data => (
  [{
    start: 0,
    end: data,
  }]
);

const getGroupedChart = R.compose(
  R.prop(1),
  R.mapAccum((lastValue, meal) => ([
    lastValue + meal, {
      start: lastValue,
      end: lastValue + meal,
    },
  ]), 0),
);

const gradientByColor = {
  '#4cc770': id => `url(#greenGradient${id})`,
  '#fc6b53': id => `url(#redGradient${id})`,
  '#8ec5d0': id => `url(#sapphirineGradient${id})`,
};

const getAFlow = (data, normMin, normMax) => {
  let end;

  if (data >= normMin && data <= normMax) {
    end = ((data - normMin) * (100 / (normMax - normMin)) * 0.3) + 35;
  } else if (data < normMin) {
    end = data * (100 / normMin) * 0.35;
  } else if (data >= normMax && (data < normMax * 2)) {
    end = ((data - normMax) * (100 / normMax) * 0.35) + 65;
  } else if (data > normMax && (data >= normMax * 2)) {
    end = 100;
  }

  return end;
};

const getBFlow = (data, normMin) => {
  let end;

  if (data > normMin && data < normMin * 2) {
    end = ((data - normMin) * (100 / normMin) * 0.65) + 35;
  } else if (data >= normMin * 2) {
    end = 100;
  } else if (data <= normMin) {
    end = data * (100 / normMin) * 0.35;
  }

  return end;
};

const getCFlow = (data, normMin, normMax) => {
  let end;

  if (data >= 0 && data < normMax) {
    end = data * (100 / normMax) * 0.65;
  } else if (data >= normMax && data < normMax * 2) {
    end = ((data - normMax) * (100 / normMax) * 0.35) + 65;
  } else if (data >= normMax * 2) {
    end = 100;
  }

  return end;
};

const getEnd = (data, normMin, normMax) => {
  let end;

  if (normMin > 0 && normMax < Infinity) {
    end = getAFlow(data, normMin, normMax);
  } else if (normMin > 0 && !isFinite(normMax)) {
    end = getBFlow(data, normMin, normMax);
  } else if (normMin === 0 && isFinite(normMax)) {
    end = getCFlow(data, normMin, normMax);
  }

  return end;
};

const getWithTwoBoundariesOfNorm = (data, normMin, normMax) => {
  if (R.isEmpty(data)) {
    return [];
  }

  if (!normMax && !normMin) {
    const dataArray = [].concat(data);
    const sum = R.sum(dataArray);
    let prevEnd = 0;

    const final = dataArray.map((item) => {
      const start = prevEnd;

      prevEnd = start + ((item / sum) * 50);

      return {
        start,
        end: prevEnd,
      };
    });

    return final;
  }

  if (Array.isArray(data)) {
    let prevEnd = 0;
    let sum = 0;

    return data.map((value) => {
      const percent = getOnePercent(normMin, normMax);
      const start = prevEnd;
      const end = getEnd(value + sum, normMin, normMax);

      prevEnd = end * percent;
      sum += value;

      return {
        start,
        end: prevEnd,
      };
    });
  }

  const percent = getOnePercent(normMin, normMax);
  const end = getEnd(data, normMin, normMax);

  return [{
    start: 0,
    end: end * percent,
  }];
};

const getRatio = (percents) => {
  if (percents < 100) {
    return (100 - percents) / 100;
  }

  return (percents - 100) / 900;
};

const getColorStatus = (valueInPercent, color) => {
  if (color) {
    return propColorMap[color];
  }
  const percents = valueInPercent <= 0 ? valueInPercent + 100 : valueInPercent;

  if (percents === 100) {
    return '#4cc770';
  }

  const ratio = getRatio(percents);
  return getColorChemical(R.clamp(0, 1, ratio), chemicalColors.first, chemicalColors.second);
};

export {
  getStandartChart,
  getGroupedChart,
  getPercent,
  getOnePercent,
  getColorStatus,
  getWithTwoBoundariesOfNorm,
  gradientByColor,
  groupedColors,
  propColorMap,
  chemicalColors,
  baseColors,
  CHART_PADDING_TOP,
};

import * as R from 'ramda';
import createCachedSelector from 'redux/utils/createCachedOrmSelector';

import { appKey } from 'config';

import getKeyInfo from 'utils/getKeyInfo';

export const getKeyById = id => id ? `${appKey}t39r${id}` : null;

const toTemplateInfo = item => ({
  ...item.ref,
  key: item.key,
  value: getKeyInfo(item.key).record.toString(),
  id: getKeyInfo(item.key).record.toString(),
  content: item.custom_название,
  duration: item.custom_продолжительность,
  owner: R.propOr(null, '___owner', item.ref),
  isDeleting: item['custom.custom_delete'],
});

const getTemplatesByType = createCachedSelector(
  (state, type) => type,

  (session, type) => {
    const { Ration } = session;

    return Ration
      .filter({
        custom_тип_шаблона: type,
      })
      .exclude({
        isDeleting: true,
      })
      .exclude({
        isDeleted: true,
      })
      .orderBy(a => getKeyInfo(a.key).record)
      .toModelArray()
      .map(toTemplateInfo)
      .map(R.assoc('withView', true));
  },
);

export const getPersonalTemplate = state => R.compose(
  R.map(R.assoc('withDelete', true)),
  R.map(R.assoc('withEdit', true)),
  R.reject(R.prop('custom_не_назначен')),
  getTemplatesByType,
)(state, 1);

export const getInstituteTemplate = (state, right, userKey) =>
  R.compose(
    R.map((template) => {
      const isOwner = userKey === R.propOr(null, 'owner', template);
      const isCanEdit = right || isOwner;

      return R.compose(
        R.assoc('withDelete', isCanEdit),
        R.assoc('withEdit', isCanEdit),
      )(template);
    }),
    getTemplatesByType,
  )(state, 2);

export const getSystemTemplates = state => R.compose(
  R.sortBy(R.prop('content')),
  getTemplatesByType,
)(state, 3);

export const getGeneratorTemplates = state => R.compose(
  R.sortBy(R.prop('content')),
  getTemplatesByType,
)(state, 4);

export const getRecommendedTemplates = (energy, disease, excludedKingdsFood, state, editRight) => {
  const getFilter = (kindsFood, d) => R.compose(
    R.filter(R.propSatisfies((value = []) => {
      if (R.isEmpty(value) && !R.isEmpty(kindsFood)) {
        return false;
      }

      const intersection = R.intersection(kindsFood, value);

      return R.length(intersection) === R.length(value);
    }, 'custom_спец_виды_питания')),
    R.filter(R.propSatisfies((value = []) => {
      if (R.isEmpty(value) && !R.isEmpty(d)) {
        return false;
      }

      const intersection = R.intersection(d, value);

      return R.length(intersection) === R.length(value);
    }, 'custom_прописано_при')),
  );

  const namesTemplatesByTypes = {
    1: 'личные',
    2: 'учреждения',
  };
  const items = R.unnest([
    getTemplatesByType(state, 1),
    getTemplatesByType(state, 2),
    getTemplatesByType(state, 3),
  ]);

  const templates = R.compose(
    R.sortBy(R.prop('content')),
    R.map(R.assoc('withDelete', false)),
    R.map(R.assoc('withEdit', editRight)),
    R.filter(R.propSatisfies(prop => !prop || prop <= energy, 'custom_спк__от')),
    R.filter(R.propSatisfies(prop => !prop || prop >= energy, 'custom_спк__до')),
    R.map(item => ({
      ...item,
      label: namesTemplatesByTypes[item.custom_тип_шаблона],
    })),
  )(items);

  let result = getFilter(excludedKingdsFood, disease)(templates);

  if (R.isEmpty(result)) {
    result = getFilter([], disease)(templates);
  }

  if (R.isEmpty(result)) {
    result = getFilter(excludedKingdsFood, [])(templates);
  }

  if (R.isEmpty(result)) {
    result = getFilter([], [])(templates);
  }

  return result;
};

export const getRestTemplates = (energy, disease, excludedKingdsFood, state, editRight) => {
  const recommended = R.compose(
    R.pluck('key'),
    getRecommendedTemplates,
  )(energy, disease, excludedKingdsFood, state);

  return R.compose(
    R.sortBy(R.prop('content')),
    R.map(R.assoc('withDelete', false)),
    R.map(R.assoc('withEdit', editRight)),
    R.reject(item => R.contains(item.key, recommended)),
    getSystemTemplates,
  )(state);
};

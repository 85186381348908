import createCachedOrmSelector from 'redux/utils/createCachedOrmSelector';

import getAnalysisKey from '../../getAnalysisKey';

export default createCachedOrmSelector(
  getAnalysisKey,

  (session, key) => {
    const { AnalysisSalt } = session;

    return AnalysisSalt
      .filter({
        ___parent: key,
      })
      .toModelArray()
      .map(item => ({
        key: item.key,
        itemKey: item.custom_химический_элемент.key,
        label: item.custom_химический_элемент.custom_наименование,
        value: item.custom_результат ? 'Обнаружено' : 'Не обнаружено',
      }));
  },
);

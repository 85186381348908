import * as R from 'ramda';
import { attr, fk, Model } from 'redux-orm';

import getKeyInfo from 'utils/getKeyInfo';

import generator from 'redux/hor/orm-module';
import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,

  fetch,
  fetchSuccess,
  create,
  save,
  saveSuccess,
  removeWithUndo,
} = generator({
  name: 'PatientAnalysis',
  tableId: 19,
  parseItem: R.evolve({
    ___parent: R.prop('key'),
    custom_вид_анализа: R.prop('key'),
  }),
});

export default class PatientAnalysis extends Model {
  static modelName = 'PatientAnalysis';

  static reducer = reducer;

  static options = {
    idAttribute: 'key',
  }

  static fields = {
    ___parent: attr(), // TODO: fk to patient
    custom_вид_анализа: fk('AnalysisType'),
    custom_дата: attr(),
    custom_дата_текстом: attr(),
  }

  get id() {
    return getKeyInfo(this.key).record;
  }
}

export const fetchAnalysis = fetchWithView(fetch, 'common');

export {
  fetchSuccess,
  create,
  save,
  saveSuccess,
  removeWithUndo,
};

import * as R from 'ramda';
import { startSubmit, stopSubmit } from 'redux-form';
import { call, put, all, takeEvery } from 'redux-saga/effects';

import { appKey } from 'config';

import { get } from 'utils/api';
import removeCustom from 'utils/removeCustom';

import { getPublicToken } from 'redux/utils/getPublicToken';
import callFunction from 'redux/utils/callFunction';
import dispatchAndWait from 'redux/utils/dispatchAndWait';

import { fetchList as fetchInstitutions } from 'redux/modules/institutions';
import { fetchList as fetchCities } from 'redux/modules/cities';
import { fetchList as fetchCountries } from 'redux/modules/countries';

import {
  activate,
  activateInvite,
  activateFinish,
  checkInvite,
  checkInviteSuccess,
} from './reducer';

function* checkInviteFlow(action) {
  const { query } = action.payload;

  if (query.email) {
    try {
      const token = yield call(getPublicToken);
      const response = yield call(callFunction, {
        key: `${appKey}t179r`,
        name: 'staffActions',
        parameters: {
          action: 61,
          activation_id: query.token,
          staff_mail: query.email,
        },
        token,
      })

      if (!response.data.lite_user) {
        yield all([
          dispatchAndWait(fetchInstitutions({ token })),
          dispatchAndWait(fetchCities({ token })),
          dispatchAndWait(fetchCountries({ token })),
        ])
      }

      yield put(checkInviteSuccess({
        isSingleStep: response.data.lite_user,
        isLight: response.data.lite_user,
      }));
    } catch (error) {
      yield put(checkInviteSuccess());
      yield put(activateFinish({
        status: 'ACTIVATION_ERROR',
      }));
    }
    return
  }

  yield put(checkInviteSuccess({
    isSingleStep: true,
    isLight: false,
  }));
}

function* activateFlow(action) {
  const { payload } = action;
  const token = yield call(getPublicToken);

  try {
    const isLite = new URLSearchParams(window.location.search).get('lite').toLowerCase() === 'true';

    yield call(callFunction, {
      key: `${appKey}t179r`,
      name: 'staffActions',
      parameters: {
        action: isLite ? 14 : 13,
        activation_id_control: payload.token,
        staff_key: payload.key,
      },
      token,
    })

    yield put(activateFinish({
      status: 'ACTIVATED',
    }));
  } catch (error) {
    const message = R.pathOr(null, ['response', 'data', 'exception', 'message'], error)

    if (message === 'Активация уже выполнена') {
      yield put(activateFinish({
        status: 'ALREADY_ACTIVE',
      }));
      return;
    }

    yield put(activateFinish({
      status: 'ACTIVATION_ERROR',
    }));
  }
}

function* activateInviteFlow(action) {
  const { payload } = action;
  yield put(startSubmit(payload.form))
  const token = yield call(getPublicToken);

  try {
    yield call(callFunction, {
      key: `${appKey}t179r`,
      name: 'staffActions',
      parameters: {
        action: payload.email ? 62 : 33,
        activation_id: payload.token,
        activation_id_control: payload.token,
        staff_key: payload.key,
        staff: removeCustom({
          ...payload.staff,
          custom_login: payload.email,
        }),
        institution: removeCustom(payload.institution || {}),
      },
      token,
    })
    yield put(activateFinish({
      status: 'ACTIVATED',
    }));
  } catch (error) {
    console.error(error)
    yield put(activateFinish({
      status: 'ACTIVATION_ERROR',
    }));
  } finally {
    yield put(stopSubmit(payload.form))
  }
}

export default function* saga() {
  yield all([
    takeEvery(checkInvite, checkInviteFlow),
    takeEvery(activate, activateFlow),
    takeEvery(activateInvite, activateInviteFlow),
  ]);
}

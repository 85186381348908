import { createSelector } from 'reselect';

import { getRestTemplates } from 'modules/Rations/redux/modules/ration/selectors';
import { getDietanamnezKindsFood } from 'modules/Dietanamnez/redux/selectors';

import getPatientEnergy from 'redux/selectors/getPatientEnergy';
import getPatientDisease from 'redux/selectors/getPatientDisease';
import getUserCanEditSystemTemplates from 'redux/selectors/getUserCanEditSystemTemplates';

export default createSelector(
  getPatientEnergy,
  getPatientDisease,
  getDietanamnezKindsFood,
  state => state,
  getUserCanEditSystemTemplates,

  getRestTemplates,
);

export default function getErrorTextFromResponse(error) {
  const { response = {}, message, code } = error || {};
  const { status, statusText, data } = response;
  const { exception = {} } = data || {};

  if (exception.type || exception.message ) {
    return `${exception.type} — ${exception.message}.`;
  }

  if (status || statusText) {
    return `${status}. ${statusText}`;
  }

  if (code || message) {
    return `${code}. ${message}`
  }

  return '';
}

import * as R from 'ramda';
import { takeEvery, all, call, select, put } from 'redux-saga/effects';

import { appKey } from 'config';
import { request } from 'utils/api';

import {
  getInstitutionKey
} from 'redux/selectors';

import runButton from 'redux/utils/runButton';
import getSubitemsEffect from 'redux/utils/getSubitemsEffect';

import subitems from 'redux/modules/subitems';

import {
  getApplicationUser
} from 'redux/selectors';

import {
  save,
  fetchItem,
  fetchSuccess,
  fetchItemSuccess,
  fetchItemFailure,

  stopAuto,
  stopAutoSuccess,
  stopAutoFailure,

  fetchList,
  fetchListSuccess,
  fetchListFailure,

  trialInform,
} from './index';

function* fetchItemFlow(action) {
  const userAkey = yield select(getApplicationUser)
  try {
    const response = yield call(() => request.post(`/${appKey}t179r/json/v2`, {
      parameters: {
        userAkey,
      },
    }, {
      headers: {
        Function: 'getFullUserInfo',
      },
    }));

    const data = response.data.учреждение;
    yield put(fetchSuccess({
      response: data,
    }))
    const effects = getSubitemsEffect(data, subitems);

    yield all(effects);
    yield put(fetchItemSuccess())
  } catch (error) {
    yield put(fetchItemFailure({
      ...action.payload,
      error,
    }))
  } finally {
    action.meta.resolve();
  }
}

function* stopAutoFlow(action) {
  const institution = yield select(getInstitutionKey);

  try {
    yield call(() => request.post(`/${appKey}t179r/json/v2`, {
      parameters: {
        inst_key: institution,
        cancellation_reason: action.payload.join(', '),
      },
    }, {
      headers: {
        Function: 'stopAutomaticRenewal',
      },
    }));

    yield call(fetchItemFlow);
    yield put(stopAutoSuccess())
  } catch (error) {
    yield put(stopAutoFailure({
      ...action.payload,
      error,
    }))
  } finally {
    action.meta.resolve();
  }
}

function* fetchListFlow(action) {
  try {
    const response = yield call(() => request.post(`/${appKey}t179r/json/v2`, {}, {
      headers: {
        token: action.payload.token,
        Function: 'getInstitutionsList',
      },
    }));

    yield put(fetchSuccess({
      ...action.payload,
      response: R.over(R.lensProp(['list']), (data) => {
        return Object.keys(data).map(key => ({
          key,
          fields: {
            custom_название: data[key]
          }
        }))
      }, response.data)
    }))
    yield put(fetchListSuccess())
  }  catch (error) {
    yield put(fetchListFailure({
      ...action.payload,
      error,
    }))
  }
}

export default function* saga() {
  yield all([
    takeEvery(trialInform, runButton({
      button: 'custom.informed',
      save,
      data: {
        custom_информирован_о_триальной_подписке: true,
      },
    })),

    takeEvery(fetchItem, fetchItemFlow),
    takeEvery(stopAuto, stopAutoFlow),
    takeEvery(fetchList, fetchListFlow)
  ]);
}

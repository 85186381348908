import * as meals from '../meals';
import * as ingredients from '../ingredients';
import * as supplement from '../supplement';

const modules = {
  meals,
  ingredients,
  supplement,
};

const handleAction = action => payload => modules[payload.type][action](payload);

export const fetch = handleAction('fetch');
export const create = handleAction('create');
export const save = handleAction('save');
export const remove = handleAction('remove');
export const removeWithUndo = handleAction('removeWithUndo');
export const copyComposition = handleAction('copyComposition');

export {
  getSelect,
  getProductsByGroup,
  getItemById,
  getKeyById,
  getItem,
  getImage,
  getFeatures,
  getSource,
  getDiseases,
  getRecipe,
  getCaption,
  getRigths,
  getDescription,
} from './selectors';

import * as R from 'ramda';
import moment from 'moment';
import createOrmSelector from 'redux/utils/createOrmSelector';
import createCachedOrmSelector from 'redux/utils/createCachedOrmSelector';

const getItems = createOrmSelector(
  (session) => {
    const { Worker } = session;

    return Worker
      .all()
      .orderBy('custom_login')
      .toRefArray()
      .map((item) => {
        const status = R.propOr(null, 'custom_состояние', item);
        const isInvite = status === 31;
        const getBold = isInvite ?
          R.compose(
            date => moment(date).format('DD.MM.YYYY'),
            R.propOr(undefined, '___createddate'),
          ) : R.propOr(null, 'custom_lastname');
        const getData = isInvite ?
          R.compose(
            date => moment(date).format('hh:mm:ss'),
            R.propOr(undefined, '___createddate'),
          ) : R.compose(
            R.join(' '),
            R.reject(R.isNil),
            R.values,
            R.pick(['custom_firstname', 'custom_midname']),
          );

        return {
          ...item,
          main: {
            login: item.custom_login,
            bold: getBold(item),
            data: getData(item),
          },
          role: R.propOr(null, 'custom_role', item),
        };
      });
  },
);

const getItemsByStatus = status => R.compose(
  R.filter(R.propEq('custom_состояние', status)),
  getItems,
);

export const getActiveItems = getItemsByStatus(2);
export const getInactiveItems = getItemsByStatus(3);
export const getInvitedItems = getItemsByStatus(31);

export const getList = createOrmSelector(
  (session) => {
    const { Worker } = session;

    return Worker
      .all()
      .toRefArray();
  },
);

export const getSelectList = R.compose(
  R.map(item => ({
    value: item.key,
    label: `${item.main.bold} ${item.main.data}`,
  })),
  getActiveItems,
);

export const getItem = createCachedOrmSelector(
  (state, key) => key,

  (session, key) => {
    const { Worker } = session;

    try {
      if (Worker.idExists(key)) {
        return Worker
          .withId(key)
          .ref;
      }

      return Worker
        .filter({
          oldKey: key,
        })
        .last()
        .ref;
    } catch (error) {
      return null;
    }
  },
);

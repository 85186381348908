import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import memoizee from 'memoizee';
import { reduxForm } from 'redux-form';
import { connect } from 'utils/react-redux';

import onFormChange from 'decorators/onFormChange';

import {
  getAnalysisId,
  getAnalysisKey,
} from '../redux/selectors';
import {
  save as saveAnalysis,
} from '../redux/modules/patientAnalysis';

export default function formAnalysisItem({
  save,
  saveParameterCreator,
  formatValue = R.identity,
}) {
  return (WrappedComponent) => {
    const mapStateToProps = () => {
      const getInitialValues = memoizee(R.objOf('value'), { max: 1 });

      return (state, props) => {
        const { name, value } = props;
        const analysisId = getAnalysisId(state);

        return {
          analysisKey: getAnalysisKey(state, { analysisId }),
          initialValues: getInitialValues(value),
          form: name,
        };
      };
    };

    const mapDispatchToProps = {
      save,
      saveAnalysis,
    };

    class FormedContainer extends PureComponent {
      static propTypes = {
        analysisKey: PropTypes.string,
        name: PropTypes.string,
        reset: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        save: PropTypes.func.isRequired,
        saveAnalysis: PropTypes.func.isRequired,
        updateKey: PropTypes.string,
      }

      handleSubmit = this.props.handleSubmit(({ value: originalValue }) => {
        const value = formatValue(originalValue);

        if (R.isNil(value)) {
          return;
        }

        if (R.isEmpty(value)) {
          this.props.reset();
          return;
        }

        this.handleSave(value);
      });

      handleSave = (value) => {
        const { analysisKey, updateKey, name } = this.props;

        return this.props.save({
          key: updateKey,
          data: {
            ___parent: analysisKey,
            ...saveParameterCreator({
              key: name,
              value,
            }),
          },
        });
      }

      render() {
        return (
          <form onSubmit={this.handleSubmit}>
            <WrappedComponent {...this.props} name='value' />
          </form>
        );
      }
    }

    return R.compose(
      connect(mapStateToProps, mapDispatchToProps),
      reduxForm({
        enableReinitialize: true,
        fields: [
          'value',
        ],
      }),
      onFormChange('handleSubmit'),
    )(FormedContainer);
  };
}

import instance from '../../request';

const genericRequest = (config) => {
  switch (config.method.toUpperCase()) {
    case 'POST':
      return instance.post(config.url, config.data, config);
    case 'PUT':
      return instance.put(config.url, config.data, config);
    case 'DELETE':
      return instance.delete(config.url, config);
    default:
      return instance.get(config.url, config);
  }
};

export default genericRequest;


import * as R from 'ramda';
import { createSelector } from 'reselect';

import getRationTableElements from '../getRationTableElements';

export default createSelector(
  getRationTableElements,

  R.pluck('custom_элемент_состава'),
);

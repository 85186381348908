import * as R from 'ramda';
import generator from 'redux/hor/module';

const {
  reducer,
  fetch,
  fetchSuccess,
} = generator({
  name: 'user',
  parseItem: R.evolve({
    ___territory: R.propOr(null, 'key'),
    ___profile: R.propOr(null, 'key'),
  }),
});

export {
  fetch,
  fetchSuccess,
};

export {
  getItem,
} from 'redux/hor/module/selectors';

export {
  getInstitutionKey,
  getProfileKey,
} from './selectors';

export default reducer;

import { createSelector } from 'reselect';

import { save } from 'redux/modules/staff';

import getUser from '../getUser';
import getUserIsLight from '../getUserIsLight';

export default createSelector(
  getUser,
  state => state.windowsOpen.isNeedSideBar,
  getUserIsLight,
  (user, isNeedSideBar, isLight) => {
    if (!isLight) return null;
    if (!user || user.custom_номер_нпа || !isNeedSideBar || user.isCloseNpa ) {
      return null;
    }

    return {
      type: 'isNpa',
      content: {
        onClose: save({
          key: user.key,
          data: {
            isCloseNpa: true,
          },
          isLocal: true,
        }),
      },
    };
  },
);

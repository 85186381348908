import createOrmSelector from 'redux/utils/createOrmSelector';

import getDietanamnezKey from '../getDietanamnezKey';

export default createOrmSelector(
  getDietanamnezKey,

  (session, key) => {
    const { Dietanamnez } = session;

    try {
      const result = Dietanamnez.withId(key).ref;

      return result;
    } catch (error) {
      return undefined;
    }
  },
);

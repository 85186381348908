import * as R from 'ramda';
import { fk, attr, Model } from 'redux-orm';

import generator from 'redux/hor/orm-module';
import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,
  fetch,
} = generator({
  name: 'Elements',
  tableId: 28,
  parseItem: R.evolve({
    ___parent: R.propOr(null, 'key'),
  }),
});

export const fetchList = fetchWithView(fetch, 'ts5c28cs9r349', {
  useLS: true,
});

export default class Elements extends Model {
  static modelName = 'Elements';

  static reducer = reducer;

  static options = {
    idAttribute: 'key',
  }

  static fields = {
    ___parent: fk('Elements', 'items'),
    custom_наименование: attr(),
    custom_сокращенное_наименование: attr(),
    custom_ед_изм: attr(), // TODO: fk
    custom_для_сортировки: attr(),
    custom_light: attr(),
    custom_used_in_pro: attr(),
    custom_источники: attr(),
    custom_влияние: attr(),
  }

  toString() {
    return `Elements: ${this.key}`;
  }
}

import moment from 'moment';

import createCachedOrmSelector from 'redux/utils/createCachedOrmSelector';

import getAnalysisKey from '../getAnalysisKey';

export default createCachedOrmSelector(
  getAnalysisKey,

  (session, analysisKey) => {
    const { PatientAnalysis } = session;

    let item;

    if (PatientAnalysis.idExists(analysisKey)) {
      item = PatientAnalysis.withId(analysisKey);
    } else {
      item = PatientAnalysis.filter({ oldKey: analysisKey }).first();
    }

    if (!item) {
      return {};
    }

    return {
      key: item.key || analysisKey,
      id: item.id,
      time: item.custom_дата_текстом || 'Сегодня',
      type: item.custom_вид_анализа.key,
      typeId: item.custom_вид_анализа.id,
      caption: item.custom_вид_анализа.custom_наименование,
      date: item.custom_дата || moment(),
      ___parent: item.___parent,
    };
  },
);

import * as R from 'ramda';
import createOrmSelector from 'redux/utils/createOrmSelector';

import timeToNumber from '../../../utils/timeToNumber';

import getDayKey from '../getDayKey';

export default createOrmSelector(
  getDayKey,

  (session, key) => {
    const { Day } = session;
    const day = Day.withId(key);

    const groups = day.groups;

    return R.compose(
      R.sortBy(timeToNumber),
      R.keys,
    )(groups);
  },
);

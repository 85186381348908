import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';

import styles from './IconLoaderFilled.module.css';

const cx = classNames.bind(styles);

export default class IconLoaderFilled extends PureComponent {
  static propTypes = {
    size: PropTypes.oneOf([
      'l',
      'xl',
    ]),
  }

  static defaultProps = {
    size: undefined,
  }

  render() {
    const { size } = this.props;

    return (
      <div
        className={cx('iconLoader', {
          [`iconLoader_size_${size}`]: size,
        })}
      />
    );
  }
}

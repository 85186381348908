import * as R from 'ramda';
import memoizee from 'memoizee';

import { getItem, getItemsArray } from 'redux/hor/module/selectors';

export const getItemsList = R.compose(
  memoizee(R.map(item => ({
    ...item,
    value: item.key,
    label: item.custom_название,
  }))),
  getItemsArray,
);

export const getProductsByGroups = R.compose(
  memoizee(R.groupBy(R.prop('custom_группа'))),
  getItemsList,
);

export const getIsCompositionCalcing = R.compose(
  R.propOr(false, 'isCalcComposition'),
  getItem,
);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import AnalysisItemSalt from '../../components/AnalysisItemSalt';
import { save } from '../../redux/modules/analysisSalt';
import formAnalysisItem from '../../utils/formAnalysisItem';

const saveParameterCreator = ({
  key,
  value,
}) => ({
  custom_химический_элемент: key,
  custom_результат: value,
});

class AnalysisItemSaltContainer extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.array,
    updateKey: PropTypes.array,
  }

  render() {
    return (
      <AnalysisItemSalt
        name={this.props.name}
        label={this.props.label}
        options={this.props.options}
        updateKey={this.props.updateKey}
      />
    );
  }
}

export default R.compose(
  formAnalysisItem({
    save,
    saveParameterCreator,
  }),
)(AnalysisItemSaltContainer);

import * as R from 'ramda';

import generator from 'redux/hor/module';

const {
  reducer,
  fetchSuccess,
} = generator({
  name: 'SupplementAllergen',
  tableId: 167,
  parentField: 'custom_пациент',
  parseItem: R.evolve({
    custom_пациент: R.propOr(null, 'key'),
    custom_бад: R.propOr(null, 'key'),
  }),
});

export {
  fetchSuccess,
};

export {
  getPatientAllergen,
} from './selectors';

export default reducer;

import { createAction, createReducer } from 'redux-act'

export const loadId = createAction('INBODY__LOADID')
export const loadIdSuccess = createAction('INBODY__LOADID_SUCCESS')
export const loadIdFailure = createAction('INBODY__LOADID_FAILURE')

export const loadInbodyReport = createAction('INBODY__LOADREPORT')
export const loadInbodyReportSuccess = createAction('INBODY__LOADREPORT_SUCCESS')
export const loadInbodyReportFailure = createAction('INBODY__LOADREPORT_FAILURE')


const reducer = createReducer({
  [loadIdSuccess]: (state, id) => ({
    ...state,
    id,
  }),
  [loadIdFailure]: (state) => ({
    ...state,
    id: null,
  }),
}, {
  id: null,
})

export {
  getItems,
} from './selectors';

export default reducer;

export { default as saga } from './saga';

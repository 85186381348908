import * as R from 'ramda';

function roundNumber(precision, value) {
  if (typeof value !== 'number' || typeof precision !== 'number') {
    throw new Error('Value and precision should be number');
  }

  const accuracy = 10 ** precision;
  return Math.round(value * accuracy) / accuracy;
}

export default R.curry(roundNumber);

import * as R from "ramda";
import memoizee from "memoizee";

import { getItemsArray } from "redux/hor/module/selectors";

export const getItems = memoizee(
  R.compose(
    R.map((item) => ({
      label: item.custom_название,
      value: item.key,
      localization: item.custom_элементы_локализации,
    })),
    getItemsArray
  ),
  { max: 1 }
);

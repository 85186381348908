import { combineReducers } from 'redux';
import { all, call } from 'redux-saga/effects';

import supplementAllergen from './modules/supplementAllergen';
import ui from './modules/ui';

import supplementSaga from './modules/supplement/saga';

export default combineReducers({
  supplementAllergen,
  ui,
});

export function* saga() {
  yield all([
    call(supplementSaga),
  ]);
}

import * as R from 'ramda';
import { createSelector } from 'reselect';

import getPatient from '../getPatient';

const emptyArray = [];

export default createSelector(
  getPatient,

  R.compose(
    R.defaultTo(emptyArray),
    R.propOr(emptyArray, 'custom_болезни'),
  ),
);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Router, applyRouterMiddleware } from 'react-router';
import { useScroll as scrollMiddleware } from 'react-router-scroll';

import AppLoader from 'containers/AppLoader';

export default class AppContainer extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    routes: PropTypes.func.isRequired,
  }

  render() {
    const { store, history, routes } = this.props;

    return (
      <Provider store={store} key='provider'>
        <div>
          <Router
            history={history}
            routes={routes(store)}
            render={applyRouterMiddleware(scrollMiddleware((_, { location }) => location.action !== 'REPLACE'))}
          />
          <AppLoader />
        </div>
      </Provider>
    );
  }
}

import * as R from "ramda";
import { setUser, captureMessage, addBreadcrumb } from "@sentry/browser";

import { logout } from "redux/modules/auth";
import { fetchSuccess } from "redux/modules/user";

import { getApplicationUser, getUserEmail } from "redux/selectors";

import getErrorTextFromResponse from "../utils/getErrorTextFromResponse";

const actionBlackList = [
  "@@redux-form/REGISTER_FIELD",
  "@@redux-form/UPDATE_SYNC_ERRORS",
  "@@redux-form/INITIALIZE",
  "@@redux-form/DESTROY",
  "@@redux-form/BLUR",
  "@@redux-form/FOCUS",
  "@@redux-form/TOUCH",
  "@@redux-form/UNREGISTER_FIELD",
  "@@redux-form/CHANGE",

  "AUTH/TOKEN_UPDATE",
  "AUTH/LOGIN_FAILURE",
  "PING/PING_FAILURE",
];

const exceptionBlackList = [
  (exception) => exception.message === "Network Error",
  (exception) => exception?.sentryIgnore,
  (exception) =>
    exception?.response?.data?.exception?.message?.includes(
      "Не удалось определить принадлежность учреждению с идентификатором"
    ),
  (exception) =>
    /Invalid key .+ for json GET request/.test(
      exception?.response?.data?.exception?.message
    ),
];

const formatActionType = R.replace(/^\[\d+\] /, "");
const getIsError = R.contains("FAILURE");
const getIsSuccess = R.contains("SUCCESS");

const setUserContext = (action, store) => {
  if (action.type === fetchSuccess.toString()) {
    setTimeout(() => {
      const state = store.getState();

      setUser({
        id: getApplicationUser(state),
        email: getUserEmail(state),
      });
    }, 0);
  }

  if (action.type === logout.toString()) {
    setUser(null);
  }
};

const setBreadcrumb = (isFiltered, isError, action, actionType) => {
  if (!isFiltered && !isError) {
    addBreadcrumb({
      category: "redux",
      message: actionType,
      data: getIsSuccess(action.type) ? undefined : action.payload,
    });
  }
};

const ravenMiddleware = (store) => (next) => (action) => {
  const actionType = formatActionType(action.type);
  const isError = getIsError(actionType);
  const isFilteredByAction = R.contains(actionType, actionBlackList);

  setUserContext(action, store);
  setBreadcrumb(isFilteredByAction, isError, action, actionType);

  const response = R.pathOr({}, ["error", "response"], action.payload);
  const isLogout = R.propEq("status", 401, response);

  if (isError && !isLogout && !isFilteredByAction) {
    const isFilteredByError =
      action.payload?.error &&
      R.any((fn) => fn(action.payload?.error), exceptionBlackList);

    if (!isFilteredByError) {
      const exception = getErrorTextFromResponse(action.payload?.error);

      captureMessage(`${actionType}. ${exception}`, {
        extra: {
          action,
          response,
        },
      });
    }
  }

  return next(action);
};

export default ravenMiddleware;

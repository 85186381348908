import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getProfileKey } from 'redux/modules/user';
import getApplicationUser from '../getApplicationUser';

export default createSelector(
  R.path(['auth', 'profileKey']),
  getApplicationUser,
  R.path(['user']),

  (authProfileKey, userKey, state) => {
    const profileKey = getProfileKey(userKey, state);

    return profileKey || authProfileKey;
  },
);

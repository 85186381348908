import moment from 'moment';

import getKeyInfo from 'utils/getKeyInfo';

import createOrmSelector from 'redux/utils/createOrmSelector';

import getPatientKey from 'redux/selectors/getPatientKey';

const getName = (item) => {
  try {
    return item.custom_специалист.fullname;
  } catch (e) {
    return null;
  }
};

export default createOrmSelector(
  getPatientKey,

  (session, patientKey) => {
    const { Dietanamnez } = session;

    return Dietanamnez
      .exclude({
        isDeleted: true,
      })
      .filter({
        ___parent: patientKey,
      })
      .filter(item => item.custom_дата_назначения)
      .orderBy('custom_дата_назначения', 'desc')
      .toModelArray()
      .map((item) => {
        const start = moment(item.custom_дата_назначения);
        const id = getKeyInfo(item.key).record;

        return {
          id: id ? id.toString() : undefined,
          key: item.key,
          dateStart: start.format("DD.MM.YYYY HH:mm"),
          specialist: getName(item),
          isDeleting: item.isDeleting,
          custom_селфтест_до: item.custom_селфтест_до
        };
      })
      .filter(item => item.id);
  },
);

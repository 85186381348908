import * as R from 'ramda';
import { createReducer, createAction } from 'redux-act';

import { logout } from 'redux/modules/auth';

const getOrigin = R.pathOr(null, ['location', 'host']);

const checkOrigin = () => {
  try {
    return getOrigin(window.opener) !== getOrigin(window);
  } catch (e) {
    return true;
  }
};

const initialState = {
  openedWindows: [],
  isNeedSideBar: checkOrigin(),
};

export const openWindow = createAction('WINDOWS_OPEN__OPEN_ONE_WINDOW');
export const closeWindows = createAction('WINDOWS_OPEN__CLOSE_ALL_WINDOWS');

const handleOpenWindow = (state, url) => {
  const width = Math.min(window.screen.width, 1200);
  const height = Math.min(window.screen.height, 800);
  const newWindow = window.open(url, null, `width=${width},height=${height}`);
  const openedWindows = state.openedWindows.concat(newWindow);

  return {
    ...state,
    openedWindows,
  };
};

const handleCloseWindows = (state) => {
  state.openedWindows.forEach((oneWindow) => {
    oneWindow.close();
  });
  return initialState;
};

const reducer = createReducer((on) => {
  on(openWindow, handleOpenWindow);
  on(closeWindows, handleCloseWindows);
  on(logout, handleCloseWindows);
}, initialState);

export default reducer;

import * as R from 'ramda';
import { createSelector } from 'reselect';

import getPatient from '../getPatient';

export default createSelector(
  getPatient,

  R.compose(
    R.indexBy(R.prop('element')),
    R.map((item) => {
      const norm = R.propOr(null, 'норма_среднее', item);
      let min = R.propOr(null, 'от', item);
      let max = Number(R.propOr(null, 'до', item));

      if (min === 0 && max === 0) {
        min = null;
        max = null;
      }

      return {
        element: R.prop('элемент', item),
        norm,
        normMin: min,
        normMax: max,
      };
    }),
    R.propOr([], 'custom_коридор_спк')
  )
);

import moment from 'moment';

const convertFromServerTimeZoneToCurrentTimeZone = value => {
  if (!value) {
    return value;
  }

  const timeDifference = new Date().getTimezoneOffset() + 180
  const date = moment(value).add(-timeDifference, 'minutes').format();

  return date;
}

export default convertFromServerTimeZoneToCurrentTimeZone;
import { createAction, createReducer } from 'redux-act';
import { LOCATION_CHANGE } from 'react-router-redux';

const initialState = {
  application: true,
};

export const showLoader = createAction('LOADER__SHOW');
export const hideLoader = createAction('LOADER__HIDE');

const handleSetLoader = status => (state, payload) => {
  if (typeof payload === 'string') {
    const currentStatus = state[payload];

    if (currentStatus === status) {
      return state;
    }

    return {
      ...state,
      [payload]: status,
    };
  }

  return {
    ...state,
    [payload.name]: {
      ...payload,
      status,
    },
  };
};

const reducer = createReducer((on) => {
  on(showLoader, handleSetLoader(true));
  on(hideLoader, handleSetLoader(false));
  on(LOCATION_CHANGE, (state) => handleSetLoader(true)(state, 'global'));
}, initialState);

export default reducer;

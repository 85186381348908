import createOrmSelector from 'redux/utils/createOrmSelector';

import getUserType from 'redux/selectors/getUserType';

import getDietanamnezType from '../getDietanamnezType';

export default createOrmSelector(
  getDietanamnezType,
  getUserType,

  (session, daType, userType) => {
    const { DietanamnezTiles } = session;

    return DietanamnezTiles
      .exclude({
        ___parent: null,
      })
      .filter(({ custom_формат }) => !custom_формат || custom_формат === daType)
      .filter(({ custom_версия_приложения }) => !custom_версия_приложения || custom_версия_приложения === userType)
      .toRefArray();
  },
);

import React, { PureComponent } from 'react';
import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'utils/react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { getItems as getInstitutions } from 'redux/modules/institutions';
import { getItemsByCountry as getCities } from 'redux/modules/cities';
import { getItems as getCountries } from 'redux/modules/countries';
import InstituteForm from 'components/InstituteForm';

import createValidate from 'utils/validation';
import { instituteFormPreset } from 'utils/validation/presets';

const mapStateToProps = (state) => {
  const values = getFormValues('registerInstitute')(state) || {};
  const country = values.custom_страна;

  return {
    showInstitutions: values.custom_филиал === 'true',
    institutions: getInstitutions(state.institutions),
    cities: getCities(values.custom_страна, state.cities),
    emptyCities: getCities(null, state.cities),
    countries: getCountries(state.countries),
    country,
  };
};

const form = {
  form: 'registerInstitute',
  validate: createValidate((values, { institutions, showInstitutions }) => ({
    ...instituteFormPreset.validation,
    custom_название: {
      ...instituteFormPreset.validation.custom_название,
      unique: {
        list: R.pluck('label', institutions || []),
        errorText: 'Такое учреждение уже существует',
      },
    },
    custom_головное_учреждение: {
      required: showInstitutions,
    },
  })),
  fields: [
    'custom_логотип',
    'custom_название',
    'custom_филиал',
    'custom_головное_учреждение',
    'custom_тип',
    'custom_страна',
    'custom_город',
    'custom_улица',
    'custom_дом',
    'custom_корпус',
    'custom_телефон',
    'custom_эл_почта',
  ],
  initialValues: {
    custom_филиал: 'false',
    custom_тип: '2',
  },
};

class InstituteFormContainer extends PureComponent {
  static propTypes = {
    change: PropTypes.func,
    cities: PropTypes.array,
    countries: PropTypes.array,
    country: PropTypes.string,
    emptyCities: PropTypes.array,
    handleSubmit: PropTypes.func.isRequired,
    institutions: PropTypes.array,
    showInstitutions: PropTypes.bool,
    valid: PropTypes.bool,
    submitting: PropTypes.bool,
  }

  static defaultProps = {
    change: () => { },
    cities: [],
    countries: [],
    country: undefined,
    emptyCities: [],
    handleSubmit: () => { },
    institutions: [],
    showInstitutions: false,
    valid: false,
    submitting: false,
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.country && this.props.country !== nextProps.country) {
      this.props.change('custom_город', null);
    }
  }

  handleSubmit = this.props.handleSubmit((values) => {
    const data = R.evolve({
      custom_тип: Number,
      custom_филиал: R.equals('true'),
      custom_название: R.trim,
    }, values);

    this.props.onSubmit(data, this.props.form);
  })

  render() {
    return (
      <InstituteForm
        step='registration'
        institutions={this.props.institutions}
        countries={this.props.countries}
        cities={this.props.cities}
        emptyCities={this.props.emptyCities}
        showInstitutions={this.props.showInstitutions}
        onSubmit={this.handleSubmit}
        valid={this.props.valid}
        submitting={this.props.submitting}
      />
    );
  }
}

export default R.compose(
  connect(mapStateToProps),
  reduxForm(form),
)(InstituteFormContainer);

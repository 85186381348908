export default function declOfNum(titles) {
  const cases = [2, 0, 1, 1, 1, 2];

  return (number) => {
    const indexByCase = cases[(number % 10 < 5) ? number % 10 : 5];
    const index = (number % 100 > 4 && number % 100 < 20) ? 2 : indexByCase;

    return titles ? titles[index] : '';
  };
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';

import styles from './ToolTip.module.css';

const cx = classNames.bind(styles);

export default class Tooltip extends PureComponent {
  static propTypes = {
    toolTipX: PropTypes.number,
    toolTipY: PropTypes.number,
    toolTipColor: PropTypes.string,
    toolTipText: PropTypes.string,
  }

  render() {
    return (
      <div
        className={cx('tooltip')}
        style={{
          backgroundColor: this.props.toolTipColor,
          left: this.props.toolTipX + 5,
          top: this.props.toolTipY - 25,
        }}
      >
        {this.props.toolTipText}
      </div>
    );
  }
}

import * as R from 'ramda';
import { createAction, createReducer } from 'redux-act';

const initialState = {
  serverUnderMaintenance: false,
};

export const ping = createAction('PING__PING');
export const pingSuccess = createAction('PING__PING_SUCCESS');
export const pingFailure = createAction('PING__PING_FAILURE');

export const handlePingSuccess = R.assoc('serverUnderMaintenance', false);

export const handlePingFailure = (state) => {
  if (state.serverUnderMaintenance) {
    return state;
  }

  return {
    ...state,
    serverUnderMaintenance: true,
  };
};

export default createReducer({
  [pingSuccess]: handlePingSuccess,
  [pingFailure]: handlePingFailure,
}, initialState);

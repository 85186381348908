import * as R from 'ramda';

import { getItem } from 'redux/hor/module/selectors';

export const getInstitutionKey = R.compose(
  R.propOr(null, '___territory'),
  getItem,
);

export const getProfileKey = R.compose(
  R.propOr(null, '___profile'),
  getItem,
);

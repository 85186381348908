import createOrmSelector from 'redux/utils/createOrmSelector';
import getPatientKey from 'redux/selectors/getPatientKey';

export default createOrmSelector(
  getPatientKey,

  (session, patientKey) => {
    const { Dietanamnez } = session;
    const list = Dietanamnez
      .exclude({
        isDeleted: true,
      })
      .filter({
        ___parent: patientKey,
      })
      .filter(item => !item.custom_селфтест_до)
      .orderBy('custom_дата_назначения')
      .toModelArray();

    return list
  }
)
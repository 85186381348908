import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';

import styles from './Title.module.css';

const cx = classNames.bind(styles);

export default class Title extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    color: PropTypes.oneOf([
      'inherit',
    ]),
    component: PropTypes.string,
    ellipsis: PropTypes.bool,
    size: PropTypes.oneOf([
      'small',
      'medium',
      'post-medium',
      'big',
    ]),
  };

  static defaultProps = {
    children: undefined,
    color: undefined,
    component: undefined,
    ellipsis: false,
    size: undefined,
    weight: undefined,
  };

  getComponentBySize(size) {
    const map = {
      big: 'h1',
      medium: 'h3',
    };

    return map[size] || 'h2';
  }

  render() {
    const {
      color,
      size,
      component,
      ellipsis,
    } = this.props;
    const Tag = component || this.getComponentBySize(size);

    return (
      <Tag
        title={ellipsis ? this.props.children : null}
        className={cx({
          title: true,
          [`title_size_${size}`]: size,
          [`title_color_${color}`]: color,
          title_ellipsis: ellipsis,
        })}
      >
        {this.props.children}
      </Tag>
    );
  }
}

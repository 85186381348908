import React from "react";

import Text from 'components/Text';
import Modal from 'components/Modal';
import ModalForm from 'components/ModalForm';
import Title from "components/Title"
import { withRouter } from "react-router";
import styles from "./AmwayPopup.module.css";
import classnames from "classnames/bind";

const cx = classnames.bind(styles);


const AmwayPopup = (props) => {
    const { isVisible } = props;
    const redirectToAmway = "/lite";
    const handleSubmit = () => { props.router.push(redirectToAmway) }
    return <Modal
        ariaHideApp={false}
        isOpen={isVisible}
    >
        <ModalForm
            submitBtn='Да'
            cancelBtn='Нет'
            onSubmit={handleSubmit}
            valid={true}
            onCancel={props.handleHide}
            noPadding
        >
            <div className={cx("body")}>
                <div className={cx("left-part")}>
                    <Title size='medium'>
                        Вы являетесь независимым предпринимателем Amway?
                    </Title>
                    <div className={cx("description")}>
                        <Text>
                            Мы предлагаем Вам специальную версию нашего сервиса
                        </Text>
                    </div>

                </div>
                <img alt='' src="http://go.nutrilogic.ru/static/media/amway_welcome.jpg" className={cx("right")} />
            </div>
        </ModalForm>
    </Modal>
}

export default withRouter(AmwayPopup);
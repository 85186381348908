import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import { Field as FormField } from 'redux-form';
import containerQuery from 'decorators/containerQuery';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'utils/react-redux';
import { Row, Column } from 'components/Grid';
import Button from 'components/Button';
import Field from 'components/Field';
import FieldPassword from 'components/FieldPassword';
import Text from 'components/Text';
import Title from 'components/Title';
import Info from 'components/Info';
import AvatarPicker from 'components/AvatarPicker';
import RadioGroup from 'components/RadioGroup';
import RequiredNotice from 'components/RequiredNotice';
import Link from 'components/Link';
import Checkbox from 'components/Checkbox';
import Select from 'components/Select';
import FieldDate from 'components/FieldDate';
import styles from './UserForm.module.css';
import {getUser} from "../../redux/selectors";

const cx = classNames.bind(styles);

const radioGroupSex = [
  {
    id: 'radioGroupSexMale',
    value: '1',
    content: 'Мужской',
  },
  {
    id: 'radioGroupSexFemale',
    value: '2',
    content: 'Женский',
  },
];

const mapStateToProps = () => {
  return (state) => {
    const {
      User: { statistic: { deleting } }
    } = state
    return {
      deleting,
    }
  }
}

class UserForm extends PureComponent {
  static propTypes = {
    cities: PropTypes.array,
    containerQuery: PropTypes.object,
    countries: PropTypes.array,
    dirty: PropTypes.bool,
    emptyCities: PropTypes.array,
    step: PropTypes.string,
    valid: PropTypes.bool,
    onReset: PropTypes.func,
    onSubmit: PropTypes.func,
    onExitGroup: PropTypes.func,
    submitting: PropTypes.bool,
    isAccessOpen: PropTypes.bool,
    onAddAccess: PropTypes.func,
    onConfirm: PropTypes.func,
    isLight: PropTypes.bool,
    isConfirmed: PropTypes.bool,
    deleting: PropTypes.bool,
    invite: PropTypes.object,
  }

  static defaultProps = {
    cities: [],
    containerQuery: PropTypes.object,
    countries: [],
    dirty: false,
    emptyCities: [],
    step: 'registration',
    valid: false,
    onReset: () => { },
    onSubmit: () => { },
    onExitGroup: () => {},
    submitting: false,
    isAccessOpen: false,
    onAddAccess: () => { },
    isLight: false,
    invite: {},
  }

  handlerOnFocusLinkRegistry = (event) => {
    event.target.select()
  }

  renderSupportButton() {
    const { isAccessOpen } = this.props;

    const button = (
      <Button
        size='small'
        color='blue'
        bordered
        onClick={this.props.onAddAccess}
        disabled={isAccessOpen}
      >
        Открыть доступ поддержке
      </Button>
    );

    if (isAccessOpen) {
      return (
        <Info toggler={button}>
          <Text>
            Вы уже дали временный доступ службе поддержки. Дождитесь истечения текущего.
          </Text>
        </Info>
      );
    }

    return button;
  }

  renderForm() {
    const { step, isLight, isPartner, invite: { inviteName, inviteKey }, deleting } = this.props;
    const isInvite = step === 'invite';
    const isProfile = step === 'profile';
    const isRegistration = step === 'registration';
    const titleByStep = {
      invite: 'Приглашение',
      registration: 'Регистрация пользователя',
    };
    const isRequired = isInvite || isRegistration;
    const hasInvite = inviteKey && inviteName;

    return (
      <Row>
        <Column small={4} medium={2} big={2} mediumOrder={1} bigOrder={1}>
          <div className={cx('filePicker')}>
            <AvatarPicker
              id='instituteFilePicker'
              name='custom_фото'
              title='Фото'
              fileTitle='Изменить фото'
            />
          </div>
          <hr className={cx('hr')} />
        </Column>

        <Column small={4} medium={4} big={4}>
          <Row>
            <Column small={4} medium={4} big={4}>
              {(isRegistration || isInvite) &&
                <div className={cx('title')}>
                  <Title>
                    {titleByStep[step]}
                  </Title>
                </div>
              }

              {isRegistration && <div className={cx('subtitle')}>
                <RequiredNotice />
              </div>}
            </Column>
            <Column small={4} medium={4} big={4}>
              <div className={cx('container')}>
                <fieldset className={cx('fieldset')}>
                  <div className={cx('field')}>
                    <Field
                      id='userSurname'
                      name='custom_lastname'
                      label='Фамилия'
                      required={true}
                    />
                  </div>

                  <div className={cx('field')}>
                    <Field
                      id='userName'
                      name='custom_firstname'
                      label='Имя'
                      required={true}
                    />
                  </div>

                  <div className={cx('field')}>
                    <Field
                      id='userPatron'
                      name='custom_midname'
                      label='Отчество'
                    />
                  </div>

                  <Row>
                    <Column small={2} medium={2} big={2}>
                      <div className={cx('select-title')}>
                        <Text color='gray'>Дата рождения</Text>
                      </div>
                      <div className={cx('select-group')}>
                        <FieldDate
                          name='custom_dob'
                        />
                      </div>
                    </Column>
                    <Column small={2} medium={2} big={2}>
                      <div className={cx('radio-title')}>
                        <Text color='gray'>Пол</Text>
                      </div>
                      <RadioGroup
                        name='custom_sexint'
                        items={radioGroupSex}
                      />
                    </Column>
                  </Row>

                  <div className={cx('field')}>
                    <Field
                      required={!isInvite}
                      id='userPhone'
                      name='custom_мобильный_телефон'
                      label='Личный номер мобильного телефона'
                      placeholder='Поможет хранить данные в безопасности, упростит поддержку'
                      mask='+??9 (999) 999-99-99'
                      phone={true}
                    />
                    {(isProfile && process.env.REACT_APP_API !== 'dd') &&
                      <FormField
                        name='custom_мобильный_телефон'
                        component={({ input, meta }) => (
                          <div className={cx('phone')} >
                            {this.props.isConfirmed && input?.value === this.props.phoneValue || this.props.isConfirmedPhone ?
                              <Text color='green'>
                                подтвержден
                              </Text> :
                              <Button
                                block
                                size='small'
                                bordered
                                disabled={input?.value !== this.props.phoneValue && !this.props.phoneIsValid}
                                onClick={this.props.onConfirm}
                              >
                                Подтвердить
                              </Button>
                            }
                          </div>
                        )}
                      />
                    }
                  </div>
                  {
                    isLight &&
                    <div className={cx('field')}>
                      <Field
                        name='custom_номер_нпа'
                        label='Номер НПА'
                        required={true}
                      />
                    </div>
                  }
                  {!isLight &&
                    <div className={cx('field')}>
                      <Field
                        id='userPost'
                        name='custom_должность'
                        label='Должность'
                      />
                    </div>
                  }

                  {isProfile &&
                    <Row>
                      <Column small={2} medium={2} big={2}>
                        <div className={cx('field')}>
                          <Select
                            id='instituteCity'
                            name='custom_страна'
                            label='Страна'
                            options={this.props.countries}
                          />
                        </div>
                      </Column>
                      <Column small={2} medium={2} big={2}>
                        <div className={cx('field')}>
                          <Select
                            id='instituteCity'
                            name='custom_город'
                            label='Город'
                            disabled={R.isEmpty(this.props.cities)}
                            options={this.props.cities}
                            extraOptions={this.props.emptyCities}
                            alwaysShowExtra
                            onBlurResetsInput
                            minLengthForResult={0}
                            searchable
                          />
                        </div>
                      </Column>
                    </Row>
                  }
                  {
                    (isLight && !isRegistration) && hasInvite &&
                    <div className={cx('field')} style={{marginTop: '20px'}}>
                      <Field
                        label='Вы состоите в группе пользователя'
                        value={inviteName}
                        disabled
                      />
                      <FormField
                        name='custom_invite'
                        component={({ input, meta }) => (
                          <div className={cx('phone')}>
                            <Button
                              block
                              isLoading={deleting}
                              size='small'
                              bordered
                              onClick={() => this.props.onExitGroup(inviteKey)}
                            >
                              Выйти
                            </Button>
                          </div>
                        )}
                      />
                    </div>
                  }
                  {!isProfile && <hr className={cx('hr')} />}
                  {!isInvite && !isProfile &&
                    <div className={cx('field')}>
                      <Field
                        id='userEmail'
                        name='custom_login'
                        label='Эл. почта'
                        required={isRequired}
                        disabled={isProfile}
                        placeholder='Будет являться логином для входа на сайт'
                      />
                    </div>
                  }
                  {isProfile &&
                    <div>
                      <div className={cx('link')}>
                        <Button
                          size='small'
                          to='/user/profile/change-password'
                          color='blue'
                          bordered
                        >
                          Изменить пароль
                        </Button>
                      </div>
                      <div className={cx('link')}>
                        {this.renderSupportButton()}
                      </div>
                    </div>
                  }
                  {(isRegistration || isInvite) &&
                    <div className={cx('field')}>
                      <FieldPassword
                        id='custom_password'
                        name='custom_password'
                        label='Пароль'
                        required={isRequired}
                        placeholder='Не менее 6 символов'
                      />
                    </div>
                  }
                </fieldset>
              </div>
            </Column>
          </Row>
        </Column>
      </Row>
    );
  }

  renderButtons() {
    const { valid, dirty, step, onReset, submitting } = this.props;
    const isRegistration = step === 'registration';
    const isInvite = step === 'invite';
    const isProfile = step === 'profile';

    return (
      <div>
        <hr className={cx('hr')} />

        {(isRegistration || isInvite) &&
          <div className={cx('buttons')}>
            <div className={cx('button')}>
              <Button
                id={isRegistration && 'userSignup'}
                type='submit'
                size='big'
                bordered
                disabled={!valid}
                isLoading={submitting}
              >
                Продолжить
              </Button>
            </div>

            {process.env.REACT_APP_API !== 'dd' && <div className={cx('checkbox')}>
              <Checkbox
                sizeCheck='small'
                name='eula'
                align='top'
                theme='dark'
              >
                Нажимая&nbsp;“Продолжить”&nbsp;вы&nbsp;соглашаетесь&nbsp;с&ensp;
                <div className={cx('line')}>
                  <Link
                    target='_blank'
                    color='inherit'
                    underline
                    hover='blue'
                    href='https://go.nutrilogic.ru/files/User%20agreement.pdf'
                  >
                    Пользовательским соглашением
                  </Link>
                  {' '}
                  и
                  {' '}
                  <Link
                    target='_blank'
                    color='inherit'
                    underline
                    hover='blue'
                    href='https://go.nutrilogic.ru/files/Privacy%20policy.pdf'
                  >
                    Политикой конфиденциальности
                  </Link>
                  <span className={cx('required')}>*</span>
                </div>
              </Checkbox>
            </div>}
          </div>
        }

        {isProfile &&
          <div className={cx('buttons')}>
            <div className={cx('button')}>
              <Button type='submit' size='big' bordered disabled={!valid}>
                Сохранить
              </Button>
            </div>
            <div className={cx('button')}>
              <Button type='button' size='big' onClick={onReset} bordered disabled={!dirty}>
                Отмена
              </Button>
            </div>
          </div>
        }
      </div>
    );
  }

  render() {
    return (
      <form
        onSubmit={this.props.onSubmit}
        className={cx('userForm', this.props.containerQuery)}
      >
        {this.renderForm()}
        {this.renderButtons()}
      </form>
    );
  }
}

export default R.compose(
  containerQuery(styles),
  connect(mapStateToProps)
)(UserForm);

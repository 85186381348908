import * as R from 'ramda';
import { createSelector } from 'reselect';

import getDietanamnezProducts from '../getDietanamnezProducts';

export default createSelector(
  getDietanamnezProducts,

  R.filter(R.propEq('value', 1)),
);

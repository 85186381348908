import * as R from 'ramda';

const ignoreClear = [
  /conclusion-collapsed_.+/,
  /showChart_.+/,
  /useDietanamnez_.+/,
];

export const setItem = (key, value) => {
  const item = JSON.stringify(value);
  try {
    localStorage.setItem(key, item);
  } catch (e) {
    // continue regardless of error
  }
};


export const getItem = (key) => {
  const itemJson = localStorage.getItem(key);

  if (!itemJson) {
    return null;
  }

  return JSON.parse(itemJson);
};

export const clear = () => {
  R.compose(
    R.forEach(key => localStorage.removeItem(key)),
    R.reject(value => R.any(R.test(R.__, value), ignoreClear)),
    R.keys,
  )(localStorage);
};
const TTL = 72 * 60 * 60 * 1000; // время жизни для регистрации на селфтесте
export const setLSItemTTL = (key, value, ttl = TTL) => {

  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl
  }
  try {
    localStorage.setItem(key, JSON.stringify(item))
  } catch (e) {

  }
}

export const getLSItemTTL = (key) => {
  const itemStr = localStorage.getItem(key)

  if (!itemStr) {
    return null
  }
  const item = JSON.parse(itemStr)
  const now = new Date()

  if (now.getTime() > item.expiry) {

    localStorage.removeItem(key)
    return null
  }
  return item.value
}

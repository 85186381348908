import * as R from 'ramda';
import moment from 'moment';
import createOrmSelector from 'redux/utils/createOrmSelector';

import getUser from '../getUser';
import getUserPatients from '../getUserPatients';

// NOTE: memoize by result as we in some cases always get new startDate (like in case of 'now')?
export default createOrmSelector(
  getUser,
  getUserPatients,
  (_, params) => R.propOr(null, 'startDate', params),
  (_, params) => R.propOr(null, 'endDate', params),
  (_, params) => R.propOr(null, 'custom_отключено', params),

  (
    { PatientReceptions },
    worker,
    patientList,
    propsStartDate,
    propsEndDate,
    props_custom_отключено,
  ) => {
    const workerKey = R.propOr(null, 'key', worker);
    const startDate = propsStartDate ? moment(propsStartDate) : null;
    const endDate = propsEndDate ? moment(propsEndDate) : null;

    return PatientReceptions
      .filter(({ ___parent, custom_vremya, custom_отключено }) => (
        (startDate ? custom_vremya.isAfter(startDate) : true) &&
        (endDate ? custom_vremya.isBefore(endDate) : true) &&
        (props_custom_отключено !== null ? custom_отключено === props_custom_отключено : true) &&
        ___parent === workerKey
      ))
      .exclude({
        isDeleting: true,
      })
      .exclude({
        isDeleted: true,
      })
      .orderBy('custom_vremya')
      .toModelArray()
      .map(item => ({
        ...item.ref,
        id: item.id,
        patient: R.find(R.propEq('key', item.custom_planned_patient), patientList),
      }));
  },
);

import * as R from 'ramda';
import { createSelector } from 'reselect';
import moment from 'moment';

import { getItemsArray, getItemsArrayByParent } from 'redux/hor/module/selectors';

const parseJournal = R.compose(
  R.reverse,
  R.sortBy(R.prop('date')),
  R.map(visit => ({
    date: R.propOr(null, 'custom_дата', visit),
    doctor: R.propOr(null, '___parent', visit),
    patient: R.propOr(null, 'custom_пациент', visit),
    action: R.compose(
      R.defaultTo('Прием'),
      R.propOr(null, 'custom_действие'),
    )(visit),
  })),
);

const getJournal = createSelector(
  (userKey, state) => userKey === 'all' ? getItemsArray(state) : getItemsArrayByParent(userKey, state),

  parseJournal,
);

export const getUserActiveDays = createSelector(
  getJournal,

  R.compose(
    R.uniqBy(value => moment(value).format('DD.MM.YYYY')),
    R.pluck('date'),
  ),
);

export const getUserJournal = createSelector(
  date => date,
  (date, userKey, state) => getJournal(userKey, state),

  (date, journal) =>
    R.filter(
      R.propSatisfies(d => !date || moment(d).isSame(moment(date, 'DD.MM.YYYY'), 'day'), 'date'),
      journal,
    ),
);

export const getPatientJournal = createSelector(
  R.identity,
  (patientKey, state) => getItemsArray(state),

  R.uncurryN(2, patientKey => R.compose(
    R.uniqBy(({ date, action }) => moment(date).format('DD.MM.YYYY') + action),
    parseJournal,
    R.filter(R.propEq('custom_пациент', patientKey)),
  )),
);

import * as R from 'ramda';

import createOrmSelector from 'redux/utils/createOrmSelector';

import { timeToMinutes } from "utils/time";

import getRationKey from '../getRationKey';

export default createOrmSelector(
  getRationKey,

  (session, rationKey) => {
    const { Ration } = session;

    return Ration
      .withId(rationKey)
      .days
      .orderBy('custom_номер')
      .toModelArray()
      .map((day) => {
        const number = day.custom_номер;

        return {
          key: day.key,
          date: `День ${number}`,
          sections: Object.keys(day.groups)
            .sort((a, b) => timeToMinutes(a) - timeToMinutes(b))
            .map(time => {
              const group = day.groups[time]

              return {
                time,
                data: group.группы_альтернатив.map((group, index) => index),
              }
            })
            .filter(groups => !R.isEmpty(groups.data)),
        };
      });
  },
);

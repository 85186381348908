import * as R from 'ramda';
import { createSelector } from 'reselect';

import { appKey } from 'config';

import getKeyInfo from 'utils/getKeyInfo';

import { getItemsArray, getIsButtonRunning } from 'redux/hor/module/selectors';

export const getItems = createSelector(
  getItemsArray,

  R.map(item => ({
    ...item,
    id: getKeyInfo(item.key).record,
  })),
);

const energyButton = `${appKey}ts9c5c3c1r319`;
const consumptionByTimeButton = `${appKey}ts9c5c3c1r166`;
const consumptionByProfessionButton = `${appKey}ts9c5c3c1r133`;

export const getIsEnergyCalcing = getIsButtonRunning(energyButton);
export const getIsConsumptionCalcing = R.either(
  getIsButtonRunning(consumptionByTimeButton),
  getIsButtonRunning(consumptionByProfessionButton),
);

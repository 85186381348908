import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getSelect } from 'redux/modules/products';

const getState = createSelector(
  R.path(['meals']),

  meals => ({ meals }),
);

export default createSelector(
  getState,

  state => getSelect(null, 'meals', state),
);

import * as R from 'ramda';

import createOrmSelector from 'redux/utils/createOrmSelector';

import { getElementsList } from 'redux/modules/elements/selectors';
import getDietanamnezKey from '../getDietanamnezKey';

const toPercent = value => Math.round(value * 100);

const prop = R.compose(
  toPercent,
  R.defaultTo(0),
  R.prop,
);

export default createOrmSelector(
  getDietanamnezKey,
  getElementsList,

  (session, dietanamnezKey, elements) => {
    const { Dietanamnez } = session;

    try {
      const dietanamnez = Dietanamnez.withId(dietanamnezKey);

      return elements
        .filter(item => item.___parent)
        .map((element) => {
          const item = dietanamnez
            .custom_профиль_потребления_веществ
            .find(item => item.элемент_состава === element.key);

          return [
            element.custom_наименование,
            prop('среднее_за_день', item) / 100,
            prop('мясо', item),
            prop('рыба', item),
            prop('молоко_и_яйца', item),
            prop('напитки', item),
            prop('хлеб', item),
            prop('каши_макароны', item),
            prop('картофель', item),
            prop('овощи', item),
            prop('супы', item),
            prop('фрукты', item),
            prop('орехи', item),
            prop('сладкое', item),
            prop('жиры_соусы', item),
            prop('алкоголь', item),
            prop('фастфуд', item),
          ];
        });
    } catch (error) {
      console.error(error);
      return [];
    }
  },
);

import * as R from 'ramda';
import { takeEvery, all, put, select, call } from 'redux-saga/effects';
import { appKey } from 'config';

import { request } from 'utils/api';

import { getItemsArrayByParent as getTechcard, save as saveTechcard } from 'redux/modules/supplementTechCard';
import { getItemsArrayByParent as getComposition, save as saveComposition } from 'redux/modules/supplementComposition';

import {
  fetchSuccess,
  saveSuccess,
  fetchList,
  fetchListSuccess,
  fetchListFailure,
} from './index';

function* saveSuccessFlow(action) {
  const { payload } = action;
  const { key, response } = payload;
  const newKey = R.pathOr(null, ['data', 'key'], response);

  if (key === newKey || !newKey) {
    return;
  }

  const techcard = yield select(state => getTechcard(key, state.supplementTechCard));
  const composition = yield select(state => getComposition(key, state.supplementComposition));

  const techcardEffect = techcard.map(item => put(saveTechcard({
    key: item.key,
    data: {
      ___parent: newKey,
    },
  })));
  const compositionEffect = composition.map(item => put(saveComposition({
    key: item.key,
    data: {
      ___parent: newKey,
    },
  })));

  yield all(techcardEffect);
  yield all(compositionEffect);
}

function* fetchListFlow(action) {
  try {
    const response = yield call(request.post, `/${appKey}t179r/json/v2`, {}, {
      headers: {
        Function: 'getBadList',
      }
    });

    yield put(fetchSuccess({
      ...action.payload,
      response: response.data,
    }))
    yield put(fetchListSuccess());
  } catch (error) {
    yield put(fetchListFailure({
      error,
    }))
  }
}
export default function* saga() {
  yield all([
    takeEvery(saveSuccess, saveSuccessFlow),
    takeEvery(fetchList, fetchListFlow),
  ]);
}

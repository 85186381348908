import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import Button from 'components/Button';
import Title from 'components/Title';

import styles from './Subscription.module.css';

const cx = classNames.bind(styles);

export default class Subscription extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    isLoading: PropTypes.bool,
    submitBtn: PropTypes.string,
    cancelBtn: PropTypes.string,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
  }

  static defaultProps = {
    children: null,
    isLoading: false,
    submitBtn: null,
    cancelBtn: null,
    onCancel: () => { },
    onSubmit: () => { },
  }

  render() {
    const {
      submitBtn,
      cancelBtn,
      onSubmit,
      onCancel,
      isLoading,
    } = this.props;

    return (
      <form className={cx('subscription')} onSubmit={onSubmit}>
        <div className={cx('title')}>
          <Title size='small'>
            {this.props.children}
          </Title>
        </div>

        {(submitBtn || cancelBtn) &&
          <div className={cx('buttons')}>
            {submitBtn &&
              <div className={cx('button')}>
                <Button
                  isLoading={isLoading}
                  disabled={isLoading}
                  type='submit'
                  upper
                  bordered
                  block
                >
                  {submitBtn}
                </Button>
              </div>
            }
            {cancelBtn &&
              <div className={cx('button')}>
                <Button
                  onClick={onCancel}
                  upper
                  bordered
                  block
                >
                  {cancelBtn}
                </Button>
              </div>
            }
          </div>
        }
      </form>
    );
  }
}

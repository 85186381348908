import * as R from 'ramda';
import { attr, Model, fk } from 'redux-orm';

import getKeyInfo from 'utils/getKeyInfo';

import generator from 'redux/hor/orm-module';

const {
  reducer,
  fetch,
} = generator({
  name: 'TileImageMap',
  tableId: 171,
  parseItem: R.evolve({
    ___parent: R.propOr(null, 'key'),
    custom_тип: R.propOr(null, 'label'),
  }),
});

export default class TileImageMap extends Model {
  static modelName = 'TileImageMap';

  static reducer = reducer;

  static options = {
    idAttribute: 'key',
  }

  static fields = {
    ___parent: fk('DietanamnezTiles', 'map'),
    custom_количество: attr(),
    custom_координаты: attr(),
    custom_тип: attr(),
  }

  get id() {
    return getKeyInfo(this.key).record;
  }
}

export {
  fetch,
};

import { createAction, createReducer } from 'redux-act'

export const loadFiles = createAction('MEDASS__LOADFILES')
export const loadFilesSuccess = createAction('MEDASS__LOADFILES_SUCCESS')
export const loadFilesFailure = createAction('MEDASS__LOADFILES_FAILURE')

export const loadMedassReport = createAction('MEDASS__LOADREPORT')
export const loadMedassReportSuccess = createAction('MEDASS__LOADREPORT_SUCCESS')
export const loadMedassReportFailure = createAction('MEDASS__LOADREPORT_FAILURE')

const reducer = createReducer({
	[loadFilesSuccess]: (state, csvFile, imageFile) => ({
		...state,
		csvFile,
		imageFile,
	}),
	[loadFilesFailure]: (state) => ({
		...state,
		csvFile: null,
		imageFile: null,
	})
}, {
	csvFile: null,
	imageFile: null,
})

export {
	getItems,
} from './selectors';

export default reducer;

export { default as saga } from './saga';

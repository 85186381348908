import * as R from 'ramda';

import defaultConfig from './defaultConfig';
import defaultText from './defaultText';

const getErrorText = rules =>
  R.mapObjIndexed((firstError, fieldName) => {
    const validatorConfig = {
      ...defaultConfig[firstError],
      ...R.pathOr({}, [fieldName, firstError], rules),
    };
    const getText = R.propOr(defaultText[firstError], 'errorText', validatorConfig);
    const text = typeof getText === 'function' ? getText(validatorConfig) : getText;

    return text || firstError || 'Неизвестная ошибка';
  });

export default getErrorText;

import { filter, prop } from "remeda";
import { createSelector } from "reselect";

import getAuthUser from "../getAuthUser";
import getPatientsList from "../getPatientsList";

export default createSelector(
  getPatientsList,
  getAuthUser,

  (patients, userAuth) => {
    return filter(patients, (patient) => patient.___createduser === userAuth);
  }
);

import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getItemsArray } from 'redux/modules/subscriptionTariffs';

export default createSelector(
  R.path(['subscriptionTariffs']),

  getItemsArray,
);

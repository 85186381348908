import memoizee from 'memoizee';

const requests = new Map();

const constructObject = memoizee((url, view, filter) => ({
  url,
  view,
  filter,
}));

const getQueueKeyByConfig = (config) => {
  const url = config.url;
  const { filter, view } = config.headers;

  return constructObject(url, view, filter);
};

export function queueRequest(config) {
  if (config.method !== 'get') {
    return config;
  }

  const cacheKey = getQueueKeyByConfig(config);
  const existItem = requests.get(cacheKey);

  if (!existItem) {
    requests.set(cacheKey, []);
    return config;
  }

  return new Promise((resolve, reject) => {
    requests.set(cacheKey, existItem.concat({
      resolve,
      reject,
      config,
    }));
  });
}

export function queueResponse(payload) {
  const { config } = payload;
  const cacheKey = getQueueKeyByConfig(config);
  const existItem = requests.get(cacheKey);

  if (existItem) {
    requests.delete(cacheKey);
    existItem.forEach((request) => {
      request.reject({
        fromQueue: payload,
      });
    });
  }

  return payload;
}

export function queueResponseError(error) {
  if (error.fromQueue) {
    return Promise.resolve({
      ...error.fromQueue,
      fromQueue: true,
    });
  }

  if (error.config) {
    const cacheKey = getQueueKeyByConfig(error.config);
    const existItem = requests.get(cacheKey);

    if (existItem) {
      requests.delete(cacheKey);
      existItem.forEach((request) => {
        request.reject(error);
      });
    }
  }

  return Promise.reject(error);
}

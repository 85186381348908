import * as R from "ramda";
import { createAction, createReducer } from "redux-act";

const initialState = {
  token: "",
  type: null,
  remember: false,
  timeLastRequest: undefined,
  hasInvite: null,
};

export const login = createAction("AUTH__LOGIN");
export const loginSuccess = createAction("AUTH__LOGIN_SUCCESS");
export const loginFailure = createAction("AUTH__LOGIN_FAILURE");
export const setProfile = createAction("AUTH__SET_PROFILE");
export const setInviteByToken = createAction("INVITE__SET_BY_TOKEN");
export const inviteByTokenSuccess = createAction("INVITE__BY_TOKEN_SUCCESS");
export const inviteByTokenFailure = createAction("INVITE__BY_TOKEN_FAILURE");

export const switchUser = createAction("AUTH__SWITCH_USER");
export const switchUserSuccess = createAction("AUTH__SWITCH_USER_SUCCESS");

export const logout = createAction("AUTH__LOGOUT");

export const resendEmail = createAction("AUTH__RESEND_EMAIL");
export const hideModal = createAction("AUTH__HIDE_MODAL");

const handleLoginSuccess = (
  state,
  { response, remember, anonymous = false }
) => {
  const token = R.path(["data", "token"], response);
  const domain = R.path(["data", "domain"], response);
  const userAKey = R.pathOr(null, ["data", "UserAKey"], response);
  const profileKey = R.pathOr(null, ["data", "ProfileKey"], response);

  return {
    ...state,
    remember,
    anonymous,
    domain: domain ? `https://${domain}` : undefined,
    token,
    userAKey,
    profileKey,
  };
};

const handleLogout = () => initialState;

const handleSwitchUserSuccess = (state, payload) => {
  const { userAKey, token } = payload;

  return {
    ...state,
    userAKey,
    token,
  };
};

const handleSetProfile = (state, profileKey) => ({
  ...state,
  profileKey,
});

export default createReducer(
  {
    [loginSuccess]: handleLoginSuccess,
    [logout]: handleLogout,
    [switchUserSuccess]: handleSwitchUserSuccess,
    [setProfile]: handleSetProfile,
  },
  initialState
);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import { Link as LinkRR } from 'react-router';

import IconDotLoader from 'components/IconDotLoader';

import styles from './Link.module.css';

const cx = classNames.bind(styles);

export default class Link extends PureComponent {
  static propTypes = {
    block: PropTypes.bool,
    bold: PropTypes.bool,
    black: PropTypes.bool,
    children: PropTypes.node.isRequired,
    color: PropTypes.oneOf([
      'inherit',
      'red',
      'white',
      'sapphirine',
      'blue',
    ]),
    column: PropTypes.bool,
    disabled: PropTypes.bool,
    ellipsis: PropTypes.bool,
    hover: PropTypes.oneOf([
      'white',
      'blue',
      'sky-blue',
      'sapphirine',
      'red',
      'dark-red',
      'custom',
    ]),
    href: PropTypes.string,
    inherit: PropTypes.bool,
    isActive: PropTypes.bool,
    isLoading: PropTypes.bool,
    large: PropTypes.bool,
    medium: PropTypes.bool,
    nowrap: PropTypes.bool,
    onlyActiveOnIndex: PropTypes.bool,
    sidebarMenu: PropTypes.bool,
    small: PropTypes.bool,
    to: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    underline: PropTypes.bool,
    onClick: PropTypes.func,
    target: PropTypes.string,
    title: PropTypes.string,
  }

  static defaultProps = {
    block: false,
    bold: false,
    children: undefined,
    color: undefined,
    column: false,
    disabled: false,
    ellipsis: false,
    hover: undefined,
    href: undefined,
    inherit: false,
    isActive: false,
    isLoading: false,
    large: false,
    medium: false,
    nowrap: false,
    onlyActiveOnIndex: false,
    sidebarMenu: false,
    small: false,
    to: undefined,
    underline: false,
    onClick: () => {},
    title: undefined,
  }

  getTitle() {
    const { title, ellipsis, children } = this.props;

    if (title) {
      return title;
    }

    if (ellipsis) {
      return children;
    }

    return null;
  }

  render() {
    const {
      href,
      to,
      onClick,
      isActive,
      color,
      children,
      medium,
      small,
      large,
      bold,
      column,
      hover,
      disabled,
      underline,
      onlyActiveOnIndex,
      isLoading,
      nowrap,
      inherit,
      block,
      sidebarMenu,
      ellipsis,
      target,
      black,
    } = this.props;

    const props = {
      type: 'button',
    };
    let ComponentName = 'button';

    if (to) {
      ComponentName = LinkRR;
      props.to = to;
      props.type = null;
      props.activeClassName = cx('link_active');
      props.onlyActiveOnIndex = onlyActiveOnIndex;
    }

    if (href) {
      ComponentName = 'a';
      props.type = null;
      props.href = href;
      props.target = target;
    }

    return (
      <ComponentName
        {...props}
        onClick={onClick}
        className={cx('link', {
          link_active: isActive,
          link_size_medium: medium,
          link_underline: underline,
          link_block: block,
          link_loading: isLoading,
          link_size_large: large,
          link_size_small: small,
          link_size_inherit: inherit,
          link_theme_bold: bold,
          link_theme_black: black,
          link_column: column,
          [`link_color_${color}`]: color,
          link_hover_custom: hover,
          [`link_hover_${hover}`]: hover,
          link_ellipsis: ellipsis,
          link_nowrap: nowrap,
          link_theme_sidebarMenu: sidebarMenu,
        })}
        disabled={disabled || isLoading}
        title={this.getTitle()}
      >
        <span className={cx('inner')}>
          {children}
        </span>

        <span className={cx('loader')}>
          <IconDotLoader />
        </span>
      </ComponentName>
    );
  }
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';

import styles from './AppLoader.module.css';

const cx = classNames.bind(styles);

export default class AppLoader extends PureComponent {
  static propTypes = {
    isActive: PropTypes.bool,
    children: PropTypes.node,
  };

  static defaultProps = {
    isActive: false,
    children: undefined,
  }

  state = {
    isRendered: true,
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isActive && !nextProps.isActive) {
      setTimeout(() => {
        this.setState({
          isRendered: false,
        })
      }, 300);
    }
  }

  render() {
    const { isActive } = this.props;
    const { isRendered } = this.state;

    if (!isRendered) {
      return null;
    }

    return (
      <div
        className={cx('appLoader', {
          appLoader_hidden: !isActive,
        })}
      >
        <div className={cx('icons')}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

import { attr, Model } from 'redux-orm';

import generator from 'redux/hor/orm-module';
import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,
  fetch,
} = generator({
  name: 'chemicalElements',
  tableId: 23,
});

export default class ChemicalElement extends Model {
  static modelName = 'ChemicalElement';

  static reducer = reducer;

  static options = {
    idAttribute: 'key',
  }

  static fields = {
    custom_наименование: attr(),
    custom_анализ_волос: attr(),
    custom_анализ_крови: attr(),
    custom_анализ_мочи: attr(),
    custom_норма_анализа_волос__мг: attr(),
    custom_норма_анализа_волос_от__мг: attr(),
    custom_норма_анализа_волос_до__мг: attr(),
    custom_норма_анализа_крови__мг: attr(),
    custom_норма_анализа_крови_от__мг: attr(),
    custom_норма_анализа_крови_до__мг: attr(),
  }
}

export const fetchList = fetchWithView(fetch, 'ts5c23cs9r43', {
  useLS: true,
});

export {
  fetch,
};

import {
  createEffect,
  restore,
  Store,
  Effect,
  createEvent,
  Event,
} from "effector";

import { request } from "../../utils/api";

import { ApiListResponse, ApiItemResponse } from "../../types/ApiResponse";
import { AxiosResponse } from "axios";

type Config<T extends object> = {
  tableId: number;
  view?: string;
  parseItem?: (item: ApiItemResponse<T>) => T;
};

export function createDictionaryModel<T extends object>({
  tableId,
  view,
  parseItem = (item) =>
    ({
      key: item.key,
      ...item.fields,
    } as T),
}: Config<T>): [Store<T[]>, Effect<void, T[], Error>, Event<void>] {
  const fetchFx = createEffect<void, T[]>();

  const resetEvent = createEvent();

  const $store = restore(fetchFx.doneData, []);

  fetchFx.use(async () => {
    const currentData = $store.getState();

    if (currentData.length) {
      return currentData;
    }

    const response = (await request.get(`/c105a902t${tableId}r/json/v2`, {
      headers: {
        view: view!,
        limit: 10000,
      },
    })) as AxiosResponse<ApiListResponse<T>>;

    return response.data.list.map(parseItem);
  });

  return [$store, fetchFx, resetEvent];
}

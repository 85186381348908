import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getCorrectionValuesByWeight } from 'modules/Rations/redux/modules/correction';

import getPatientWeight from '../getPatientWeight';

export default createSelector(
  getPatientWeight,
  R.path(['Rations', 'correction']),

  getCorrectionValuesByWeight,
);

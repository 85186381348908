import * as R from 'ramda';
import { createSelector } from 'reselect';

import { appKey } from 'config';

import getUserProfile from '../getUserProfile';

export default createSelector(
  getUserProfile,

  R.equals(`${appKey}ts18r12`),
);

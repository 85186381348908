import { createSelector } from 'reselect';

import getPatientEnergyMin from '../getPatientEnergyMin';
import getPatientEnergyMax from '../getPatientEnergyMax';

export default createSelector(
  getPatientEnergyMin,
  getPatientEnergyMax,

  (min, max) => {
    if (!min || !max) {
      return null;
    }

    return (min + max) / 2;
  },
);

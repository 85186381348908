import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import containerQuery from 'decorators/containerQuery';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import Text from 'components/Text';
import Link from 'components/Link';
import Icon from 'components/Icon';

import GenesRow from '../../containers/AnalysisItemGenes';
import VitaminRow from '../../containers/AnalysisItemVitamin';

import styles from './RowsTable.module.css';

const cx = classNames.bind(styles);

const getDefaultRows = R.filter(R.prop('showByDefault'));
const getNotDefaultRows = R.reject(R.prop('showByDefault'));
const showShortListByDefault = R.compose(
  R.all(R.propSatisfies(R.isNil, 'value')),
  R.reject(R.prop('showByDefault')),
);

class RowsTable extends PureComponent {
  static propTypes = {
    rows: PropTypes.array,
    selectList: PropTypes.array,
    titles: PropTypes.array,
    vitaminsAnalys: PropTypes.bool,
    containerQuery: PropTypes.object,
  }

  static defaultProps = {
    rows: [],
    titles: [],
  };

  state = {
    showShortList: showShortListByDefault(this.props.rows),
  }

  handleToggleList = () => {
    this.setState({
      showShortList: !this.state.showShortList,
    });
  }

  render() {
    const {
      titles,
      selectList,
      vitaminsAnalys,
    } = this.props;
    const { showShortList } = this.state;
    const notDefault = getNotDefaultRows(this.props.rows);
    const rows = showShortList ?
      getDefaultRows(this.props.rows) :
      [...getDefaultRows(this.props.rows), ...notDefault];
    const AnalysRow = vitaminsAnalys ? VitaminRow : GenesRow;
    const rowsList = rows.map(item => (
      <div
        className={cx('rowContainer')}
        key={item.key}
      >
        <AnalysRow
          name={item.key}
          value={item.value}
          update={item.update}
          updateKey={item.updateKey}
          items={item.items}
          title={item.title}
          influence={item.influence}
          selectList={selectList}
          disabledInitially={R.isNil(item.value)}
        />
      </div>
    ));
    const tableHeader = titles.map((item, index) => (
      <div
        key={index}
        className={cx({
          titleColumn: true,
          [`titleColumn_type_${index + 1}`]: true,
        })}
      >
        <Text uppercase color='gray' size='small'>
          {item}
        </Text>
      </div>
    ));

    return (
      <div className={cx('rowsTable', this.props.containerQuery)}>
        <div className={cx('titles')}>
          {tableHeader}
        </div>
        <div>
          {rowsList}
        </div>
        {!R.isEmpty(notDefault) &&
          <div className={cx('show_all_button')}>
            <Link
              color='sapphirine'
              onClick={this.handleToggleList}
            >
              <div className={cx('plusIcon')}>
                <Icon icon={showShortList ? 'plus' : 'minus'} />
              </div>
              {showShortList ? 'Показать все' : 'Показать основные'}
            </Link>
          </div>
        }
      </div>
    );
  }
}

export default R.compose(
  containerQuery(styles),
)(RowsTable);

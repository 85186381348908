import * as R from 'ramda';
import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form';

import { getUserActiveDays } from 'redux/modules/journal';

export default createSelector(
  R.compose(
    R.propOr(null, 'worker'),
    getFormValues('journal'),
  ),
  R.path(['journal']),

  getUserActiveDays,
);

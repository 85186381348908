import * as R from 'ramda';
import { createAction } from 'redux-act';

import generator from 'redux/hor/module';
import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,

  fetch,
  fetchSuccess,
  create,
  save,
  saveSuccess,
  remove,
  removeSuccess,
  removeWithUndo,
} = generator({
  name: 'Ingredients',
  tableId: 51,
  parseItem: R.compose(
    (item) => {
      /* eslint-disable no-param-reassign */
      if (item.custom_группа || item.custom_группа_ключ) {
        item.custom_группа = item.custom_группа || item.custom_группа_ключ;
      }
      /* eslint-enable no-param-reassign */

      return item;
    },
    R.evolve({
      ___owner: R.propOr(null, 'key'),
      ___territory: R.propOr(null, 'key'),
      custom_группа: R.propOr(null, 'key'),
      custom_единица_измерения__гр_мл: R.propOr(null, 'key'),
      custom_территория_вспомогательное: R.propOr(null, 'key'),
      custom_специфические_виды_питания: R.compose(
        R.pluck('key'),
        R.defaultTo([]),
      ),
    }),
  ),
});

export const fetchItem = fetchWithView(fetch, 'ts5c51cs6r348');
export const fetchSystemIngredients = fetchWithView(fetch, 'ts5c51cs9r345', {
  useLS: true,
});
export const fetchUserIngredients = fetchWithView(fetch, 'ts5c51cs9r346');
export const fetchCatalogList = createAction('INGREDIENTS__FETCH_CATALOG_LIST');
export const fetchCatalogListSuccess = createAction('INGREDIENTS__FETCH_CATALOG_LIST_SUCCESS');
export const copyComposition = createAction('INGREDIENTS__COPY_COMPOSITION');

export {
  fetch,
  fetchSuccess,
  create,
  save,
  saveSuccess,
  remove,
  removeSuccess,
  removeWithUndo,
};

export {
  getItemById,
  getKeyById,
  getItems,
  getItem,
  getItemsArray,
} from 'redux/hor/module/selectors';

export {
  getSelectData,
  getItemsList,
} from './selectors';

export { default as saga } from './saga';

export default reducer;

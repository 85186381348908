import * as R from 'ramda';
import { createAction } from 'redux-act';

import generator from 'redux/hor/module';

const {
  reducer,

  fetch,
  fetchSuccess,
  create,
  save,
} = generator({
  name: 'Institutions',
  tableId: 48,
  parseItem: R.evolve({
    custom_страна: R.propOr(null, 'key'),
    custom_город: R.propOr(null, 'key'),
    custom_головное_учреждение: R.propOr(null, 'key'),
    custom_тип: R.propOr(null, 'value'),
    custom_схема_кастомизации: R.propOr(null, 'key'),
    custom_текущий_рекуррент: R.propOr(null, 'value'),
  }),
});

export const trialInform = createAction('INSTITUTIONS__TRIAL_INFORM');
export const fetchItem = createAction('INSTITUTIONS__FETCH_ITEM');
export const fetchItemSuccess = createAction('INSTITUTIONS__FETCH_ITEM_SUCCESS');
export const fetchItemFailure = createAction('INSTITUTIONS__FETCH_ITEM_FAILURE');
export const stopAuto = createAction('INSTITUTIONS__STOP_AUTO');
export const stopAutoSuccess = createAction('INSTITUTIONS__STOP_AUTO_SUCCESS');
export const stopAutoFailure = createAction('INSTITUTIONS__STOP_AUTO_FAILURE');
export const fetchList = createAction('INSTITUTIONS__FETCH_LIST');
export const fetchListSuccess = createAction('INSTITUTIONS__FETCH_LIST_SUCCESS');
export const fetchListFailure = createAction('INSTITUTIONS__FETCH_LIST_FAILURE');

export {
  fetch,
  fetchSuccess,
  create,
  save,
};

export {
  getItem,
} from 'redux/hor/module/selectors';

export {
  getItems,
} from './selectors';

export { default as saga } from './saga';

export default reducer;

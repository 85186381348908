import * as R from 'ramda';
import createSelector from 'redux/utils/createOrmSelector';
import moment from 'moment';

import getKeyInfo from 'utils/getKeyInfo';

import getStaff from 'redux/selectors/getStaff';
import getPatientKey from 'redux/selectors/getPatientKey';
import getAuthUser from 'redux/selectors/getAuthUser';
import { getUserRight } from 'redux/selectors/getUserRights';

export default createSelector(
  state => getUserRight('INSTITUTION_ADMINISTER', state),
  getAuthUser,
  getPatientKey,
  getStaff,

  (session, isAdmin, userKey, patientKey, staff) => {
    const { Ration } = session;

    return Ration
      .filter({
        ___parent: patientKey,
        custom_не_назначен: false,
      })
      .exclude({
        custom_удалено: true,
      })
      .filter(item => !item.isDeleted)
      .toModelArray()
      .sort((a, b) => moment(b.custom_начало_диеты) - moment(a.custom_начало_диеты))
      .map((item) => {
        const start = moment(item.custom_начало_диеты);
        const duration = item.custom_продолжительность;
        const id = getKeyInfo(item.key).record;
        const author = R.find(R.propEq('custom_ключ_системного_пользователяold', item.___createduser), staff);

        return {
          withDelete: R.pathEq(['___createduser'], userKey, item) || isAdmin,
          withView: R.pathEq(['___createduser'], userKey, item) || isAdmin,
          id: id ? id.toString() : undefined,
          key: item.key,
          title: item.custom_название || start.format('DD.MM.YYYY HH:mm'),
          dateStart: start.format('DD.MM.YYYY HH:mm'),
          duration,
          specialist: R.compose(
            R.join(' '),
            R.values,
            R.pick(['custom_firstname', 'custom_midname', 'custom_lastname']),
            R.defaultTo({}),
          )(author),
          isDeleting: item.isDeleting,
        };
      });
  },
);

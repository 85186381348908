import * as R from 'ramda';

import { createSelector } from 'reselect';

export default createSelector(
  R.path(['routeParams', 'analysisId']),
  (state, props = {}) => props.analysisId,

  (stateId, propsId) => propsId || stateId,
);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';

import Radio from 'components/Radio';

import styles from './RadioGroup.module.css';

const cx = classNames.bind(styles);

export default class RadioGroup extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    mobileVersion: PropTypes.bool,
    items: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    disabled: false,
    items: [],
    name: undefined,
    onChange: () => {},
  }

  renderRadio(item, index) {
    return (
      <div className={cx('item', {
        item_mobile: this.props.mobileVersion
      })} key={index}>
        <Radio
          mobileVersion={this.props.mobileVersion}
          name={this.props.name}
          value={item.value}
          disabled={this.props.disabled}
          onChange={this.props.onChange}
          checked={this.props.value === item.value}
        >
          {item.content}
        </Radio>
      </div>
    );
  }

  render() {
    return (
      <div className={cx('radioGroup', {
        radioGroup_mobile: this.props.mobileVersion
      })}>
        {this.props.items.map(this.renderRadio, this)}
      </div>
    );
  }
}

import * as R from 'ramda';
import { createSelector } from 'reselect';

import getInstitutionSubscription from '../getInstitutionSubscription';
import getTariffsAll from '../getTariffsAll';

export default createSelector(
  state => {
    const subscription = getInstitutionSubscription(state);
    const list = getTariffsAll(state);

    return R.find(R.propEq('key', subscription.custom_действующий_тарифный_план), list)
  },

  R.propOr(false, 'custom_триал'),
) as () => boolean;

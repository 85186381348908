import * as R from 'ramda';
import { pingFailure } from 'redux/modules/ping';
import { apiUrl } from 'config';

import genericRequest from '../genericRequest';

let requests = [];

export const handleServerMaintenance = store => () => {
  const serverUnderMaintenance = R.path(['ping', 'serverUnderMaintenance'], store.getState());

  if (serverUnderMaintenance || R.length(requests) === 0) {
    return Promise.resolve();
  }

  const getIndex = request => R.findIndex(R.equals(request), requests);

  return R.compose(
    val => Promise.all(val),
    R.map((request) => {
      request.resent = true; // eslint-disable-line no-param-reassign

      return genericRequest(request.response.config)
        .then((response) => {
          const index = getIndex(request);
          request.resolve(response);
          requests = R.remove(index, 1, requests);
        })
        .catch((response = {}) => {
          if (response.status === 503) {
            return;
          }

          const index = getIndex(request);
          request.reject(response);
          requests = R.remove(index, 1, requests);
        });
    }),
    R.reject(R.propEq('resent', true)),
  )(requests);
};

export const dispatchPing = (store, payload) => store.dispatch(pingFailure(payload));

const getIsShouldReject = response =>
  response.status !== 503 || response.config.url === `${apiUrl}/ping/json/v2`;

export const serverMaintenanceInterceptor = (store, payload = {}) => {
  const { response = {} } = payload;

  if (getIsShouldReject(response)) {
    return Promise.reject(payload);
  }

  const resentRequestIndex = R.findIndex(R.pathEq(['response', 'config'], response.config), requests);
  const resentRequest = resentRequestIndex > -1 ? requests[resentRequestIndex] : null;
  const request = resentRequest ? {
    ...resentRequest,
    resent: false,
  } : {
    response,
    resent: false,
  };

  if (resentRequest) {
    requests = R.update(resentRequestIndex, request, requests);
  } else {
    requests = requests.concat(request);
  }

  dispatchPing(store, payload);

  return new Promise((resolve, reject) => {
    request.resolve = resolve;
    request.reject = reject;
  });
};

export const getRequests = () => R.clone(requests);

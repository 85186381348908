import React from 'react'
import { createSelector } from 'reselect';

import Link from 'components/Link';

import getInstitutionTariffIsTrial from '../getInstitutionTariffIsTrial'
import getUserIsLight from '../getUserIsLight'
import getUserIsPartner from '../getUserIsPartner'

export default createSelector(
  getInstitutionTariffIsTrial,
  getUserIsLight,
  getUserIsPartner,

  (isTrial, isLight, isPartner) => {
    if (isTrial) {
      if (isLight) {
        return (
          <>
            Вы не можете создавать клиентов во время пробного периода.
            Пожалуйста, <Link inherit color='blue' to='/user/subscription'>продлите подписку здесь</Link>, чтобы создавать клиентов без ограничений.
          </>
        )
      }

      if (isPartner) {
        return (
          <>
            Вы не можете создавать пациентов во время пробного периода.
            Пожалуйста, <Link inherit color='blue' href='https://онлайн-диетолог.рф/' target='_blank'>продлите подписку здесь</Link>, чтобы снять ограничения.
          </>
        )
      }
    }

    return null
  }
)

import * as R from "ramda";
import { attr, Model } from "redux-orm";

import getKeyInfo from "utils/getKeyInfo";

import generator from "redux/hor/orm-module";
import fetchWithView from "redux/utils/fetchWithView";

const {
  reducer,

  fetch,
  fetchSuccess,
  save,
  saveSuccess,
  removeWithUndo,
} = generator({
  name: "RegistrationLink",
  tableId: 212,
  parseItem: R.evolve({
    ___parent: R.propOr(null, "key"),
    custom_тип_опроса: R.propOr(null, "value"),
  }),
});

export default class RegistrationLink extends Model {
  static modelName = "RegistrationLink";

  static options = {
    idAttribute: "key",
  };

  static fields = {
    ___parent: attr(),
    custom_группа: attr(),
    custom_идентификатор: attr(),
    custom_именной: attr(),
    custom_тип_опроса: attr(),
    custom_дефолтный: attr(),
  };

  static reducer = reducer;

  get id() {
    return getKeyInfo(this.key).record;
  }
}

export const fetchList = fetchWithView(fetch, "ts5c212cs9r2985");

export { fetch, fetchSuccess, save, saveSuccess, removeWithUndo };

import * as R from 'ramda';
import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form';
import getKeyInfo from 'utils/getKeyInfo';

import { getUserJournal } from 'redux/modules/journal';

import getStaff from '../getStaff';
import getUserKey from '../getUserKey';
import getPatientsList from '../getPatientsList';

export default createSelector(
  getStaff,
  getPatientsList,
  state => state.routeParams.date,
  R.compose(
    R.propOr(null, 'worker'),
    getFormValues('journal'),
  ),
  getUserKey,
  R.path(['journal']),

  (staff, patients, date, userFormKey, userKey, journal) => R.compose(
    R.reject(R.isNil),
    R.map((item) => {
      const patient = R.find(R.propEq('key', item.patient), patients);
      const doctor = R.find(R.propEq('key', item.doctor), staff);

      if (!patient || !doctor) {
        return null;
      }

      return {
        ...item,
        doctorName: R.propOr(null, 'custom_firstname', doctor),
        doctorLastName: R.propOr(null, 'custom_lastname', doctor),
        patientName: R.propOr(null, 'custom_firstname', patient),
        patientLastName: R.propOr(null, 'custom_lastname', patient),
        patientId: getKeyInfo(item.patient).record,
      };
    }),
    getUserJournal,
  )(date, userFormKey || userKey, journal),
);

import * as R from 'ramda';
import { createAction } from 'redux-act';
import generator from 'redux/hor/module';
import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,

  fetch,
  fetchSuccess,
  create,
  save,
  saveSuccess,
  remove,
  removeWithUndo,
} = generator({
  name: 'dietarySupplement',
  tableId: 157,
  parseItem: R.evolve({
    custom_производитель: R.propOr(null, 'key'),
    custom_лекарственная_форма: R.propOr(null, 'value'),
    custom_пол: R.propOr(0, 'value'),
    custom_from_bad: R.compose(
      R.pluck('key'),
      R.defaultTo([])
    ),
    custom_страны: R.compose(
      R.pluck('key'),
      R.defaultTo([])
    )
  }),
});

export const fetchItem = fetch;
export const fetchCatalogList = fetchWithView(fetch, 'ts5c157cs9r572');
export const fetchList = createAction('DIETARY_SUPPLEMENT_FETCH');
export const fetchListSuccess = createAction('DIETARY_SUPPLEMENT_FETCH_SUCCESS');
export const fetchListFailure = createAction('DIETARY_SUPPLEMENT_FETCH_FAILURE');

export {
  fetch,
  fetchSuccess,
  create,
  save,
  saveSuccess,
  remove,
  removeWithUndo,
};

export {
  getItemById,
  getKeyById,
  getItems,
  getItem,
  getItemsArray,
} from 'redux/hor/module/selectors';

export {
  getItemsList,
  getProductsByGroups,
  getIsCompositionCalcing,
  getSetTechcard,
} from './selectors';

export { default as saga } from './saga';

export default reducer;

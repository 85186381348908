import createOrmSelector from 'redux/utils/createOrmSelector';

import getAnalysisKey from '../../getAnalysisKey';

export default createOrmSelector(
  getAnalysisKey,

  (session, analysisKey) => {
    const { ChemicalElement, AnalysisSalt } = session;

    return ChemicalElement
      .filter({
        custom_анализ_мочи: true,
      })
      .toRefArray()
      .map((item) => {
        const existedItem = AnalysisSalt
          .filter({
            ___parent: analysisKey,
            custom_химический_элемент: item.key,
          })
          .first() || {};

        return {
          key: item.key,
          label: item.custom_наименование,
          value: existedItem.custom_результат,
          updateKey: existedItem.key,
        };
      });
  },
);

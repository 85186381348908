import * as R from 'ramda';
import { createAction } from 'redux-act';
import moment from 'moment';

import { appKey } from 'config';

import generator from 'redux/hor/module';
import fetchWithView from 'redux/utils/fetchWithView';

import roundNumber from 'utils/roundNumber';

const transformKeyArray = R.compose(
  R.pluck('key'),
  R.defaultTo([]),
);

const {
  reducer,
  fetch,
  fetchSuccess,
  create,
  save,
  saveSuccess,
  removeWithUndo,
  removeWithUndoSuccess,
} = generator({
  name: 'Patients',
  tableId: 5,
  parseItem: R.compose(
    R.omit(['custom_диагнозы']),
    R.evolve({
      ___territory: R.propOr(null, 'key'),
      custom_белки_1: roundNumber(2),
      custom_жиры_1: roundNumber(2),
      custom_углеводы_1: roundNumber(2),
      custom_страна: R.propOr(null, 'key'),
      custom_город: R.propOr(null, 'key'),
      custom_детская_жизненная_активность: R.propOr(null, 'key'),
      custom_жизненная_активность: R.propOr(null, 'key'),
      custom_спортивная_автивность: R.propOr(null, 'key'),
      custom_болезни: transformKeyArray,
      custom_сопутствующие_заболевания: transformKeyArray,
      custom_специфические_виды_питания: transformKeyArray,
      ___createduser: R.propOr(null, 'key'),
      custom_основное_заболевание: R.propOr(null, 'key'),
      custom_sexint_save: R.propOr(null, 'value'),
      custom_дата_рождения: value => R.isNil(value) ? value : moment(value).format('DD.MM.YYYY'),
      custom_коридор_спк: R.compose(
        R.map(R.evolve({
          родитель: R.replace('cXaX', appKey),
          элемент: R.replace('cXaX', appKey)
        })),
        R.defaultTo([]),
      ) as any
    })
  ),
});


export const fetchList = fetchWithView(fetch, 'ts5c5cs9r193');
export const fetchItem = fetchWithView(fetch, 'patient_main');
export const fetchParams = fetchWithView(fetch, 'chart_params');
export const fetchNorm = fetchWithView(fetch, 'elements_norm');
export const fetchDiagnosis = fetchWithView(fetch, 'diagnosis');
export const fetchAnalyses = fetchWithView(fetch, 'analyses');
export const fetchDietanamnez = fetchWithView(fetch, 'dietanamnez');
export const fetchDietanamnezReport = fetchWithView(fetch, 'dietanamnez_report');
export const fetchForRation = fetchWithView(fetch, 'for_ration');

export const startVisit = createAction('PATIENTS__START_VISIT');
export const switchArchive = createAction<{ key: string }>('PATIENTS__SWITCH_ARCHIVE');
export const logPrint = createAction('PATIENTS__LOG_PRINT');
export const calcElementsNorm = createAction('PATIENTS__CALC_ELEMENTS_NORM');
export const calcEnergy = createAction('PATIENTS__CALC_ENERGY');
export const calcConsumptionByTime = createAction('PATIENTS__CALC_CONSUMPTION_BY_TIME');
export const calcConsumptionByProfession = createAction('PATIENTS__CALC_CONSUMPTION_BY_PROFESSION');
export const savePatientRequest = createAction('PATIENT__REQUEST_SAVE');
export const sendInvite = createAction('PATIENT__SEND_INVITE');
export const assignToStaff = createAction('PATIENT__ASIGN_TO_STAFF');
export const assignToStaffSuccess = createAction('PATIENT__ASIGN_TO_STAFF_SUCCESS');

export {
  fetch,
  fetchSuccess,
  create,
  save,
  saveSuccess,
  removeWithUndo,
  removeWithUndoSuccess,
};

export {
  getItem,
  getItemById,
  getKeyById,
} from 'redux/hor/module/selectors';

export {
  getItems,
  getIsEnergyCalcing,
  getIsConsumptionCalcing,
} from './selectors';

export { default as saga } from './saga';

export default reducer;

export * from './types';
export default function timeToMinutes(value) {
  if (!value) {
    return value;
  }

  if (!isNaN(value)) {
      return value
  }

  const [hour, minutes] = value.split(':')
    .map(item => parseInt(item.replace('_', '0'), 10) || 0);

  return (hour * 60) + minutes;
}

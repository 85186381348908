import * as R from 'ramda';
import { createSelector } from 'reselect';

import getSupplementAll from '../getSupplementAll';
import getPatientAllergen from '../getPatientAllergen';

export default createSelector(
  getSupplementAll,
  getPatientAllergen,

  (productList, allergen) => {
    const setAllergen = (item) => {
      if (R.contains(item.key, allergen)) {
        return {
          ...item,
          forbidden: 'allergen',
        };
      }

      return item;
    };

    return R.map(setAllergen, productList);
  },
);

import { createSelector } from 'reselect';

import getInstitutionDiscount from '../getInstitutionDiscount';
import getPromocodeDiscount from '../getPromocodeDiscount';

export default createSelector(
  getInstitutionDiscount,
  getPromocodeDiscount,

  Math.max,
);

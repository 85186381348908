import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Field from 'components/Field';

import { save } from '../../redux/modules/analysisBlood';
import formAnalysisItem from '../../utils/formAnalysisItem';
import formatValue from '../../utils/bloodAndHairAnalysis/formatValue';

const saveParameterCreator = ({
  key,
  value,
}) => ({
  custom_химический_элемент: key,
  custom_факт: value,
});

class BloodAnalysisItemContainer extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
  }

  render() {
    const { name } = this.props;

    return (
      <Field
        name={name}
        placeholder='Значение'
      />
    );
  }
}

export default R.compose(
  formAnalysisItem({
    save,
    saveParameterCreator,
    formatValue,
  }),
)(BloodAnalysisItemContainer);

import { createAction, createReducer } from 'redux-act';
import { LOCATION_CHANGE } from 'react-router-redux';

import * as R from 'ramda';

const initialState = {};

export const setNotification = createAction('NOTIFICATION__SET_NOTIFICATION');

const handleSetNotification = (state, payload) => {
  const types = ['isProcess', 'isDone', 'isFailedNetwork', 'isFailed', 'isInvalid', 'isRemove', 'isNextReception', 'isNpa', 'isInfo'];

  return R.reduce((acc, status) => {
    const action = payload[status];

    if (action !== undefined) {
      const lens = R.lensProp(status);
      return R.set(lens, action, acc);
    }
    return acc;
  }, state, types);
};

const handleLocationChange = state =>
  handleSetNotification(state, {
    isProcess: false,
    isDone: false,
    isFailed: false,
    isRemove: false,
    isNpa: false
  });

const reducer = createReducer((on) => {
  on(setNotification, handleSetNotification);
  on(LOCATION_CHANGE, handleLocationChange);
}, initialState);

export default reducer;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'utils/react-redux';
import { reduxForm } from 'redux-form';
import { withRouter } from "react-router";
import UserForm from 'components/UserForm';

import createValidate from 'utils/validation';
import { userFormPreset, userFormLightPreset } from 'utils/validation/presets';
import options from 'utils/validation/defaultOptions';

import { setUser, setUTM } from '../../redux/signup';

const mapStateToProps = state => ({
  utm: state.Public.signup.utm,
});

const mapDispatchToProps = {
  setUser,
  setUTM
};
const defFields = [
  'custom_фото',
  'custom_firstname',
  'custom_lastname',
  'custom_midname',
  'custom_sexint',
  'custom_dob',
  'custom_мобильный_телефон',
  'custom_должность',
  'custom_город',
  'custom_login',
  'custom_password',
  '___territory',
  'eula',
  'custom_номер_нпа'
];
const form = {
  form: 'registerUser',
  destroyOnUnmount: false,
  validate: createValidate((values, props) => {
    const preset = (props.isLight ? userFormLightPreset : userFormPreset).validation;
    return {
      ...preset,
      custom_password: options.password,
      ___territory: {
        required: props.showInstitutions,
      },
      custom_login: R.trim,
      eula: {
        custom: {
          validator: R.equals(true),
        },
      },
    }
  }),
  fields: defFields,
  initialValues: {
    eula: true,
  },
};

//const formLight = {
//  form: 'registerUser',
//  destroyOnUnmount: false,
//  validate: createValidate((values, props) => {
//    console.log(props)
//    return {
//      ...userFormLightPreset.validation,
//      custom_password: options.password,
//      ___territory: {
//        required: props.showInstitutions,
//      },
//      eula: {
//        custom: {
//          validator: R.equals(true),
//        },
//      },
//    }
//  }),
//  fields: [
//    'custom_фото',
//    'custom_firstname',
//    'custom_lastname',
//    'custom_midname',
//    'custom_sexint',
//    'custom_dob',
//    'custom_мобильный_телефон',
//    'custom_номер_нпа',
//    'custom_должность',
//    'custom_город',
//    'custom_login',
//    'custom_password',
//    '___territory',
//    'eula',
//  ],
//  initialValues: {
//    eula: true,
//  },
//};

//const mapStateToProps = (state, ownProps) => {
//  return ownProps.isLight ? formLight : form;
//}
class PublicRegisterUserContainer extends PureComponent {
  static propTypes = {
    setUser: PropTypes.func.isRequired,
    setUTM: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    valid: PropTypes.bool,
    submitting: PropTypes.bool,
    isLight: PropTypes.bool,
    router: PropTypes.object.isRequired,
    utm: PropTypes.object,
  }

  static defaultProps = {
    setUser: () => { },
    handleSubmit: () => { },
    valid: false,
    submitting: false,
    isLight: false,
    router: {},
    utm: {}
  }

  handleSubmit = this.props.handleSubmit(({ eula, ...values }) => {
    const { utm } = this.props;
    const data = R.evolve({
      custom_sexint: Number,
      custom_firstname: R.trim,
      custom_lastname: R.trim,
      custom_midname: R.trim,
      custom_login: R.trim,
    }, values);
    if (utm && utm.utm_medium) {
      data.utm_medium = utm.utm_medium;
      data.utm_source = utm.utm_source;
    }
    this.props.setUser({
      data,
      isLight: this.props.isLight,
    });
  })

  componentWillMount() {
    const { query } = this.props.router.location;
    const { setUTM } = this.props;
    if (query.utm_medium && query.utm_source) {
      setUTM({
        utm_medium: query.utm_medium,
        utm_source: query.utm_source
      })
    }

  }

  render() {

    return (
      <UserForm
        step='registration'
        onSubmit={this.handleSubmit}
        valid={this.props.valid}
        submitting={this.props.submitting}
        isLight={this.props.isLight}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm(form)
)(PublicRegisterUserContainer);

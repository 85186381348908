import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class Gradient extends PureComponent {
  static propTypes = {
    dark: PropTypes.string.isRequired,
    light: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }

  static defaultProps = {
    dark: undefined,
    light: undefined,
    name: undefined,
  }

  render() {
    const { light, dark, name } = this.props;

    return (
      <pattern
        id={name}
        x='0'
        y='0'
        width='2'
        height='2'
        patternUnits='userSpaceOnUse'
        patternContentUnits='userSpaceOnUse'
      >
        <rect x='0' y='0' width='1' height='1' fill={light} />
        <rect x='1' y='1' width='1' height='1' fill={light} />
        <rect x='0' y='1' width='1' height='1' fill={dark} />
        <rect x='1' y='0' width='1' height='1' fill={dark} />
      </pattern>
    );
  }
}

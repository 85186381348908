import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getItems as getKingsFood } from 'redux/modules/specificEatingTypes';
import getUserIsLight from '../getUserIsLight';

export default createSelector(
  R.path(['specificEatingTypes']),
  getUserIsLight,

  getKingsFood,
);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  gradientByColor,
  propColorMap,
  CHART_PADDING_TOP,
} from '../../helpers';

export default class HighertLimit extends PureComponent {
  static propTypes = {
    data: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.array,
    ]),
    dataInCenter: PropTypes.number,
    norm: PropTypes.number,
    normMin: PropTypes.number,
    normMax: PropTypes.number,
    multiplier: PropTypes.number,
    height: PropTypes.number,
    id: PropTypes.string,
    color: PropTypes.oneOf(['sapphirine']),
    x: PropTypes.func,
    y: PropTypes.func,
    width: PropTypes.number,
  }

  static defaultProps = {
    width: 15,
  }

  getColor(sum) {
    const { color, normMin, normMax, id } = this.props;

    const fill = (sum > normMin && sum < normMax) ? '#49c76f' : '#fc6b53';

    if (color) {
      const propColor = propColorMap[color];
      const gradientColor = color && gradientByColor[propColor] ?
        gradientByColor[propColor](id) : fill;

      return gradientColor;
    }

    return fill;
  }
  render() {
    const {
      height,
      data,
      norm,
      dataInCenter,
      x,
      y,
      multiplier,
      width,
    } = this.props;
    const start = dataInCenter * 2;
    const sum = Array.isArray(data) ? R.sum(data) : data;

    if (!data || !norm || sum < norm * 2 * multiplier) {
      return null;
    }

    const fill = this.getColor(sum);

    return (
      <rect
        style={{
          pointerEvents: 'none',
        }}
        x={x(start)}
        y={CHART_PADDING_TOP}
        height={y(height - 10)}
        width={width}
        fill={fill}
      />
    );
  }
}

import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getPatientAllergen } from 'modules/Rations/redux/modules/allergen';

import getPatientKey from '../getPatientKey';

export default createSelector(
  getPatientKey,
  R.path(['Rations', 'allergen']),

  getPatientAllergen,
);

import moment from 'moment';
import * as R from 'ramda';
import { startSubmit, stopSubmit } from 'redux-form';
import { takeEvery, all, put, call, select } from 'redux-saga/effects';
import { appKey } from 'config';

import { request } from 'utils/api';
import { superuserRoleKey, dietologRoleKey } from 'helpers/constants';
import dispatchAndWait from 'redux/utils/dispatchAndWait';

import * as api from 'utils/api';
import removeCustom from 'utils/removeCustom';
import getErrorFromResponse from 'redux/utils/getErrorFromResponse';
import { getRealFullName } from 'utils/getFullName';
import {
  getApplicationUser,
  getUserIsLight,
} from 'redux/selectors';
import {
  authorizeUserInCarrotByUserData,
  trackAddStaff,
  trackSignup,
} from 'utils/carrot';

import {
  save,
  saveSuccess,
  allowLogin,
  setRole,
  changePassword,
  invite,
  inviteLight,
  saveProfile,
  setStatus, getMobilePhoneStatus,
} from './index';

import {
  fetchItem,
} from '../institutions'
import { getTokenInfo } from "../../../utils/cookie/token";

function* allowLoginFlow(action) {
  try {
    yield put(save({
      key: action.payload.key,
      data: {
        custom_разрешен_вход_по_пользователем_до: moment().add(15, 'minutes').toISOString(),
      },
      isLocal: true,
    }));

    yield call(api.post, {
      key: action.payload.key,
      button: 'custom.allow_to_login_as',
    });
  } catch (e) {
    yield put(save({
      key: action.payload.key,
      data: {
        custom_разрешен_вход_по_пользователем_до: null,
      },
      isLocal: true,
    }));
  }
}

function* setRoleFlow(action) {
  const { payload, meta } = action;
  const { data, form } = payload;
  const { userKey } = meta;

  yield put(startSubmit(form))

  if (payload.role === superuserRoleKey) {
    yield put(save({
      key: userKey,
      data: {
        custom_role: dietologRoleKey,
      },
      isLocal: true,
    }));
  }

  try {
    yield put(save({
      key: payload.key,
      data: {
        custom_role: payload.role,
      },
      isLocal: true,
    }));
    yield call(() => request.post(`/${appKey}t179r/json/v2`, {
      parameters: {
        action: 102,
        staff_key: payload.key,
        role_key: payload.role,
        requared_password: data.custom_requared_password,
      }
    }, {
      headers: {
        Function: 'staffActions',
      },
    }));
    yield put(stopSubmit(form))
  } catch (error) {
    const message = R.pathOr(null, ['response', 'data', 'exception', 'message'], error)

    yield put(save({
      key: userKey,
      data: {
        custom_role: superuserRoleKey,
      },
      isLocal: true,
    }));

    if (message === 'Пароль подтверждения неверен') {
      yield put(stopSubmit(form, {
        custom_requared_password: 'Введен неверный пароль'
      }))
      return;
    }
  }
}

function* saveSuccessFlow(action) {

  if (action.payload.form === 'staffInvite') {
    yield trackAddStaff();
  }
  if (action.payload.form === 'registerUser') {
    const user = action.payload.data;
    const isLight = yield select(getUserIsLight);
    authorizeUserInCarrotByUserData({
      ...user,
      isLight,
    });
    const name = getRealFullName(user);
    const email = user.custom_login;
    yield trackSignup(email, name);
  }
}

function* changePasswordFlow(action) {
  const { form, data } = action.payload
  yield put(startSubmit(form))
  try {
    yield call(() => request.post(`/${appKey}t179r/json/v2`, {
      parameters: {
        action: 101,
        ...data
      }
    }, {
      headers: {
        Function: 'staffActions',
      },
    }));
    yield put(stopSubmit(form))
  } catch (error) {
    const errors = getErrorFromResponse(error, form)
    yield all(errors)
  }
}

function* inviteLightFlow(action) {
  const { form, data } = action.payload;
  yield put(startSubmit(form))
  try {
    yield call(() => request.post(`/${appKey}t197r/json/v2`, {
      parameters: {
        ...removeCustom(data)
      }
    }, {
      headers: {
        Function: 'createGroupInvitation',
      },
    }))
    yield dispatchAndWait(fetchItem())
    yield put(stopSubmit(form))
  } catch (error) {
    const exception = R.pathOr(null, ['response', 'data', 'exception'], error);
    yield put(stopSubmit(form, {
      custom_email: exception.message
    }))
  }
}

function* inviteFlow(action) {
  const { form, data } = action.payload;
  yield put(startSubmit(form));

  try {
    yield call(() => request.post(`/${appKey}t179r/json/v2`, {
      parameters: {
        action: 31,
        staff: removeCustom(data),
      }
    }, {
      headers: {
        Function: 'staffActions',
      },
    }));
    yield dispatchAndWait(fetchItem())
    yield put(stopSubmit(form))
  } catch (error) {

    const errors = getErrorFromResponse(error, form)
    yield all(errors)
  }
}

function* saveProfileFlow(action) {
  const { key, form, data } = action.payload;
  yield put(startSubmit(form))
  try {
    yield call(() => request.post(`/${appKey}t179r/json/v2`, {
      parameters: {
        action: 100,
        requared_password: data.custom_requared_password,
        staff: {
          _key: key,
          ...removeCustom(data),
        },
      }
    }, {
      headers: {
        Function: 'staffActions',
      },
    }));
    yield put(save({
      key,
      data,
      isLocal: true,
    }))
    yield put(stopSubmit(form))
  } catch (error) {
    const errors = getErrorFromResponse(error, form)
    yield all(errors)
  }
}

function* getMobilePhoneStatusFlow(action) {
  const { token } = getTokenInfo();
  const { key, name } = action.payload
  const userAkey = yield select(getApplicationUser)
  const path = key ? ['patientPhone'] : [userAkey, '___phonemobile']
  try {
    const response = yield call(() => request.post(`/${appKey}t179r/json/v2`, {
      parameters: {
        key: key ? key : null
      },
    }, {
      headers: {
        token,
        Function: 'getDecryptedData',
      }
    }))
    const data = key ? response.data['телефон_пациента'] : response.data['мобильный_телефон']
    yield put({
      type: `NUMBER__PHONE_${name}`, payload: {
        data: data || '',
        path,
      }
    })
  } catch (error) {
    console.error(error.message)
  }
}

function* setStatusFlow(action) {
  const { key, status } = action.payload
  const actions = {
    DEACTIVATE: 50,
    ACTIVATE: 51,
    INVITE: 32,
  }
  yield put(save({
    key,
    data: {
      isLoading: true,
    },
    isLocal: true,
  }))
  yield call(() => request.post(`/${appKey}t179r/json/v2`, {
    parameters: {
      staff_key: key,
      action: actions[status],
      staff: {},
    }
  }, {
    headers: {
      Function: 'staffActions',
    },
  }));
  yield dispatchAndWait(fetchItem())
  yield put(save({
    key,
    data: {
      isLoading: false,
      isModal: status === 'INVITE',
    },
    isLocal: true,
  }))
}

export default function* saga() {
  yield all([
    takeEvery(allowLogin, allowLoginFlow),
    takeEvery(getMobilePhoneStatus, getMobilePhoneStatusFlow),
    takeEvery(setRole, setRoleFlow),
    takeEvery(saveSuccess, saveSuccessFlow),
    takeEvery(changePassword, changePasswordFlow),
    takeEvery(invite, inviteFlow),
    takeEvery(inviteLight, inviteLightFlow),
    takeEvery(saveProfile, saveProfileFlow),
    takeEvery(setStatus, setStatusFlow),
  ]);
}

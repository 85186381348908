import options from './defaultOptions';

export const userFormPreset = {
  validation: {
    custom_фото: options.logo,
    custom_firstname: {
      ...options.username,
      required: true,
    },
    custom_lastname: {
      ...options.username,
      required: true,
    },
    custom_midname: options.username,
    custom_sexint: {},
    custom_должность: options.role,
    custom_мобильный_телефон: {
      required: true,
      ...options.phone,
    },
    custom_login: {
      ...options.email,
      unique: {
        errorText: 'Пользователь с таким логином уже зарегистрирован',
      },
    },
  },
};

export const userFormLightPreset = {
  validation: {
    custom_фото: options.logo,
    custom_firstname: {
      ...options.username,
      required: true,
    },
    custom_lastname: {
      ...options.username,
      required: true,
    },
    custom_midname: options.username,
    custom_sexint: {},
    custom_должность: options.role,
    custom_мобильный_телефон: {
      required: true,
      ...options.phone,
    },
    custom_номер_нпа: {
      required: true,
      ...options.npa,
    },
    custom_login: {
      ...options.email,
      unique: {
        errorText: 'Пользователь с таким логином уже зарегистрирован',
      },
    },
  },
}

export const instituteFormPreset = {
  validation: {
    custom_логотип: options.logo,
    custom_название: {
      ...options.agency,
      required: true,
    },
    custom_тип: {},
    custom_филиал: {},
    custom_город: {
      required: true,
    },
    custom_улица: {
      ...options.street,
      required: true,
    },
    custom_дом: {
      ...options.house,
      required: true,
    },
    custom_корпус: options.casehouse,
//    custom_телефон: options.phone,
    custom_эл_почта: {
      ...options.email,
      required: false,
    },
  },
};

import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';

import Text from 'components/Text';
import Legend from 'components/Legend';

import styles from './RequiredNotice.module.css';

const cx = classNames.bind(styles);

export default class RequiredNotice extends PureComponent {
  render() {
    return (
      <div className={cx('requiredNotice')}>
        <Text>
          Поля отмеченные звездочкой (
          <Legend required />
          ) обязательны для заполнения
        </Text>
      </div>
    );
  }
}

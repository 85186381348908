/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import cookie from 'react-cookie';

export const auth = (email, hash) => {
  const isSupport = cookie.load('isSupport');
  if (isSupport) {
    return;
  }
  window.carrotquest && carrotquest.auth(email, hash);
};

export const identify = (data) => {
  const isSupport = cookie.load('isSupport');
  if (isSupport) {
    return;
  }
  window.carrotquest && carrotquest.identify(data);
};

export const track = (event, props = null) => {
  window.carrotquest && carrotquest.track(event, props);
};

import { createSelector } from 'reselect';

import getKeyInfo from 'utils/getKeyInfo';

import getUserRationNewTemplateKey from '../getUserRationNewTemplateKey';

export default createSelector(
  getUserRationNewTemplateKey,

  (key) => {
    if (!key) {
      return null;
    }

    return getKeyInfo(key).record;
  },
);

import createOrmSelector from 'redux/utils/createOrmSelector';

import getPatientKey from 'redux/selectors/getPatientKey';

export default createOrmSelector(
  getPatientKey,

  (session, patientKey) => {
    const { Dietanamnez } = session;

    const item = Dietanamnez
      .exclude({
        isDeleted: true,
      })
      .filter({
        ___parent: patientKey,
      })
      .filter(i => i.custom_дата_назначения)
      .orderBy('custom_дата_назначения')
      .last();

    if (!item) {
      return null;
    }

    return item.key;
  },
);

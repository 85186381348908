import * as R from 'ramda';
import createOrmSelector from 'redux/utils/createOrmSelector';

import getRationKey from '../getRationKey';

export default createOrmSelector(
  getRationKey,
  R.path(['productComposition']),

  (session, key, state) => {
    const { Ration } = session;

    const compositions = Ration
      .withId(key)
      .getGroupsComposition(state);

    const initial = R.compose(
      R.fromPairs,
      R.map(item => [item, [0]]),
      R.flatten,
      R.map(R.keys),
    )(compositions);

    return R.reduce(R.mergeWith((a, b) => a.concat(b || 0)), initial, compositions);
  },
);

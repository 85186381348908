import createOrmSelector from 'redux/utils/createOrmSelector';
import moment from 'moment';
import * as R from 'ramda';

import getKeyInfo from 'utils/getKeyInfo';

import getPatientKey from 'redux/selectors/getPatientKey';
import getSupplementKey from 'redux/selectors/getSupplementKey';

export const getSupplement = createOrmSelector(
  getSupplementKey,

  (session, key) => {
    const { Supplement } = session;

    const item = Supplement
      .filter({
        key,
      })
      .first();

    if (item) {
      return item.ref;
    }

    return item;
  },
);

export const getLastSupplementRation = createOrmSelector(
  getPatientKey,

  (session, patientKey) => {
    const { Supplement } = session;

    return Supplement
      .filter({
        ___parent: patientKey,
      })
      .exclude({
        isDeleted: true,
      })
      .exclude({
        isDeleting: true,
      })
      .orderBy(a => getKeyInfo(a.key).record)
      .last();
  },
);

export const getLastSupplementRationKey = R.compose(
  item => item ? item.key : null,
  getLastSupplementRation,
);

export const getLastSupplementRationId = R.compose(
  item => item ? item.id : null,
  getLastSupplementRation,
);

export const getSupplementItems = createOrmSelector(
  getPatientKey,
  getSupplementKey,

  (session, patientKey, supplementKey) => {
    const { Supplement } = session;

    return Supplement
      .filter({
        ___parent: patientKey,
      })
      .exclude(item => item.isDeleted && item.key !== supplementKey)
      .orderBy(a => getKeyInfo(a.key).record, 'desc')
      .toModelArray()
      .map(item => ({
        value: item.id,
        label: moment(item.___createddate).format('DD.MM.YYYY HH:mm'), // eslint-disable-line no-underscore-dangle
      }));
  },
);

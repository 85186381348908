import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getItem } from 'redux/modules/institutions';

import getInstitutionKey from '../getInstitutionKey';

export default createSelector(
  getInstitutionKey,
  R.path(['institutions']),

  getItem,
);

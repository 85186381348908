import { takeEvery, all, put, select } from 'redux-saga/effects';

import {
  getUserFlags
} from 'redux/selectors'

import {
  save,
  saveFlag,
} from './index';

function* saveFlagFlow(action) {
  const { payload, meta } = action
  const flags = yield select(getUserFlags)

  yield put(save({
    key: flags.key,
    data: {
      custom_пользователь: meta.applicationUser,
      [payload.field]: payload.value,
    },
  }))
}

export default function* saga() {
  yield all([
    takeEvery(saveFlag, saveFlagFlow),
  ]);
}

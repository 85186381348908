import { createSelector } from "reselect";
import * as R from "ramda";
import moment from "moment";

import getRation from "../getRation";
import getRationIsAssigned from "../getRationIsAssigned";
import getRationIsTemplate from "../getRationIsTemplate";

const getRationTitle = (ration) => {
  const start = moment(R.propOr(null, "custom_начало_диеты", ration));

  return `${start.format("DD.MM.YYYY HH:mm")}`;
};

export default createSelector(
  getRation,
  getRationIsAssigned,
  getRationIsTemplate,

  (ration, isAssigned, isTemplate) => {
    return R.compose(
      R.defaultTo(
        isAssigned && !isTemplate ? getRationTitle(ration) : "Новый рацион"
      ),
      R.propOr(null, "custom_название")
    )(ration);
  }
);

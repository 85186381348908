import React, { PureComponent } from 'react';
import { Field } from 'redux-form';
import { withReduxForm } from 'redux-form/es/ReduxFormContext';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import Select from './Select';

class SelectConnected extends PureComponent {
  static contextTypes = {
    onFormChange: PropTypes.func,
  };

  static propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
    ]),
  };

  static defaultProps = {
    name: undefined,
    onChange: () => { },
    onFocus: () => { },
    value: undefined,
  };

  handleChange = onChange => (event) => {
    onChange(event);
    this.props.onChange(event);
    setTimeout(this.context.onFormChange, 0);
  }

  renderField = (field = {}) => (
    <Select
      {...this.props}
      {...field}
      {...field.input}
      {...field.meta}
      value={field.input.value === '' ? (this.props.value || '') : field.input.value}
      onChange={this.handleChange(field.input.onChange)}
      onFocus={this.props.onFocus}
    />
  );

  render() {
    const { _reduxForm: reduxForm } = this.props;
    const isFieldArray = R.test(/\[\d+\]/, this.props.name || '');

    if (!reduxForm || (!R.includes(this.props.name, reduxForm.fields) && !isFieldArray)) {

      return (
        <Select {...this.props} />
      );
    }

    return (
      <Field
        component={this.renderField}
        name={this.props.name}
        {...this.props}
        onChange={undefined}
      />
    );
  }
}

export default withReduxForm(SelectConnected);
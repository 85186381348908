import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  getColorStatus,
  getOnePercent,
  CHART_PADDING_TOP,
} from '../../helpers';

export default class HighertLimit extends PureComponent {
  static propTypes = {
    data: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.array,
    ]),
    dataChart: PropTypes.array,
    dataInCenter: PropTypes.number,
    normMin: PropTypes.number,
    normMax: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.oneOf(['sapphirine']),
    x: PropTypes.func,
    y: PropTypes.func,
  }

  static defaultProps = {

  }

  getPercent = () => {
    const { data, normMin, normMax } = this.props;
    const sum = Array.isArray(data) ? R.sum(data) : data;


    if (sum < normMin) {
      return Math.floor(((sum - normMin) * (100 / normMin)));
    } else if (sum > normMax) {
      return Math.floor(sum * (100 / normMax));
    }
    return 100;
  }

  getIsRender() {
    const { normMax, normMin, dataChart } = this.props;
    const data = R.compose(
      R.propOr(0, 'end'),
      R.last,
    )(dataChart);
    const onePercent = getOnePercent(normMin, normMax);
    if (!data || (!normMax && !normMin)) {
      return false;
    }

    if (isFinite(normMax) && !normMax) {
      return false;
    }

    if (data < (100 * onePercent)) {
      return false;
    }

    return true;
  }

  render() {
    const { height, dataInCenter, x, y } = this.props;
    const isRendered = this.getIsRender();
    const valueInPercent = this.getPercent();
    const color = getColorStatus(valueInPercent, this.props.color);
    const start = dataInCenter * 2;
    const duration = isRendered ? 0.3 : 0;

    return (
      <rect
        x={x(start)}
        y={CHART_PADDING_TOP}
        height={y(height - 10)}
        width={isRendered ? 15 : 0}
        fill={color}
        style={{
          transition: `width ${duration}s ease ${duration}s`,
        }}
      />
    );
  }
}

import { createSelector } from 'reselect';

import getKeyInfo from 'utils/getKeyInfo';

import getDietanamnezLastKeyWithoutNotEnded from '../getDietanamnezLastKeyWithoutNotEnded';

export default createSelector(
  getDietanamnezLastKeyWithoutNotEnded,

  (key) => {
    if (!key) {
      return null;
    }

    return getKeyInfo(key).record;
  },
);

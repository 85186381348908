import { combineReducers } from 'redux';
import { all, call } from 'redux-saga/effects';

import { saga as actionsSaga } from './actions';
import signup, { saga as signupSaga } from './signup';

export default combineReducers({
  signup,
});

export function* saga() {
  yield all([
    call(actionsSaga),
    call(signupSaga),
  ]);
}

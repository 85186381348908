import getAnalysisItem from '../getAnalysisItem';

import AnalysisGenes from '../../../components/AnalysisGenes';
import AnalysisSalt from '../../../components/AnalysisSalt';
import AnalysisBlood from '../../../components/AnalysisBlood';
import AnalysisHair from '../../../components/AnalysisHair';
import AnalysisVitamin from '../../../components/AnalysisVitamin';

const getAnalysisViewComponent = (state) => {
  const analysis = getAnalysisItem(state);
  const { typeId } = analysis || {};
  const analysisTypeToStateMap = {
    194: AnalysisBlood,
    196: AnalysisGenes,
    193: AnalysisVitamin,
    192: AnalysisHair,
    195: AnalysisSalt,
  };

  if (!typeId) {
    return () => null;
  }

  return analysisTypeToStateMap[typeId];
};

export default getAnalysisViewComponent;
import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getItemsArray } from 'redux/modules/journal';

export default createSelector(
  R.path(['journal']),

  getItemsArray,
);

import * as R from "ramda";
import { createSelector } from "reselect";

import { appKey } from "config";

import getUserProfile from "../getUserProfile";
import getUser from "../getUser";
import getInstitutionSubscriptionIsBlocked from "../getInstitutionSubscriptionIsBlocked";

export default createSelector(
  getUserProfile,
  getUser,
  getInstitutionSubscriptionIsBlocked,

  (profile, worker, isBlocked) => {
    const actualProfile = isBlocked && worker ? worker.custom_профиль : profile;

    if (window.location.pathname.toLowerCase() === "/l") {
      return true;
    }

    return R.equals(`${appKey}ts18r17`, actualProfile);
  }
) as unknown as () => boolean;

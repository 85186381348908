import * as R from 'ramda';
import { createSelector } from 'reselect';

import getPatientAllergenIngredientsGrouped from '../getPatientAllergenIngredientsGrouped';

export default createSelector(
  getPatientAllergenIngredientsGrouped,

  R.compose(
    R.unnest,
    R.pluck('custom_ингредиенты_аллергены'),
  ),
);

import * as R from 'ramda';
import { attr, Model, fk } from 'redux-orm';
import moment from 'moment';

import generator from 'redux/hor/orm-module';
import fetchWithView from 'redux/utils/fetchWithView';
import getKeyInfo from 'utils/getKeyInfo';

const DEFAULT_VIEW = 'ts5c172cs9r616';
const ACTIVE_VIEW = 'active';

const {
  fetch,
  save,
  saveSuccess,
  removeWithUndo,

  reducer,
} = generator({
  name: 'PatientReceptions',
  tableId: 172,
  parseItem: R.evolve({
    custom_vremya: value => R.isNil(value) ? value : moment(value),
    custom_время_удаления: value => R.isNil(value) ? value : moment(value),
    custom_planned_patient: R.propOr(null, 'key'),
    ___parent: R.propOr(null, 'key'),
  }),
});

export {
  fetch,
  save,
  saveSuccess,
  removeWithUndo,
};

export default class PatientReceptions extends Model {
  static modelName = 'PatientReceptions';

  static reducer = reducer;

  static options = {
    idAttribute: 'key',
  }

  static fields = {
    ___parent: fk('Worker', '_worker'),
    custom_vremya: attr(),
    custom_отключено: attr(),
    custom_planned_patient: attr(),
    custom_время_удаления: attr(),
  }

  get id() {
    return getKeyInfo(this.key).record;
  }
}

export const fetchWithDefaultView = fetchWithView(fetch, DEFAULT_VIEW);
export const fetchWithActiveView = fetchWithView(fetch, ACTIVE_VIEW, {
  lazyLoad: true,
});

export { default as saga } from './saga';

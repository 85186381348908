import * as R from 'ramda';
import { takeEvery, all, put, select, call } from 'redux-saga/effects';

import { appKey } from 'config';

import { request } from 'utils/api';

import dispatchAndWait from 'redux/utils/dispatchAndWait';
import runButton from 'redux/utils/runButton';
import {
  trackAddMeal,
} from 'utils/carrot';
import isLocalKey from 'utils/isLocalKey';

import { getItemsArrayByParent as getTechcard, save as saveTechcard } from 'redux/modules/productTechCard';
import {
  getItemsArrayByParent as getComposition,
  save as saveComposition,
  fetchSuccess as fetchCompositionSuccess,
} from 'redux/modules/productComposition';

import {
  save,
  saveSuccess,
  fetchComposition,
  fetchSystemMeals,
  fetchUserMeals,
  fetchCatalogList,
  fetchCatalogListSuccess,
  calcComposition,
  copyComposition,
} from './index';

function* calcCompositionFlow(action) {
  const { key } = action.payload;

  yield put(save({
    key,
    data: {
      isCalcComposition: true,
    },
    isLocal: true,
  }));

  const response = yield call(request.post, `/${appKey}t179r/json/v2`, {
    parameters: {
      dish: key,
    },
  }, {
    headers: {
      Function: 'fillCompositionDish',
    },
  });

  yield put(fetchCompositionSuccess({
    response: {
      list: response.data,
    },
    parents: [key],
  }));

  yield put(save({
    key,
    data: {
      isCalcComposition: false,
    },
    isLocal: true,
  }));
}

function* saveSuccessFlow(action) {
  const { meta, payload } = action;
  const { key, response } = payload;
  const newKey = R.pathOr(null, ['data', 'key'], response);

  if (key === newKey || !newKey) {
    return;
  }

  const techcard = yield select(state => getTechcard(key, state.productTechCard));
  const composition = yield select(state => getComposition(key, state.productComposition));

  const techcardEffect = techcard.map(item => put(saveTechcard({
    key: item.key,
    data: {
      ___parent: newKey,
    },
  })));
  const compositionEffect = composition.map(item => put(saveComposition({
    key: item.key,
    data: {
      ___parent: newKey,
    },
  })));

  yield all(techcardEffect);
  yield all(compositionEffect);

  if (isLocalKey(meta.entity.key)) {
    yield trackAddMeal();
  }
}

function* fetchCatalogListFlow(action) {
  try {
    yield all([
      dispatchAndWait(fetchSystemMeals(action.payload)),
      dispatchAndWait(fetchUserMeals(action.payload)),
    ])
  } catch (error) { }

  yield put(fetchCatalogListSuccess());
}

export default function* saga() {
  yield all([
    takeEvery(saveSuccess, saveSuccessFlow),
    takeEvery(fetchCatalogList, fetchCatalogListFlow),
    takeEvery(calcComposition, calcCompositionFlow),
    takeEvery(copyComposition, runButton({
      button: `${appKey}ts9c4c3c1r125`,
      fetch: fetchComposition,
      save,
    })),
  ]);
}

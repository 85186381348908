import * as R from 'ramda';
import { takeEvery, all, select, put, call } from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';
import { stringify } from 'query-string';
import delay from 'utils/delay';

import { appKey, oneDayKey } from 'config';

import {
  getItemByKey,
  getUserEmail,
  getInstitutionTariffIsTrial,
  getInstitutionTariffIsSpecial,
  getInstitutionSubscriptionIsExpired,
  getInstitutionSubscriptionIsBlocked,
  getInstitutionWorkplaceCount,
  getInstitutionSubscriptionState,
  getUserIsLight
} from 'redux/selectors';
import { fetchItem as fetchInstitution } from 'redux/modules/institutions';

import dispatchAndWait from 'redux/utils/dispatchAndWait';
import { trackRenewSubscription } from 'utils/carrot';
import getKeyInfo from 'utils/getKeyInfo';

import { getVariable } from 'api/getVariable';

import {
  fetch,
  save,

  cancel,
  renewal,
  changeFutureOneDayDate,
} from './index';

const nameById = {
  10698526: 'Pro-1д',
  5370656: 'Pro-1',
  5371410: 'Pro-12',
  5370654: 'Lite-1',
  5370883: 'Lite-12',
};

function* getOperationParams(entity) {
  const operationId = getKeyInfo(entity.custom_операция).record;
  const tariffId = getKeyInfo(entity.custom_тарифный_план).record;

  let text = nameById[tariffId];
  let quantity = entity.custom_значение_операции;

  if (operationId === 5370661) {
    const workplaces = yield select(getInstitutionWorkplaceCount);
    text = 'Pro Подписка дополнительный аккаунт';
    quantity -= workplaces;
  }

  return { text, quantity };
}

function* getFormYoomoney(entity) {
  const email = yield select(getUserEmail);
  const form = document.createElement('form');
  form.setAttribute('method', 'post');
  form.setAttribute('action', 'https://yoomoney.ru/eshop.xml');

  const { text, quantity } = yield call(getOperationParams, entity);

  const fields = {
    shopId: '143678',
    scid: '103820',
    sum: entity.custom_ordersumrequired,
    customerNumber: entity.custom_customernumber,
    orderNumber: entity.custom_ordernumber,
    ym_merchant_receipt: JSON.stringify({
      customerContact: email,
      taxSystem: 2,
      items: [
        {
          quantity,
          price: {
            amount: entity.custom_ordersumrequired / quantity,
          },
          tax: 1,
          text,
          paymentMethodType: 'full_payment',
          paymentSubjectType: 'service',
        },
      ],
    }),
  };

  Object.keys(fields).forEach((key) => {
    const value = fields[key];
    const i = document.createElement('input');

    i.setAttribute('name', key);
    i.setAttribute('value', value);
    form.appendChild(i);
  });

  document.body.appendChild(form);
  form.submit();
}

function* getFormRobokassa(entity, merchantLogin, isTest) {
  const { text } = yield call(getOperationParams, entity);

  const params = {
    MerchantLogin: merchantLogin,
    OutSum: entity.custom_ordersumrequired.toFixed(0),
    Description: `Оказание услуг доступа к ПО Нутрилоджик. Учетный период - ${text}`,
    InvoiceID: getKeyInfo(entity.key).record,
    Receipt: encodeURIComponent(encodeURIComponent(
      JSON.stringify({
        "sno": "usn_income",
        "items": [
          {
            "name": `Оказание услуг доступа к ПО Нутрилоджик. Учетный период - ${text}`,
            "quantity": 1,
            "sum": +entity.custom_ordersumrequired.toFixed(0),
            "payment_method": "full_payment",
            "payment_object": "service",
            "tax": "none",
          },
        ]
      })
    )),
    Culture: 'ru',
    Encoding: 'utf-8',
    SignatureValue: entity.custom_signaturevalue,
  }

  if (isTest) {
    window.location = `https://auth.robokassa.ru/Merchant/Index.aspx?${stringify({
      ...params,
      IsTest: 1,
    })}`;
  } else {
    yield new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://auth.robokassa.ru/Merchant/bundle/robokassa_iframe.js';
      script.addEventListener('load', () => {
        resolve()
      })

      document.body.appendChild(script);
    })

    window.Robokassa.StartPayment({
      ...params,
      Recurring: entity.custom_recurring,
    })

    yield new Promise(resolve => {
      window.addEventListener('message', (event) => {
        if (event?.data?.action === "closeRobokassaFrame") {
          resolve();
        }
      })
    })
  }
}

function* saveSuccessFlow(action) {
  const { key, response } = action.payload;
  const newKey = R.pathOr(null, ['data', 'key'], response);

  if (key === newKey || !newKey) {
    return;
  }

  const isBlocked = yield select(getInstitutionSubscriptionIsBlocked);

  if (isBlocked) {
    window.location = '/user/subscription';
  }
}

function* cancelFlow(action) {
  try {
    yield dispatchAndWait(save({
      key: action.payload.key,
      data: {
        custom_currentstatus: 'cancelOrder',
      },
    }));

    const isExpired = yield select(getInstitutionSubscriptionIsExpired);

    if (isExpired) {
      window.location = '/patients';
    }
  } catch (e) {

  }
}

function* checkOperation(key) {
  let newItem;

  for (var i = 0; i < 3; i++) {
    yield call(delay, 5000);
    yield dispatchAndWait(fetch({ key }));
    newItem = yield select(getItemByKey(key));
    if (newItem.custom_currentstatus === "PostProcessingDone") {
      return newItem;
    }
  }

  return newItem;
}

function* renewalFlow(action) {
  const { payload } = action;
  const { type, data, form } = payload;

  yield put(startSubmit(form));

  yield dispatchAndWait(fetchInstitution());

  try {
    const isTrial = yield select(getInstitutionTariffIsTrial);
    const isSpecial = yield select(getInstitutionTariffIsSpecial);
    const isLight = yield select(getUserIsLight);
    const state = yield select(getInstitutionSubscriptionState);
    const isNewSubscriptionOneDay = data.custom_тарифный_план === oneDayKey;
    let actionId = null;
    if (isNewSubscriptionOneDay) {
      actionId = 6448820; // Покупка однодневной подписки
    } else if (isTrial || isSpecial) {
      actionId = 5370660; // Смена тарифного плана
    } else {
      actionId = 5385772; // Продление текущего тарифного плана
    }
    const response = yield dispatchAndWait(save({
      data: {
        ...data,
        custom_currentstatus: type,
        custom_recurring: isLight && (state !== 'DEFAULT_RECURRING' || !!data.custom_промокод),
        custom_операция: data.custom_операция || `${appKey}t122r${actionId}`,
      },
    }));

    if (type === 'newOrder') {
      const key = response.payload.response.data.key;

      yield dispatchAndWait(fetch({
        key,
      }));
      const item = yield select(getItemByKey(key));
      const [payments, merchantLogin] = yield all([
        call(getVariable, 'payments'),
        call(getVariable, 'MerchantLogin')
      ])
      const isTest = payments[payments.use]?.active?.isTest || false;
      if (payments.use === 'umoney') {
        yield call(getFormYoomoney, item, merchantLogin, isTest);
      } else if (payments.use === 'robokassa') {
        yield call(getFormRobokassa, item, merchantLogin, isTest);
      }

      const newItem = yield call(checkOperation, item.key);

      if (newItem.custom_currentstatus === "PostProcessingDone") {
        yield dispatchAndWait(fetchInstitution());
        action.meta.resolve(true);
        yield put(stopSubmit(form));
      } else {
        action.meta.resolve(false);
        yield put(stopSubmit(form));
      }
    } else {
      yield call(saveSuccessFlow, response);
      yield put(stopSubmit(form));
    }
  } catch (error) {
    console.error(error);
    yield put(stopSubmit(form));
  }

  yield trackRenewSubscription();
}

function* changeFutureOneDayDateFlow(action) {
  try {
    const response = yield dispatchAndWait(save({
      data: {
        ...action.payload.data,
        custom_currentstatus: 'newManualOrder',
        custom_операция: `${appKey}t122r6449623`, // Перенос даты однодневной подписки
      },
    }));
    // Текущая подписка учреждения изменилась, если перенесли дату на текущий день
    yield dispatchAndWait(fetchInstitution());
    yield call(saveSuccessFlow, response);
  } catch (error) {
    console.error(error);
  }
}

export default function* saga() {
  yield all([
    takeEvery(cancel, cancelFlow),
    takeEvery(renewal, renewalFlow),
    takeEvery(changeFutureOneDayDate, changeFutureOneDayDateFlow),
  ]);
}

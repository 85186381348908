import createSelector from 'redux/utils/createOrmSelector';

import getApplicationUser from 'redux/selectors/getApplicationUser';

export default createSelector(
  getApplicationUser,

  (session, appUser) => {
    const { Ration } = session;

    const lastRation = Ration
      .filter({
        ___owner: appUser,
        custom_не_назначен: true,
        custom_удалено: false,
      })
      .exclude({
        custom_тип_шаблона: 0,
      })
      .exclude({
        isDeleting: true,
      })
      .last();

    if (!lastRation) {
      return null;
    }

    return lastRation.key;
  },
);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Gradient from '../Gradient';

export default class Defs extends PureComponent {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    id: PropTypes.string,
    gradientColor: PropTypes.string,
    extraWidth: PropTypes.number,
  }

  render() {
    const { width, height, id, gradientColor, extraWidth } = this.props;
    const redGradientDarkColor = gradientColor || '#fc6b53';

    return (
      <defs>
        <clipPath id={`chartClip${id}`}>
          <rect
            x={0}
            y='0'
            width={width + extraWidth}
            height={height}
          />
        </clipPath>
        <clipPath id={`normClip${id}`}>
          <rect
            x={0}
            y='0'
            width={width}
            height={height}
          />
        </clipPath>

        <Gradient
          light='#ffffff'
          dark={redGradientDarkColor}
          name={`redGradient${id}`}
        />

        <Gradient
          light='#dbf4e2'
          dark='#49c76f'
          name={`greenGradient${id}`}
        />

        <Gradient
          light='#dbf4e2'
          dark='#8ec5d0'
          name={`sapphirineGradient${id}`}
        />
      </defs>
    );
  }
}

import * as R from 'ramda';
import { createSelector } from 'reselect';

import {
  getSelectValues,
} from 'modules/Rations/redux/modules/mode';

export default createSelector(
  R.path(['Rations', 'mode']),

  getSelectValues,
);

import createOrmSelector from 'redux/utils/createOrmSelector';

import { appKey } from 'config';

import isLocalKey from 'utils/isLocalKey';

import getAnalysisId from '../getAnalysisId';

export default createOrmSelector(
  getAnalysisId,

  (session, id) => {
    if (!id) {
      return id;
    }

    if (!isLocalKey(id)) {
      return `${appKey}t19r${id}`;
    }

    try {
      const { PatientAnalysis } = session;
      const item = PatientAnalysis.filter({ oldKey: id }).first();

      return item.key;
    } catch (error) {
      return id;
    }
  },
);

import { createSelector } from 'redux-orm';

import orm from 'redux/orm';

const createOrmSelector = (...args) => createSelector(
  orm,
  state => state.orm,
  ...args,
);

export default createOrmSelector;
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';

import styles from './PageNotFound.module.css';

if (process.env.REACT_APP_API === 'dd') {
  import('./PageNotFound.dd.module.css');
}

const cx = classNames.bind(styles);

export default class Subscription extends PureComponent {
  static propTypes = {
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string,
    children: PropTypes.node,
    status: PropTypes.number,
    onClick: PropTypes.func,
    errorText: PropTypes.string,
  }

  static defaultProps = {
    buttonLink: null,
    buttonText: 'Назад',
    children: undefined,
    status: 404,
    onClick: () => {},
    errorText: 'Ошибка',
  }

  renderStatus = () => {
    const { status } = this.props;

    if (!status) {
      return null;
    }

    return (
      <div className={cx('errorNumber')}>
        {status}
      </div>
    );
  }

  render() {
    const { buttonText, buttonLink, onClick, errorText } = this.props;

    return (
      <div className={cx('error')}>
        <div className={cx('container')}>
          <div className={cx('circle')} />
          <div className={cx('main')}>
            {errorText &&
              <div className={cx('errorText')}>
                {errorText}
              </div>
            }
            {this.renderStatus()}
            <div className={cx('note')}>
              {this.props.children}
            </div>
          </div>
          <a className={cx('button')} href={buttonLink} onClick={onClick} bordered>
            {buttonText}
          </a>
          <div className={cx('logo')} />
        </div>
      </div>
    );
  }
}

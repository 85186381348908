import moment from 'moment';
import * as R from 'ramda';

import { appKey } from 'config';

import declOfNums from 'utils/declOfNums';
import getKeyInfo from 'utils/getKeyInfo';

import createOrmSelector from 'redux/utils/createOrmSelector';

const getLabel = declOfNums(['аккаунт', 'аккаунта', 'аккаунтов']);
const getLabel1 = declOfNums(['аккаунта', 'аккаунтов', 'аккаунтов']);

export const getActiveBill = createOrmSelector(
  (session) => {
    const { SubscriptionOperation } = session;
    const activeBill = SubscriptionOperation
      .exclude({
        isSaving: true,
      })
      .exclude({
        custom_операция: `${appKey}t122r6449623`, // Перенос даты однодневной подписки
      })
      .filter({
        custom_currentstatus: 'newManualOrder',
      })
      .first();

    return activeBill ? activeBill.ref : undefined;
  },
);

export const getBillList = createOrmSelector(
  (session) => {
    const { SubscriptionOperation } = session;

    return SubscriptionOperation
      .exclude({
        custom_currentstatus: 'cancelOrder',
      })
      .exclude({
        custom_currentstatus: 'cancelManualOrder',
      })
      .exclude({
        custom_currentstatus: 'newOrder',
      })
      .exclude({
        custom_операция: `${appKey}t122r6135158`,
      })
      .exclude({
        custom_операция: `${appKey}t122r6449623`,
      })
      .orderBy(a => moment(a.custom_дата_операции).valueOf(), 'desc')
      .toModelArray()
      .map((item) => {
        const tariff = item.custom_тарифный_план;

        const durationLabel = {
          1: 'месяц',
          12: 'год',
        };

        const duration = tariff ? durationLabel[tariff.custom_количество_месяцев_подписки] : undefined;

        const operationId = getKeyInfo(item.custom_операция).record
        const textByOperation = {
          5370660: `${item.custom_значение_операции} ${getLabel(item.custom_значение_операции)} на ${duration}`,
          5385772: `${item.custom_значение_операции} ${getLabel(item.custom_значение_операции)} на ${duration}`,
          5370661: `Увеличение до ${item.custom_значение_операции} ${getLabel1(item.custom_значение_операции)}`,
          6448820: `${item.custom_значение_операции} ${getLabel(item.custom_значение_операции)} на 1 день`,
        }

        return {
          key: item.key,
          date: item.custom_дата_операции,
          sum: item.custom_ordersumamount,
          invoiceUri: item.custom_счет,
          text: textByOperation[operationId],
        };
      });
  },
);

export const getLastProlongation = createOrmSelector(
  (session) => {
    const { SubscriptionOperation } = session;

    const item = SubscriptionOperation
      .filter(i => R.includes(i.custom_операция, [`${appKey}t122r5385772`, `${appKey}t122r6448820`]))
      .orderBy(a => moment(a.custom_дата_операции).valueOf(), 'desc')
      .first();

    return item ? item.ref : null;
  },
);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import Select from 'components/Select';

import styles from './AnalysisItemSalt.module.css';

const cx = classNames.bind(styles);

export default class AnalysisItemSalt extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.array,
  }

  render() {
    const {
      name,
      label,
      options,
    } = this.props;

    return (
      <div className={cx('item')}>
        <div className={cx('title')}>
          {label}
        </div>
        <div className={cx('select')}>
          <Select
            name={name}
            options={options}
          />
        </div>
      </div>
    );
  }
}

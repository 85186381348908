import * as R from 'ramda';

import { getItemsArrayByParent } from 'redux/hor/module/selectors';

export const getSumComposition = (value, productKey, state) => {
  const composition = getItemsArrayByParent(productKey, state);

  if (!value) {
    return {};
  }

  return R.compose(
    R.map(elementValue => Math.round(elementValue * value) / 100),
    R.pluck('custom_значение'),
    R.indexBy(R.prop('custom_элемент_рациона')),
  )(composition);
};

export const getIsHaveComposition = R.compose(
  R.not,
  R.isEmpty,
  getItemsArrayByParent,
);

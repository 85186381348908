import createOrmSelector from 'redux/utils/createOrmSelector';

import { appKey } from 'config';

import getDayKey from '../getDayKey';

export default createOrmSelector(
  getDayKey,
  (state, props) => props.groupKey,
  (state, props) => props.itemKey,

  (session, dayKey, groupKey, itemKey) => {
    const { Day } = session;

    const group = Day.withId(dayKey).groups[groupKey];

    if (!group) {
      return {};
    }

    const dishGroup = group.группы_альтернатив[itemKey];
    const activeKey = dishGroup.активное.replace('cXaX', appKey);

    return {
      key: activeKey,
      custom_колич: dishGroup.блюда[dishGroup.активное],
      custom_блюдо: activeKey,
    }
  },
);

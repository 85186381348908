import * as R from 'ramda';
import { createSelector } from 'reselect';

import getPatient from '../getPatient';

export default createSelector(
  getPatient,

  R.propOr(null, 'custom_масса_тела'),
);

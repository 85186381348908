import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import Link from 'components/Link';
import Text from 'components/Text';

import styles from './LoginLayout.module.css';

const cx = classNames.bind(styles);

export default class PublicLoginLayout extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    logo: PropTypes.node,
  }

  static defaultProps = {
    children: undefined,
  }

  render() {
    const { children } = this.props;

    return (
      <div className={cx('loginLayout')}>
        <h1 className={cx('header')}>
          {this.props.logo}
        </h1>

        {children}

        <footer className={cx('footer')}>
          {this.props.site && <Link
            href={this.props.site}
            small
            color='white'
          >
            Вернуться на сайт
          </Link>}
          {this.props.supportUrl && <div>
            <span className={cx('footerSupport')}>Помощь: </span>
            <Text
              size='mini'
              color='white'
            >
              {this.props.supportUrl}
            </Text>
          </div>}
        </footer>
      </div>
    );
  }
}

import { createSelector } from 'reselect';
import moment from 'moment';

import getInstitutionSubscriptionEnd from '../getInstitutionSubscriptionEnd';

export default createSelector(
  getInstitutionSubscriptionEnd,

  endDate => moment(endDate).isBefore(moment()),
);

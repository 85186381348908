import createOrmSelector from 'redux/utils/createOrmSelector';

import getAnalysisKey from '../../getAnalysisKey';

export default createOrmSelector(
  getAnalysisKey,

  (session, analysisKey) => {
    const { ChemicalElement, AnalysisHair } = session;

    const subitems = ChemicalElement
      .filter({
        custom_анализ_крови: true,
      })
      .toRefArray()
      .map((item) => {
        const existedItem = AnalysisHair
          .filter({
            ___parent: analysisKey,
            custom_химический_элемент: item.key,
          })
          .first() || {};

        const commonProps = {
          chemicalElement: item.key,
          normMin: item.custom_норма_анализа_волос_от__мг,
          norm: item.custom_норма_анализа_волос__мг,
          normMax: item.custom_норма_анализа_волос_до__мг,
          label: `${item.custom_наименование}, мкг/г`,
          nameField: item.key,
          element: item.key,
        };

        return {
          ...commonProps,
          data: existedItem.custom_факт,
          updateKey: existedItem.key,
        };
      });

    return [
      {
        element: 'root',
        subitems,
      },
    ];
  },
);

import * as R from 'ramda';
import { createSelector } from 'reselect';

import getInstitutionSubscription from '../getInstitutionSubscription';

export default createSelector(
  getInstitutionSubscription,

  R.compose(
    R.not,
    R.isNil,
    R.propOr(null, 'custom_оплачено'),
  ),
);

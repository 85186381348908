import * as R from 'ramda';
import { attr, fk, Model } from 'redux-orm';

import generator from 'redux/hor/orm-module';
import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,
  fetch,
} = generator({
  name: 'DietanamnezPortion',
  tableId: 66,
  parseItem: R.evolve({
    ___parent: R.propOr(null, 'key'),
  }),
});



export default class DietanamnezPortion extends Model {
  static modelName = 'DietanamnezPortion';

  static reducer = reducer;

  static options = {
    idAttribute: 'key',
  }

  static fields = {
    ___parent: fk('DietanamnezTiles', 'portions'),
    custom_мера: attr(),
  }
}

export const fetchList = fetchWithView(fetch, 'ts5c66cs9r149', {
  useLS: true,
});


export {
  fetch,
};

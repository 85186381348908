import { call, put, all, takeLatest, takeEvery, select } from 'redux-saga/effects';
import moment from 'moment';
import { startSubmit, stopSubmit } from 'redux-form';
import { request } from 'utils/api';
import { appKey } from 'config';

import { fetchData, fetchDataSuccess, deleteDataRow, toggleDelete } from './index';
import { fetchMe } from '../../../../redux/modules/staff'

function* fetchDataFlow(action) {
  const { payload } = action;
  const getAuth = state => {
    return state.auth.userAKey
  };
  const me = yield select(getAuth);
  const date = moment(payload.date, 'MM.YYYY');
  const params = payload.date.length !== 7 ? {
    from: payload.date,
    to: payload.date,
  } : {
    from: date.startOf('month').format('DD.MM.YYYY'),
    to: date.endOf('month').format('DD.MM.YYYY'),
  };
  const { data: invites } = yield call(request.get, `/${appKey}t197r/json/v2`);

  const response = yield call(request.post, `/${appKey}t155r/json/v2`, {
    parameters: {
      ...params,
      all: true,
    },
  }, {
    headers: {
      Function: 'AgencyStatistics',
    },
  });
  yield put(fetchDataSuccess({
    ...payload,
    response,
    invites: invites.list,
    me
  }));
  if (payload.form) {
    yield put(stopSubmit(payload.form));
  }
}

function* deleteDataRowFlow(action) {
  const {payload: { key, form }} = action
  try {
    yield put(toggleDelete(true));
    if (form) {
      yield put(startSubmit(form));
    }
    yield call(request.put, `/${key}/json/v2`, {
      data: {
        custom_время_удаления: moment()
      }
    })
    if (form) {
      const routeParam = state => {

        return state.routeParams.date
      }
      const date = yield select(routeParam)
      yield put(fetchData({ date, form }));
    }
    yield put(fetchMe());
  } catch (e) {
    yield put(stopSubmit(form));
  } finally {
    yield put(toggleDelete(false));
  }
}

export default function* saga() {
  yield all([
    takeLatest(fetchData, fetchDataFlow),
    takeEvery(deleteDataRow, deleteDataRowFlow)
  ]);
}

import * as R from 'ramda';
import { createAction } from 'redux-act';
import { fk, attr, Model as ORMModel } from 'redux-orm';

import { appKey } from 'config';

import generator from 'redux/hor/orm-module';
import fetchWithView from 'redux/utils/fetchWithView';

import { getSumComposition } from 'redux/modules/productComposition';

const {
  reducer,

  fetch,
  fetchSuccess,
  create,
  save,
  removeWithUndo,
  undoRemove,
} = generator({
  name: 'DAY',
  tableId: 29,
  parentField: 'custom_рацион_пациента',
  parseItem: R.evolve({
    custom_рацион_пациента: R.propOr(null, 'key'),
  }),
});

export default class Day extends ORMModel {
  static modelName = 'Day';

  static options = {
    idAttribute: 'key',
  }

  static fields = {
    custom_номер: attr(),
    custom_название_для_шаблона: attr(),
    custom_шаблон_дня: attr(),
    custom_записи_и_рекомендации: attr(),
    custom_рацион_пациента: fk('Ration', '_days'),
    custom_меню: attr(),
  }

  static reducer = reducer;

  get dishes() {
    return [];
  }

  get groups() {
    return this.custom_меню || {}
  }

  get items() {
    return Object.keys(this.custom_dishesjson).map(key => {
      return ({
        ___parent: this,
        key,
        custom_колич: this.custom_dishesjson[key],
        custom_блюдо: key.replace('cXaX', appKey),
        isActive: key === this.custom_ключ_активного_блюда,
      })
    })
  }

  getComposition(state) {
    const data = R.reduce(R.mergeWith(R.add), {}, this.getGroupsComposition(state));

    return data;
  }

  getGroupsComposition(state) {
    return R.compose(
      R.values,
      R.map(group => this.getGroupComposition(group, state)),
      Object.keys,
    )(this.groups);
  }

  getGroupComposition(time, state) {
    const group = this.groups[time];
    const compositions = group.группы_альтернатив.map(item => this.getDishComposition(item, state));

    return R.reduce(R.mergeWith(R.add), {}, compositions);
  }

  getDishComposition(dishGroup, state) {
    const dish = dishGroup.активное.replace('cXaX', appKey);
    const count = dishGroup.блюда[dishGroup.активное];

    return getSumComposition(count, dish, state);
  }

  getIsFilled() {
    return Object.values(this.groups).some(item => !!item.группы_альтернатив && item.группы_альтернатив.length > 0);
  }
}

export const copy = createAction('DAY__COPY');
export const sort = createAction('DAY__SORT');
export const sortSuccess = createAction('DAY__SORT_SUCCESS');
export const sortFailure = createAction('DAY__SORT_FAILURE');
export const fillFromTemplate = createAction('DAY__FILL_FROM_TEMPLATE');
export const fillFromTemplateSuccess = createAction('DAY__FILL_FROM_TEMPLATE_SUCCESS');
export const fillFromTemplateFailure = createAction('DAY__FILL_FROM_TEMPLATE_FAILURE');
export const addGroup = createAction('DAY__ADD_GROUP');
export const removeGroup = createAction('DAY__REMOVE_GROUP');
export const updateGroupTime = createAction('DAY__UPDATE_GROUP_TIME');
export const sortGroupItems = createAction('DAY__SORT_GROUP_ITEMS');
export const removeDishGroup = createAction('DAY__REMOVE_DISHGROUP');
export const changeDishGroupActive = createAction('DAY__CHANGE_DISHGROUP_ACTIVE');
export const removeDishGroupDish = createAction('DAY__REMOVE_DISHGROUP_DISH');
export const addDishGroupDish = createAction('DAY__ADD_DISHGROUP_DISH');
export const changeDishGroupDishAmount = createAction('DAY__CHANGE_DISHGROUP_DISH_AMOUNT');
export const fetchItem = fetchWithView(fetch, 'ts5c29cs6r372');
export const fetchTemplates = fetchWithView(fetch, 'templates', {
  useLS: true,
});

const handleAddGroup = (Model, session, payload) => {
  const { key, time } = payload;

  const day = Model.withId(key);

  const menu = day.custom_меню || {};

  day.update({
    custom_меню: {
      ...menu,
      [time]: {
        группы_альтернатив: [],
      }
    }
  })
}

const handleRemoveGroup = (Model, session, payload) => {
  const { key, time } = payload;

  const day = Model.withId(key);

  const menu = day.custom_меню || {};
  const newMenu = R.omit([time], menu);

  day.update({
    custom_меню: newMenu,
  })
}

const handleUpdateGroupTime = (Model, session, payload) => {
  const { key, currentTime, newTime } = payload;
  const day = Model.withId(key);

  const menu = day.custom_меню || {};

  if (menu[currentTime]) {
    const newMenu = R.omit([currentTime], menu);

    if(newMenu[newTime]) {
      newMenu[newTime]["группы_альтернатив"] = [...newMenu[newTime]["группы_альтернатив"] , ...menu[currentTime]["группы_альтернатив"]]
    }
    else {
      newMenu[newTime] = menu[currentTime];
    }

    day.update({
      custom_меню: newMenu,
    })
  }
}

const handleSortGroupItems = (Model, session, payload) => {
  const { key, groupKey, itemKey, index } = payload;
  const day = Model.withId(key);

  const menu = day.custom_меню || {};
  const dishes = menu[groupKey].группы_альтернатив;

  const newDish = R.compose(
    R.addIndex(R.map)((item, itemIndex) => ({
      ...item,
      номер: itemIndex,
    })),
    R.insert(index, dishes[itemKey]),
    R.addIndex(R.filter)((item, itemIndex) => itemIndex !== itemKey),
  )(dishes);

  const newMenu = R.assocPath([groupKey, 'группы_альтернатив'], newDish, menu);

  day.update({
    custom_меню: newMenu,
  })
}

const handleRemoveDishGroup = (Model, session, payload) => {
  const { key, groupKey, itemKey } = payload;
  const day = Model.withId(key);

  const menu = day.custom_меню || {};
  const dishes = menu[groupKey].группы_альтернатив;

  const newDish = dishes.filter((item, index) => index !== itemKey).map((item, index) => ({
    ...item,
    номер: index,
  }));

  const newMenu = R.assocPath([groupKey, 'группы_альтернатив'], newDish, menu);

  day.update({
    custom_меню: newMenu,
  })
}

const handleRemoveDishGroupDish = (Model, session, payload) => {
  const { key, groupKey, itemKey, dishKey } = payload;
  const day = Model.withId(key);

  const menu = day.custom_меню || {};
  const newMenu = R.compose(
    R.over(R.lensPath([groupKey, 'группы_альтернатив']), R.compose(
      val => val.map((item, index) => ({
        ...item,
        номер: index,
      })),
      R.reject(R.isNil),
    )),
    R.over(R.lensPath([groupKey, 'группы_альтернатив', itemKey]), (value) => {
      const baseKey = dishKey.replace(appKey, 'cXaX');
      const newItems = R.omit([baseKey], value.блюда)

      if (Object.keys(newItems).length === 0) {
        return null;
      }

      return {
        ...value,
        активное: value.активное === baseKey ? Object.keys(newItems)[0] : value.активное,
        блюда: newItems
      }
    })
  )(menu);

  day.update({
    custom_меню: newMenu,
  })
}

const handleChangeDishGroupActive = (Model, session, payload) => {
  const { key, groupKey, itemKey, newActiveKey } = payload;
  const day = Model.withId(key);

  const menu = day.custom_меню || {};
  const newMenu = R.assocPath(
    [groupKey, 'группы_альтернатив', itemKey, 'активное'],
    newActiveKey.replace(appKey, 'cXaX'),
    menu
  );

  day.update({
    custom_меню: newMenu,
  })
}

const handleAddDishGroupDish = (Model, session, payload) => {
  const { key, groupKey, itemKey, dishKey, amount } = payload;
  const day = Model.withId(key);

  const menu = day.custom_меню || {};
  const baseKey = dishKey.replace(appKey, 'cXaX')

  if (itemKey !== undefined) {
    const newMenu = R.assocPath(
      [groupKey, 'группы_альтернатив', itemKey, 'блюда', baseKey],
      amount,
      menu
    );

    day.update({
      custom_меню: newMenu,
    })
  } else {
    const newMenu = R.over(
      R.lensPath([groupKey, 'группы_альтернатив']),
      value => value.concat({
        номер: value.length,
        активное: baseKey,
        блюда: {
          [baseKey]: amount,
        }
      }),
      menu
    );

    day.update({
      custom_меню: newMenu,
    })
  }
}

const handleChangeDishGroupDishAmount = (Model, session, payload) => {
  const { key, groupKey, itemKey, dishKey, amount } = payload;
  const day = Model.withId(key);

  const menu = day.custom_меню || {};
  const baseKey = dishKey.replace(appKey, 'cXaX')
  const newMenu = R.assocPath(
    [groupKey, 'группы_альтернатив', itemKey, 'блюда', baseKey],
    amount,
    menu
  );

  day.update({
    custom_меню: newMenu,
  })
}

const handleSort = (Model, session, payload) => {
  const { key, from, to } = payload;

  const list = Model
    .filter({
      custom_рацион_пациента: key,
    })
    .exclude({
      isDeleted: true,
    })
    .exclude({
      isDeleting: true,
    })
    .orderBy('custom_номер');

  const currentDayKey = list
    .filter({
      custom_номер: from,
    })
    .first()
    .ref
    .key;

  const days = list
    .toRefArray()
    .map(item => item.key);

  const newDays = R.compose(
    R.insert(to - 1, currentDayKey),
    R.without([currentDayKey]),
  )(days);

  newDays.forEach((dayKey, i) => Model.withId(dayKey).update({
    custom_номер: i + 1,
  }));
};

reducer.on(sort, handleSort);
reducer.on(addGroup, handleAddGroup);
reducer.on(removeGroup, handleRemoveGroup);
reducer.on(updateGroupTime, handleUpdateGroupTime);
reducer.on(sortGroupItems, handleSortGroupItems);
reducer.on(removeDishGroup, handleRemoveDishGroup);
reducer.on(removeDishGroupDish, handleRemoveDishGroupDish);
reducer.on(changeDishGroupActive, handleChangeDishGroupActive);
reducer.on(changeDishGroupDishAmount, handleChangeDishGroupDishAmount);
reducer.on(addDishGroupDish, handleAddDishGroupDish);

reducer.on(removeWithUndo, (Model, session, payload) => {
  const { key } = payload;

  const rationKey = Model.withId(key).ref.custom_рацион_пациента;

  Model.withId(key).update({
    isDeleting: true,
  });

  const firstDayNumber = Model
    .filter({
      custom_рацион_пациента: rationKey,
    })
    .exclude({
      isDeleted: true,
    })
    .exclude({
      isDeleting: true,
    })
    .orderBy('custom_номер')
    .first()
    .ref
    .custom_номер;


  handleSort(Model, session, {
    key: rationKey,
    from: firstDayNumber,
    to: 1,
  });
});

reducer.on(undoRemove, (Model, session, payload) => {
  const { key } = payload;

  Model.withId(key).update({
    isDeleted: false,
    isDeleting: false,
  });

  const day = Model.withId(key).ref;

  handleSort(Model, session, {
    key: day.custom_рацион_пациента,
    from: 1,
    to: 1,
  });
});

export {
  fetch,
  fetchSuccess,
  create,
  save,
  removeWithUndo,
};

/* eslint-disable no-param-reassign */

export default function pagePermissions(param) {
  const config = [].concat(param);

  return (target) => {
    target.pagePermission = config;

    return target;
  };
}

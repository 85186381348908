import { fromReduxState } from "model";
import { getInstitution } from "redux/selectors";
import {
  $groups,
  createGroupFx,
  Group,
  removeGroupFx,
  setPatientGroupFx,
  updateGroupFx,
} from ".";

/* Загрузка групп */
const institution = fromReduxState(getInstitution);

$groups.on(institution, (state, payload) => {
  return ((payload as any).custom_группы_пациентов as Group[]) || [];
});

/* Создание группы */
$groups.on(createGroupFx.doneData, (_, payload) => payload);

/* Обновление группы */
$groups.on(updateGroupFx.doneData, (_, payload) => payload);

/* Удаление группы */
$groups.on(removeGroupFx.doneData, (_, payload) => payload);

/* Добавление пациента в группу */
$groups.on(setPatientGroupFx.doneData, (_, payload) => payload);

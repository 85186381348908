import * as R from 'ramda';
import { createSelector } from 'reselect';

export const getToken = R.propOr('', 'token');

export const getLoginId = createSelector(
    (state) => state,
    (state) => state.auth.id
)
export const isRegistryTest = createSelector(
  (state) => state.auth,
  (val) => val.type === 'registry'
)
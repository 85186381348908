import * as R from 'ramda';

import createOrmSelector from 'redux/utils/createOrmSelector';

import getTariff from '../getTariff';

export default createOrmSelector(
  R.compose(
    R.prop('key'),
    getTariff,
  ),

  (session, key) => {
    const { SubscriptionDay } = session;

    return SubscriptionDay
      .filter({
        ___parent: key,
      })
      .orderBy('custom_количество_месяцев_подписки')
      .toRefArray()
      .map(item => {
        const [name, promo] = item.custom_название.split('(')
        return ({
          value: item.key,
          content: item.custom_название,
          price_promo: item.custom_стоимость_исходная,
          price: item.custom_стоимость,
          duration: item.custom_количество_месяцев_подписки,
          name,
          promo: promo?.replace(')', ''),
        })
      });
  },
) as () => {
  value: string,
  content: string,
  price_promo?: number,
  price: number,
  duration: 1 | 12,
  name: string;
  promo?: string;
}[]
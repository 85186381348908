import * as R from 'ramda';
import { createSelector } from 'reselect';

import getTariffReal from '../getTariffReal';

export default createSelector(
  getTariffReal,

  R.propOr(null, 'custom_название'),
);

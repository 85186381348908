import { attr, Model } from 'redux-orm';

import generator from 'redux/hor/orm-module';
import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,
  fetch,
} = generator({
  name: 'genotypes',
  tableId: 10,
});

export default class Genotype extends Model {
  static modelName = 'Genotype';

  static reducer = reducer;

  static options = {
    idAttribute: 'key',
  }

  static fields = {
    custom_наименование: attr(),
  }
}

export const fetchList = fetchWithView(fetch, 'ts5c10cs9r18', {
  useLS: true,
});

export {
  fetch,
};

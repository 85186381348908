import * as R from "ramda";
import { createSelector } from "reselect";

import { getNorm } from "redux/modules/elementNorms";

import getUserCountry from "../getUserCountry";

export default createSelector(
  R.path(["elementNorms"]),
  getUserCountry,

  getNorm
);

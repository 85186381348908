import React, { PureComponent } from "react";
import { Field as FormField } from "redux-form";
import { withReduxForm } from "redux-form/es/ReduxFormContext";
import PropTypes from "prop-types";
import * as R from "ramda";

import Field from "./Field";

class FieldConnected extends PureComponent {
  static contextTypes = {
    onFormChange: PropTypes.func,
    onLogging: PropTypes.func,
  };

  static propTypes = {
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    isLoggingEqualValues: PropTypes.bool,
    autoFocus: PropTypes.bool,
    mobileVersion: PropTypes.bool,
  };

  static defaultProps = {
    name: undefined,
    onBlur: () => {},
    onChange: () => {},
    onFocus: () => {},
    isLoggingEqualValues: false,
    mobileVersion: false,
  };

  state = {
    isСhanging: false,
  };

  handleKeyPress = (event) => {
    if (event.which === 13 && this.context.onFormChange) {
      this.context.onFormChange();
      this.value = event.target.value;
    }
  };

  handleFocus = (onFocus) => (event) => {
    this.value = event.target.value;
    onFocus(event);
    this.props.onFocus(event);
  };

  handleBlur = (onBlur) => (eventOrValue) => {
    const value = R.pathOr("", ["target", "value"])(eventOrValue);
    const isEquals = this.value === value;
    onBlur(eventOrValue);
    this.props.onBlur(eventOrValue);

    if (this.props.isLoggingEqualValues && this.state.isСhanging && isEquals) {
      const key = R.path(["_reduxForm", "patientKey"], this.props);
      this.setState({ isСhanging: false });
      this.context.onLogging({
        key,
        value,
        fieldName: this.props.name.replace("custom_", ""),
        withNotification: true,
      });
    }

    if (!isEquals && this.context.onFormChange && !this.props.disableSubmit) {
      this.context.onFormChange();
    }
  };

  handleChange = (onChange) => (value, event) => {
    onChange(event);
    this.props.onChange(value, event);
    this.setState({ isСhanging: true });
  };

  renderField = (field = {}) => (
    <Field
      {...this.props}
      {...field.input}
      {...field.meta}
      onKeyPress={this.handleKeyPress}
      onChange={this.handleChange(field.input.onChange)}
      onFocus={this.handleFocus(field.input.onFocus)}
      onBlur={this.handleBlur(field.input.onBlur)}
    />
  );

  render() {
    const { _reduxForm: reduxForm } = this.props;
    const isFieldArray = R.test(/\[\d+\]/, this.props.name || "");

    if (
      !reduxForm ||
      (!R.contains(this.props.name, reduxForm.fields) && !isFieldArray)
    ) {
      return <Field {...this.props} />;
    }

    return <FormField component={this.renderField} {...this.props} />;
  }
}

export default withReduxForm(FieldConnected);

import * as R from 'ramda';
import { takeEvery, all, select, put, call } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { startSubmit, stopSubmit } from 'redux-form';
import moment from 'moment';

import { appKey } from 'config';

import { request } from 'utils/api';
import getKeyInfo from 'utils/getKeyInfo';
import { addDateField, dateFieldMap } from 'utils/dateField';
import roundNumber from 'utils/roundNumber';
import {
  trackArchivePatient,
  trackUnarchivePatient,
  trackAddPatient,
  trackRemovePatient,
  shouldTrackAddPatientDiagnosis,
  trackAddPatientDiagnosis,
  shouldTrackAddPatientAntropometry,
  trackAddPatientAntropometry,
  trackAddPatientConsumption,
} from 'utils/carrot';
import { getChangedData } from 'redux/hor/module';
import { getItemByKey } from 'redux/selectors';
import { create as createParams } from 'redux/modules/patientParams';
import {
  getNotesByPatient,
  save as saveNote,
} from 'modules/Patient/redux/notes';
import runButton from 'redux/utils/runButton';
import dispatchAndWait from 'redux/utils/dispatchAndWait';
import { setNotification } from '../notification';

import {
  fetchItem,
  save,
  saveSuccess,
  removeWithUndoSuccess,
  removeWithUndo,
  fetchParams,
  startVisit,
  logPrint,
  calcElementsNorm,
  calcEnergy,
  calcConsumptionByTime,
  calcConsumptionByProfession,
  savePatientRequest,
  switchArchive,
  sendInvite,
  fetchDietanamnez,
  assignToStaff,
  assignToStaffSuccess,
} from './index';

function* switchArchiveFlow(action) {
  const patient = yield select(getItemByKey(action.payload.key));
  const isInArchive = R.propOr(false, 'custom_в_архив_1', patient);
  const buttonPatientToArchive = `${appKey}ts9c5c3c1r194`;
  const buttonPatientFromArchive = `${appKey}ts9c5c3c1r195`;
  const button = isInArchive ? buttonPatientFromArchive : buttonPatientToArchive;

  yield runButton({
    button,
    save,
    data: {
      custom_в_архив_1: !isInArchive,
    },
  })(action);
}

function* saveFlow(action) {
  const { payload, meta } = action;
  const patient = meta.entity;
  const changed = getChangedData(patient, payload.data);

  const paramsMap = {
    custom_масса_тела: 137,
    custom_рост: 140,
    custom_объем_талии: 142,
    custom_объем_бедер: 143,
    custom_объем_плеча: 144,
    custom_жировая_масса: 146,
    custom_мышечная_масса: 147,
    custom_общая_жидкость: 149,
    custom_вунутриклеточная_жидкость: 151,
    custom_внеклеточная_жидкость: 150,
    custom_тощая_масса_тела: 152,
    custom_активная_клеточная_масса: 153,
    custom_висцеральный_жир: 154,
    custom_складка_грудь_подмышка: patient?.custom_sexint_save === 1 ? 156 : 159,
    custom_складка_живот: 157,
    custom_складка_бедро: 158,
    custom_число_калорий: 169,
    custom_окисление_белка: 3198,
    custom_окисление_жиров: 3199,
    custom_окисление_углеводов: 3200,
    custom_число_калорий_при_физической_активности: 3201,
  };
  const loggedChanged = R.pickBy((val, key) => R.has(key, paramsMap), changed);

  const effects = R.compose(
    R.values,
    R.mapObjIndexed((val, key) => put(createParams({
      data: {
        ___parent: payload.key,
        custom_показатель: `${appKey}t12r${paramsMap[key]}`,
        custom_значение: roundNumber(2, typeof val === 'number' ? val : parseFloat(val)),
        custom_дата: moment().toISOString(),
      },
    }))),
  )(loggedChanged);

  const dateFields = addDateField(changed);

  if (!payload.isLocal && !R.isEmpty(dateFields)) {
    yield put(save({
      key: payload.key,
      data: dateFields,
      isLocal: true,
    }));
  }

  yield all(effects);

  // Need data before editing to find if should track
  if (shouldTrackAddPatientDiagnosis(patient, changed)) {
    yield trackAddPatientDiagnosis(action.payload.key);
  }
  if (shouldTrackAddPatientAntropometry(patient, changed)) {
    yield trackAddPatientAntropometry(action.payload.key);
  }
}

/* eslint-disable complexity */
function* saveSuccessFlow(action) {
  const { key, response, data } = action.payload;
  const newKey = R.pathOr(null, ['data', 'key'], response);

  if (R.has('custom_в_архив_1', data)) {
    yield (
      R.prop('custom_в_архив_1', data)
        ? trackArchivePatient
        : trackUnarchivePatient
    )(key);
  }

  if (key === newKey || !newKey) {
    return;
  }

  const notes = yield select(state => getNotesByPatient(key, state.Patient.notes));

  const effect = notes.map(note => dispatchAndWait(saveNote({
    key: note.key,
    data: {
      ___parent: newKey,
    },
  })));

  const patientId = getKeyInfo(newKey).record;

  try {
    yield all(effect);
    yield trackAddPatient(key);
  } catch (error) {
    console.error(error);
  } finally {
    yield put(push(`/patients/${patientId}`));
  }
}

function* calcEnergyFlow(action) {
  const { payload } = action;
  yield put(startSubmit(payload.form));
  const response = yield call(request.post, `/${payload.key}/json/v2`, {
    parameters: R.evolve({
      co2: R.compose(parseFloat, R.replace(',', '.')),
      o2: R.compose(parseFloat, R.replace(',', '.')),
      urea: R.compose(parseFloat, R.replace(',', '.')),
      urine: R.compose(parseFloat, R.replace(',', '.')),
    }, payload.data),
  }, {
    headers: {
      Function: 'KBZU_basedOnAnalyzes',
    },
  });

  const { data } = response;
  yield put(save({
    key: payload.key,
    data: {
      custom_число_калорий: data.k,
      custom_белки_1: data.b,
      custom_жиры_1: data.u,
      custom_углеводы_1: data.z,
    },
    isLocal: true,
  }));
  yield put(stopSubmit(payload.form));
}

function* removeWithUndoSuccessFlow(action) {
  yield trackRemovePatient(action.payload.key);
}

function* calcConsumptionByProfessionFlow(action) {
  yield runButton({
    button: `${appKey}ts9c5c3c1r133`,
    fetch: fetchItem,
    save,
  })(action);
  yield trackAddPatientConsumption(action.payload.key);
}

function* removeWithUndoFlow(action) {
  // Need to change location here and not on remove success, because the latter
  // shows an undo notification that would disappear after location change
  const currentLocation = window.location.pathname;
  const patientId = getKeyInfo(action.payload.key).record;
  const pathPrefix = `/patients/${patientId}`;
  if (R.startsWith(pathPrefix, currentLocation)) {
    yield put(push('/patients'));
  }
}

function* savePatientSuccessFlow(action) {
  const { payload } = action;
  const { key, value, fieldName, withNotification } = payload;
  const patient = R.pathOr({}, ['meta', 'entity'])(action);
  const fullFieldName = `custom_${fieldName}`;

  const prevDate = R.propOr(moment().subtract(1, 'days'), dateFieldMap[fullFieldName])(patient);
  const difference = moment(new Date(), 'DD-MM-YYYY').diff(moment(prevDate, 'DD-MM-YYYY'), 'days');

  if (difference <= 0) {
    return;
  }

  try {
    if (withNotification) {
      yield put(setNotification({
        type: 'global',
        isDone: false,
        isProcess: true,
      }));
    }

    yield call(request.post, `/${key}/json/v2`, {
      parameters: {
        equalAllowed: true,
        value,
        fieldName,
      },
    }, {
      headers: {
        Function: 'savePatientIndex',
      },
    });

    yield put(save({
      key,
      data: addDateField({ [fullFieldName]: value }),
      isLocal: true,
    }));

    yield put(fetchParams({
      key,
    }));

    if (withNotification) {
      yield put(setNotification({
        type: 'global',
        isDone: true,
        isProcess: false,
      }));
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

function* sendInviteFlow(action) {
  const { payload, meta } = action;
  const { key, type, values } = payload;
  const { entity } = meta;

  yield put(startSubmit(payload.form));

  const entityFields = {
    PHONE: 'custom_телефон',
    EMAIL: 'custom_login',
  };
  const fieldToSend = {
    PHONE: 'custom_моб_тел',
    EMAIL: 'custom_e_mail',
  };

  const field = entityFields[type]

  yield put(save({
    key: entity.key,
    data: {
      [field]: values[field],
    },
    disableNotification: true,
  }))
  yield call(request.post, `/${appKey}t183r/json/v2`, {
    parameters: {
      custom_тип_да: payload.custom_тип_да,
      custom_аккаунт_для_пациента: key,
      [fieldToSend[type]]: values[field],
    }
  }, {
    headers: {
      Function: 'Greatecontactforselftest',
    },
  })

  yield dispatchAndWait(fetchDietanamnez({
    key,
  }));

  yield put(stopSubmit(payload.form));
}

function* assignToStaffFlow(action) {
  const { payload } = action;
  const { key } = payload;

  yield call(request.post, `/${key}/json/v2`, {}, {
    headers: {
      Function: 'AssignToStaff',
    },
  })

  yield put(save({
    key,
    data: {
      custom_самостоятельная_регистрация: false,
    },
    isLocal: true,
    disableNotification: true,
  }))

  yield put(assignToStaffSuccess());
}

export default function* saga() {
  yield all([
    takeEvery(startVisit, runButton({
      button: `${appKey}ts9c5c3c1r264`,
      save,
    })),
    takeEvery(logPrint, runButton({
      button: `${appKey}ts9c5c3c1r317`,
      save,
    })),
    takeEvery(calcElementsNorm, runButton({
      button: `${appKey}ts9c5c3c1r79`,
      save,
    })),
    takeEvery(calcEnergy, calcEnergyFlow),
    takeEvery(calcConsumptionByTime, runButton({
      button: `${appKey}ts9c5c3c1r166`,
      fetch: fetchItem,
      save,
    })),
    takeEvery(calcConsumptionByProfession, calcConsumptionByProfessionFlow),
    takeEvery(switchArchive, switchArchiveFlow),
    takeEvery(save, saveFlow),
    takeEvery(saveSuccess, saveSuccessFlow),
    takeEvery(removeWithUndoSuccess, removeWithUndoSuccessFlow),
    takeEvery(removeWithUndo, removeWithUndoFlow),
    takeEvery(savePatientRequest, savePatientSuccessFlow),
    takeEvery(sendInvite, sendInviteFlow),
    takeEvery(assignToStaff, assignToStaffFlow),
  ]);
}

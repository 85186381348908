/* eslint-disable require-yield */

import { track } from '../carrot';

const EVENT_ADD_RATION_TEMPLATE = 'add_ration_template';
export function* trackAddRationTemplate() {
  track(EVENT_ADD_RATION_TEMPLATE);
}

const EVENT_ADD_MEAL = 'add_meal';
export function* trackAddMeal() {
  track(EVENT_ADD_MEAL);
}

const EVENT_ADD_STAFF = 'add_staff';
export function* trackAddStaff() {
  track(EVENT_ADD_STAFF);
}

const EVENT_RENEW_SUBSCRIPTION = 'renew_subscription';
export function* trackRenewSubscription() {
  track(EVENT_RENEW_SUBSCRIPTION);
}

const EVENT_AUTHORIZE = 'authorize';
export function* trackAuthorize() {
  track(EVENT_AUTHORIZE);
}

const EVENT_SIGNUP = 'signup';
export function* trackSignup(email, name) {
  track(EVENT_SIGNUP, {
    email,
    name,
  });
}

const EVENT_ACTIVATE = 'activate';
export function* trackActivate(email, name) {
  track(EVENT_ACTIVATE, {
    email,
    name,
  });
}

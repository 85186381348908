import * as R from 'ramda';
import memoizee from 'memoizee';

const getValues = memoizee(R.values);

export const getItemField = (field, item) => R.pathOr(null, [field], item);

export const getItemWithAllFields = R.identity;

const pickByParent = memoizee(
  (parent, key, values) => R.pickBy(item => getItemField(parent, item) === key, values),
  { length: 3 },
);

export const getAllItems = memoizee(R.compose(
  R.defaultTo({}),
  R.prop('itemsByKey'),
));

export const getItems = R.compose(
  memoizee(R.pickBy(val => !val.isDeleted)),
  memoizee(R.pickBy(val => !val.isDeleting)),
  getAllItems,
);

export const getItemsArray = R.compose(
  getValues,
  getItems,
);

export const getItem = (key, state) => {
  const item = R.pathOr(null, ['itemsByKey', key], state);

  if (item) {
    return item;
  }

  return R.compose(
    R.find(R.propEq('oldKey', key)),
    getValues,
    R.prop('itemsByKey'),
  )(state);
};

export const getField = (field, key, state) => getItemField(field, getItem(key, state), state);
export const getItemById = (id, state) => {
  const key = `${state.tableKey}${id}`;
  return state.itemsByKey[key];
};

export const getKeyById = (id, state) => {
  const { tableKey } = state;

  if (!id) {
    return null;
  }

  return tableKey + id;
};

export const getAllItemsByParent = R.curry(memoizee((key, state) =>
  R.compose(
    values => pickByParent(state.parentField, key, values),
    getAllItems,
  )(state),
));
export const getItemsByParent = R.curry(memoizee((key, state) =>
  R.compose(
    values => pickByParent(state.parentField, key, values),
    getItems,
  )(state),
));
export const getItemsArrayByParent = R.curry(memoizee(R.compose(
  getValues,
  getItemsByParent,
)));
export const getAllItemsArrayByParent = R.curry(memoizee(R.compose(
  getValues,
  getAllItemsByParent,
)));
export const getIsButtonRunning = button => R.compose(
  R.propOr(false, button),
  getItem,
);

import { attr, Model } from 'redux-orm';
import * as R from 'ramda';

import generator from 'redux/hor/orm-module';
import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,
  fetch,
} = generator({
  name: 'genes',
  tableId: 3,
  parseItem: R.evolve({
    custom_генотипы: R.compose(
      R.pluck('key'),
      R.defaultTo([]),
    ),
  }),
});

export default class Gene extends Model {
  static modelName = 'Gene';

  static reducer = reducer;

  static options = {
    idAttribute: 'key',
  }

  static fields = {
    custom_название: attr(),
    custom_описание: attr(),
    custom_видно_по_умолчанию: attr(),
    custom_генотипы: attr(),
  }
}

export const fetchList = fetchWithView(fetch, 'ts5c3cs9r5', {
  useLS: true,
});

export {
  fetch,
};

import * as R from 'ramda';

import { createSelector } from 'reselect';

import getDietanamnezLastIdWithoutNotEnded from '../getDietanamnezLastIdWithoutNotEnded';

export default createSelector(
  R.path(['routeParams', 'dietanamnezId']),
  (state, props) => R.pathOr(null, ['settings', 'dietanamnezId'], props),
  getDietanamnezLastIdWithoutNotEnded,

  (routeId, propsId, lastId) =>  {
    return lastId || propsId || routeId
  },
);

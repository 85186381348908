import * as R from 'ramda';
import createOrmSelector from 'redux/utils/createOrmSelector';

import {oneDayKey} from 'config';

export default createOrmSelector(
  (session) => {
    const { InstitutionSubscription } = session;
    const item = InstitutionSubscription.first();

    if (!item) {
      return {};
    }
//    const bill = SubscriptionOperation
//      .filter(i => R.includes(i.custom_операция, [`${appKey}t122r5385772`, `${appKey}t122r6448820`]))
//      .orderBy(a => moment(a.custom_дата_операции).valueOf(), 'desc')
//      .first();

    let duration = R.pathOr(null, ['custom_оплачено', 'custom_количество_месяцев_подписки'], item);
    let option = item.custom_текущий_вариант_подписки || {
      custom_стоимость: 0,
      custom_стоимость_исходная: 0,
      custom_количество_месяцев_подписки: 1,
    };

    const { custom_текущий_вариант_подписки, custom_оплачено } = item.ref;
    const isCurrentSubscriptionExists = !!custom_текущий_вариант_подписки;
    const isFutureSubscriptionExists = !!custom_оплачено;
    const isCurrentSubscriptionOneDay = custom_текущий_вариант_подписки === oneDayKey;
    const isFutureSubscriptionOneDay = custom_оплачено === oneDayKey;
    const isProlonged = isCurrentSubscriptionExists && isFutureSubscriptionExists;

    let days = 365;
    if (isCurrentSubscriptionOneDay) {
      days = 1;
    } else if (option.custom_количество_месяцев_подписки === 1) {
      days = 31;
    }
//    const realPrice = isProlonged && bill.ref.custom_промокод ? bill.custom_ordersumamount : option.custom_стоимость;
    const dayPrice = option.custom_стоимость / days;
    const currentPrice = item.custom_стоимость_текущей_подписки / (isCurrentSubscriptionOneDay ? 1 : option.custom_количество_месяцев_подписки) / item.custom_количество_пользователей;

    return {
      ...item.ref,
      duration,
      currentPrice,
      dayPrice,
      futureMonths:
      isCurrentSubscriptionExists,
      isFutureSubscriptionExists,
      isCurrentSubscriptionOneDay,
      isFutureSubscriptionOneDay,
      isProlonged,
    };
  },
);

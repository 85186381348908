import * as R from 'ramda';
import { createSelector } from 'reselect';

import getUser from '../getUser';

export default createSelector(
  getUser,

  R.propOr(false, 'custom_ред_сис_шаблонов'),
);

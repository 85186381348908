import * as R from 'ramda';
import { createSelector } from 'reselect';

import getInstitutionTariffKey from '../getInstitutionTariffKey';
import getTariffsAll from '../getTariffsAll';

export default createSelector(
  getInstitutionTariffKey,
  getTariffsAll,

  (key, tariffs) => R.find(R.propEq('key', key), tariffs),
);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import * as R from 'ramda';

import Title from 'components/Title';
import Field from 'components/Field';
import FieldPassword from 'components/FieldPassword';
import Checkbox from 'components/Checkbox';
import Link from 'components/Link';
import Button from 'components/Button';
import Message from 'components/Message';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import Subscription from 'components/Subscription';

import styles from './Login.module.css';

const cx = classNames.bind(styles);

export default class PublicLogin extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    error: PropTypes.string,
    submitting: PropTypes.bool,
    valid: PropTypes.bool,
    onSubmit: PropTypes.func,
    onHideModal: PropTypes.func,
    onSubmitModal: PropTypes.func,
  }

  static defaultProps = {
    children: undefined,
    error: undefined,
    submitting: false,
    valid: false,
    onSubmit: () => {},
    onHideModal: () => {},
    onSubmitModal: () => {},
  }

  renderErrorModal(error = {}) {
    return (
      <Modal
        ariaHideApp={false}
        contentLabel='Ошибка'
        isOpen={!R.isEmpty(error)}
        onRequestClose={this.props.onHideModal}
      >
        <Subscription
          submitBtn={error.submitBtn}
          cancelBtn='Закрыть'
          onSubmit={this.props.onSubmitModal}
          onCancel={this.props.onHideModal}
        >
          {error.text}
        </Subscription>
      </Modal>
    );
  }

  render() {
    const { error, valid, submitting, registration } = this.props;

    return (
      <div className={cx('login')}>
        <Title>
          Вход
        </Title>

        <form
          className={cx('form')}
          onSubmit={this.props.onSubmit}
        >
          <div className={cx('field')} >
            <Field
              name='email'
              label='Эл. почта'
              center
              parse={value => value ? value.trim() : value}
            />
          </div>
          <div className={cx('field')}>
            <FieldPassword
              name='password'
              label='Пароль'
              center
              parse={value => value ? value.trim() : value}
            />
          </div>
          <div className={cx('form-footer')}>
            <Checkbox
              id='remember'
              name='remember'
              theme='bright'
            >
              Запомнить меня
            </Checkbox>
            <div className={cx('restore')}>
              <Link to={this.props.recoveryLink} color='blue' hover='sapphirine'>
                Восстановить пароль
              </Link>
            </div>
          </div>
          {error && typeof error === 'string' &&
            <div className={cx('message')}>
              <Message text={error} />
            </div>
          }
          <div className={cx('submit')}>
            <Button
              disabled={!valid || submitting}
              isLoading={submitting}
              type='submit'
              block
              upper
              bordered
            >
              Войти
            </Button>
          </div>
        </form>

        {registration &&
          <div className={cx('register')}>
            <Link color='blue' hover='sapphirine' large bold {...registration}>
              Регистрация
              <span className={cx('signupIcon')}>
                <Icon icon='signup' />
              </span>
            </Link>
          </div>
        }

        {this.renderErrorModal(typeof error === 'object' ? error : undefined)}
        {this.props.children}
      </div>
    );
  }
}

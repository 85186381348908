import React from "react";
import {
  IndexRoute,
  Route,
  createRoutes as createRoutesRR,
} from "react-router";
import * as R from "ramda";
import memoizee from "memoizee";

import {
  getUserRight,
  getInstitutionSubscriptionIsBlocked,
} from "redux/selectors";

import { hideLoader } from "redux/modules/loader";
import { loadingStart } from "redux/modules/loading";

import App from "containers/App";

import PublicMain from "modules/Public/pages/Main";
import Login from "modules/Public/pages/Login";
import PasswordRecovery from "modules/Public/pages/PasswordRecovery";
import SignupLayout from "modules/Public/pages/SignupLayout";
import Signup from "modules/Public/pages/Signup";
import SignupLight from "modules/Public/pages/SignupLight";
import SignupPro from "modules/Public/pages/Pro";
import Invite from "modules/Public/pages/Invite";

import Error from "pages/Error";

const getRedirectUrl = (store, params, component) => {
  const state = {
    ...store.getState(),
    routeParams: params,
  };

  const result = R.compose(
    R.find(R.propEq("canViewPage", false)),
    R.map(({ rule: rightOrFunc, url: urlOrFunc = "/403" }) => {
      const url = typeof urlOrFunc === "string" ? urlOrFunc : urlOrFunc(state);

      if (typeof rightOrFunc === "string") {
        return {
          canViewPage: getUserRight(rightOrFunc, state),
          url,
        };
      }

      if (typeof rightOrFunc === "function") {
        return {
          canViewPage: rightOrFunc(state, { params }),
          url,
        };
      }

      return {
        canViewPage: false,
        url,
      };
    }),
    R.defaultTo([])
  )(component.pagePermission);

  if (result) {
    return result.url;
  }

  return null;
};

const getRoutes = (store) => {
  let isLoading = false;

  const onRouteChange = () => {
    setTimeout(() => {
      if (!isLoading) {
        store.dispatch(hideLoader("global"));
      }
    });

    if (document.activeElement.blur) {
      document.activeElement.blur();
    }
  };

  const onEnter = function onEnter(nextState, replace, callback) {
    const { params, location } = nextState;
    const component = this.component;
    const isExpired = getInstitutionSubscriptionIsBlocked(store.getState());

    if (
      isExpired &&
      ["/patients", "/login", "/", "/sd", "/l"].indexOf(location.pathname) ===
        -1
    ) {
      replace("/patients");
      callback();
      return;
    }

    if (
      store.getState().ping.serverUnderMaintenance &&
      location.pathname !== "/login"
    ) {
      replace("/login");
      callback();
      return;
    }
    const isLast = R.compose(
      R.propEq("component", component),
      R.defaultTo({}),
      R.last,
      R.defaultTo([])
    )(nextState.routes);

    if (isLoading) {
      if (isLast) {
        isLoading = false;
      }

      const redirectUrl = getRedirectUrl(store, params, component);

      if (redirectUrl) {
        replace(redirectUrl);
      }

      callback();
      return;
    }

    isLoading = true;

    const components = R.compose(
      R.last,
      R.splitWhen(R.equals(this.component)),
      R.reject(R.isNil),
      R.pluck("component")
    )(nextState.routes);
    const fetcher = R.unnest(components.map((item) => item.fetcher || []));

    store.dispatch(
      loadingStart({
        fetcher,
        params,
        state: nextState,
        store,
        callback: () => {
          const redirectUrl = getRedirectUrl(store, params, component);
          if (redirectUrl) {
            replace(redirectUrl);
          }

          if (!redirectUrl) {
            store.dispatch(hideLoader("application"));
          }

          if (isLast) {
            isLoading = false;
          }

          callback();
        },
      })
    );
  };

  const createRoutes = memoizee((module) => {
    return createRoutesRR(module.default(onEnter, store, createRoutes));
  });

  return (
    <Route path="/" component={App} onEnter={onEnter} onChange={onRouteChange}>
      <IndexRoute component={Login} onEnter={onEnter} />
      <Route component={PublicMain} onEnter={onEnter}>
        <Route path="/g" component={Login} onEnter={onEnter} />
        <Route path="/login" component={Login} onEnter={onEnter}>
          <Route
            path="recovery"
            component={PasswordRecovery}
            onEnter={onEnter}
          />
        </Route>
        {process.env.REACT_APP_API !== 'dd' &&
          <Route path="/sd" component={Login} onEnter={onEnter}>
            <Route
              path="recovery"
              component={PasswordRecovery}
              onEnter={onEnter}
            />
          </Route>
        }

        <Route component={SignupLayout} onEnter={onEnter}>
          <Route path="/signup" component={Signup} onEnter={onEnter} />
          {process.env.REACT_APP_API !== 'dd' &&
            <Route path="/lite" component={SignupLight} onEnter={onEnter} />
          }
          <Route path="/pro" component={SignupPro} onEnter={onEnter} />
          <Route path="/invite" component={Invite} onEnter={onEnter} />
        </Route>
      </Route>

      <Route
        getChildRoutes={(state, callback) => {
          import("./inner").then((module) => {
            const routes = createRoutes(module);
            return callback(null, routes);
          });
        }}
      />

      <Route path="/403" component={Error} onEnter={onEnter} status={403} />
      <Route path="/404" component={Error} onEnter={onEnter} status={404} />
      <Route path="*" component={Error} onEnter={onEnter} status={404} />
    </Route>
  );
};

export default getRoutes;

import request from "./request";
import formatHeaders from "./utils/formatHeaders";

type Params = {
  key: string;
  data: Record<string, any>;
  token?: string;
};

export function put<T>({ key, token, data }: Params) {
  return request.put<T>(
    `/${key}/json/v2`,
    { data },
    {
      headers: formatHeaders({
        token,
      }),
    }
  );
}

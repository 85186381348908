import * as R from "ramda";

import { createSelector } from "reselect";

import createOrmSelector from "redux/utils/createOrmSelector";

import { getItemsArrayByParent } from "redux/modules/productComposition";
import { getNorm } from "redux/modules/elementNorms";

import getUserCountry from "../getUserCountry";

const getItemComposition = createSelector(
  (state, props) => props.key,
  (state, { type }) =>
    type === "supplement"
      ? state.supplementComposition
      : state.productComposition,

  getItemsArrayByParent
);

export default createOrmSelector(
  (state, { type }) => type,
  getItemComposition,
  getUserCountry,
  (state) => state.elementNorms,

  (session, type, compositions, userCountry, elementNorms) => {
    const { Elements } = session;
    const elements = Elements.all().toRefArray();

    return R.compose(
      R.indexBy(R.prop("element")),
      R.map((i) => {
        const elementField =
          type === "supplement" ? "custom_элемент" : "custom_элемент_рациона";
        const valueField =
          type === "supplement"
            ? "custom_значение_на_порцию"
            : "custom_значение";

        const composition = R.find(R.propEq(elementField, i.key), compositions);

        const norms = getNorm(elementNorms, userCountry);
        const elementNormItem = norms[i.key] || {};
        const data = R.propOr(undefined, valueField, composition);

        return {
          element: i.key,
          data,
          key: R.propOr(null, "key", composition),
          ...elementNormItem,
        };
      })
    )(elements);
  }
);

import React, { PureComponent } from 'react';
import { connect } from 'utils/react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {getPublicToken} from 'redux/utils/getPublicToken';
import { fetchList as fetchInstitutions } from 'redux/modules/institutions';
import { fetchList as fetchCities } from 'redux/modules/cities';
import { fetchList as fetchCountries } from 'redux/modules/countries';

import RegisterUser from '../../containers/RegisterUser';
import RegisterInstitute from '../../containers/RegisterInstitute';
import AmwayPopup from '../../../../components/AmwayPopup';

const mapStateToProps = state => ({
  step: state.Public.signup.step,
});

const mapDispatchToProps = {
  fetchInstitutions,
  fetchCities,
  fetchCountries,
};

class PublicSignupPage extends PureComponent {
  static propTypes = {
    step: PropTypes.string.isRequired,
    fetchCities: PropTypes.func.isRequired,
    fetchCountries: PropTypes.func.isRequired,
    fetchInstitutions: PropTypes.func.isRequired,
  }

  static defaultProps = {
    step: undefined,
    fetchCities: () => { },
    fetchCountries: () => { },
    fetchInstitutions: () => { },
  }

  state = {
    isVisible: process.env.REACT_APP_API !== 'dd'
  }

  componentWillMount() {
    getPublicToken().then((token) => {
      this.props.fetchInstitutions({
        token,
      });
      this.props.fetchCities({
        token,
      });
      this.props.fetchCountries({
        token,
      });
    });
  }

  handleHide = () => {
    this.setState({ isVisible: false })
  }

  render() {
    const { step } = this.props;
    const { isVisible } = this.state;
    const componentByStep = {
      user: RegisterUser,
      institute: RegisterInstitute,
    };

    const Component = componentByStep[step];

    return (
      <div>
        <Helmet title='Регистрация' />
        <AmwayPopup isVisible={isVisible} handleHide={this.handleHide} />
        <Component />
      </div>
    );
  }
}

export default R.compose(
  connect(mapStateToProps, mapDispatchToProps),
)(PublicSignupPage);

import generator from 'redux/hor/module';
import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,
  fetch,
} = generator({
  name: 'Disease',
  tableId: 11,
});

export const fetchList = fetchWithView(fetch, 'ts5c11cs9r20', {
  useLS: true,
});

export {
  getItem,
} from 'redux/hor/module/selectors';

export {
  getItems,
  getSpecificConditions,
} from './selectors';

export default reducer;

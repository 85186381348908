import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getItems } from 'modules/Patient/redux/notifications';
import getInstitutionBanner from '../getInstitutionBanner';
import getUserNotificationsExcluded from '../getUserNotificationsExcluded';
import getUserIsLight from '../getUserIsLight';

export default createSelector(
  getInstitutionBanner,
  getUserNotificationsExcluded,
  getUserIsLight,
  R.path(['Patient', 'notifications']),

  (institutionBanner, excluded, isLight, state) => {
    const field = isLight ? 'custom_for_lite' : 'custom_for_pro'
    const userNotification = R.compose(
      R.filter(item => item[field]),
      R.map((item) => {
        const exclude = R.find(R.propEq('___parent', item.key), excluded);

        return {
          ...item,
          excluded: Boolean(exclude),
          excludedKey: R.propOr(null, 'key', exclude),
        };
      }),
      getItems,
    )(state);

    if (R.isEmpty(institutionBanner)) {
      return userNotification;
    }

    return [institutionBanner, ...userNotification];
  },
);

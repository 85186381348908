import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';

import AnalysisItemSalt from '../../containers/AnalysisItemSalt';

import styles from './AnalysisSalt.module.css';

const cx = classNames.bind(styles);

export default class AnalysisSalt extends PureComponent {
  static propTypes = {
    data: PropTypes.array,
    itemSelectDictionary: PropTypes.array,
  }

  static defaultProps = {
    data: [],
  }

  renderItem(item) {
    const { itemSelectDictionary } = this.props;

    return (
      <div
        className={cx('item')}
        key={item.key}
      >
        <AnalysisItemSalt
          name={item.key}
          value={item.value}
          update={item.update}
          updateKey={item.updateKey}
          label={item.label}
          options={itemSelectDictionary}
        />
      </div>
    );
  }

  render() {
    return (
      <div className={cx('analysisSalt')}>
        {this.props.data.map(this.renderItem, this)}
      </div>
    );
  }
}

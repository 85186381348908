import * as R from 'ramda';
import { takeEvery, all, select, put } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import { appKey } from 'config';

import runButton from 'redux/utils/runButton';
import dispatchAndWait from 'redux/utils/dispatchAndWait';
import { trackAddPatientDietanamnez } from 'utils/carrot';

import {
  getPatientId,
  getPatientKey,
} from 'redux/selectors';

import {
  getDietanamnezItems,
} from '../../selectors';

import {
  save as saveDietanamnezItem,
  remove as removeDietanamnezItem,
} from '../dietanamnezItem';

import {
  save,
  saveSuccess,
  calcDietanamnezReport,
  assign,
  assignSuccess,
  switchType,
} from './index';

function* saveSuccessFlow(action) {
  const { payload } = action;
  const { key, response } = payload;
  const newKey = R.pathOr(null, ['data', 'key'], response);

  if (key === newKey || !newKey || key === 'LOCAL_DIETANAMNEZ') {
    return;
  }

  const items = yield select(state => getDietanamnezItems(state, {
    dietanamnezKey: newKey,
  }));

  const effect = items.map(note => dispatchAndWait(saveDietanamnezItem({
    key: note.key,
    data: {
      ___parent: newKey,
    },
  })));

  yield all(effect);
}

function* assignFlow(action) {
  const { payload } = action;
  const { key, redirect = true } = payload;

  try {
    yield dispatchAndWait(save({
      key,
      data: {
        isAssigning: true,
        custom_дата_назначения: new Date(),
      },
    }));
    if (redirect) {
      const patientId = yield select(getPatientId);
      yield put(push(`/patients/${patientId}/dietanamnez/`));
    }
  } catch (error) {

  } finally {
    yield put(save({
      key,
      data: {
        isAssigning: false,
      },
      isLocal: true,
    }));
    yield put(assignSuccess({
      key,
    }));
    const patientKey = yield select(getPatientKey);
    yield trackAddPatientDietanamnez(patientKey);
  }
}

function* switchTypeFlow(action) {
  const { payload } = action;
  const { key, value } = payload;

  yield put(save({
    key,
    data: {
      custom_тип_да: value,
    },
    isLocal: action.payload.isLocal,
  }));

  const items = yield select(state => getDietanamnezItems(state, {
    dietanamnezKey: key,
  }));

  const effect = items.map(item => dispatchAndWait(removeDietanamnezItem({
    key: item.key,
    isLocal: true,
  })));

  yield all(effect);
}

export default function* saga() {
  yield all([
    takeEvery(calcDietanamnezReport, runButton({
      button: `${appKey}ts9c140c3c1r8`,
      save,
    })),
    takeEvery(saveSuccess, saveSuccessFlow),
    takeEvery(assign, assignFlow),
    takeEvery(switchType, switchTypeFlow),
  ]);
}

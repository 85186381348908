import { all, takeEvery, select, put } from 'redux-saga/effects';
import * as R from 'ramda';

import * as api from 'utils/api';
import { appKey } from 'config';

import {
  updateList,
  updateListSuccess,
} from './index';

import {
  getRationTableElements
} from '../../selectors';
import { getPatientKey } from 'redux/selectors';

function* updateListFlow(action) {
  const patientKey = yield select(getPatientKey);
  const currentItems = yield select(getRationTableElements);

  const added = action.payload.filter(key => {
    return !currentItems.some(item => item.custom_элемент_состава === key);
  });
  const { current = [], removed = [] } = R.groupBy(item => {
    return action.payload.includes(item.custom_элемент_состава) ? 'current' : 'removed'
  }, currentItems)

  if (!added.length && !removed.length) {
    return;
  }

  const items = yield all([
    ...added.map(async item => {
      const data = {
        ___parent: patientKey,
        custom_элемент_состава: item,
      };

      try {
        const response = await api.post({
          key: `${appKey}t96r`,
          data,
        })

        return {
          key: response.data.key,
          ...data,
        };
      } catch (error) {
        return undefined;
      }
    }),
    ...removed.map(async item => {
      try {
        await api.del({
          key: item.key,
        })
        return undefined;
      } catch (error) {
        return item;
      }
    }),
  ])

  const total = [
    ...current,
    ...items.filter(Boolean),
  ]

  yield put(updateListSuccess({
    parentKey: patientKey,
    items: total,
  }));
}

export default function* saga() {
  yield all([
    takeEvery(updateList, updateListFlow),
  ]);
}

import { createSelector } from 'reselect';

import { getCurrentNotification } from 'redux/modules/notification/selectors';
import getUserPhoneNotification from 'redux/selectors/getUserPhoneNotification';
import getUserNpaNotification from 'redux/selectors/getUserNpaNotification';

export default createSelector(
  getCurrentNotification,
  getUserPhoneNotification,
  getUserNpaNotification,
  (
    notification,
    phoneNotification,
    nbaNotification
  ) => {
    return notification || nbaNotification || phoneNotification
  }
);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import BarchartTable from 'components/BarchartTable';

import AnalysisItemHair from '../../containers/AnalysisItemHair';
import getValidator from '../../utils/bloodAndHairAnalysis/getValidator';

export default class HairAnalysis extends PureComponent {
  static propTypes = {
    data: PropTypes.array,
  }

  fieldRenderer(state) {
    const {
      name,
      value,
      update,
      updateKey,
    } = state;

    return (
      <AnalysisItemHair
        name={name}
        value={value}
        update={update}
        updateKey={updateKey}
        validate={getValidator}
      />
    );
  }

  render() {
    const { data } = this.props;

    return (
      <BarchartTable
        data={data}
        multiplier={1.5}
        fieldRenderer={this.fieldRenderer}
      />
    );
  }
}

import createCachedSelector from 're-reselect';

import getAnalysisItem from '../getAnalysisItem';
import getAnalysisData from '../getAnalysisData';

export default createCachedSelector(
  getAnalysisItem,
  getAnalysisData,

  (item, data) => ({
    ...item,
    data,
  }),
)((state, props) => props.analysisId);

import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getItemsArray } from 'redux/hor/module/selectors';

export const getItems = createSelector(
  disease => disease,
  (disease, state) => getItemsArray(state),

  (disease, items) => {
    const diseaseMap = R.indexBy(R.prop('value'), disease);

    return R.map(item => ({
      label: item.custom_полное_наименование,
      value: item.key,
      group: item.custom_заболевание,
      hint: R.pathOr(null, [item.custom_заболевание, 'hint'], diseaseMap),
    }))(items);
  },
);

import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { Row, Column } from 'components/Grid';
import Field from 'components/Field';
import Select from 'components/Select';
import ImagePicker from 'components/ImagePicker';
import Editor from 'components/Editor';

import styles from './ProductFormIngredient.module.css';

const cx = classNames.bind(styles);

const medicineOptions = [
  {
    label: 'Таблетка',
    value: 1,
  },
  {
    label: 'Капсула',
    value: 2,
  },
  {
    label: 'Драже',
    value: 3,
  },
  {
    label: 'Гранула',
    value: 4,
  },
  {
    label: 'Порошок (грамм)',
    value: 5,
  },
  {
    label: 'Суточная доза',
    value: 6,
  },
];

export const sexOptions = [
  {
    value: 0,
    label: 'Для всех',
  },
  {
    value: 1,
    label: 'Мужской',
  },
  {
    value: 2,
    label: 'Женский',
  },
];

export default class ProductFormIngredient extends PureComponent {
  static propTypes = {
    productGroups: PropTypes.array,
    specificEatingTypes: PropTypes.array,
    units: PropTypes.array,
  };

  renderSelect(title, params) {
    return (
      <div className={cx('field')}>
        <Select
          placeholder='Выбрать'
          label={title}
          {...params}
        />
      </div>
    );
  }

  render() {
    return (
      <Row>
        <Column small={4} medium={3} big={4}>
          <div className={cx('field')}>
            <Field
              name='custom_наименование'
              label='Название БАДа'
            />
          </div>
        </Column>
        <Column small={4} medium={3} big={4}>
          <div className={cx('field')}>
            <ImagePicker name='custom_картинка' />
          </div>
        </Column>
        <Column small={4} medium={3} big={4}>
          {this.renderSelect('Производитель', {
            name: 'custom_производитель',
            options: this.props.productGroups,
          })}
        </Column>
        <Column small={4} medium={3} big={4}>
          {this.renderSelect('Лекарственная форма', {
            name: 'custom_лекарственная_форма',
            options: medicineOptions,
          })}
        </Column>
        <Column small={4} medium={3} big={4}>
          {this.renderSelect('Пол', {
            name: 'custom_пол',
            options: sexOptions,
          })}
        </Column>
        <Column small={4} medium={3} big={4}>
          <div className={cx('field')}>
            <Field
              name='custom_объем_упаковки'
              label='Объем упаковки'
            />
          </div>
        </Column>
        <Column small={4} medium={3} big={4}>
          <div className={cx('field')}>
            <Field
              name='custom_стандартный_курс'
              label='Стандартный курс'
            />
          </div>
        </Column>
        <Column small={4} medium={6} big={8}>
          <div className={cx('field')}>
            <Editor
              name='custom_описание'
              maxLength={10000}
            >
              Описание
            </Editor>
          </div>
        </Column>
      </Row>
    );
  }
}

import * as R from 'ramda';
import { createSelector } from 'reselect';

import {
  getKeyById,
} from 'modules/Rations/redux/modules/ration/selectors';

import getRationId from '../getRationId';

export default createSelector(
  getRationId,
  (state, props = {}) => props.rationKey,
  R.path(['Rations', 'ration']),

  (rationId, possibleRationKey, ration) => {
    if (possibleRationKey) {
      return possibleRationKey;
    }

    return getKeyById(rationId, ration);
  },
);

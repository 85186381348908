import { createSelector } from 'reselect';

import getPartner from '../getPartner';

export default createSelector(
  getPartner,

  (partner) => {
    const subscriptionUrl = partner ? partner.custom_subscribe_url : null

    if (process.env.REACT_APP_API === 'dd') {
      return {
        href: 'https://xn--80adffhqmpddacw4o.xn--p1ai/',
        target: '_blank'
      };
    }

    return {
      to: partner ? null : '/user/subscription',
      href: partner ? subscriptionUrl : null,
      target: partner ? '_blank' : null,
    }
  },
);

import * as R from 'ramda';
import memoizee from 'memoizee';
import moment from 'moment';

import { getItemsArray } from 'redux/hor/module/selectors';

export const getStatisticDates = memoizee((institution, state) => R.compose(
  R.map(item => moment(item.custom_дата).toDate()),
  R.filter(item => institution === 'all' || institution === item.custom_учреждение),
  getItemsArray,
)(state), { max: 1 });

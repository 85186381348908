import createSelector from 'redux/utils/createOrmSelector';

import getDayKey from '../getDayKey';

export default createSelector(
  getDayKey,

  (session, key) => {
    const { Day } = session;

    return Day.withId(key).getIsFilled();
  },
);

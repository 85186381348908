import { apiUrl } from 'config';

const icons = [
  `${apiUrl}/images_nutrilogic/dieta_icons/01_meat/01_sosiski.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/11_sauce/01_maslo.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/02_fish/01_seafish.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/03_milk_eggs/01_milk_low.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/09_fruits/01_yabloko_grusha.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/04_drinks/03_tea.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/08_soups/01_soup_vegan.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/10_candy/01_med_sahar.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/06_kasha/01_makarony.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/12_restaurant/01_pizza.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/05_bread/06_whitebread.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/13_dp/dp_ovoshi.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/07_ovoshi/01_kartofel.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/02_fish/02_riverfish.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/03_milk_eggs/02_milk_high.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/09_fruits/02_yagody.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/01_meat/02_kolbasy_okorok.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/04_drinks/01_coffee.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/08_soups/02_soup_veg_kartoshka.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/10_candy/02_konfety_caramel.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/05_bread/07_rzhanoy.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/11_sauce/03_margarin.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/12_restaurant/02_sushi.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/13_dp/dp_fruit.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/06_kasha/02_kasha.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/07_ovoshi/02_ogurec_pomidor.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/03_milk_eggs/03_milk2_low.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/02_fish/03_vyalenaya.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/01_meat/04_beef.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/09_fruits/03_vishnya.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/04_drinks/05_coke.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/08_soups/03_soup_meat.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/10_candy/03_chocolat.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/05_bread/08_blackbread.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/07_ovoshi/03_zelen.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/07_ovoshi/10_goroh.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/11_sauce/04_butter.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/12_restaurant/03_burger.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/13_dp/dp_meat.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/03_milk_eggs/05_tvorog.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/02_fish/04_krevetki.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/01_meat/05_pork.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/04_drinks/06_beer.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/08_soups/04_soup_meat_kartoshka.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/07_ovoshi/04_repa_redis.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/10_candy/04_tort.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/12_restaurant/04_shaurma.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/11_sauce/05_salo.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/13_dp/dp_kasha.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/05_bread/02_bliny.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/03_milk_eggs/06_smetana.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/02_fish/05_kalmar.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/01_meat/06_lamb.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/04_drinks/07_champagne.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/08_soups/05_soup_pure.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/11_sauce/06_soy.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/09_fruits/04_citus.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/07_ovoshi/05_tykva.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/12_restaurant/05_chips.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/05_bread/05_pechenye.svg`,
  // `${apiUrl}/images_nutrilogic/dieta_icons/03_milk_eggs/12_zefir_pastila.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/02_fish/06_midii.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/01_meat/08_chicken.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/08_soups/06_soup_miso.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/11_sauce/01_maslo.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/09_fruits/05_avocado.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/07_ovoshi/06_kapusta.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/03_milk_eggs/04_syrok.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/03_milk_eggs/09_sgushenka.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/12_restaurant/09_khinkali.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/04_drinks/11_vino.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/02_fish/07_chuka.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/03_milk_eggs/07_cheese.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/04_drinks/08_nastoyka.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/09_fruits/06_banan_vinograd.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/07_ovoshi/07_morkovka.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/11_sauce/08_sauce.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/01_meat/10_konina.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/03_milk_eggs/11_icecream.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/03_milk_eggs/08_brynza.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/07_ovoshi/08_svekla.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/11_sauce/09_hot_hren.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/01_meat/09_krolik.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/02_fish/08_ikra.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/04_drinks/10_cocktail.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/09_fruits/12_viograd.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/01_meat/07_liver.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/03_milk_eggs/10_egg.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/04_drinks/09_krepkiy.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/09_fruits/07_arbuz.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/07_ovoshi/14_griby.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/07_ovoshi/09_selderey.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/09_fruits/09_konserv_fruits.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/09_fruits/08_orehi.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/07_ovoshi/11_marinovannye.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/07_ovoshi/12_ovoshipar.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/09_fruits/11_suhofrukt.svg`,
  `${apiUrl}/images_nutrilogic/dieta_icons/07_ovoshi/15_tofu_soy.svg`,
];

export default icons;
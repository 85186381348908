import createOrmSelector from 'redux/utils/createOrmSelector';

import getAnalysisKey from '../../getAnalysisKey';

export default createOrmSelector(
  getAnalysisKey,

  (session, analysisKey) => {
    const { Vitamin, AnalysisVitamin } = session;

    return Vitamin
      .all()
      .toRefArray()
      .map((item) => {
        const existedItem = AnalysisVitamin
          .filter({
            ___parent: analysisKey,
            custom_витамин: item.key,
          })
          .first() || {};

        return {
          key: item.key,
          title: item.custom_наименование,
          influence: item.custom_рекомендации,
          showByDefault: true,
          value: existedItem.custom_оценка,
          updateKey: existedItem.key,
        };
      });
  },
);

import * as R from 'ramda';
import { createSelector } from 'reselect';

import getUserIsLight from '../getUserIsLight';
import getTariffsAll from '../getTariffsAll';

export default createSelector(
  getUserIsLight,
  getTariffsAll,

  (isLight, list) => R.compose(
    R.head,
    R.reverse,
    R.reject(R.prop('custom_спецтариф')),
    R.reject(R.prop('custom_архивный')),
    R.reject(R.prop('custom_триал')),
    R.filter(R.propEq('custom_группа_тарифов_light', isLight)),
  )(list),
);

import { attr, Model } from 'redux-orm';

import generator from 'redux/hor/orm-module';

const {
  reducer,
  fetch,
  fetchSuccess,
  create,
  save,
  saveSuccess,
} = generator({
  name: 'partner',
  tableId: 192,
});

export default class Partner extends Model {
  static modelName = 'Partner';

  static options = {
    idAttribute: 'key',
  };

  static fields = {
    custom_subscribe_url: attr(),
    custom_ссылка_на_логотип: attr(),
    custom_ссылка_на_логитип_в_печати: attr(),
  }

  static reducer = reducer;
}

export {
  fetch,
  fetchSuccess,
  save,
  saveSuccess,
  create,
};

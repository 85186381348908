import * as R from 'ramda';
import memoizee from 'memoizee';
import * as selectors from 'redux/hor/module/selectors';
import roundNumber from 'utils/roundNumber';

import { getItemsList } from 'redux/modules/ingredients';
import getKeyInfo from 'utils/getKeyInfo';

const getItemsArrayByParent = R.compose(
  // R.reject(R.prop('custom_системный_ингредиент')),
  selectors.getItemsArrayByParent,
);

const getTechcard = memoizee((key, state, ingredientsState = {}, multiplier = 1) => {
  const ingredients = getItemsList(ingredientsState);

  return R.compose(
    R.map((item) => {
      const ingredient = R.find(R.propEq('key', item.custom_ингредиент), ingredients);
      const ingredientAmount = R.propOr('', 'custom_ед_изм_наименование', ingredient);
      const itemAmount = R.propOr('', 'custom_единица_измерения__гр_мл', item);
      const amountName = itemAmount || ingredientAmount;
      const count = roundNumber(2, item.custom_вес * multiplier);
      const value = item.custom_вес ? `${count} ${amountName}.` : null;

      return {
        id: getKeyInfo(item.custom_ингредиент).record,
        name: item.name || ingredient.custom_наименование,
        value,
        isSystem: ingredient && ingredient.custom_копия_блюда,
      };
    }),
    getItemsArrayByParent,
  )(key, state);
}, { length: 4 });

export {
  getTechcard,
  getItemsArrayByParent,
};

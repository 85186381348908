import request from '../request';
import formatHeaders from '../utils/formatHeaders';

export default function deleteRequest({ key, view }) {
  return request.delete(`/${key}/json/v2`, {
    headers: formatHeaders({
      view,
    }),
  });
}

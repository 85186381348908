import { version } from 'config';
import { setItem, getItem, clear } from 'utils/localStorage';

const appKeyVersion = 'version_app';


const setItemLocalStorage = ({ cacheKey, response }) => setItem(cacheKey, response);
const getItemLocalStorage = ({ cacheKey }) => getItem(cacheKey);

const initLocalStorage = () => {
  const appVersionLS = localStorage.getItem(appKeyVersion);

  if (!appVersionLS || appVersionLS !== version) {
    clear();
    localStorage.setItem(appKeyVersion, version);
  }
};

export { getItemLocalStorage, setItemLocalStorage, initLocalStorage };

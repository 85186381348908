import createSelector from 'redux/utils/createOrmSelector';

import getRationKey from '../getRationKey';

export default createSelector(
  getRationKey,

  (session, key) => {
    const { Ration } = session;

    return Ration
      .withId(key)
      .days
      .toModelArray()
      .filter(item => item.getIsFilled())
      .map(item => item.custom_номер);
  },
);

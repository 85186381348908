import * as R from 'ramda';

import getAnalysisItem from '../getAnalysisItem';
import getGenotypes from '../getGenotypes';

const analysisTypeToStateMap = {
  192: R.always(null),
  196: getGenotypes,
  193: R.always([
    { value: 'В норме', label: 'В норме' },
    { value: 'Повышено', label: 'Повышено' },
    { value: 'Понижено', label: 'Понижено' },
  ]),
  194: R.always(null),
  195: R.always([
    { value: false, label: 'Не обнаружено' },
    { value: true, label: 'Обнаружено' },
  ]),
};

const getAnalysisViewOptions = (state) => {
  const analysis = getAnalysisItem(state);
  const { typeId } = analysis;
  const getData = analysisTypeToStateMap[typeId] || R.always(null);

  return getData(state);
};

export default getAnalysisViewOptions;
import * as R from 'ramda';
import { select } from 'redux-saga/effects';

import {
  getItemByKey,
} from 'redux/selectors';
import getFullName from 'utils/getFullName';

import { track } from '../carrot';

function* trackPatientEvent(patientKey, event, props = {}) {
  const patient = yield select(getItemByKey(patientKey));
  const name = getFullName(patient);
  track(event, {
    ...props,
    patient_name: name,
  });
}

const EVENT_ARCHIVE_PATIENT = 'archive_patient';
export function* trackArchivePatient(patientKey) {
  yield trackPatientEvent(patientKey, EVENT_ARCHIVE_PATIENT);
}

const EVENT_UNARCHIVE_PATIENT = 'unarchive_patient';
export function* trackUnarchivePatient(patientKey) {
  yield trackPatientEvent(patientKey, EVENT_UNARCHIVE_PATIENT);
}

const EVENT_ADD_PATIENT = 'add_patient';
export function* trackAddPatient(patientKey) {
  yield trackPatientEvent(patientKey, EVENT_ADD_PATIENT);
}

const EVENT_REMOVE_PATIENT = 'remove_patient';
export function* trackRemovePatient(patientKey) {
  yield trackPatientEvent(patientKey, EVENT_REMOVE_PATIENT);
}

const EVENT_ADD_PATIENT_NOTE = 'add_patient_note';
export function* trackAddPatientNote(patientKey) {
  yield trackPatientEvent(patientKey, EVENT_ADD_PATIENT_NOTE);
}

const EVENT_ADD_PATIENT_ANALYSIS = 'add_patient_analysis';
export function* trackAddPatientAnalysis(patientKey) {
  yield trackPatientEvent(patientKey, EVENT_ADD_PATIENT_ANALYSIS);
}

const EVENT_ADD_PATIENT_RATION = 'add_patient_ration';
export function* trackAddPatientRation(patientKey) {
  yield trackPatientEvent(patientKey, EVENT_ADD_PATIENT_RATION);
}

const EVENT_ADD_PATIENT_DIETANAMNEZ = 'add_patient_dietanamnez';
export function* trackAddPatientDietanamnez(patientKey) {
  yield trackPatientEvent(patientKey, EVENT_ADD_PATIENT_DIETANAMNEZ);
}

/**
 * Returns a function of an entity and changes to that entity that checks
 * if some checked field is changed to a non-empty value and all checked fields
 * before were empty.
 * 
 * Note: checkedFieldsRegistry is a registry of fields to check where values are
 * empty value checker functions (R.isEmpty, R.isNil, R.not, etc.)
 * 
 * Every checked field can be undefined, so always check R.isNil; check array and
 * string fields using R.isEmpty, nullable using R.isNil, scalar using R.not
 */
const shouldTrackChangesFromCheckedFields = checkedFieldsRegistry =>
  (entity, changes) => {
    const checkedFields = R.keys(checkedFieldsRegistry);
    const isRegistryFieldEmpty = (registry, field) =>
      R.prop(field, checkedFieldsRegistry)(R.prop(field, registry));
    const isSomeCheckedChangedToNotEmpty = checkedFields.some(field => (
      R.has(field, changes) &&
      !isRegistryFieldEmpty(changes, field)
    ));
    const wasCheckedAllEmpty = checkedFields.every(field => (
      isRegistryFieldEmpty(entity, field)
    ));
    return isSomeCheckedChangedToNotEmpty && wasCheckedAllEmpty;
  };

// For now track only if diagnosis before was empty
// and any diagnosis field is being filled
export const shouldTrackAddPatientDiagnosis =
  shouldTrackChangesFromCheckedFields({
    custom_болезни: R.either(R.isEmpty, R.isNil),
    custom_сопутствующие_заболевания: R.either(R.isEmpty, R.isNil),
    custom_основное_заболевание: R.isNil,
    custom_анамнез: R.either(R.isEmpty, R.isNil),
  });

const EVENT_ADD_PATIENT_DIAGNOSIS = 'add_patient_diagnosis';
export function* trackAddPatientDiagnosis(patientKey) {
  yield trackPatientEvent(patientKey, EVENT_ADD_PATIENT_DIAGNOSIS);
}

// Same as diagnosis
export const shouldTrackAddPatientAntropometry =
  shouldTrackChangesFromCheckedFields({
    // Antropometry
    custom_масса_тела: R.not,
    custom_рост: R.not,
    custom_объем_талии: R.not,
    custom_объем_бедер: R.not,
    custom_объем_плеча: R.not,
    // Composition
    custom_жировая_масса: R.not,
    custom_мышечная_масса: R.not,
    custom_складка_грудь_подмышка: R.not,
    custom_складка_живот: R.not,
    custom_складка_бедро: R.not,
    custom_общая_жидкость: R.not,
    custom_вунутриклеточная_жидкость: R.not,
    custom_внеклеточная_жидкость: R.not,
    custom_тощая_масса_тела: R.not,
    custom_активная_клеточная_масса: R.not,
    custom_висцеральный_жир: R.not,
  });

const EVENT_ADD_PATIENT_ANTROPOMETRY = 'add_patient_antropometry';
export function* trackAddPatientAntropometry(patientKey) {
  yield trackPatientEvent(patientKey, EVENT_ADD_PATIENT_ANTROPOMETRY);
}

const EVENT_ADD_PATIENT_CONSUMPTION = 'add_patient_consumption';
export function* trackAddPatientConsumption(patientKey) {
  yield trackPatientEvent(patientKey, EVENT_ADD_PATIENT_CONSUMPTION);
}

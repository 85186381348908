import generator from 'redux/hor/module';
import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,
  fetch,
} = generator({
  name: 'MealTypes',
  tableId: 36,
});

export const fetchList = fetchWithView(fetch, 'ts5c36cs9r353', {
  useLS: true,
});

export {
  fetch,
};

export {
  getItem,
  getItemsArray,
} from 'redux/hor/module/selectors';

export {
  getSelectData,
} from './selectors';

export default reducer;

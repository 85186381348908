export default function minutesToTime(value, delimiter = ' : ') {
  if (!value || value === '0') {
    return null;
  }

  const hour = `${Math.floor(value / 60)}`.padStart(2, '0');
  const minutes = `${value - (hour * 60)}`.padStart(2, '0');

  return `${hour}${delimiter}${minutes}`;
}

import * as R from 'ramda';
import memoizee from 'memoizee';

import { getItemsArray } from 'redux/hor/module/selectors';

export const getSelectData = memoizee(
  R.compose(
    R.sortBy(R.prop('label')),
    R.map(i => ({
      value: i.key,
      label: i.custom_полное_наименование,
    })),
    getItemsArray,
  ),
  { max: 1 },
);

const text = {
  email: 'Неверный формат почты',
  date: 'Некорректная дата',
  time: 'Некорректное время',
  locate: 'Кириллица, латиница и дефис',
  locateNum: 'Кириллица, латиница и дефис',
  positiveNumber: 'Допустимы только числа',
  numeric: 'Допустимы только числа',
  required: 'Поле обязательно для заполнения',
  nonRemovable: 'Поле обязательно для заполнения',
  minDate: 'Эта дата уже прошла',
  maxDate: 'Эта дата еще не наступила',
  min: params => `Необходимо ввести не меньше ${params.limiter} символов`,
  max: params => `Нельзя ввести больше ${params.limiter} символов`,
  phone: 'Неверный формат телефона',
  simplePass: 'Только латиница, цифры и спец.символы',
  typeFiles: 'Неверный тип файла',
  sizeFiles: 'Превышен размер файла',
  unique: 'Такое значение уже существует',
};

export default text;

import * as R from 'ramda';
import {
  getItemByKey,
  getApplicationUser,
  getUserKey,
} from 'redux/selectors';

const paramsMiddleware = store => next => (action) => {
  const state = store.getState();
  const userKey = getUserKey(state);
  const applicationUser = getApplicationUser(state);
  const key = R.pathOr(null, ['payload', 'key'], action);
  const entity = key ? getItemByKey(key, state) : undefined;

  let resolve;
  const resolvePromise = new Promise((res) => {
    resolve = res;
  })
  return next({
    ...action,
    meta: {
      ...action.meta,
      ...state.routeParams,
      token: state.auth.token,
      userKey,
      applicationUser,
      entity,
      resolvePromise,
      resolve,
    },
  });
};

export default paramsMiddleware;
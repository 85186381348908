import * as R from 'ramda';
import { attr, fk, Model } from 'redux-orm';

import generator from 'redux/hor/orm-module';

const {
  reducer,
  fetchSuccess,
  save,
} = generator({
  name: 'analysisSalt',
  tableId: 27,
  parseItem: R.evolve({
    ___parent: R.propOr(null, 'key'),
    custom_химический_элемент: R.propOr(null, 'key'),
  }),
});

export default class AnalysisSalt extends Model {
  static modelName = 'AnalysisSalt';

  static reducer = reducer;

  static options = {
    idAttribute: 'key',
  }

  static fields = {
    ___parent: fk('PatientAnalysis'),
    custom_химический_элемент: fk('ChemicalElement'),
    custom_результат: attr(),
  }
}

export {
  fetchSuccess,
  save,
};

const ageOptions = [ // Порядок важен
  {
    value: 'Любой возраст',
    text: 'Любой возраст',
    small: 'Любой возраст',
    min: 0,
    max: 100,
  },
  {
    value: '1-3 года',
    text: '1-3 года',
    small: '1-3',
    min: 1,
    max: 3,
  },
  {
    value: '4-6 лет',
    text: '4-6 лет',
    small: '4-6',
    min: 4,
    max: 6,
  },
  {
    value: '7-10 лет',
    text: '7-10 лет',
    small: '7-10',
    min: 7,
    max: 10,
  },
  {
    value: '11-17 лет',
    text: '11-17 лет',
    small: '11-17',
    min: 11,
    max: 17,
  },
  {
    value: '18 лет и старше',
    text: '18 и старше',
    small: '18+',
    min: 18,
    max: 100,
  },
];

export default ageOptions;
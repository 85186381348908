import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getItemsArray } from 'redux/hor/module/selectors';

export const getItems = createSelector(
  getItemsArray,

  R.map(item => ({
    label: item.custom_наименование,
    value: item.key,
    hint: item.custom_описание_влияния,
    custom_специфическое_состояние: item.custom_специфическое_состояние,
  })),
);

export const getSpecificConditions = createSelector(
  getItems,

  R.filter(R.prop('custom_специфическое_состояние')),
);

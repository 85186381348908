import * as R from 'ramda';
import { createSelector } from 'reselect';

import getDay from '../getDay';

export default createSelector(
  getDay,
  (state, props) => props ? props.dayKey : undefined,

  (day, dayKey) => dayKey || R.propOr(null, 'key', day),
);

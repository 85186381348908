import * as R from 'ramda';
import { createSelector } from 'reselect';
import moment from 'moment';

import getPatient from '../getPatient';

export default createSelector(
  getPatient,

  R.compose(
    val => val ? moment().diff(moment(val, 'DD.MM.YYYY'), 'years') : null,
    R.propOr(null, 'custom_дата_рождения'),
  ),
);

import * as R from 'ramda';
import { createSelector } from 'reselect';

import getAnalysesTypes from '../getAnalysesTypes';

export default createSelector(
  getAnalysesTypes,

  R.concat([{
    value: 'all',
    label: 'Все анализы',
  }]),
);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class MiddleColumnHead extends PureComponent {
  static propTypes = {
    multiplier: PropTypes.number.isRequired,
    className: PropTypes.string.isRequired,
  }

  static defaultProps = {
    multiplier: 1,
    className: undefined,
  }

  render() {
    const { multiplier } = this.props;

    return (
      <div className={this.props.className}>
        <div>
          0%
        </div>
        <div>
          {100 * multiplier}%
        </div>
        <div>
          {200 * multiplier}%
        </div>
      </div>
    );
  }
}

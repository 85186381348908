import * as R from 'ramda';
import { createSelector } from 'reselect';

import getAnalysis from '../getAnalysis';

export default createSelector(
  (state, props) => R.pathOr([], ['location', 'query', 'comparision'], props),
  R.identity,

  (items, state) => R.map(item => getAnalysis(state, {
    analysisId: item,
  }), items),
);

import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import containerQuery from 'decorators/containerQuery';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { Row, Column } from 'components/Grid';
import Button from 'components/Button';
import Field from 'components/Field';
import Select from 'components/Select';
import Text from 'components/Text';
import Title from 'components/Title';
import AvatarPicker from 'components/AvatarPicker';
import RadioGroup from 'components/RadioGroup';
import RequiredNotice from 'components/RequiredNotice';
import Legend from 'components/Legend';

import styles from './InstituteForm.module.css';

const cx = classNames.bind(styles);

const radioGroupFilial = [
  {
    id: 'radioGroupFilialYes',
    value: 'true',
    content: 'Да',
  },
  {
    id: 'radioGroupFilialNo',
    value: 'false',
    content: 'Нет',
  },
];

const radioGroupType = [
  {
    id: 'radioGroupTypeCommercial',
    value: '1',
    content: 'Коммерческое',
  },
  {
    id: 'radioGroupTypeNational',
    value: '2',
    content: 'Государственное',
  },
];

class InstituteForm extends PureComponent {
  static propTypes = {
    cities: PropTypes.array,
    containerQuery: PropTypes.object,
    countries: PropTypes.array,
    dirty: PropTypes.bool,
    emptyCities: PropTypes.array,
    institutions: PropTypes.array,
    showInstitutions: PropTypes.bool,
    step: PropTypes.string,
    valid: PropTypes.bool,
    onReset: PropTypes.func,
    onSubmit: PropTypes.func,
    readOnly: PropTypes.bool,
    submitting: PropTypes.bool,
  }

  static defaultProps = {
    cities: [],
    containerQuery: {},
    countries: [],
    dirty: false,
    emptyCities: [],
    institutions: [],
    showInstitutions: false,
    step: 'registration',
    valid: false,
    onReset: () => { },
    onSubmit: () => { },
    readOnly: false,
    submitting: false,
  }

  renderButtons() {
    const { valid, step, dirty, onReset, readOnly, submitting } = this.props;
    const isReg = step === 'registration';

    if (readOnly) {
      return null;
    }

    return (
      <div>
        <hr className={cx('hr', 'hr_wide')} />
        {isReg ?
          <div className={cx('buttons')}>
            <Button
              id={isReg && 'institutionSignup'}
              type='submit'
              size='big'
              bordered
              disabled={!valid}
              isLoading={submitting}
            >
              Готово
            </Button>
          </div> :
          <div className={cx('buttons')}>
            <div className={cx('button')}>
              <Button type='submit' size='big' bordered disabled={!valid}>
                Сохранить
              </Button>
            </div>
            <Button type='button' size='big' bordered disabled={!dirty} onClick={onReset}>
              Отмена
            </Button>
          </div>
        }
      </div>
    );
  }

  renderForm() {
    const { readOnly, step, showInstitutions } = this.props;
    const isReg = step === 'registration';

    return (
      <Row>
        <Column small={4} medium={4} big={8}>
          {isReg &&
            <div className={cx('title')}>
              <Title>
                Регистрация учреждения
              </Title>
            </div>
          }

          {isReg && <div className={cx('subtitle')}>
            <RequiredNotice />
          </div>}
        </Column>

        <Column small={4} medium={2} big={2} mediumOrder={1} bigOrder={1}>
          <div
            className={cx({
              filePicker: true,
              filePicker_registration: isReg,
            })}
          >
            <AvatarPicker
              square
              disabled={readOnly}
              id='instituteFilePicker'
              name='custom_логотип'
              title='Логотип'
              fileTitle='Изменить логотип'
            />
          </div>

          <hr className={cx('hr')} />
        </Column>

        <Column small={4} medium={4} big={4}>
          <div className={cx('container')}>
            <div className={cx('field')}>
              <Field
                disabled={readOnly}
                id='instituteName'
                name='custom_название'
                label='Название'
                required
              />
            </div>
            <div className={cx('field')}>
              {step === 'show' ?
                <Field
                  disabled={readOnly}
                  id='instituteNamePrint'
                  name='custom_название_на_печати'
                  label='Название для печати'
                  required={isReg}
                /> : null}
            </div>

            <div className={cx('radio')}>
              <div className={cx('radio-title')}>
                <Text size='medium'>Учреждение является филиалом?</Text>
              </div>
              <RadioGroup
                disabled={readOnly}
                name='custom_филиал'
                items={radioGroupFilial}
              />
            </div>

            <div
              className={cx({
                field: true,
                field_hidden: !showInstitutions,
              })}
            >
              <Select
                readOnly={readOnly}
                theme='plain'
                searchable
                minLengthForResult={2}
                placeholder='Учреждение'
                searchPlaceholder='Введите название для поиска'
                name='custom_головное_учреждение'
                options={this.props.institutions}
                openOnFocus={false}
              />
            </div>

            <div className={cx('radio')}>
              <div className={cx('radio-title')}>
                <Legend required={isReg}>Тип</Legend>
              </div>
              <RadioGroup
                disabled={readOnly}
                name='custom_тип'
                items={radioGroupType}
              />
            </div>
            <hr className={cx('hr')} />
            <div className={cx('fields-title')}>
              <Legend>Адрес</Legend>
            </div>
            <Row>
              <Column small={2} medium={2} big={2}>
                <div className={cx('field')}>
                  <Select
                    readOnly={readOnly}
                    id='instituteCity'
                    name='custom_страна'
                    label='Страна'
                    required
                    options={this.props.countries}
                  />
                </div>
              </Column>
              <Column small={2} medium={2} big={2}>
                <div className={cx('field')}>
                  <Select
                    readOnly={readOnly}
                    id='instituteCity'
                    name='custom_город'
                    label='Город'
                    disabled={R.isEmpty(this.props.cities)}
                    options={this.props.cities}
                    extraOptions={this.props.emptyCities}
                    alwaysShowExtra
                    minLengthForResult={0}
                    onBlurResetsInput
                    required
                    searchable
                  />
                </div>
              </Column>
            </Row>
            <div className={cx('field')}>
              <Field
                disabled={readOnly}
                id='instituteStreet'
                name='custom_улица'
                label='Улица'
                required
              />
            </div>
            <div className={cx('fields-line')}>
              <div className={cx('fields-item')}>
                <Field
                  disabled={readOnly}
                  id='instituteHouse'
                  name='custom_дом'
                  label='Дом'
                  required
                />
              </div>
              <div className={cx('fields-item')}>
                <Field
                  disabled={readOnly}
                  id='instituteCorps'
                  name='custom_корпус'
                  label='Корпус'
                />
              </div>
            </div>
            <hr className={cx('hr')} />
            <div className={cx('field')}>
              <Field
                disabled={readOnly}
                id='institutePhone'
                name='custom_телефон'
                label='Телефон учреждения'
                mask='+??9 (999) 999-99-99'
                phone={true}
              />
            </div>
            <div className={cx('field')}>
              <Field
                disabled={readOnly}
                id='instituteEmail'
                name='custom_эл_почта'
                label='Эл. почта'
                type='email'
              />
            </div>
            {this.renderButtons()}
          </div>
        </Column>
      </Row>
    );
  }

  render() {
    return (
      <form
        onSubmit={this.props.onSubmit}
        className={cx('instituteForm', this.props.containerQuery)}
      >
        {this.renderForm()}
      </form>
    );
  }
}

export default R.compose(
  containerQuery(styles),
)(InstituteForm);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import AnalysRow from '../../components/AnalysRow';
import { save } from '../../redux/modules/analysisGenes';
import formAnalysisItem from '../../utils/formAnalysisItem';

const saveParameterCreator = ({
  key,
  value,
}) => ({
  custom_ген: key,
  custom_генотип: value,
});

class GeneticAnalysisItemContainer extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    influence: PropTypes.string,
    selectList: PropTypes.array,
    items: PropTypes.array,
    name: PropTypes.string,
    disabledInitially: PropTypes.bool,
  }

  render() {
    return (
      <AnalysRow
        title={this.props.title}
        influence={this.props.influence}
        selectList={this.props.selectList}
        items={this.props.items}
        disabledInitially={this.props.disabledInitially}
        selectName={this.props.name}
      />
    );
  }
}

export default R.compose(
  formAnalysisItem({
    save,
    saveParameterCreator,
  }),
)(GeneticAnalysisItemContainer);

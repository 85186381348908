import * as R from 'ramda';
import createCachedOrmSelector from 'redux/utils/createCachedOrmSelector';

import getDayKey from '../getDayKey';

export default createCachedOrmSelector(
  getDayKey,
  (state, props) => props.groupKey,
  R.path(['productComposition']),

  (session, dayKey, time, state) => {
    const { Day } = session;

    return Day.withId(dayKey).getGroupComposition(time, state);
  },
);

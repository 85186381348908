import * as R from 'ramda';

import generator from 'redux/hor/module';
import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,

  fetch,
} = generator({
  name: 'SubscriptionTariffs',
  tableId: 116,
  parseItem: R.evolve({
    custom_количество_дней_базового_периода: R.propOr(null, 'key'),
    custom_состав_тарифа: R.compose(
      R.pluck('key'),
      R.defaultTo([]),
    ),
  }),
});

export const fetchList = fetchWithView(fetch, 'ts5c116cs9r394', {
  useLS: true,
});

export {
  fetch,
};

export {
  getItemsArray,
} from 'redux/hor/module/selectors';

export default reducer;

import createCachedOrmSelector from 'redux/utils/createCachedOrmSelector';

export default createCachedOrmSelector(
  (state, props) => props.key,

  (session, key) => {
    const { DietanamnezTiles } = session;

    try {
      return DietanamnezTiles
        .withId(key)
        .map
        .toRefArray();
    } catch (error) {
      return [];
    }
  },
);

import * as R from 'ramda';
import { createSelector } from 'reselect';

import getUser from '../getUser';

export default createSelector(
  getUser,

  R.either(
    R.propOr(false, 'custom_is_support'),
    R.propOr(false, 'custom_избранный'),
  ),
);

import { createEvent, createStore } from "effector";

/* Синхронизируем стор редакса с стором эффектора до тех пора, пока всё не переедет с редакса */

type StoreType = any;

export const $reduxStore = createStore<StoreType>(null);

export const updateStore = createEvent<StoreType>("UPDATE_STORE");

$reduxStore.on(updateStore, (_, data) => data);

export const fromReduxState = <T>(selector: (state: any) => T) =>
  $reduxStore.map((state) => selector(state));

import * as R from 'ramda';
import { all, takeEvery, put, select } from 'redux-saga/effects';
// import { replace } from 'react-router-redux';

import isLocalKey from 'utils/isLocalKey';
import getKeyInfo from 'utils/getKeyInfo';
import { trackAddPatientAnalysis } from 'utils/carrot';

// import {
//   getPatientId,
// } from 'redux/selectors';

import {
  getAnalysisItem,
  getAnalysisData,
} from '../../selectors';

import { save as saveAnalysisBlood } from '../analysisBlood';
import { save as saveAnalysisGenes } from '../analysisGenes';
import { save as saveAnalysisHair } from '../analysisHair';
import { save as saveAnalysisSalt } from '../analysisSalt';
import { save as saveAnalysisVitamin } from '../analysisVitamin';

import {
  save,
  saveSuccess,
} from './index';

const itemsMap = {
  194: saveAnalysisBlood,
  196: saveAnalysisGenes,
  192: saveAnalysisHair,
  195: saveAnalysisSalt,
  193: saveAnalysisVitamin,
};

function* itemsSaveFlow(action) {
  const parent = R.pathOr(null, ['payload', 'data', '___parent'], action);

  if (!parent || !isLocalKey(parent)) {
    return;
  }

  yield put(save({
    key: parent,
  }));
}

function* saveSuccessFlow(action) {
  const { payload } = action;
  const { key, response } = payload;
  const newKey = R.pathOr(null, ['data', 'key'], response);

  if (key === newKey || !newKey) {
    return;
  }

  const item = yield select(state => getAnalysisItem(state, {
    analysisId: getKeyInfo(newKey).record,
  }));
  const data = yield select(state => getAnalysisData(state, {
    analysisId: getKeyInfo(newKey).record,
  }));
  const saveItem = itemsMap[item.typeId];

  const effect = data.map(({ key: itemKey }) => put(saveItem({
    key: itemKey,
  })));

  // const id = getKeyInfo(newKey).record;
  // const patientId = yield select(getPatientId);

  // yield put(replace(`/patients/${patientId}/analyses/${id}`));
  yield all(effect);
  yield trackAddPatientAnalysis(item.___parent);
}

export default function* saga() {
  yield all([
    takeEvery(R.values(itemsMap), itemsSaveFlow),
    takeEvery(saveSuccess, saveSuccessFlow),
  ]);
}

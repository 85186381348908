import { createSelector } from 'reselect';

import getUserIsPartner from '../getUserIsPartner';

export default createSelector(
  getUserIsPartner,

  (isPartner) => {
    if (process.env.REACT_APP_API === 'dd') {
      return null;
    }

    if (isPartner) {
      return 'info@dietologi-russia.ru'
    }

    return 'support@nutrilogic.ru'
  },
);

import * as R from 'ramda';
import { appKey } from 'config';
import { createSelector } from 'reselect';

import getInstitutionSubscription from '../getInstitutionSubscription';
import getInstitutionTariffIsTrial from '../getInstitutionTariffIsTrial';
import getUserIsLight from '../getUserIsLight';

export default createSelector(
  getInstitutionSubscription,
  getInstitutionTariffIsTrial,
  getUserIsLight,

  (subscription, isTrial, isLight) => {
    if (isTrial) {
      return `${appKey}t116r${isLight ? 5370657 : 5370658}`
    }

    return R.propOr(null, 'custom_действующий_тарифный_план', subscription)
  },
);

import { request } from 'utils/api';

export default function callFunction({ key, parameters, name, token }) {
  return request.post(`/${key}/json/v2`, {
    parameters
  }, {
    headers: {
      token,
      Function: name,
    },
  })
}

import { takeEvery, all, put, select } from 'redux-saga/effects';
import * as R from 'ramda';

import { appKey } from 'config';

import dispatchAndWait from 'redux/utils/dispatchAndWait';
import runButton from 'redux/utils/runButton';

import { getItemsArrayByParent as getComposition, save as saveComposition } from 'redux/modules/productComposition';

import {
  save,
  saveSuccess,
  fetchItem,
  fetchSystemIngredients,
  fetchUserIngredients,
  fetchCatalogList,
  fetchCatalogListSuccess,
  copyComposition,
} from './index';

function* saveSuccessFlow(action) {
  const { payload } = action;
  const { key, response } = payload;
  const newKey = R.pathOr(null, ['data', 'key'], response);

  if (key === newKey || !newKey) {
    return;
  }

  const composition = yield select(state => getComposition(key, state.productComposition));

  const compositionEffect = composition.map(item => put(saveComposition({
    key: item.key,
    data: {
      ___parent: newKey,
    },
  })));

  yield all(compositionEffect);
}


function* fetchCatalogListFlow(action) {
  yield all([
    dispatchAndWait(fetchSystemIngredients(action.payload)),
    dispatchAndWait(fetchUserIngredients(action.payload)),
  ])
  yield put(fetchCatalogListSuccess());
}

export default function* saga() {
  yield all([
    takeEvery(saveSuccess, saveSuccessFlow),
    takeEvery(fetchCatalogList, fetchCatalogListFlow),
    takeEvery(copyComposition, runButton({
      save,
      button: `${appKey}ts9c51c3c1r53`,
      fetch: fetchItem,
    })),
  ]);
}

import * as R from 'ramda';

const startsWith = (prefix, xs) => R.equals(R.take(prefix.length, xs), prefix);

export default function getServerFields(entity) {
  return R.compose(
    R.omit(['custom_счет', 'custom_профиль_потребления_веществ', 'custom_профиль_потребления_продуктов', 'custom_частота_потребления_групп_продуктов', 'custom_коридор_спк']),
    R.pickBy((val, key) => startsWith('___', key) || startsWith('custom_', key)),
    R.defaultTo({}),
  )(entity);
}

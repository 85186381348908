import * as R from 'ramda';
import { createSelector } from 'reselect';

import getApplicationUser from '../getApplicationUser';
import getMealsWithStatus from '../getMealsWithStatus';

export default createSelector(
  getApplicationUser,
  getMealsWithStatus,

  (userKey, meals) => R.filter((item) => {
    const isTerritory = item.custom_показывать_в_рамках_учреждения === true;
    const owner = item.___owner; // eslint-disable-line
    const isBase = !owner;

    return isBase || isTerritory || owner === userKey;
  }, meals),
);

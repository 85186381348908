import { createReducer, createAction } from 'redux-act';

const initialState = {
  patientId: null,
};

export const setPatient = createAction('CATALOG__SET_PATIENT');
export const setPatientSuccess = createAction('CATALOG__SET_PATIENT_SUCCESS');

const handleGetPatient = (state, patientId) => ({
  ...state,
  patientId,
});

const reducer = createReducer((on) => {
  on(setPatient, handleGetPatient);
}, initialState);

export { default as saga } from './saga';

export default reducer;

import * as R from 'ramda';
import generator from 'redux/hor/module';

const {
  reducer,
  fetch,
  fetchSuccess,

  create,
  save,
  remove,
} = generator({
  name: 'ProductTechCard',
  tableId: 54,
  parseItem: R.compose(
    R.evolve({
      ___parent: R.prop('key'),
      custom_ингредиент: R.prop('key'),
      custom_единица_измерения__гр_мл: R.prop('label'),
      custom_тип_приготовления: R.prop('key'),
      ___createduser: R.prop('key'),
      ___modifieduser: R.prop('key'),
    }),
    item => ({
      ...item,
      name: R.path(['custom_ингредиент', 'label'], item),
    }),
  ),
});

export {
  fetch,
  fetchSuccess,
  create,
  save,
  remove,
};

export {
  getTechcard,
  getItemsArrayByParent,
} from './selectors';

export default reducer;

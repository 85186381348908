import * as R from 'ramda';
import { createSelector } from 'reselect';
import memoize from 'memoizee';

import { getItemsArray } from 'redux/hor/module/selectors';

export const getSelectData = memoize(
  R.compose(
    R.sortBy(R.prop('label')),
    R.map(i => ({
      value: i.key,
      label: i.custom_наименование,
    })),
    getItemsArray,
  ),
  { max: 1 },
);

export const getItems = createSelector(
  getItemsArray,
  (state, isLight) => isLight,

  (items, isLight) => R.compose(
    R.map(item => ({
      key: item.key,
      id: item.key,
      name: item.custom_наименование,
      text: item.custom_наименование,
      value: item.key,
      tooltip: item.custom_подсказка,
    })),
    R.filter(item => isLight ? item.custom_light : true),
  )(items),
);

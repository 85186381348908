import React, { PureComponent } from 'react';
import { connect } from 'utils/react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { stringify } from 'query-string';

import { getToken } from 'redux/modules/auth';

import pagePermission from 'decorators/pagePermission';

import Modal from 'components/Modal';
import Subscription from 'components/Subscription';
import Icon from 'components/Icon';

import Activation from '../../containers/Activation';
import Login from '../../containers/Login';
import LoginLayout from '../../components/LoginLayout';

const mapStateToProps = ({
  auth,
  ping,
  activation,
  windowsOpen: { isNeedSideBar },
}) => ({
  inviteActivation: activation.status,
  userLogged: !!getToken(auth) && !auth.anonymous,
  showModalError: auth.showModalError,
  modalError: auth.modalError,
  modalButton: auth.modalButton,
  serverUnderMaintenance: ping.serverUnderMaintenance,
  isNeedSideBar,
});

class PublicLoginPage extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    router: PropTypes.object,
    serverUnderMaintenance: PropTypes.bool,
    userLogged: PropTypes.bool,
    isNeedSideBar: PropTypes.bool,
    inviteActivation: PropTypes.string,
    location: PropTypes.shape({
      query: PropTypes.object,
    }),
  }

  static defaultProps = {
    children: undefined,
    router: {},
    serverUnderMaintenance: false,
    userLogged: false,
    isNeedSideBar: false,
  }

  componentWillReceiveProps(nextProps) {
    const { userLogged, serverUnderMaintenance } = this.props;

    if (serverUnderMaintenance && !nextProps.serverUnderMaintenance && userLogged) {
      this.props.router.push('/patients');
    }
  }

  handleHideAfterSignup = () => {
    const { query } = this.props.location;
    const { logout, afterSignup, ...rest } = query;

    this.props.router.push({
      pathname: '/login',
      search: `?${stringify(rest)}`,
    });
  }

  renderModalSessionIsOver({ cancelBtn, onCancel } = {}) {
    return (
      <Modal
        ariaHideApp={false}
        contentLabel=''
        isOpen
      >
        <Subscription
          submitBtn={null}
          cancelBtn={cancelBtn}
          onCancel={onCancel}
        >
          Сессия истекла. Войдите в систему заново.
        </Subscription>
      </Modal>
    );
  }


  renderWarningSessionIsOver() {
    const { router } = this.props;
    const isSessionOver = R.pathOr(false, ['location', 'query', 'logout'], router);

    if (!isSessionOver) {
      return null;
    }

    return (
      this.renderModalSessionIsOver({
        onCancel: this.handleHideAfterSignup,
        cancelBtn: 'Закрыть',
      })
    );
  }

  renderAfterSignupModal() {
    const { router } = this.props;
    const showAfterSignup = R.pathOr(false, ['location', 'query', 'afterSignup'], router);
    const email = R.pathOr(false, ['location', 'query', 'email'], router);

    return (
      <Modal
        ariaHideApp={false}
        contentLabel='Письмо отправлено'
        isOpen={showAfterSignup}
      >
        <Subscription
          submitBtn={null}
          cancelBtn='Закрыть'
          onCancel={this.handleHideAfterSignup}
        >
          На ваш e-mail {email ? `(${email}) ` : ''} отправлено письмо.
          Пожалуйста, подтвердите адрес почты перейдя по ссылке в письме в течение суток,
          иначе регистрация будет отменена.
          <div style={{ marginTop: '0.5em' }}>
            Нет письма? Проверьте папку "Спам".
          </div>
        </Subscription>
      </Modal>
    );
  }

  render() {
    if (!this.props.isNeedSideBar) {
      return this.renderModalSessionIsOver();
    }

    const themes = {
      login: {
        logo: <Icon icon='logo_white' width={310} height={84}/>,
        recoveryLink: '/login/recovery',
        registration: process.env.REACT_APP_API === 'dd' ? null : {
          to: '/signup',
        },
        supportUrl: process.env.REACT_APP_API === 'dd' ? null : 'support@nutrilogic.ru',
        site: process.env.REACT_APP_API === 'dd' ? null :  'http://nutrilogic.ru/',
      },
      sd: {
        logo: <img src='https://go.nutrilogic.ru/files/logos/sd.svg' alt='' />,
        recoveryLink: '/sd/recovery',
        registration: {
          href: 'https://xn----7sbkdfreogmdhjb1d.xn--p1ai/'
        },
        supportUrl: 'info@dietologi-russia.ru',
        site: 'https://xn----7sbkdfreogmdhjb1d.xn--p1ai/',
      }
    }
    const themeName = this.props.location.pathname.split('/')[1]
    const invite = themeName === 'g' && this.props.location.query.i;
    const theme = themes[themeName] || themes.login
    return (
      <LoginLayout
        logo={theme.logo}
        supportUrl={theme.supportUrl}
        site={theme.site}
      >
        <Helmet title='Авторизация' />

        <Login
          recoveryLink={theme.recoveryLink}
          registration={theme.registration}
          invite={invite}
        >
          {this.props.children}
        </Login>
        <Activation />

        {this.renderAfterSignupModal()}
        {this.renderWarningSessionIsOver()}
      </LoginLayout>
    );
  }
}

export default R.compose(
  pagePermission({
    rule: (state) => {
      const { auth, ping, routing: { locationBeforeTransitions }  } = state;
      const isMaintenance = ping.serverUnderMaintenance;

      return locationBeforeTransitions.pathname === '/g' || isMaintenance || !getToken(auth);
    },
    url: '/patients',
  }),
  connect(mapStateToProps),
)(PublicLoginPage);

import * as R from 'ramda';
import { call, all, takeEvery, put, select, take, race } from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';
import { push, LOCATION_CHANGE } from 'react-router-redux';

import { appKey } from 'config';

import { request } from 'utils/api';
import isLocalKey from 'utils/isLocalKey';
import getKeyInfo from 'utils/getKeyInfo';

import runButton from 'redux/utils/runButton';
import dispatchAndWait from 'redux/utils/dispatchAndWait';
import getSubitemsEffect from 'redux/utils/getSubitemsEffect';
import subitems from 'redux/modules/subitems';
import {
  trackAddPatientRation,
  trackAddRationTemplate,
} from 'utils/carrot';

import {
  getPatientId,
  getPatientKey,
} from 'redux/selectors';
import { fetchSuccess as fetchProductCompositionSuccess } from 'redux/modules/productComposition';
import { showLoader, hideLoader } from 'redux/modules/loader';
import {
  getPatientRationNewKey,
  getUserRationNewTemplateKey,
} from '../../selectors';

import {
  fetchSuccess,
  fetchBase,
  fetchRationComposition,
  fetchRationCompositionSuccess,
  fetchRationCompositionFailure,
  save,
  saveSuccess,
  remove,
  autogenerate,
  assign,
  createTemplate,
  manual,
  addDay,
  checkDiet,
  checkDietSuccess,
  checkDietFailure,
} from './index';

import {
  fetchSuccess as fetchSuccessDay,
} from '../day';
import { generateGuid } from "../../../../../helpers/rnd_generator";

function* fetchRationCompositionFlow(action) {
  const { payload } = action;

  try {
    const response = yield call(request.post, `/${payload.key}/json/v2`, {
      parameters: {
        num_: payload.num || (payload.print ? 'kbzu' : 'all'),
      },
    }, {
      cache: true,
      headers: {
        Function: 'getDailyFoodComposition',
      },
    });
    const parsed = {
      list: R.compose(
        R.values,
        R.mapObjIndexed((val, key) => {
          const [___parent, custom_элемент_рациона, custom_значение] = val.split(';');
          const fields = {
            ___parent,
            custom_элемент_рациона,
            custom_значение,
          };

          return {
            key: key.replace('cXaX', appKey),
            fields: R.evolve({
              ___parent: R.compose(
                R.objOf('key'),
                R.replace('cXaX', appKey),
              ),
              custom_элемент_рациона: R.compose(
                R.objOf('key'),
                R.replace('cXaX', appKey),
              ),
              custom_значение: R.compose(
                Number,
                R.replace(',', '.'),
              ),
            }, fields),
          };
        }),
        R.defaultTo({}),
      )(response.data.sostav),
    };
    yield put(fetchProductCompositionSuccess({
      ...payload,
      response: parsed,
    }));
    yield put(fetchRationCompositionSuccess({
      key: payload.key,
    }));
  } catch (error) {
    yield put(fetchRationCompositionFailure({
      key: payload.key,
      error,
    }));
  }
}

function* createFlow(action) {
  yield put(startSubmit(action.payload.form));
  try {
    const saveAction = yield put(save({
      ...action.payload,
      form: undefined,
      isLocal: true,
    }));

    const response = yield call(request.post, `/${appKey}t155r/json/v2`, {
      parameters: {
        ...action.payload.data,
        автогенерация: action.payload.data.custom_автогенерация,
        meal_time: action.payload.timeValues,
      },
    }, {
      headers: {
        Function: 'createDietV2',
      },
    });

    const newKey = R.path(['data', 'key'], response);
    yield put(saveSuccess({
      ...action.payload,
      key: saveAction.payload.key,
      response: {
        data: {
          key: newKey,
        },
      },
    }));
    yield put(fetchSuccess({
      ...action.payload,
      response: response.data,
    }));
    return newKey;
  } catch (error) {
    console.error(error);
    throw error;
  } finally {
    yield put(stopSubmit(action.payload.form));
  }
}

function* manualFlow(action) {
  const patient = R.pathOr(null, ['payload', 'data', '___parent'], action);

  yield put(showLoader({
    name: 'page',
    title: patient ? 'Загрузка рациона' : 'Загрузка шаблона',
    duration: 13,
  }));

  yield call(createFlow, action);
}

function* saveFlow(action) {
  const { payload } = action;
  const isNew = !payload.key || isLocalKey(payload.key);
  const patientKey = R.pathOr(null, ['data', '___parent'], payload);
  const ownerKey = R.pathOr(null, ['data', '___owner'], payload);
  let newRationKey;

  if (patientKey) {
    newRationKey = yield select(getPatientRationNewKey);
  } else if (ownerKey) {
    newRationKey = yield select(getUserRationNewTemplateKey);
  }

  if (isNew && newRationKey) {
    yield put(save({
      key: newRationKey,
      data: {
        custom_удалено: true,
      },
      isLocal: true,
    }));
  }
}

function* navigateToRation(key, urlPrefix) {
  const rationId = getKeyInfo(key).record;

  yield put(push(`${urlPrefix}/${rationId}/1`));
}

function* autogenerateFlow(action) {
  yield put(startSubmit(action.payload.form));
  const patientId = yield select(getPatientId);
  const cancelCode = generateGuid();
  action.payload.data.cancelСode = cancelCode;

  yield put(showLoader({
    name: 'page',
    title: 'Генерация нового рациона',
    duration: 80,
    onCancel: `/patients/${patientId}/rations`,
  }));
  // TODO = тут костыль cancelAutogenerate не работает когда еще не сгенерировался рацион. Так как нет key
  try {
    const [key] = yield race([
      call(createFlow, {
        payload: R.omit(['form'], action.payload),
      }),
      take(LOCATION_CHANGE),
    ]);

    if (key) {
      yield call(navigateToRation, key, action.payload.urlPrefix);
    } else {
      yield put(hideLoader('page'));
      yield put(stopSubmit(action.payload.form));
      yield call(request.post, `/${appKey}t155r/json/v2`, {
        parameters: {
          cancelCode
        },
      }, {
        headers: {
          Function: 'cancelAutogenerate',
        },
      });
      yield put(save({
        key,
        data: {
          custom_удалено: true,
        },
        isLocal: true,
      }));

    }
  } catch (error) {
    console.error(error);
    yield put(hideLoader('page'));
  } finally {
    yield put(stopSubmit(action.payload.form));
  }
}

function* saveSuccessFlow(action) {
  const { payload } = action;
  const key = R.pathOr(null, ['response', 'data', 'key'], payload);
  const isAutogenerate = R.pathOr(null, ['data', 'custom_автогенерация'], payload);

  if (!key || payload.isLocal) {
    return;
  }

  if (payload.urlPrefix && !isAutogenerate) {
    yield call(navigateToRation, key, action.payload.urlPrefix);
    yield put(stopSubmit(action.payload.form));
  }

  if (action.payload.form === 'templateSave') {
    yield trackAddRationTemplate();
  }
}

function* removeFlow(action) {
  if (action.payload.form) {
    yield put(startSubmit(action.payload.form));
  }

  yield put(save({
    key: action.payload.key,
    data: {
      isDeleting: true,
    },
    isLocal: true,
  }));

  yield runButton({
    button: 'custom.custom_delete',
    save,
  })(action);

  yield put(save({
    key: action.payload.key,
    data: {
      isDeleting: false,
      custom_удалено: true,
    },
    isLocal: true,
  }));

  if (action.payload.form) {
    yield put(stopSubmit(action.payload.form));
  }
}

function* createTemplateFlow(action) {
  const { payload } = action;
  const { key, data } = payload;
  const type = R.propOr('private', 'owner', data);

  const response = yield call(request.post, `/${key}/json/v2`, {
    parameters: {
      name: data.custom_название,
      прописано_при: (data.custom_прописано_при || []).join(','),
      спец_виды_питания: (data.custom_спец_виды_питания || []).join(','),
      type: type === 'private' ? '1' : '2',
    },
  }, {
    headers: {
      Function: 'createTemplate',
    },
  });

  yield dispatchAndWait(fetchBase({
    key: response.data.template_key,
  }));

  yield trackAddRationTemplate();

  if (action.meta.resolve) {
    action.meta.resolve();
  }
}

function* addDayFlow(action) {
  const { key } = action.payload;

  try {
    const response = yield call(request.post, `/${key}/json/v2`, {}, {
      headers: {
        Function: 'addDayToRation',
      },
    });

    const effects = getSubitemsEffect(response.data, subitems);

    yield all([
      put(fetchSuccessDay({
        response: response.data,
      })),
      ...effects,
    ]);
  } catch (error) {

  }
}

function* assignFlow(action) {
  yield runButton({
    save,
    button: `${appKey}ts9c39c3c1r77`,
    data: {
      custom_не_назначен: false,
      custom_начало_диеты: (new Date()).toISOString(),
    },
  })(action);
  const patientKey = yield select(getPatientKey);
  yield trackAddPatientRation(patientKey);
}

function* checkDietFlow(action) {
  try {
    yield call(request.post, `/${appKey}t179r/json/v2`, {
      parameters: {
        diet_key: action.payload.key
      },
    }, {
      headers: {
        Function: 'checkDiet',
      },
    })
    yield put(checkDietSuccess(action.payload));
  } catch (error) {
    yield put(checkDietFailure({
      ...action.payload,
      error
    }))
  }
}

export default function* saga() {
  yield all([
    takeEvery(fetchRationComposition, fetchRationCompositionFlow),
    takeEvery(manual, manualFlow),
    takeEvery(save, saveFlow),
    takeEvery(saveSuccess, saveSuccessFlow),
    takeEvery(autogenerate, autogenerateFlow),
    takeEvery(remove, removeFlow),
    takeEvery(createTemplate, createTemplateFlow),
    takeEvery(addDay, addDayFlow),
    takeEvery(assign, assignFlow),
    takeEvery(checkDiet, checkDietFlow),
  ]);
}

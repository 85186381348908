import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';

import RegisterUser from '../../containers/RegisterUser';

class PublicSignupPage extends PureComponent {
  render() {
    return (
      <div>
        <Helmet title='Регистрация' />
        <RegisterUser isLight />
      </div>
    );
  }
}

export default PublicSignupPage;

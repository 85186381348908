import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import containerQuery from 'decorators/containerQuery';

import Text from 'components/Text';
import Select from 'components/Select';
import More from 'components/More';

import styles from './AnalysRow.module.css';

const cx = classNames.bind(styles);

export class AnalysRow extends PureComponent {
  static propTypes = {
    selectList: PropTypes.array,
    title: PropTypes.string,
    influence: PropTypes.string,
    selectName: PropTypes.string,
    disabledInitially: PropTypes.bool,
    containerQuery: PropTypes.object,
  }

  static defaultProps = {
    selectList: [],
  }

  state = {
    focused: false,
    disabled: this.props.disabledInitially,
  }

  handleFocusSelect = () => {
    this.setState({ focused: true });
  }

  handleBlurSelect = () => {
    this.setState({ focused: false });
  }

  handleSelectChange = () => {
    this.setState({ disabled: false });
  }

  handleClose = () => {
    this.setState({ focused: false });
  }

  render() {
    const {
      selectList,
      title,
      influence,
      selectName,
      items,
    } = this.props;
    const { focused, disabled } = this.state;

    const options = items ?
      selectList.filter(i => R.contains(i.value, items)) :
      selectList;

    return (
      <div className={cx('analysRow', this.props.containerQuery)}>
        <div
          className={cx({
            container: true,
            container_focused: focused,
            container_disabled: disabled,
          })}
        >
          <div
            className={cx({
              column: true,
              column_type_1: true,
            })}
          >
            <Text color='inherit' size='inherit'>{title}</Text>
          </div>
          <div
            className={cx({
              column: true,
              column_type_2: true,
            })}
          >
            <Select
              name={selectName}
              options={options}
              onFocus={this.handleFocusSelect}
              onBlur={this.handleBlurSelect}
              onChange={this.handleSelectChange}
              onClose={this.handleClose}
            />
          </div>
          <div
            className={cx({
              column: true,
              column_type_3: true,
            })}
          >
            <More
              text={influence}
              backgroundColor={focused ? null : 'dayWhite'}
              lines={3}
              lineHeight={19.6}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default R.compose(
  containerQuery(styles),
)(AnalysRow);

import * as R from 'ramda';
import createOrmSelector from 'redux/utils/createOrmSelector';

import getDayKey from '../getDayKey';

export default createOrmSelector(
  getDayKey,
  R.path(['productComposition']),

  (session, key, state) => {
    const { Day } = session;

    const compositions = Day
      .withId(key)
      .getGroupsComposition(state);

    const initial = R.compose(
      R.fromPairs,
      R.map(item => [item, [0]]),
      R.flatten,
      R.map(R.keys),
    )(compositions);

    return R.reduce(R.mergeWith((a, b) => a.concat(b || 0)), initial, compositions);
  },
);

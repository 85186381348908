import createOrmSelector from 'redux/utils/createOrmSelector';

import getAnalysisKey from '../../getAnalysisKey';

export default createOrmSelector(
  getAnalysisKey,

  (session, analysisKey) => {
    const { Gene, AnalysisGenes } = session;

    return Gene
      .all()
      .toRefArray()
      .map((item) => {
        const existedItem = AnalysisGenes
          .filter({
            ___parent: analysisKey,
            custom_ген: item.key,
          })
          .first() || {};

        const gene = existedItem.custom_генотип;

        return {
          key: item.key,
          title: item.custom_название,
          influence: item.custom_описание,
          showByDefault: item.custom_видно_по_умолчанию,
          value: gene ? gene.key : undefined,
          items: item.custom_генотипы,
          updateKey: existedItem.key,
        };
      });
  },
);

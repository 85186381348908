import * as R from 'ramda';
import memoizee from 'memoizee';
import { appKey } from 'config';

import { getItemsArray } from 'redux/hor/module/selectors';

const visibleRoles = [
  `${appKey}ts8r4`,
  `${appKey}ts8r18`,
  `${appKey}ts8r19`,
];

export const getAllRoles = memoizee(
  R.compose(
    R.map(item => ({
      key: item.key,
      label: R.pathOr(null, ['custom_роль', 'label'], item),
      role: R.pathOr(null, ['custom_роль', 'key'], item),
      rights: R.compose(
        R.map(R.always(true)),
        R.indexBy(R.prop('key')),
        R.defaultTo([]),
        R.propOr([], 'custom_права_роли'),
      )(item),
    })),
    R.sortBy(item => visibleRoles[item.key]),
    getItemsArray,
  ),
  { max: 1 },
);


export const getRoles = memoizee(
  R.compose(
    R.filter(R.pathSatisfies(R.contains(R.__, visibleRoles), ['role'])),
    getAllRoles,
  ),
  { max: 1 },
);

export const getRolesSelect = memoizee(
  R.compose(
    R.map(item => ({
      value: item.role,
      label: item.label,
    })),
    getRoles,
  ),
  { max: 1 },
);

export const getRole = (role, state) =>
  R.compose(
    R.find(R.propEq('role', role)),
    getAllRoles,
  )(state);

export const getRoleRights = R.compose(
  R.propOr({}, 'rights'),
  getRole,
);

import * as R from 'ramda';
import { attr, Model } from 'redux-orm';

import getKeyInfo from 'utils/getKeyInfo';

import generator from 'redux/hor/orm-module';
import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,

  fetch,
  fetchSuccess,
  create,
  save,
  saveSuccess,
  remove,
} = generator({
  name: 'SupplementDish',
  tableId: 160,
  parseItem: R.evolve({
    ___parent: R.propOr(null, 'key'),
    custom_бад: R.propOr(null, 'key'),
  }),
});

export default class SupplementDish extends Model {
  static modelName = 'SupplementDish';

  static options = {
    idAttribute: 'key',
  }

  static fields = {
    ___parent: attr(),
    custom_бад: attr(),
    custom_порций: attr(),
    custom_курс: attr(),
  }

  static reducer = reducer;


  get id() {
    return getKeyInfo(this.key).record;
  }
}

export const fetchItem = fetch;
export const fetchList = fetchWithView(fetch, 'ts5c160cs9r574');

export {
  fetch,
  fetchSuccess,

  create,
  save,
  saveSuccess,
  remove,
};

import React, { PureComponent } from 'react';
import { Field as FormField } from 'redux-form';
import { withReduxForm } from 'redux-form/es/ReduxFormContext';
import { dateFieldMap } from 'utils/dateField';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import moment from 'moment';

class FieldDate extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
  }

  static defaultProps = {
    name: undefined,
  }

  renderField = (field = {}) => {
    const date = moment(field.input.value, 'DD.MM.YYYY');

    if (!date.isValid()) {
      return null;
    }

    return (
      <span>
        {date.format('D MMM / YYYY').replace('.', '')}
      </span>
    );
  }

  render() {
    const { _reduxForm: reduxForm = {} } = this.props;
    const { fields = [] } = reduxForm || {};
    const name = dateFieldMap[this.props.name];

    if (!reduxForm || !R.contains(this.props.name, fields) || !R.contains(name, fields)) {
      return null;
    }

    return (
      <FormField
        component={this.renderField}
        {...this.props}
        name={name}
      />
    );
  }
}

export default withReduxForm(FieldDate)
import * as R from 'ramda';
import memoizee from 'memoizee';

import { getItemsArrayByParent } from 'redux/hor/module/selectors';

export const getSumComposition = memoizee((value, productKey, state) => {
  const composition = getItemsArrayByParent(productKey, state);
  console.log("----------------");
  console.log(composition);

  if (!value) {
    return {};
  }

  return R.reduce((acc, item) => {
    const element = R.prop('custom_элемент', item);
    const elementValue = R.prop('custom_значение_на_порцию', item);
    const lens = R.lensProp(element);
    const realValue = Math.round(elementValue * value) / 100;

    return R.set(lens, realValue, acc);
  }, {}, composition);
});

export const getIsHaveComposition = R.compose(
  R.not,
  R.isEmpty,
  getItemsArrayByParent,
);

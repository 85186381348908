import createOrmSelector from 'redux/utils/createOrmSelector';

export default createOrmSelector(
  (session) => {
    const { Partner } = session;

    try {
      return Partner
        .first()
        .ref;
    } catch (error) {
      return null
    }
  },
);

import * as R from 'ramda';
import generator from 'redux/hor/module';
import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,
  fetch,
} = generator({
  name: 'ELEMENT_NORMS',
  tableId: 64,
  parseItem: R.evolve({
    ___parent: R.propOr(null, 'key'),
    custom_страна: R.propOr(null, 'key'),
    custom_значение_от: Number,
    custom_значение_до: Number,
  }),
});

export const fetchList = fetchWithView(fetch, 'ts5c64cs9r350', {
  useLS: true,
});

export {
  getNorm,
} from './selectors';

export default reducer;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'utils/react-redux';

import { getLoaderByType } from 'redux/modules/loader';

import AppLoader from 'components/AppLoader';
import LoaderIcons from 'components/LoaderIcons';

const mapStateToProps = ({
  loader,
}) => ({
  isActive: getLoaderByType('application', loader),
});

class AppLoaderContainer extends PureComponent {
  static propTypes = {
    isActive: PropTypes.bool,
    icons: PropTypes.object,
    loadIcons: PropTypes.func,
  }

  render() {
    const {isActive } = this.props;

    return (
      <AppLoader isActive={isActive}>
        <LoaderIcons />
      </AppLoader>
    );
  }
}

export default R.compose(
  connect(mapStateToProps),
)(AppLoaderContainer);

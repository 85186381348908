import { takeLatest, all, put, call } from 'redux-saga/effects';

import delay from 'utils/delay';

import request from 'utils/api/request';

import {
  ping,
  pingSuccess,
  pingFailure,
} from './index';

function* pingFlow() {
  try {
    yield call(request.get, '/ping/json/v2');
    yield put(pingSuccess());
  } catch (error) {
    yield put(pingFailure({
      error,
    }));
    yield delay(5000);
    yield pingFlow();
  }
}

export default function* saga() {
  yield all([
    takeLatest(ping, pingFlow),
  ]);
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Editor as DraftEditor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, CharacterMetadata } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import Icon from 'components/Icon';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Option from './Option';
import styles from './Editor.module.css';

const cx = classNames.bind(styles);

export default class Editor extends PureComponent {
  static propTypes = {
    isLight: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.string,
    children: PropTypes.node,
    withNumber: PropTypes.bool,
    titleLength: PropTypes.number,

    title: PropTypes.node,
    maxLength: PropTypes.number,
  }

  static defaultProps = {
    scrollable: false,
    onFocus: () => { },
    onBlur: () => { },
    onChange: () => { },
    value: '',
    children: undefined,
    withNumber: false,
    maxLength: 0,
  }

  constructor(props) {
    super(props);
    const contentState = stateFromHTML(this.props.value);
    const editorState = EditorState.createWithContent(contentState);

    this.state = {
      editorState,
      isFocused: false,
    };
  }

  handleEditorStateChange = (state, b, c) => {
    let editorState = state;

    const contentState = editorState.getCurrentContent();
    const oldContent = this.state.editorState.getCurrentContent();
    const { maxLength } = this.props;

    if (contentState === oldContent || contentState.getPlainText().length <= maxLength) {

      if (editorState.getLastChangeType() === 'insert-characters') {
        editorState = this.removeInlineStyles(editorState, ['BOLD', 'ITALIC']);
      }

      this.setState({ editorState });

      const value = stateToHTML(contentState);
      if (value !== this.props.value) {
        this.props.onChange(value);
      }
    }
  }
  // https://github.com/facebook/draft-js/issues/119#issuecomment-190277461
  handleBeforeInput = (chars) => {
    const contentState = this.state.editorState.getCurrentContent();
    const totalLength = contentState.getPlainText().length + chars.length;
    return totalLength > this.props.maxLength;
  }

  handleFocus = (event) => {
    this.setState({
      isFocused: true,
    });
    this.props.onFocus(event);
  }

  handleBlur = (event) => {
    this.setState({
      isFocused: false,
    });
    this.props.onBlur(event);
  }

  removeInlineStyles = (editorState, retainInlineStyles) => {
    let isChanged = false;
    const blocks = editorState
      .getCurrentContent()
      .getBlocksAsArray()
      .map(singleBlock =>
        singleBlock.set(
          "characterList",
          singleBlock.getCharacterList().map(charMetaData => {

            if (!charMetaData) {
              return charMetaData;
            }
            const entity = charMetaData.getEntity();
            const style = charMetaData.getStyle();
            const newStyle = style.intersect(retainInlineStyles)

            if (style.size !== newStyle.size) {
              isChanged = true
            }

            return CharacterMetadata.create({
              entity: entity,
              style: newStyle,
            });
          })
        )
      );

    if (isChanged) {
      return EditorState.createWithContent(
        ContentState.createFromBlockArray(blocks)
      );
    }

    return editorState
  };

  renderOptions({ config, currentState, onChange }) {
    const iconMap = {
      bold: 'b',
      italic: 'i',
      unordered: 'list_u',
      ordered: 'list_o',
    };

    return (
      <div className='rdw-inline-wrapper' aria-label='rdw-inline-control'>
        {config.options.map((style, index) => {
          return (
            <Option
              key={index}
              value={style}
              onClick={onChange}
              active={currentState[style] === true}
            >
              <Icon
                width={24}
                height={24}
                icon={iconMap[style]}
              />
            </Option>
          )
        })
        }
      </div >
    );
  }

  render() {
    const { editorState } = this.state;
    const length = editorState.getCurrentContent().getPlainText().length;
    const { maxLength, titleLength } = this.props;

    return (
      <div
        className={cx('textarea', {
          [`textarea_title_${titleLength}`]: titleLength,
        })}
      >
        <div className={cx('title')}>
          {this.props.children}
        </div>

        {this.props.withNumber &&
          <span className={cx('count')}>
            {maxLength && maxLength > 0 ? maxLength - length : length}
          </span>
        }

        <DraftEditor
          title={this.props.title}
          editorState={editorState}
          onEditorStateChange={!this.props.isLight ? this.handleEditorStateChange : () => { }}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          handleBeforeInput={this.handleBeforeInput} // eslint-disable-line react/jsx-handler-names
          toolbar={!this.props.isLight ? {
            options: ['inline', 'list'],
            inline: {
              options: ['bold', 'italic'],
              component: this.renderOptions,
            },
            list: {
              options: ['unordered', 'ordered'],
              component: this.renderOptions,
            },
          } : {
            options: [],
          }}
          handlePastedText={() => { }}
        />
        <div
          className={cx('border', {
            border_focused: this.state.isFocused,
          })}
        />
      </div>
    );
  }
}

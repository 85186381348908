import * as R from 'ramda';
import generator from 'redux/hor/module';
import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,
  fetch,
} = generator({
  name: 'Cities',
  tableId: 105,
  parseItem: R.evolve({
    custom_страна: R.propOr(null, 'key'),
  }),
});

export const fetchList = fetchWithView(fetch, 'ts5c105cs9r300', {
  useLS: true,
});

export {
  getItemsByCountry,
} from './selectors';

export default reducer;

import * as R from 'ramda';

import { save } from 'modules/Rations/redux/modules/ration';
import { getAuthUser } from 'redux/selectors';

const createdUserMiddleware = store => next => (action) => {
  if (!R.contains(action.type, [save.toString()])) {
    return next(action);
  }

  const user = getAuthUser(store.getState());
  const newAction = R.assocPath(['payload', 'data', '___createduser'], user, action);

  return next(newAction);
};

export default createdUserMiddleware;
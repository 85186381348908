import * as R from 'ramda';

import { getItemsArray } from 'redux/hor/module/selectors';

export const getCorrectionValuesByWeight = (weight, state) =>
  R.compose(
    R.map((item) => {
      const itemWeight = R.propOr(1, 'custom_от_массы_тела', item) * weight;

      return {
        value: item.key,
        label: `${itemWeight.toFixed(1)} кг/мес`,
      };
    }),
    R.sortBy(R.prop('custom_от_массы_тела')),
    getItemsArray,
  )(state);

import moment from 'moment';
import { createSelector } from 'reselect';

import getInstitutionSubscription from '../getInstitutionSubscription';
import getInstitutionSubscriptionEnd from '../getInstitutionSubscriptionEnd';

export default createSelector(
  getInstitutionSubscription,
  getInstitutionSubscriptionEnd,

  (tariff, endDate) => {
    return moment(endDate).add(tariff.isFutureSubscriptionOneDay ? 0 : tariff.duration, 'months').diff(moment().startOf('day'), 'days')
  },
);

import * as R from 'ramda';
import { createSelector } from 'reselect';

import getDayComposition from '../getDayComposition';
import getRationNorm from '../getRationNorm';

export default createSelector(
  getDayComposition,
  getRationNorm,

  R.merge,
);

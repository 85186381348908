import * as R from 'ramda';
import createOrmSelector from 'redux/utils/createOrmSelector';
import memoizeByResult from 'utils/memoizeByResult';

import { appKey } from 'config';

import getRationKey from '../getRationKey';
import getRationDayNumber from '../getRationDayNumber';

export default createOrmSelector(
  getRationKey,
  getRationDayNumber,

  memoizeByResult(
    (session, rationKey, currentDay) => {
      const { Ration } = session;

      const dishList = Ration
        .withId(rationKey)
        .days
        .toModelArray()
        .map(day => {
          return Object.keys(day.groups).map(time => {
            const groups = day.groups[time].группы_альтернатив;

            return groups.map(group => {
              const dishes = Object.keys(group.блюда);

              return dishes.map(item => {
                const key = item.replace('cXaX', appKey);

                return {
                  dish: key,
                  number: Number(day.custom_номер),
                  isActive: item === group.активное,
                }
              }).flat()
            }).flat()
          }).flat()
        }).flat();

      return R.compose(
        R.pickBy(R.complement(R.isEmpty)),
        R.map(R.omit([currentDay])),
        R.map(R.map(R.any(R.prop('isActive')))),
        R.map(R.groupBy(R.prop('number'))),
        R.groupBy(R.prop('dish')),
      )(dishList);
    },
    (_, rationKey) => rationKey,
  ),
);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class MiddleColumnHead extends PureComponent {
  static propTypes = {
    className: PropTypes.string.isRequired,
  }

  static defaultProps = {
    className: undefined,
  }

  render() {
    return (
      <div className={this.props.className}>
        <div>-100%</div>
        <div>Норма</div>
        <div>100%</div>
      </div>
    );
  }
}

import * as R from 'ramda';

import generator from 'redux/hor/module';

const {
  reducer,

  fetchSuccess,
  save,
  remove,
} = generator({
  name: 'patientsAllergen',
  tableId: 138,
  parseItem: R.evolve({
    ___parent: R.propOr(null, 'key'),
    custom_ингредиенты_аллергены: R.compose(
      R.pluck('key'),
      R.defaultTo([]),
    ),
  }),
});

export {
  fetchSuccess,
  save,
  remove,
};

export {
  getItems,
} from './selectors';

export default reducer;

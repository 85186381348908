import * as R from 'ramda';
import { createSelector } from 'reselect';
import moment from 'moment';

import { save } from 'redux/modules/staff';

import getUser from '../getUser';
import getUserIsPartner from '../getUserIsPartner';

export default createSelector(
  getUser,
  getUserIsPartner,
  state => state.windowsOpen.isNeedSideBar,

  (user, isPartner, isNeedSideBar) => {
    const created = moment(R.propOr(null, '___createddate', user));
    const diff = moment().diff(created, 'days');

    if (!user || user.custom_verify_phone || user.isClosePhone || isPartner ||
      diff < 2 || !isNeedSideBar || process.env.REACT_APP_API === 'dd') {
      return null;
    }

    return {
      type: 'isInfo',
      content: {
        onClose: save({
          key: user.key,
          data: {
            isClosePhone: true,
          },
          isLocal: true,
        }),
      },
    };
  },
);

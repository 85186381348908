import * as R from 'ramda';
import uuid from 'uuid/v4';
import { createAction as createAct, createReducer } from 'redux-act';

import { appKey } from 'config';
import isLocalKey from 'utils/isLocalKey';

import { login } from 'redux/modules/auth';

import parseResult from '../../utils/parseResult';

import { getItemField, getItem } from './selectors';

const ignoreFields = ['custom_действие']; // TODO add config


export const getChangedData = (item = {}, data = {}) => R.pickBy(
  (val, prop) => !R.equals(item[prop], val) || R.contains(prop, ignoreFields),
  data,
);

const defaultPath = 'itemsByKey'
const itemsLens = R.lensProp(defaultPath);
const getCustomItemsLens = (path) => R.lensPath([defaultPath, ...path])

export const set = R.curry((key, data, state) => {
  if (!data) {
    return {
      ...state,
      itemsByKey: R.omit([key], state.itemsByKey),
    };
  }

  return R.over(R.lensPath(['itemsByKey', key]), R.merge(R.__, data), state);
});

export default function generator(config = {}) {
  const {
    name = 'Module__',
    tableId,
    parseItem = R.identity,
    parentField = '___parent',
  } = config;
  const tableKey = `${appKey}t${tableId}r`;
  const initialState = {
    tableKey,
    parentField,
    itemsByKey: {},
  };

  const createAction = (action, payload) => createAct(`${name.toUpperCase()}__${action}`, payload);
  const fetch = createAction('FETCH', args => ({
    key: tableKey,
    ...args,
  }));
  const fetchSuccess = createAction('FETCH_SUCCESS');
  const fetchFailure = createAction('FETCH_FAILURE');

  const create = createAction('CREATE', args => ({
    key: uuid(),
    ...args,
  }));

  const save = createAction('SAVE', args => ({
    ...args,
    tableKey,
    key: args.key || uuid(),
  }));
  const saveSuccess = createAction('SAVE_SUCCESS');
  const saveFailure = createAction('SAVE_FAILURE');

  const remove = createAction('REMOVE');
  const removeSuccess = createAction('REMOVE_SUCCESS');
  const removeFailure = createAction('REMOVE_FAILURE');

  const removeWithUndo = createAction('REMOVE_WITH_UNDO', args => ({
    ...args,
    tableKey,
  }));
  const removeWithUndoSuccess = createAction('REMOVE_WITH_UNDO_SUCCESS');
  const removeWithUndoFailure = createAction('REMOVE_WITH_UNDO_FAILURE');

  const undoRemove = createAction('UNDO_REMOVE');
  const undoRemoveSuccess = createAction('UNDO_REMOVE_SUCCESS');
  const undoRemoveFailure = createAction('UNDO_REMOVE_FAILURE');

  const handleFetchNumber = (state, payload) => {
    const {data, path} = payload;

    return R.over(getCustomItemsLens(path), () =>  data, state)
    // return state.itemsByKey[userAkey].___phonemobile = data;

  }

  const handleFetchSuccess = (state, { response, parents }) => {
    if (response.fromCache) {
      return state;
    }
    return R.over(itemsLens, (data) => {
      const items = parents ?
        R.pickBy(item => !R.contains(getItemField(parentField, item), parents), data) :
        data;
      return parseResult(response, items, parseItem);
    }, state);
  };

  const handleCreate = (state, payload) => {
    const { data, key } = payload;

    return set(key, {
      key,
      ...data,
    }, state);
  };

  const handleSave = (state, payload) => {
    const { data = {} } = payload;
    const item = R.defaultTo({}, getItem(payload.key, state));
    const key = R.defaultTo(payload.key, item.key);

    return set(key, {
      key,
      ...data,
      isSaving: true,
      isShouldSaved: item.isShouldSaved || (item.isSaving && !payload.isLocal),
    }, state);
  };

  const handleSaveSuccess = (state, { key, response }) => {
    const item = getItem(key, state);
    const newKey = R.pathOr(item.key, ['data', 'key'], response);
    const isCreation = newKey && key !== newKey;

    let newState = set(key, {
      isSaving: false,
      isSaved: true,
      isShouldSaved: item.isShouldSaved ? !response : item.isShouldSaved,
    }, state);

    if (isCreation) {
      newState = R.compose(
        set(newKey, {
          ...getItem(key, newState),
          key: newKey,
          oldKey: key,
        }),
        set(key, undefined),
      )(newState);
    }

    return newState;
  };

  const handleSaveFailure = (state, payload) => {
    const { key, data } = payload;

    return set(key, {
      ...data,
      isSaving: false,
      isSaved: false,
    }, state);
  };

  const handleRemove = (state, payload) => {
    const { key } = payload;

    return set(key, {
      isDeleting: true,
    }, state);
  };

  const handleRemoveSuccess = (state, payload) => {
    const { key } = payload;
    const item = getItem(key, state);
    const isLocalItem = isLocalKey(item.key);

    return set(key, {
      isDeleted: true,
      isDeleting: false,
      markToDelete: isLocalItem && item.isSaving,
    }, state);
  };

  const handleRemoveFailure = (state, payload) => {
    const { key } = payload;

    return set(key, {
      isDeleting: false,
    }, state);
  };


  const handleUndoRemove = (state, payload) => {
    const { key } = payload;

    return set(key, {
      isDeleted: false,
      isDeleting: false,
      markToDelete: false,
    }, state);
  };

  const handleUndoRemoveFailure = (state, payload) => {
    const { key } = payload;

    return set(key, {
      isDeleted: true,
      isDeleting: false,
      markToDelete: false,
    }, state);
  };

  const reducer = createReducer({
    [fetchSuccess]: handleFetchSuccess,
    [`NUMBER__PHONE_${name}`]: handleFetchNumber,

    [create]: handleCreate,

    [save]: handleSave,
    [saveSuccess]: handleSaveSuccess,
    [saveFailure]: handleSaveFailure,

    [remove]: handleRemove,
    [removeSuccess]: handleRemoveSuccess,
    [removeFailure]: handleRemoveFailure,

    [removeWithUndo]: handleRemove,
    [removeWithUndoSuccess]: handleRemoveSuccess,
    [removeWithUndoFailure]: handleRemoveFailure,

    [undoRemove]: handleUndoRemove,
    [undoRemoveFailure]: handleUndoRemoveFailure,

    [login]: () => initialState,
  }, initialState);

  return {
    fetch,
    fetchSuccess,
    fetchFailure,

    create,

    save,
    saveSuccess,
    saveFailure,

    remove,
    removeSuccess,
    removeFailure,

    removeWithUndo,
    removeWithUndoSuccess,
    removeWithUndoFailure,

    undoRemove,
    undoRemoveSuccess,
    undoRemoveFailure,

    reducer,
  };
}

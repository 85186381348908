import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import containerQuery from 'decorators/containerQuery';

import styles from './Grid.module.css';

const cx = classNames.bind(styles);

class Row extends PureComponent {
  static propTypes = {
    align: PropTypes.string,
    children: PropTypes.node,
    containerQuery: PropTypes.object,
    noPrint: PropTypes.bool,
    maximize: PropTypes.bool,
    reverseIndent: PropTypes.bool,
    withProps: PropTypes.bool,
  };

  static defaultProps = {
    align: undefined,
    children: undefined,
    containerQuery: {},
    noPrint: false,
    reverseIndent: false,
    withProps: false,
  }

  static childContextTypes = {
    width: PropTypes.number,
    level: PropTypes.string,
  };

  getChildContext() {
    return {
      level: this.getLevel(),
    };
  }

  getLevel() {
    const cq = this.props.containerQuery;
    let level = 'medium';

    if (cq.row_width_lt_720) {
      level = 'small';
    } else if (cq.row_width_gt_1020) {
      level = 'big';
    }

    return level;
  }

  render() {
    const { withProps } = this.props;
    const level = this.getLevel();

    return (
      <div
        className={cx('row', this.props.containerQuery, {
          [`row_align_${this.props.align}`]: this.props.align,
          row_reverseIndent: this.props.reverseIndent,
          row_maximize: this.props.maximize,
          row_no_print: this.props.noPrint,
        })}
      >
        {withProps ?
          React.Children.map(this.props.children, child =>
            child ? React.cloneElement(child, {
              level,
            }) : child,
          ) :
          this.props.children
        }
      </div>
    );
  }
}

export default R.compose(
  containerQuery(styles),
)(Row);

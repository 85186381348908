import { getToken } from 'redux/modules/auth';
import { getItemLocalStorage, setItemLocalStorage } from '../localStorage';

let isRequested = {};
let cache = new Map();
let prevToken;

const construtCacheKey = (url, view, filter, data) => `${url}_${view}_${filter}_${data}`;

const getCacheKeyByConfig = (config) => {
  const url = config.url;
  const { filter, view } = config.headers;

  return construtCacheKey(url, view, filter, config.data);
};

const setTimeCashWithTimeOut = (cacheKey, response) => {
  cache.set(cacheKey, {
    ...response,
    timeout: setTimeout(() => cache.delete(cacheKey), 5 * 60 * 1000),
  });
};


export function getIsInCache(config) {
  const cacheKey = construtCacheKey(config.url, config.view, config.filter);
  const currentCache = cache.get(cacheKey);

  return !!currentCache;
}

export function resetCache(store) {
  return () => {
    const token = getToken(store.getState().auth);

    if ((!token && prevToken) || (token && !prevToken)) {
      cache = new Map();
      isRequested = {};
    }

    prevToken = token;
  };
}

export function requestCache(config) {
  if (!config.cache && !config.useLS) {
    return config;
  }

  const cacheKey = getCacheKeyByConfig(config);
  let currentCache = cache.get(cacheKey);
  let isLocalCache = !!currentCache;

  if (!currentCache && config.useLS) {
    currentCache = getItemLocalStorage({ cacheKey });
    isLocalCache = isRequested[cacheKey];
    isRequested[cacheKey] = true;
  }

  if (currentCache) {
    return Promise.reject({
      cache: {
        ...currentCache,
        fromCache: isLocalCache,
      },
    });
  }

  return config;
}

export function responseCache(response) {
  const { config } = response;

  const cacheKey = getCacheKeyByConfig(config);
  const currentCache = cache.get(cacheKey);

  // TODO i dont know is need this cashing with timeout now
  if (currentCache) {
    clearTimeout(currentCache.timeout);
  }

  if (config.useLS && response.data.count !== 0) {
    setItemLocalStorage({ cacheKey, response });
  } else if (config.cache) {
    setTimeCashWithTimeOut(cacheKey, response);
  }

  return response;
}

export function responseCacheError(error) {
  if (error.cache) {
    return Promise.resolve(error.cache);
  }

  return Promise.reject(error);
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';

import Icon from 'components/Icon';


import styles from './Info.module.css';

const cx = classNames.bind(styles);

export default class Info extends PureComponent {
  static propTypes = {
    alwaysTop: PropTypes.bool,
    alwaysBottom: PropTypes.bool,
    children: PropTypes.node,
    toggler: PropTypes.node,
    togglerFullSize: PropTypes.bool,
    width: PropTypes.number,
    singleLine: PropTypes.number,
    align: PropTypes.oneOf([
      'left',
    ])
  }

  static defaultProps = {
    alwaysBottom: false,
    children: undefined,
    toggler: undefined,
    width: undefined,
    togglerFullSize: false,
    singleLine: undefined,
  }

  state = {
    hover: false,
  }

  handleShowTooltip = (event) => {
    if (!this.props.children) {
      return undefined;
    }

    const iconRect = event.target.getBoundingClientRect();
    const top = iconRect.top - 70;
    const bottom = window.innerHeight - iconRect.bottom;

    this.setState({
      top: top > bottom,
      height: Math.max(top, bottom),
      hover: true,
    });
  };

  handleHideTooltip = () => {
    this.setState({
      hover: false,
    });
  };

  renderButton() {
    const { toggler } = this.props;

    if (toggler) {
      return toggler;
    }

    return <Icon icon='info' width={20} height={20} />;
  }

  render() {
    const { alwaysTop, alwaysBottom, toggler, togglerFullSize, width, singleLine, align, children } = this.props;
    const maxHeight = this.state.height - 50;

    return (
      <div
        className={cx({
          info: true,
          info_customWidth: width,
          info_singleLine: singleLine,
          [`info_align_${align}`]: align,
        })}
      >
        <div
          className={cx({
            toggler: true,
            toggler_custom: !!toggler,
            toggler_fullSize: togglerFullSize,
            toggler_hover: this.state.hover,
          })}
          onMouseEnter={this.handleShowTooltip}
          onMouseLeave={this.handleHideTooltip}
        >
          {this.renderButton()}
        </div>
        <div
          className={cx({
            tooltip: true,
            tooltip_alwaysTop: alwaysTop,
            tooltip_top: (this.state.top && !alwaysBottom) || alwaysTop,
          })}
          style={{
            maxHeight: isNaN(maxHeight) ? null : maxHeight,
            width,
          }}
          onMouseLeave={this.handleHideTooltip}
        >
          {children}
        </div>
      </div>
    );
  }
}

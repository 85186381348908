import * as R from 'ramda';
import { createSelector } from 'reselect';

import getAnalysisItem from '../getAnalysisItem';
import getChemicalElements from '../getChemicalElements';
import getGenes from '../getGenes';
import getVitamins from '../getVitamins';

const getChemicalList = prop => createSelector(
  getChemicalElements,

  R.compose(
    R.map(item => ({
      key: item.key,
      label: R.prop('custom_наименование', item),
    })),
    R.filter(R.prop(prop)),
  ),
);

const types = {
  194: getChemicalList('custom_анализ_крови'),
  196: createSelector(
    getGenes,

    R.map(item => ({
      key: item.key,
      label: R.prop('custom_название', item),
    })),
  ),
  193: getVitamins,
  192: getChemicalList('custom_анализ_волос'),
  195: getChemicalList('custom_анализ_мочи'),
};

export default createSelector(
  (state, props) => R.compose(
    type => types[type](state, props),
    R.prop('typeId'),
    getAnalysisItem,
  )(state, props),

  R.identity,
);

import * as R from 'ramda';
import { attr, Model } from 'redux-orm';
import { createAction } from 'redux-act';

import getKeyInfo from 'utils/getKeyInfo';

import generator from 'redux/hor/orm-module';
import fetchWithView from 'redux/utils/fetchWithView';
import convertFromServerTimeZoneToCurrentTimeZone from 'utils/convertFromServerTimeZoneToCurrentTimeZone';

const {
  reducer,

  fetch,
  fetchSuccess,
  create,
  save,
  saveSuccess,
  removeWithUndo,
  removeWithUndoSuccess,
} = generator({
  name: 'Supplement',
  tableId: 158,
  parseItem: R.evolve({
    ___parent: R.propOr(null, 'key'),
    ___createddate: convertFromServerTimeZoneToCurrentTimeZone,
  }),
});

export default class Supplement extends Model {
  static modelName = 'Supplement';

  static options = {
    idAttribute: 'key',
  }

  static fields = {
    ___createddate: attr(),
    ___parent: attr(),
    custom_рекомендации: attr(),
    custom_автогенерация: attr(),
  }

  static reducer = reducer;


  get id() {
    return getKeyInfo(this.key).record;
  }
}

export const saveManual = createAction('SUPPLEMENT__SAVE_MANUAL');
export const saveAuto = createAction('SUPPLEMENT__SAVE_AUTO');
export const autogenerateButton = createAction('SUPPLEMENT__AUTOGENERATE_BUTTON');
export const fetchList = fetchWithView(fetch, 'ts5c158cs9r573');
export const fetchItem = fetchWithView(fetch, 'ts5c158cs6r566');

export {
  fetch,
  fetchSuccess,

  create,
  save,
  saveSuccess,

  removeWithUndo,
  removeWithUndoSuccess,
};

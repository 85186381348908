import { attach, createEffect, createStore } from "effector";
import { sortBy } from "remeda";

import { request } from "utils/api";

export type Group = {
  group_id: string;
  name: string;
  patients?: string[];
};

export const $groups = createStore<Group[]>([]);

export const $sortedGroups = $groups.map(sortBy((group) => group.name));

export const createGroupFx = createEffect<string, Group[]>({
  handler: async (group) => {
    const response = await request.post<Group[]>(
      `/c105a902t212r/json/v2`,
      {
        parameters: {
          name: group,
        },
      },
      {
        headers: {
          Function: "AddGroup",
        },
      }
    );

    return response.data;
  },
});

export const updateGroupFx = createEffect<
  { id: string; name: string },
  Group[]
>({
  handler: async ({ id, name }) => {
    const response = await request.post<Group[]>(
      `/c105a902t212r/json/v2`,
      {
        parameters: {
          group_id: id,
          name,
        },
      },
      {
        headers: {
          Function: "UpdateGroup",
        },
      }
    );

    return response.data;
  },
});

export const removeGroupFx = createEffect<string, Group[]>({
  handler: async (id) => {
    const response = await request.post<Group[]>(
      `/c105a902t212r/json/v2`,
      {
        parameters: {
          group_id: id,
        },
      },
      {
        headers: {
          Function: "RemoveGroup",
        },
      }
    );

    return response.data;
  },
});

export const removePatientFromGroup = attach({
  source: $groups,
  mapParams: (patientKey: string, groups) => ({
    patientKey,
    groupId: groups.find((group) => group.patients?.includes(patientKey))
      ?.group_id!,
  }),
  effect: createEffect<
    {
      patientKey: string;
      groupId: string;
    },
    Group[]
  >({
    handler: async ({ patientKey, groupId }) => {
      const response = await request.post<Group[]>(
        `/c105a902t212r/json/v2`,
        {
          parameters: {
            group_id: groupId,
            patient_key: patientKey,
          },
        },
        {
          headers: {
            Function: "RemoveFromGroup",
          },
        }
      );

      return response.data;
    },
  }),
});

export const addPatientToGroup = createEffect<
  {
    patientKey: string;
    groupId: string | null;
  },
  Group[]
>({
  handler: async ({ patientKey, groupId }) => {
    const response = await request.post<Group[]>(
      `/c105a902t212r/json/v2`,
      {
        parameters: {
          group_id: groupId,
          patient_key: patientKey,
        },
      },
      {
        headers: {
          Function: "AddToGroup",
        },
      }
    );

    return response.data;
  },
});

export const setPatientGroupFx = createEffect<
  {
    patientKey: string;
    groupId: string | null;
  },
  Group[]
>({
  handler: async ({ patientKey, groupId }) => {
    if (groupId === null) {
      return removePatientFromGroup(patientKey);
    }

    return addPatientToGroup({
      patientKey,
      groupId,
    });
  },
});

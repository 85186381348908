import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import BarchartTable from 'components/BarchartTable';

import AnalysisItemBlood from '../../containers/AnalysisItemBlood';
import getValidator from '../../utils/bloodAndHairAnalysis/getValidator';

export default class BloodAnalysis extends PureComponent {
  static propTypes = {
    data: PropTypes.array,
  }

  fieldRenderer(options) {
    const {
      name,
      value,
      update,
      updateKey,
    } = options;

    return (
      <AnalysisItemBlood
        name={name}
        value={value}
        update={update}
        updateKey={updateKey}
        validate={getValidator}
      />
    );
  }


  render() {
    const { data } = this.props;

    return (
      <BarchartTable
        data={data}
        multiplier={1.5}
        fieldRenderer={this.fieldRenderer}
      />
    );
  }
}

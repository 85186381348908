import { put, all, takeEvery, call, select } from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';
import { push } from 'react-router-redux';
import * as R from 'ramda';
import { appKey,  } from 'config';

import { request } from 'utils/api';
import removeCustom from 'utils/removeCustom';

import {getPublicToken} from 'redux/utils/getPublicToken';

import {
  setStep,
  setUser,
  setInstitution,
  setUTM
} from './';

function* flow(staff, institution, token, isLight, form) {
  try {
    yield call(() => request.post(`/${appKey}t179r/json/v2`, {
      parameters: {
        action: isLight ? 12 : 11,
        staff: removeCustom(staff),
        institution: removeCustom(institution),
      }
    }, {
      headers: {
        token,
        Function: 'staffActions',
      },
    }));
    const email = R.propOr(null, 'custom_login', staff);
    yield put(push(`/login?afterSignup=true&email=${email}`));
    yield put(setStep('user'));
    yield put(setUTM(null))
  } catch (error) {

  } finally {
    yield stopSubmit(form)
  }
}

function* setUserFlow(action) {
  const { institutionKey } = yield select(state => state.Public.signup);
  const { isLight } = action.payload;
  yield put(startSubmit('registerUser'));
  const token = yield call(getPublicToken, true);

  try {
    const isDuplicateLogin = yield call(() => request.post(`/${appKey}t155r/json/v2`, {
      parameters: {
        email: action.payload.data.custom_login,
      },
    }, {
      hasStrictDomain: true,
      headers: {
        token,
        Function: 'IsDuplicateLogin',
      },
    }));

    if (isDuplicateLogin.data.duplicate) {
      yield put(stopSubmit('registerUser', {
        custom_login: 'Пользователь с таким логином уже зарегистрирован',
      }));
      return;
    }
  } catch (error) {

  }

  if (!institutionKey && !isLight) {
    yield put(setStep('institute'));
    yield put(stopSubmit('registerUser'));
    return;
  }

  const { user } = yield select(state => state.Public.signup);
  yield call(flow, user, {}, token, isLight, 'registerUser')
}

function* setInstitutionFlow() {
  yield put(startSubmit('registerInstitute'));
  const token = yield call(getPublicToken);
  const { user, institution } = yield select(state => state.Public.signup);
  yield call(flow, user, institution, token, false, 'registerInstitute')
}

export default function* saga() {
  yield all([
    takeEvery(setUser, setUserFlow),
    takeEvery(setInstitution, setInstitutionFlow),
  ]);
}

import { appKey } from 'config';

const columns = [
  {
    name: 'ПП',
    width: 30,
  },
  {
    name: 'Время',
    width: 80,
  },
  {
    name: 'Продукт/Блюдо',
    width: 250,
  },
  {
    name: 'МЕРА, Г/МЛ',
    width: 100,
  },
  {
    name: 'ПОРЦИЯ',
    width: 120,
  },
  {
    name: 'ЭЦ, ККАЛ',
    width: 120,
    element: `${appKey}t28r219`,
    isEnergy: true,
  },
  {
    name: 'Б, Г',
    label: 'Белки',
    width: 80,
    element: `${appKey}t28r221`,
    isBzhu: true,
    k: 4,
  },
  {
    name: 'Ж, Г',
    label: 'Жиры',
    width: 80,
    element: `${appKey}t28r222`,
    isBzhu: true,
    k: 9,
  },
  {
    name: 'У, Г',
    label: 'Углеводы',
    width: 80,
    element: `${appKey}t28r223`,
    isBzhu: true,
    k: 4,
  },
];

export default columns;
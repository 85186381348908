import React, { PureComponent } from 'react';
import { connect } from 'utils/react-redux';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import createValidate from 'utils/validation';

import { login, hideModal, resendEmail, setInviteByToken } from 'redux/modules/auth';

import Login from '../../components/Login';

const form = {
  form: 'Login',
  validate: createValidate({
    email: {
      required: true,
    },
    password: {
      required: true,
      simplePass: false,
      min: false,
    },
  }),
  fields: [
    'email',
    'password',
    'remember',
  ],
};

const mapStateToProps = R.always({});
const mapDispatchToProps = {
  login,
  onHideModal: hideModal,
  resendEmail,
  setInviteByToken
};

class PublicLoginContainer extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    login: PropTypes.func,
    onHideModal: PropTypes.func,
    resendEmail: PropTypes.func,
    setInviteByToken: PropTypes.func,
    submitting: PropTypes.bool,
    valid: PropTypes.bool,
    invite: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
  }

  static defaultProps = {
    children: undefined,
    error: undefined,
    handleSubmit: () => {},
    login: () => {},
    onHideModal: () => {},
    resendEmail: () => {},
    setInviteByToken: () => {},
    submitting: false,
    valid: false,
  }
  componentDidMount() {
    const { invite, setInviteByToken } = this.props;
    if (invite) {
      setInviteByToken(invite)
    }
  }

  handleSubmit = this.props.handleSubmit((values) => {
    const evolvedValues = R.evolve({
      email: R.trim,
      password: R.trim,
    }, values);

    this.props.login(evolvedValues);
  })

  handleSubmitModal = (event) => {
    event.preventDefault();

    this.props.resendEmail();
  }

  render() {
    return (
      <Login
        onSubmit={this.handleSubmit}
        error={this.props.error}
        submitting={this.props.submitting}
        valid={this.props.valid}
        onHideModal={this.props.onHideModal}
        onSubmitModal={this.handleSubmitModal}
        recoveryLink={this.props.recoveryLink}
        registration={this.props.registration}
      >
        {this.props.children}
      </Login>
    );
  }
}

export default R.compose(
  reduxForm(form),
  connect(mapStateToProps, mapDispatchToProps),
)(PublicLoginContainer);

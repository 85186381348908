import * as R from 'ramda';
import { createSelector } from 'reselect';

import getElementsVisible from 'redux/selectors/getElementsVisible';

import getRationTableElementsKey from '../getRationTableElementsKey';

import columns from './columns';

export default createSelector(
  getRationTableElementsKey,
  getElementsVisible,

  (selected, visible) =>
    R.compose(
      R.concat(columns),
      R.map((item) => {
        const name = item.custom_сокращенное_наименование || item.custom_наименование;

        return {
          name: `${name}, ${R.path(['custom_ед_изм', 'label'], item)}`,
          label: R.prop('custom_наименование', item),
          width: 80,
          element: R.prop('key', item),
        };
      }),
      R.sortBy(R.path(['custom_элемент_состава', 'key'])),
      R.uniq,
      R.filter(item => R.contains(item.key, selected)),
    )(visible),
);

import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getItemsArray as getIngestion } from 'redux/modules/mealTypes';

export default createSelector(
  R.path(['mealTypes']),

  R.compose(
    R.map(item => ({
      key: item.key,
      custom_схема_питания: item.key,
      custom_время: item.custom_время,
    })),
    getIngestion,
  ),
);

import * as R from 'ramda';

import { createSelector } from 'reselect';

export default createSelector(
  R.path(['Dietanamnez', 'dietanamnezPublic', 'renderPrint', 'patientId']),
  R.path(['routeParams', 'patientId']),
  R.path(['Catalog', 'patientInWork', 'patientId']),

  (zero, main, secondary) => zero || main || secondary,
);

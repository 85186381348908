import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import Triangle from '../../default/NormRangeLine/Triangle';

import { getOnePercent } from '../../helpers';

export default class NormRangeLine extends PureComponent {
  static propTypes = {
    normMin: PropTypes.number,
    normMax: PropTypes.number,
    height: PropTypes.number,
    x: PropTypes.func,
    y: PropTypes.func,
  }

  static defaultProps = {
    normMin: undefined,
    normMax: undefined,
    height: 0,
    x: () => {},
    y: () => {},
  }

  getTriangles({ onePercent, normMin, normMax }) {
    if (normMax < (100 * onePercent)) {
      return [normMin, normMax];
    }
    return [normMin];
  }

  render() {
    if (R.isNil(this.props.normMin) && R.isNil(this.props.normMax)) {
      return null;
    }

    const onePercent = getOnePercent(this.props.normMin, this.props.normMax);
    const normMaxValue = isFinite(this.props.normMax) ? 65 * onePercent : 110 * onePercent;
    const normMin = isFinite(this.props.normMin) && this.props.normMin > 0 ? 35 * onePercent : 0;
    const normMax = normMaxValue || null;
    const { height, x, y } = this.props;
    const triangles = this.getTriangles({ onePercent, normMin, normMax });

    return (
      <g>
        <line
          y1={y(height / 2)}
          x1={x(normMin)}
          x2={x(normMax)}
          y2={y(height / 2)}
          stroke='black'
          strokeWidth={1}
        />
        <Triangle
          height={height}
          triangles={triangles}
          x={x}
        />
      </g>
    );
  }
}

import React, { PureComponent } from 'react';
import { Field } from 'redux-form';
import { withReduxForm } from 'redux-form/es/ReduxFormContext';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import FieldDate from './FieldDate';

class FieldConnected extends PureComponent {
  static contextTypes = {
    onFormChange: PropTypes.func,
  };

  static propTypes = {
    name: PropTypes.string,
    onBlur: PropTypes.func,
  };

  static defaultProps = {
    name: undefined,
    onBlur: () => {},
  };

  handleFocus = onFocus => (event) => {
    this.value = event.target.value;
    onFocus(event);
  };

  handleBlur = onBlur => (value) => {
    onBlur(value);

    this.props.onBlur();

    if (this.value !== value && this.context.onFormChange) {
      setTimeout(() => {
        this.context.onFormChange();
      }, 0);
    }
  };

  renderField = (field = {}) => (
    <FieldDate
      {...this.props}
      {...field.input}
      {...field.meta}
      onFocus={this.handleFocus(field.input.onFocus)}
      onBlur={this.handleBlur(field.input.onBlur)}
      name={null}
    />
  );

  render() {
    const { _reduxForm: reduxForm } = this.props;

    if (!reduxForm || !R.contains(this.props.name, reduxForm.fields)) {
      return (
        <FieldDate {...this.props} />
      );
    }

    return (
      <Field
        component={this.renderField}
        {...this.props}
      />
    );
  }
}

export default withReduxForm(FieldConnected)
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import Icon from 'components/Icon';

import styles from './Message.module.css';

const cx = classNames.bind(styles);

export default class Message extends PureComponent {
  static propTypes = {
    icon: PropTypes.string,
    text: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    inlineBlock: PropTypes.bool,
  };

  static defaultProps = {
    icon: 'error',
    text: 'Some error',
    inlineBlock: false,
  };

  render() {
    const { icon, text, inlineBlock } = this.props;

    return (
      <div className={cx({
        message: true,
        message_inline: inlineBlock,
      })}
      >
        <div className={cx({
          icon: true,
          icon_inline: inlineBlock,
        })}
        >
          <Icon icon={icon} width={30} height={22} />
        </div>
        <div className={cx('text')}>{text}</div>
      </div>
    );
  }
}

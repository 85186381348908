import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Link as LinkRR } from 'react-router';

import Icon from 'components/Icon';
import IconLoader from 'components/IconLoader';
import IconDotLoader from 'components/IconDotLoader';

import styles from './Button.module.css';

const cx = classNames.bind(styles);

export default class Button extends PureComponent {
  static propTypes = {
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    block: PropTypes.bool,
    bordered: PropTypes.bool,
    children: PropTypes.node.isRequired,
    color: PropTypes.oneOf([
      'white',
      'blue',
      "cerulean",
      "cerulean-animated",
      "dark-animated",
      "dark"
    ]),
    title: PropTypes.string,
    component: PropTypes.string,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    isDisableableLink: PropTypes.bool,
    isHovered: PropTypes.bool,
    isLoading: PropTypes.bool,
    noPadding: PropTypes.bool,
    isFictive: PropTypes.bool,
    routing: PropTypes.string,
    size: PropTypes.oneOf([
      'small',
      'big',
      "medium"
    ]),
    to: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    type: PropTypes.oneOf([
      'button',
      'submit',
    ]),
    upper: PropTypes.bool,
    rounded: PropTypes.bool,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    isFictive: false,
    block: false,
    bordered: false,
    children: undefined,
    color: undefined,
    title: undefined,
    component: 'button',
    disabled: false,
    href: undefined,
    isDisableableLink: false,
    isHovered: false,
    isLoading: false,
    noPadding: false,
    routing: '',
    size: undefined,
    to: undefined,
    type: 'button',
    upper: false,
    onMouseDown: () => { }
  };

  getComponent() {
    const { component, to, href } = this.props;

    if (to) {
      return LinkRR;
    }

    if (href) {
      return 'a';
    }

    return component;
  }

  getIsIcon() {
    return this.props.children.type === Icon;
  }

  renderChild(child, key) {
    return (
      <span
        className={cx('child')}
        key={key}
      >
        {child}
      </span>
    );
  }

  render() {
    const {
      block,
      bordered,
      children,
      color,
      disabled,
      href,
      isHovered,
      isLoading,
      onClick,
      noPadding,
      size,
      to,
      type,
      upper,
      title,
      rounded,
      target,
      onMouseDown,
      isFictive,
      id,
      itemRef
    } = this.props;

    const ComponentName = this.getComponent();

    return (
      <ComponentName
        id={id}
        onClick={onClick}
        onMouseDown={onMouseDown}
        className={cx('button', {
          button_loading: isLoading,
          button_disabled: disabled || isLoading,
          button_hover: isHovered,
          button_block: block,
          button_no_padding: noPadding,
          button_bordered: bordered,
          button_uppercase: upper,
          button_rounded: rounded,
          button_icon: this.getIsIcon(),
          [`button_size_${size}`]: size,
          [`button_color_${color}`]: color,
          button_fictive: isFictive
        })}
        disabled={disabled || isLoading}
        href={href}
        to={to}
        title={title}
        target={target}
        type={ComponentName === 'button' ? type : null}
        ref={itemRef}
      >
        <span className={cx('box')} >
          {React.Children.map(children, this.renderChild, this)}
        </span>

        <span className={cx('loader')}>
          {this.getIsIcon() ? <IconLoader size='small' /> : <IconDotLoader />}
        </span>
      </ComponentName>
    );
  }
}

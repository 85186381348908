import * as R from 'ramda';

import generator from 'redux/hor/module';

const {
  reducer,
  fetchSuccess,
  save,
  saveSuccess,
  remove,
} = generator({
  name: 'PatientNotes',
  tableId: 9,
  parseItem: R.evolve({
    ___parent: R.propOr(null, 'key'),
  }),
});

export {
  fetchSuccess,
  save,
  saveSuccess,
  remove,
};


export {
  getNotesByPatient,
} from './selectors';

export default reducer;

export { default as saga } from './saga';

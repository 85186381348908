import * as R from 'ramda';
import { startSubmit, stopSubmit, reset } from 'redux-form';
import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import moment from 'moment';

import { appKey } from 'config';

import { request } from 'utils/api';

import {
  getInstitutionDiscount,
  getInstitutionSubscription,
  getInstitutionSubscriptionIsExpired,
} from 'redux/selectors';

import {
  save as saveSubscription,
} from 'redux/modules/institutionSubscription';

import {
  checkPromo,
  setPromo,
  resetPromo,
} from './reducer';

function* checkPromoFlow(action) {
  const { payload } = action;
  const { promocode, form } = payload;
  const tariff = yield select(state => {
    return state.form.tariff.values.duration
  })

  try {
    yield put(startSubmit(form));
    const { data } = yield call(request.post, `/${appKey}t152r/json/v2`, {
      parameters: {
        promocode,
        tariff,
        exec: true,
      },
    }, {
      headers: {
        Function: 'getPromoKey',
      },
    });

    if (data.error) {
      yield put(stopSubmit(form, {
        _error: data.error,
      }));
      return;
    }

    if (!data.скидка) {
      const subscription = yield select(getInstitutionSubscription);
      const subscriptionIsIxpired = yield select(getInstitutionSubscriptionIsExpired);

      if (subscriptionIsIxpired) {
        location.reload(); // eslint-disable-line no-restricted-globals
        return;
      }

      yield put(saveSubscription({
        key: subscription.key,
        data: R.evolve({
          custom_время_окончания_подписки: value => moment(value).add(data.значение_промокода, 'days'),
        })(subscription),
        isLocal: true,
      }));
    } else {
      const institutionDiscount = yield select(getInstitutionDiscount);
      const discount = data.значение_промокода / 100;

      if (institutionDiscount > discount) {
        yield put(stopSubmit(form, {
          _error: 'Этот промокод дает скидку меньше, чем ваша постоянная скидка.',
        }));

        return;
      }

      yield put(setPromo({
        value: data.key,
        discount,
      }));
    }

    yield put(stopSubmit(form));
    yield put(reset(form));
  } catch (error) {

    yield put(stopSubmit(form, {
      _error: error,
    }));
  }

}

function* resetPromoFlow(action) {
  yield put(reset(action.payload.form));
}

export default function* saga() {
  yield all([
    takeLatest(checkPromo, checkPromoFlow),
    takeLatest(resetPromo, resetPromoFlow),
  ]);
}

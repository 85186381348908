import * as R from 'ramda';
import { createSelector } from 'reselect';

import getRation from '../getRation';

export default createSelector(
  getRation,

  R.compose(
    R.not,
    R.propEq('custom_тип_шаблона', 0),
    R.defaultTo({}),
  ),
);

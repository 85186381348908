import { createAction, createReducer } from 'redux-act';
import * as R from 'ramda';

const initialState = {

};

export const setParams = createAction('SET_PARAMS');
export const setParamsSuccess = createAction('SET_PARAMS_SUCCESS');

const handleSetParams = (state, params) =>
  R.equals(state, params) ? state : params;

export default createReducer({
  [setParams]: handleSetParams,
}, initialState);

import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getItemsArray } from 'redux/hor/module/selectors';

export const getItems = createSelector(
  getItemsArray,

  R.map(item => ({
    label: item.custom_наименование,
    value: item.key,
    text: item.custom_описание,
  })),
);
import * as R from 'ramda';
import { createSelector } from 'reselect';

import getKeyInfo from 'utils/getKeyInfo';

import { getItemsArray } from 'redux/hor/module/selectors';
import { defaultIcon } from 'helpers/constants';

export const getGroups = createSelector(
  getItemsArray,

  R.map(item => ({
    key: item.key,
    label: item.custom_группа || item.key,
  })),
);

export const getCatalogGroups = createSelector(
  getItemsArray,

  R.compose(
    R.sortBy(R.prop('title')),
    R.map(item => ({
      key: item.key,
      id: getKeyInfo(item.key).record,
      title: item.custom_группа,
      icon: item.custom_иконка_url || defaultIcon,
    })),
  ),
);

export const getSelectData = createSelector(
  getItemsArray,

  R.compose(
    R.sortBy(R.prop('label')),
    R.map(i => ({
      value: i.key,
      label: i.custom_группа,
    })),
  ),
);

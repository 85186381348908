import moment from 'moment';

import { appKey } from 'config';

import createOrmSelector from 'redux/utils/createOrmSelector';
import getInstitutionKey from '../getInstitutionKey';
import getInstitutionSubscriptionEnd from '../getInstitutionSubscriptionEnd';

export default createOrmSelector(
  getInstitutionKey,
  getInstitutionSubscriptionEnd,

  (session, institutionKey, subscriptionEnd) => {
    const before = subscriptionEnd ? moment(subscriptionEnd) : moment();
    const { SubscriptionOperation } = session;
    const item = SubscriptionOperation
      .all()
      .filter(it => (
        it.custom_currentstatus === 'PostProcessingDone' &&
        (
          it.custom_операция === `${appKey}t122r6448820` || // Покупка однодневной подписки
          it.custom_операция === `${appKey}t122r6449623` // Перенос даты однодневной подписки
        ) &&
        it.___parent === institutionKey &&
        moment(it.custom_дата_для_однодневной).isSameOrAfter(before, 'day')
      ))
      .orderBy('custom_дата_операции', 'desc')
      .first();
    return item ? item.ref : null;
  },
);

/* eslint-disable no-param-reassign */

import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'utils/react-redux';

const IS_SOME_FIELD_ACTIVE_PROP_NAME = 'INTERNAL__isSomeFieldActive';

const onFormChange = (submitName, loggingName, submitOnUnmount = true) => (Target) => {
  // If the context is inside OnFormChange, on the first render it will be empty, because we need
  // to acquire Target's ref inside OnFormChange::render to populate context, but ref is not
  // defined on the first getChildContext call, and so children would not, acquire a new context
  // and there would be a bug with form fields not submitting when blurring
  Target.childContextTypes = {
    onFormChange: PropTypes.func,
    onLogging: PropTypes.func,
  };

  Target.prototype.getChildContext = function getChildContext() {
    return {
      onFormChange: this[submitName],
      onLogging: this[loggingName],
    };
  };

  const mapStateToProps = (state, { form }) => ({
    [IS_SOME_FIELD_ACTIVE_PROP_NAME]:
      !R.isNil(R.path(['form', form, 'active'], state)),
  });

  class OnFormChange extends React.PureComponent {
    static propTypes = {
      [IS_SOME_FIELD_ACTIVE_PROP_NAME]: PropTypes.bool,
    };

    componentWillUnmount() {
      if (this.props[IS_SOME_FIELD_ACTIVE_PROP_NAME] && this.targetRef && submitOnUnmount) {
        this.targetRef[submitName]();
      }
    }

    handleTargetRef = (el) => {
      this.targetRef = el;
    };

    targetRef = null;

    render() {
      return (
        <Target
          ref={this.handleTargetRef}
          {...R.omit([IS_SOME_FIELD_ACTIVE_PROP_NAME], this.props)}
        />
      );
    }
  }

  return connect(mapStateToProps)(OnFormChange);
};

export default onFormChange;
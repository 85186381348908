import { getRoleRights } from "redux/modules/roles";
import { getKeyById } from "redux/modules/rights";
import getUserRole from "../getUserRole";

const rights = {
  INSTITUTION_ADMINISTER: 3556,
  INSTITUTION_SUBSCRIBE: 3557,
  USER_CONTROL: 3558,
  PATIENT_RATION: 3560,
  PATIENT_REGISTER: 3561,
  PATIENT_DELETE: 3562,
  PATIENT_RECEPTION: 3563,
  PATIENT_PRINT: 18705693,
} as const;

type Right = keyof typeof rights;

export function getUserRights(state) {
  const role = getUserRole(state);

  return getRoleRights(role, state.roles) as { [key in Right]?: boolean };
}

export function getUserRight(right: Right, state) {
  const rightId = rights[right];

  if (!rightId) {
    throw new Error("Right should be one of list");
  }

  const rightKey = getKeyById(rightId, state.rights);
  const userRights = getUserRights(state);

  return userRights[rightKey] || false;
}

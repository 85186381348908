import * as R from 'ramda';
import { createSelector } from 'reselect';

import getInstitutionSubscription from '../getInstitutionSubscription';

export default createSelector(
  getInstitutionSubscription,

  R.prop('custom_время_окончания_подписки'),
) as () => string;

import * as R from 'ramda'
import { takeEvery, all, put } from 'redux-saga/effects';

import {
  fetchSuccess,
} from './index';

import { setProfile } from 'redux/modules/auth'

function* fetchSuccessFlow({ payload }) {
  const profile = R.pathOr(null, ['response', 'fields', '___profile', 'key'], payload)

  yield put(setProfile(profile))
}

export default function* saga() {
  yield all([
    takeEvery(fetchSuccess, fetchSuccessFlow),
  ]);
}

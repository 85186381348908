import { combineReducers } from 'redux';

import normChildren from './normChildren';
import normMen from './normMen';
import normPregnant from './normPregnant';
import normWomen from './normWomen';

export default combineReducers({
  normChildren,
  normMen,
  normPregnant,
  normWomen,
});

import createOrmSelector from 'redux/utils/createOrmSelector';

import getApplicationUser from '../getApplicationUser';

export default createOrmSelector(
  getApplicationUser,

  (session, key) => {
    const { WorkerFlags } = session;

    const flags = WorkerFlags
      .filter({
        custom_пользователь: key,
      })
      .first();

    if (flags) {
      return flags.ref;
    }

    return {};
  },
);

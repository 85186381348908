import * as R from 'ramda';
import { createSelector } from 'reselect';

import {
  getDietanamnezKindsFood,
  getDietanamnezProductsIncluded,
  getDietanamnezProductsExcluded,
} from 'modules/Dietanamnez/redux/selectors';

import ageOptions from 'utils/ageOptions';
import { setItem, getItem } from 'utils/localStorage';

import getPatientAge from '../getPatientAge';
import getMealsAll from '../getMealsAll';
import getPatientDisease from '../getPatientDisease';
import getDiseaseList from '../getDiseaseList';
import getPatientAllergen from '../getPatientAllergen';

export default createSelector(
  getMealsAll,
  getPatientDisease,
  getDiseaseList,
  getDietanamnezProductsIncluded,
  getDietanamnezProductsExcluded,
  getPatientAllergen,
  getDietanamnezKindsFood,
  getPatientAge,

  (
    productList,
    patientDisease,
    diseaseList,
    include,
    exclude,
    allergen,
    excludedKindsFood,
    age,
  ) => {
    const goodAgeOptions = R.compose(
      R.pluck('value'),
      R.filter(item => item.min <= age && item.max >= age),
    )(ageOptions);

    const getDish = R.compose(
      R.unnest,
      R.pluck('dish'),
    );
    const disease = R.indexBy(R.prop('value'), diseaseList);

    const patientKey = `c105a902t5r${window.location.href.split("patients/")[1]?.split("/")[0]}`;
    let allowedItems = getDish(include);
    if (!allowedItems?.length) {
      allowedItems = getItem(`DietanamnezProductsIncluded_${patientKey}`) ?? [];
    }
    setItem(`DietanamnezProductsIncluded_${patientKey}`, allowedItems);

    const forbiddenItems = R.compose(
      getDish,
      R.reject(R.propEq('byKindsFood', true)),
    )(exclude);

    const itWithoutStatus = R.compose(
      R.isEmpty,
      R.pick(['allowed', 'warning', 'forbidden']),
    );

    const setDietanamnezAllowed = (item) => {
      const dietanamnezGroups = R.propOr([], 'custom_эталонные_блюда', item);

      if (
        R.intersection(dietanamnezGroups, allowedItems)?.length ||
        R.contains(item.key, allowedItems)
      ) {
        return {
          ...item,
          allowed: 'dietanamnez',
        };
      }

      return item;
    };

    const setKindsFood = (item) => {
      const dietanamnezGroups = R.propOr([], 'custom_эталонные_блюда', item);
      const kindsFoodForbidden = R.compose(
        R.defaultTo([]),
        R.propOr([], 'custom_не_применяется_в'),
      )(item);

      const kindsFoodInter = R.intersection(excludedKindsFood, kindsFoodForbidden);
      const dietanamnezInter = R.intersection(forbiddenItems, dietanamnezGroups);

      if (
        !R.isEmpty(dietanamnezInter) ||
        !R.isEmpty(kindsFoodInter)
      ) {
        return {
          ...item,
          warning: 'dietanamnez',
        };
      }

      return item;
    };

    const setDiagnoses = (item) => {
      const diagnosesForbidden = R.compose(
        R.defaultTo([]),
        R.propOr([], 'custom_запрещено_к_употреблению_при'),
      )(item);

      const diagnosesInter = R.intersection(patientDisease, diagnosesForbidden);

      if (!R.isEmpty(diagnosesInter)) {
        return {
          ...item,
          forbidden: R.compose(
            R.defaultTo('diagnoses'),
            R.propOr(null, 'label'),
            R.propOr(null, diagnosesInter[0]),
          )(disease),
        };
      }

      return item;
    };

    const setAllergen = (item) => {
      if (R.contains(item.key, allergen)) {
        return {
          ...item,
          forbidden: 'allergen',
        };
      }

      return item;
    };

    const setAge = (item) => {
      const dishAges = item.custom_возрастнаягруппа || [];

      if (age && !R.isEmpty(dishAges) && R.intersection(dishAges, goodAgeOptions).length === 0) {
        return {
          ...item,
          forbidden: 'age',
        };
      }

      return item;
    };

    return R.map(R.compose(
      R.ifElse(itWithoutStatus, setAge, R.identity),
      R.ifElse(itWithoutStatus, setDietanamnezAllowed, R.identity),
      R.ifElse(itWithoutStatus, setKindsFood, R.identity),
      R.ifElse(itWithoutStatus, setAllergen, R.identity),
      R.ifElse(itWithoutStatus, setDiagnoses, R.identity),
    ), productList);
  },
);

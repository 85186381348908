import * as R from 'ramda';
import { createSelector } from 'reselect';

export default createSelector(
  R.path(['Rations', 'ui', 'activeGroup']),
  R.path(['Rations', 'ui', 'activeItem']),
  (state, props) => props.id,
  (state, props) => props.groupKey,

  (activeGroup, activeItem, id, groupKey) => {
    const isActiveByGroup = activeGroup && activeGroup === id;
    const isActiveByItem = activeItem && (activeItem[0] === groupKey && activeItem[1] === id);

    return isActiveByGroup || isActiveByItem;
  },
);

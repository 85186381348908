import * as R from "ramda";
import { createSelector } from "reselect";

import { getItems, Patient } from "redux/modules/patients";

export default createSelector(
  R.path(["patients"]),

  getItems as any
) as unknown as () => Patient[];

import createCachedOrmSelector from 'redux/utils/createCachedOrmSelector';

import getAnalysisKey from '../../getAnalysisKey';

export default createCachedOrmSelector(
  getAnalysisKey,

  (session, key) => {
    const { AnalysisBlood } = session;

    return AnalysisBlood
      .filter({
        ___parent: key,
      })
      .toModelArray()
      .map(item => ({
        key: item.key,
        itemKey: item.custom_химический_элемент.key,
        label: `${item.custom_химический_элемент.custom_наименование}, мкг/л`,
        value: item.custom_факт,
      }));
  },
);

import * as R from 'ramda';
import { call, all, takeEvery, put, select } from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';
import { push } from 'react-router-redux';

import { appKey } from 'config';

import getKeyInfo from 'utils/getKeyInfo';
import { request } from 'utils/api';

import runButton from 'redux/utils/runButton';
import dispatchAndWait from 'redux/utils/dispatchAndWait';

import {
  getPatientId,
  getPatientKey,
  getInstitutionKey,
  getLastSupplementRationId,
  getInstitutionTariffIsTrial,
} from 'redux/selectors';

import {
  fetchSuccess,
  saveManual,
  saveAuto,
  save,
  autogenerateButton,
  removeWithUndo,
} from './index';

import { showErrorModal } from '../ui';

function* navigateToRationId(id) {
  const patientId = yield select(getPatientId);

  yield put(push(`/patients/${patientId}/rations/${id}`));
}

function* navigateToRationKey(key) {
  const rationId = getKeyInfo(key).record;

  yield call(navigateToRationId, rationId);
}

function* createRation(includeSupplements) {
  const patientKey = yield select(getPatientKey);
  const institutionKey = yield select(getInstitutionKey);

  const response = yield call(request.post,`/${appKey}t158r/json/v2`, {
    parameters: {
      "territory_key": institutionKey,
      "patient_key": patientKey,
      "include_bad": includeSupplements
    },
  }, {
    headers: {
      Function: 'Create_ration',
    }
  })

  const key = response.data.ration_key;

  yield put(fetchSuccess({
    key,
    response: response.data,
  }));
  
  return key;
}

function* checkAvailability() {
  const isTrial = yield select(getInstitutionTariffIsTrial)

  if (!isTrial) {
    return
  }

  const response = yield call(request.get, `/${appKey}t191r/json/v2`);
  const count = response.data.list.length;

  if (count === 5) {
    yield put(showErrorModal('MAX_LIMIT'))
    throw new Error()
  }

  if (count === 0) {
    yield put(showErrorModal('FIRST'))
  }
}

function* saveManualFlow(action) {
  const { payload = {} } = action;
  const { form } = payload;

  if (form) {
    yield put(startSubmit(form));
  }

  try {
    yield call(checkAvailability)
  } catch (error) {
    yield put(stopSubmit(action.payload.form));
    return
  }

  try {
    const key = yield call(createRation, action.payload.includeSupplements);
    yield call(navigateToRationKey, key);
  } catch (error) {
    console.error(error);
  } finally {
    if (form) {
      yield put(stopSubmit(action.payload.form));
    }
  }
}

function* saveAutoFlow(action) {
  const { payload = {} } = action;
  const { form } = payload;

  if (form) {
    yield put(startSubmit(form));
  }

  try {
    yield call(checkAvailability)
  } catch (error) {
    yield put(stopSubmit(action.payload.form));
    return
  }

  try {
    const key = yield call(createRation);

    yield call(runButton({
      save,
      button: `${appKey}ts9c158c3c1r5`,
    }), autogenerateButton({
      key,
    }));
    yield call(navigateToRationKey, key);
  } catch (error) {
    console.error(error);
  } finally {
    if (form) {
      yield put(stopSubmit(action.payload.form));
    }
  }
}

function* removeWithUndoFlow() {
  const lastId = yield select(getLastSupplementRationId);

  if (lastId) {
    yield call(navigateToRationId, lastId);
  } else {
    const patientId = yield select(getPatientId);
    yield put(push(`/patients/${patientId}`));
  }
}

export default function* saga() {
  yield all([
    takeEvery(saveManual, saveManualFlow),
    takeEvery(saveAuto, saveAutoFlow),
    takeEvery(removeWithUndo, removeWithUndoFlow),
  ]);
}

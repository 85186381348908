import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import ReactDOM from 'react-dom';
import ReactSelect from 'react-select';
import Icon from 'components/Icon';
import memoizee from 'memoizee';
import Fuse from 'fuse.js';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import ClickOutside from 'react-click-outside';
import IconLoaderFilled from 'components/IconLoaderFilled';

import styles from './Select.module.css';

const cx = classNames.bind(styles);

const SELECT_HEIGHT = 250;
const emptyOptions = [];
const concat = memoizee((options, extra, extraOnTop) => {
  if (extraOnTop) {
    return R.concat(extra, options);
  }

  return R.concat(options, extra);
}, { length: 2 });
const selectFilterOptions = R.nthArg(0);
const fuzzyFilterOptions = (options, search) => {
  const fuse = new Fuse(options, {
    location: 0,
    distance: 30,
    threshold: 0.2,
    maxPatternLength: 100,
    keys: [
      'label',
    ],
  });

  return fuse.search(R.trim(search));
};

export default class Select extends PureComponent {
  static propTypes = {
    active: PropTypes.bool,
    alwaysShowExtra: PropTypes.bool,
    autofocus: PropTypes.bool,
    autosize: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    extraOptions: PropTypes.array,
    filterOptions: PropTypes.func,
    grouped: PropTypes.bool,
    hideValue: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    menuRenderer: PropTypes.func,
    minLengthForResult: PropTypes.number,
    multi: PropTypes.bool,
    noBorder: PropTypes.bool,
    normal: PropTypes.bool,
    openOnFocus: PropTypes.bool,
    optionRenderer: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.object),
    placeholder: PropTypes.string,
    possibleOpenLeft: PropTypes.bool,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    searchPlaceholder: PropTypes.string,
    searchable: PropTypes.bool,
    small: PropTypes.bool,
    smallArrow: PropTypes.bool,
    submitFailed: PropTypes.bool,
    theme: PropTypes.oneOf([
      '',
      'blue',
      'alternative',
      'dish',
      'plain',
      'statistic',
    ]),
    touched: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.string,
      PropTypes.array,
      PropTypes.bool,
    ]),
    valueRenderer: PropTypes.func,
    withoutDefaultSelected: PropTypes.bool,
    labelsList: PropTypes.array,

    onBlur: PropTypes.func,
    onBlurResetsInput: PropTypes.bool,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    onCloseResetsInput: PropTypes.bool,
    onSelectResetsInput: PropTypes.bool,
    onFocus: PropTypes.func,
    onInput: PropTypes.func,
    onSubmit: PropTypes.func,
    resetOnChange: PropTypes.bool,
    noWrap: PropTypes.bool,
    block: PropTypes.bool,
    setRef: PropTypes.func,
    extraOnTop: PropTypes.bool,
    closeOnSelect: PropTypes.bool,
    notFoundText: PropTypes.string,
  };

  static defaultProps = {
    active: false,
    alwaysShowExtra: false,
    autofocus: false,
    autosize: true,
    disabled: false,
    error: false,
    extraOptions: [],
    filterOptions: undefined,
    grouped: false,
    hideValue: false,
    id: undefined,
    label: '',
    menuRenderer: undefined,
    minLengthForResult: 0,
    multi: false,
    noBorder: false,
    normal: false,
    onBlurResetsInput: false,
    onCloseResetsInput: false,
    onSelectResetsInput: false,
    openOnFocus: true,
    optionRenderer: undefined,
    options: [],
    placeholder: ' ',
    possibleOpenLeft: false,
    readOnly: false,
    required: false,
    searchable: false,
    searchPlaceholder: '',
    small: false,
    smallArrow: false,
    submitFailed: false,
    static: false,
    theme: '',
    touched: true,
    value: null,
    valueRenderer: undefined,
    withoutDefaultSelected: false,
    onChange: () => { },
    onBlur: () => { },
    onClose: () => { },
    onFocus: () => { },
    onInput: () => { },
    onSubmit: () => { },
    onOpen: () => { },
    resetOnChange: false,
    noWrap: false,
    labelsList: [],
    setRef: () => { },
    extraOnTop: false,
    notFoundText: 'Ничего не найдено',
  };

  state = {
    label: '',
    openUp: false,
    openLeft: false,
    hideTemporarily: true,
  }

  componentWillMount() {
    this.setOptions(this.props);
  }

  componentDidMount() {
    if (!this.props.multi && !this.props.onBlurResetsInput) {
      this.setLabel(this.props.value, this.props.options);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.options !== this.props.options) {
      this.changed = false;
      this.setState({
        label: '',
        openUp: false,
        openLeft: false,
        hideTemporarily: true,
        isLoading: false,
      });
      this.input.setState({ inputValue: '' });
    }

    this.setOptions(nextProps);

    if (!this.props.multi && !this.props.onBlurResetsInput) {
      this.setLabel(nextProps.value, nextProps.options);
    }
  }

  handleOpen = () => {
    const select = this.container;
    let selectParent = select;
    let selectGrandParent = null;
    const htmlTag = document.getElementsByTagName('html')[0];

    while (!selectGrandParent) {
      selectParent = selectParent.parentNode;
      selectGrandParent = selectParent.style.overflow === 'hidden' ? selectParent : null;

      if (selectParent === htmlTag) {
        selectGrandParent = htmlTag;
      }
    }

    const selectParentHeight = selectGrandParent.offsetHeight;
    const selectOffsetTop = select.offsetTop;

    const body = document.body;
    const html = document.documentElement;
    const documentHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );
    const canScroll = documentHeight - window.innerHeight - window.scrollY;

    const openUp = selectOffsetTop + SELECT_HEIGHT > selectParentHeight;
    const selectBox = select.getBoundingClientRect();
    const isPageBottom = selectBox.top + SELECT_HEIGHT > window.innerHeight + canScroll;

    this.setState({
      openUp: isPageBottom || openUp,
    });

    this.handleOpenLeft(selectGrandParent);
  }

  handleOpenLeft(selectGrandParent) {
    if (this.props.possibleOpenLeft) {
      setTimeout(() => {
        if (this.input && this.input.menuContainer) {
          const menuOuter = this.input.menuContainer;
          const selectParentWidth = selectGrandParent.offsetWidth;
          const menuRect = menuOuter.getBoundingClientRect();

          if (menuRect && menuRect.right > selectParentWidth) {
            this.setState({ openLeft: true, hideTemporarily: false });
          } else {
            this.setState({ openLeft: false, hideTemporarily: false });
          }
        }
      }, 0);
    }

    this.props.onOpen();
  }

  handleInput = (value = '', mimicEvent) => {
    const { multi, resetOnChange, onBlurResetsInput, searchable } = this.props;
    const newValue = this.props.onInput(value);
    if (value === this.state.label) {
      return value;
    }

    this.setState({
      label: value,
    });

    if (!mimicEvent) {
      this.changed = false;
    }

    if ((value === '' && !multi && this.props.value && !onBlurResetsInput) || (!mimicEvent && resetOnChange)) {
      if (searchable) {
        this.props.onChange(null);
      }
    }

    this.setOptions(this.props, value);

    return newValue;
  }

  handleChange = (item) => {
    if (this.props.multi) {
      this.handleChangeMulti(item);
    } else {

      this.handleChangeSingle(item);
    }

    this.changed = true;
  };

  handleChangeSingle = (item) => {
    if (!item) {
      return;
    }

    const { value } = item[0] || item;

    this.props.onChange(value, this.state.label);
    this.setLabel(value, this.props.options);
  }

  handleChangeMulti = (item) => {
    const current = this.props.value;
    const values = R.pluck('value', item);
    const lastValue = R.last(values);

    const newValue = R.contains(lastValue, current) ?
      R.without([lastValue], current) :
      R.concat([lastValue], current);

    this.props.onChange(newValue, this.state.label);
  }

  handleFocus = () => {
    this.changed = false;
    this.props.onFocus();
  }

  handleMenuWheel = (event) => {
    const { deltaY, currentTarget } = event;
    const { scrollTop, scrollHeight, offsetHeight } = currentTarget;

    const isMaxScroll = scrollHeight === scrollTop + offsetHeight && deltaY > 0;
    const isMinScroll = scrollTop === 0 && deltaY < 0;

    if (isMinScroll || isMaxScroll) {
      event.preventDefault();
    }
  }

  handleBlur = () => {
    if (this.props.onBlurResetsInput) {
      this.changed = false;
      this.setState({
        label: '',
      });
      this.setOptions(this.props, '');
    }

    this.setState({ openLeft: false });
    this.props.onBlur();
  }

  handleClickOutside = () => {
    this.input.setState({ isOpen: false });
  }

  setContainer = (node) => {
    this.container = ReactDOM.findDOMNode(node); // eslint-disable-line react/no-find-dom-node
  }

  setInput = (node) => {
    this.input = node;
    this.props.setRef(node);
  }

  setLabel(key, options) {
    if (!this.props.searchable) {
      return;
    }

    const value = R.find(R.propEq('value', key), [
      ...options,
      ...this.props.extraOptions,
      ...this.props.labelsList,
    ]);
    const label = R.compose(
      R.defaultTo(''),
      R.defaultTo(key),
      R.propOr(null, 'label'),
    )(value);

    if (!key && this.props.resetOnChange) {
      return;
    }

    if (this.input && label) {
      this.input.setState({ inputValue: label }); // eslint-disable-line
    }

    if (this.state.label !== label && label) {
      this.handleInput(label, true);
    }
  }

  setOptions(props = this.props, label = this.state.label) {
    if (this.changed) {
      return;
    }

    const { searchable, minLengthForResult, options, extraOptions } = props;
    const isHidden = searchable && this.state.label < minLengthForResult;

    this.index = this.index || 0;
    this.index += 1;

    if (!this.props.filterOptions) {
      const list = isHidden ? emptyOptions : this.filterOptions(label, options, extraOptions);
      this.setOptionsState(this.index)(list);
    } else {
      this.setState({
        isLoading: true,
      });

      this.props.filterOptions(label, options, this.setOptionsState(this.index));
    }
  }

  setOptionsState = index => (options) => {
    if (index !== this.index) {
      return;
    }

    this.setState({
      options,
      isLoading: false,
    });
  }

  getOptions() {
    return this.props.searchable ? this.state.options : this.props.options;
  }

  getIsWithError() {
    const { error, touched, submitFailed, active } = this.props;

    return error && (touched || submitFailed) && !active;
  }

  getNoResultText() {
    const { searchable, minLengthForResult } = this.props;
    const { label, isLoading } = this.state;
    const hideOptions = searchable && label.length < minLengthForResult;

    if (hideOptions) {
      return this.props.searchPlaceholder;
    }

    if (isLoading) {
      return 'Поиск...';
    }

    return this.props.notFoundText;
  }

  filterOptions = memoizee((search, options, extraOptions) => {
    const { searchable, minLengthForResult, extraOnTop } = this.props;

    if (!searchable) {
      return concat(options, extraOptions, extraOnTop);
    }

    if (search.length < minLengthForResult) {
      return [];
    }

    if (!search.length) {
      return concat(options, extraOptions, extraOnTop);
    }

    const result = fuzzyFilterOptions(options, search);

    return (R.isEmpty(result) && !this.props.alwaysShowExtra)
      ? []
      : concat(result, extraOptions, extraOnTop);
  })

  arrowRenderer = () => (
    <Icon
      icon={this.props.smallArrow ? 'arrow-bottom' : 'arrow-solid-bottom'}
      height={11}
      width={this.props.smallArrow ? 12 : 10}
    />
  )

  renderOption = (option, index, list, isFocused) => {
    if (!option.template) {
      return option.label;
    }

    return option.template(option, index, list, isFocused);
  };

  render() {
    const {
      searchable,
      value,
      noBorder,
      small,
      grouped,
      id,
      multi,
      withoutDefaultSelected,
    } = this.props;
    const { openUp, openLeft, isLoading } = this.state;
    const withError = this.getIsWithError();
    const withoutSelected = R.isNil(withoutDefaultSelected) ? searchable : withoutDefaultSelected;
    const options = this.getOptions();
    const noResultsText = this.getNoResultText();

    return (
      <ClickOutside
        onClickOutside={() => { }}
        className={cx({
          select: true,
          select_resetable: this.props.onBlurResetsInput,
          select_block: this.props.block,
          select_disabled: this.props.disabled,
          select_noWrap: this.props.noWrap,
          select_empty: R.isEmpty(options),
          select_searchable: searchable,
          select_noBorder: noBorder,
          select_small: small,
          select_normal: this.props.normal,
          [`select_${this.props.theme}`]: this.props.theme,
          select_smallArrow: this.props.smallArrow,
          select_openUp: openUp,
          select_openLeft: openLeft,
          select_grouped: grouped,
          select_state_error: withError,
          select_multi: multi,
          select_hide_temporarily: this.props.possibleOpenLeft && this.state.hideTemporarily,
          select_type_overflow: !this.props.autosize,
        })}
        ref={this.setContainer}
      >
        {this.props.label &&
          <span className={cx('label')}>
            {this.props.label}
            {this.props.required && <span className={cx('required')}>*</span>}
          </span>
        }

        <ReactSelect
          id={id}
          ref={this.setInput}

          options={options}
          value={this.props.hideValue ? null : value}

          clearable={false}
          onChangeResetsInput={false}

          disabled={this.props.disabled || this.props.readOnly}
          closeOnSelect={this.props.closeOnSelect}
          autofocus={this.props.autofocus}
          multi={this.props.multi}
          searchable={searchable}
          openOnFocus={this.props.openOnFocus}
          onBlurResetsInput={this.props.onBlurResetsInput}
          onCloseResetsInput={this.props.onCloseResetsInput}
          onSelectResetsInput={this.props.onSelectResetsInput}
          withoutDefaultSelected={withoutSelected}

          placeholder={this.props.placeholder}
          noResultsText={noResultsText}
          autosize={this.props.autosize}

          arrowRenderer={this.arrowRenderer}
          optionRenderer={this.props.optionRenderer || this.renderOption}
          menuRenderer={this.props.menuRenderer}
          valueRenderer={this.props.valueRenderer}
          filterOptions={selectFilterOptions}

          onInputChange={this.handleInput}
          onMenuWheel={this.handleMenuWheel}
          onOpen={this.handleOpen}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onClose={this.props.onClose}
          onSubmit={this.props.onSubmit}
        />
        {withError &&
          <span className={cx('error')}>
            {this.props.error}
          </span>
        }
        <div
          className={cx('loader', {
            loader_visible: isLoading,
          })}
        >
          <p className={cx('text')}>
            {this.state.label}
          </p>
          <IconLoaderFilled />
        </div>
      </ClickOutside>
    );
  }
}

import { createAction, createReducer } from 'redux-act';

const initialState = {
  supplement: null,
};

export const setHoveredSupplement = createAction('SUPPLEMENTNUI__SET_HOVERED_SUPPLEMENT');
export const showErrorModal = createAction('SUPPLEMENTNUI__SHOW_ERROR_MODAL');
export const hideErrorModal = createAction('SUPPLEMENTNUI__HIDE_ERROR_MODAL');

const handleSetHoveredSupplement = (state, payload) => {
  if (state.supplement === payload.key) {
    return state
  }

  return {
    ...state,
    supplement: payload.key,
  }
};

const handleShowErrorModal = (state, payload) => ({
  ...state,
  modal: payload,
})

const handleHideErrorModal = (state, payload) => ({
  ...state,
  modal: null,
})

export default createReducer({
  [setHoveredSupplement]: handleSetHoveredSupplement,
  [showErrorModal]: handleShowErrorModal,
  [hideErrorModal]: handleHideErrorModal,
}, initialState);

import { fetchSuccess as meals } from 'redux/modules/meals';
import { fetchSuccess as patients } from 'redux/modules/patients';
import { fetchSuccess as notes } from 'modules/Patient/redux/notes';
import { fetchSuccess as patientParams } from 'redux/modules/patientParams';
import { fetchSuccess as analysisBlood } from 'modules/Analyses/redux/modules/analysisBlood';
import { fetchSuccess as analysisGenes } from 'modules/Analyses/redux/modules/analysisGenes';
import { fetchSuccess as analysisHair } from 'modules/Analyses/redux/modules/analysisHair';
import { fetchSuccess as analysisSalt } from 'modules/Analyses/redux/modules/analysisSalt';
import { fetchSuccess as analysisVitamin } from 'modules/Analyses/redux/modules/analysisVitamin';
import { fetchSuccess as analyses } from 'modules/Analyses/redux/modules/patientAnalysis';
import { fetchSuccess as day } from 'modules/Rations/redux/modules/day';
import { fetchSuccess as productComposition } from 'redux/modules/productComposition';
import { fetchSuccess as rations } from 'modules/Rations/redux/modules/ration';
import { fetchSuccess as staff } from 'redux/modules/staff';
import { fetchSuccess as techCard } from 'redux/modules/productTechCard';
import { fetchSuccess as journal } from 'redux/modules/journal';
import { fetchSuccess as dietanamnezItem } from 'modules/Dietanamnez/redux/modules/dietanamnezItem';
import { fetchSuccess as ingestion } from 'modules/Rations/redux/modules/ingestion';
import { fetchSuccess as elements } from 'modules/Rations/redux/modules/patientsElements';
import { fetchSuccess as allergenMeals } from 'modules/Rations/redux/modules/allergen';
import { fetchSuccess as institutionSubscription } from 'redux/modules/institutionSubscription';
import { fetchSuccess as allergen } from 'redux/modules/patientsAllergen';
import { fetchSuccess as dietanamnez } from 'modules/Dietanamnez/redux/modules/dietanamnez';
import { fetchSuccess as supplementRation } from 'modules/Supplement/redux/modules/supplement';
import { fetchSuccess as supplementRationDish } from 'modules/Supplement/redux/modules/supplementDish';
import { fetchSuccess as supplementAllergen } from 'modules/Supplement/redux/modules/supplementAllergen';
import { fetchSuccess as supplementComposition } from 'redux/modules/supplementComposition';
import { fetchSuccess as supplementTechCard } from 'redux/modules/supplementTechCard';
import { fetchSuccess as partner } from 'redux/modules/partner';
import { fetchSuccess as links } from 'redux/modules/registrationLinks';

const subitemsMap = {
  4: meals,
  5: patients,
  9: notes,
  13: patientParams,
  19: analyses,
  21: analysisGenes,
  22: analysisVitamin,
  24: analysisBlood,
  25: analysisHair,
  27: analysisSalt,
  29: day,
  30: productComposition,
  39: rations,
  47: staff,
  54: techCard,
  57: journal,
  65: dietanamnezItem,
  95: ingestion,
  96: elements,
  99: allergenMeals,
  115: institutionSubscription,
  138: allergen,
  140: dietanamnez,
  158: supplementRation,
  160: supplementRationDish,
  161: supplementComposition,
  164: supplementTechCard,
  167: supplementAllergen,
  192: partner,
  212: links,
};

export default subitemsMap;
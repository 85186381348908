import * as R from 'ramda';

import generator from 'redux/hor/module';

const {
  reducer,
  fetch,
  fetchSuccess,
  save,
  remove,
} = generator({
  name: 'SupplementComposition',
  tableId: 161,
  parseItem: R.evolve({
    ___parent: R.prop('key'),
    custom_элемент: R.prop('key'),
  }),
});

export {
  fetch,
  fetchSuccess,
  save,
  remove,
};

export {
  getItems,
  getItemsByParent,
  getItemsArrayByParent,
} from 'redux/hor/module/selectors';

export {
  getSumComposition,
  getIsHaveComposition,
} from './selectors';

export default reducer;

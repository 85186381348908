import createOrmSelector from 'redux/utils/createOrmSelector';

import { appKey } from 'config';

import getDayKey from '../getDayKey';

const getKey = (state, props) => props.isPatch ? props.itemKey : undefined;

export default createOrmSelector(
  getDayKey,
  (state, props) => props.groupKey,
  getKey,

  (session, dayKey, groupKey, itemKey) => {
    const { Day } = session;

    const dishGroup = Day.withId(dayKey).groups[groupKey].группы_альтернатив[itemKey];

    if (!dishGroup) {
      return null;
    }

    const activeKey = dishGroup.активное.replace('cXaX', appKey);

    return {
      custom_колич: dishGroup.блюда[dishGroup.активное],
      custom_блюдо: activeKey,
    }
  },
);
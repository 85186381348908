import cookie from 'react-cookie';

function getTokenInfo() {
  const token = cookie.load('token');
  const domain = cookie.load('domain');
  const remember = cookie.load('tokenRemember') === 'true';
  const userAKey = cookie.load('userAKey');
  const profileKey = cookie.load('profileKey');

  if (!token) {
    return undefined;
  }

  return {
    token,
    domain,
    remember,
    userAKey,
    profileKey,
  };
}

function saveTokenInfo({ auth }) {
  if (auth.anonymous) {
    return;
  }


  const halfHour = 30 * 60 * 1000;
  const timeLiveToken = 999 * 2 * halfHour;

  const opt = {
    expires: new Date(Date.now() + timeLiveToken),
    path: '/',
  };

  if (auth.token) {
    cookie.save('token', auth.token, opt);
    cookie.save('tokenRemember', auth.remember, opt);
    cookie.save('userAKey', auth.userAKey, opt);
    cookie.save('profileKey', auth.profileKey, opt);

    if (auth.domain) {
      cookie.save('domain', auth.domain, opt);
    }
  } else {
    cookie.remove('token', opt);
    cookie.remove('tokenRemember', opt);
    cookie.remove('userAKey', opt);
    cookie.remove('profileKey', opt);
    cookie.remove('isSupport', opt);
    cookie.remove('APP', opt);
  }
}


export { getTokenInfo, saveTokenInfo };

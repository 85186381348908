import * as R from 'ramda';
import memoizee from 'memoizee';

import { getItemsArray } from 'redux/hor/module/selectors';

export const getRightsList = memoizee(R.compose(
  R.map(item => ({
    key: R.prop('key', item),
    title: R.prop('custom_наименование', item),
  })),
  R.sortBy(R.prop('custom_порядок')),
  getItemsArray,
));

import * as R from 'ramda';
import { createSelector } from 'reselect';

import getInstitutionSubscription from '../getInstitutionSubscription';

export default createSelector(
  getInstitutionSubscription,

  R.propOr(0, 'custom_остаток_бонусных_дней'),
);

import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getInstitutionKey } from 'redux/modules/user';
import getApplicationUser from '../getApplicationUser';

export default createSelector(
  getApplicationUser,
  R.path(['user']),

  (state, props) => props?.settings?.institutionKey,

  (user, state, key) => key || getInstitutionKey(user, state),
);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import Message from 'components/Message';

import styles from './Error.module.css';

const cx = classNames.bind(styles);

export default class Error extends PureComponent {
  static propTypes = {
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
  }

  static defaultProps = {
    error: undefined,
  }

  render() {
    const { error } = this.props;

    return (
      <div className={cx('error')}>
        <Message text={error} {...this.props} />
      </div>
    );
  }
}

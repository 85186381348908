import * as R from "ramda";
import { createSelector } from "reselect";

import { getKeyById } from "redux/modules/patients";

import getPatientId from "../getPatientId";

export default createSelector(
  getPatientId,
  R.path(["patients"]),

  getKeyById
) as unknown as () => string;

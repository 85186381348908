import createOrmSelector from 'redux/utils/createOrmSelector';

import getSupplementKey from 'redux/selectors/getSupplementKey';

export const getSupplementDish = createOrmSelector(
  getSupplementKey,

  (session, supplementKey) => {
    const { SupplementDish } = session;

    return SupplementDish
      .filter({
        ___parent: supplementKey,
      })
      .exclude({
        isDeleting: true,
      })
      .exclude({
        isDeleted: true,
      })
      .toRefArray();
  },
);

import * as R from 'ramda';
import { createAction } from 'redux-act';
import { attr, Model as ORMModel } from 'redux-orm';

import generator from 'redux/hor/orm-module';

const {
  reducer,

  fetchSuccess,
  save,
  remove,
} = generator({
  name: 'patientsElements',
  tableId: 96,
  parseItem: R.evolve({
    ___parent: R.prop('key'),
    custom_элемент_состава: R.prop('key'),
  }),
});

export default class PatientsElements extends ORMModel {
  static modelName = 'PatientsElements';

  static options = {
    idAttribute: 'key',
  }

  static fields = {
    ___parent: attr(),
    custom_элемент_состава: attr(),
  }

  static reducer = reducer;
}

export const updateList = createAction<string[]>('PATIENTSELEMENTS__UPDATE_LIST');
export const updateListSuccess = createAction<string[]>('PATIENTSELEMENTS__UPDATE_LIST_SUCCESS');

reducer.on(updateListSuccess, (Model, session, { parentKey, items }) => {
  Model
    .filter({
      ___parent: parentKey
    })
    .delete();

  items.forEach(item => Model.upsert(item));
});

export {
  fetchSuccess,
  save,
  remove,
};
import * as R from 'ramda';
import { attr, fk, Model } from 'redux-orm';

import generator from 'redux/hor/orm-module';

const {
  reducer,
  fetchSuccess,
  save,
} = generator({
  name: 'analysisVitamin',
  tableId: 22,
  parseItem: R.evolve({
    ___parent: R.propOr(null, 'key'),
    custom_витамин: R.propOr(null, 'key'),
    custom_оценка: R.propOr(null, 'label'),
  }),
});

export default class AnalysisVitamin extends Model {
  static modelName = 'AnalysisVitamin';

  static reducer = reducer;

  static options = {
    idAttribute: 'key',
  }

  static fields = {
    ___parent: fk('PatientAnalysis'),
    custom_витамин: fk('Vitamin'),
    custom_оценка: attr(),
  }
}

export {
  fetchSuccess,
  save,
};

import * as R from 'ramda';
import memoizee from 'memoizee';
import { getItemsArrayByParent } from 'redux/hor/module/selectors';

import { getItemsList } from 'redux/modules/ingredients';
import getKeyInfo from 'utils/getKeyInfo';

const getTechcard = memoizee((key, state, ingredientsState = {}) => {
  const ingredients = getItemsList(ingredientsState);

  return R.compose(
    R.map((item) => {
      const ingredient = R.find(R.propEq('key', item.custom_ингредиент), ingredients);


      return {
        id: getKeyInfo(item.custom_ингредиент).record,
        name: ingredient ? ingredient.custom_наименование : item.custom_ингредиент,
      };
    }),
    getItemsArrayByParent,
  )(key, state);
}, { length: 4 });

export {
  getTechcard,
};

import * as R from "ramda";
import { createSelector } from "reselect";

import { getSelect } from "redux/modules/products";
import getApplicationUser from "../getApplicationUser";

export default createSelector(
  (state) => state,
  getApplicationUser,

  (state, appUser) => getSelect(appUser, "supplement", state)
);

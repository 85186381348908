import createSelector from 'redux/utils/createOrmSelector';

export default createSelector(
  (session) => {
    const { Day } = session;

    return Day
      .filter({
        custom_шаблон_дня: true,
      })
      .toRefArray()
      .map(item => ({
        label: item.custom_название_для_шаблона,
        key: item.key,
      }));
  },
);

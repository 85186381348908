import * as R from 'ramda';

import createOrmSelector from 'redux/utils/createOrmSelector';

import getDayKey from '../getDayKey';

export default createOrmSelector(
  getDayKey,

  (session, key) => {
    const { Day } = session;

    const groups = R.values(Day.withId(key).groups);

    return R.any(group => !group.группы_альтернатив.length, groups);
  },
);

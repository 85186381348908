import { AxiosRequestHeaders } from "axios";
import * as R from "ramda";

type Params = {
  view?: any;
  filter?: any;
  token?: any;
  button?: any;
  limit?: any;
};

const formatHeaders = ({
  view,
  filter,
  token,
  button,
  limit,
}: Params): AxiosRequestHeaders =>
  R.pickBy(R.complement(R.isNil), {
    view,
    token,
    button,
    limit,
    filter,
    filterstate: "remake",
  });

export default formatHeaders;

import * as R from 'ramda';
import moment from 'moment';

import { appKey } from 'config';

import { getItemsArrayByParent } from 'redux/hor/module/selectors';

const ids = {
  massBody: 137,
  calories: 169,
  caloriesActive: 3201,
  carbohydrates: 3200,
  proteins: 3198,
  fats: 3199,
  activeCellMass: 153,
  skinnyWeight: 152,
  muscleMass: 147,
  intracellularFluid: 151,
  extracellularFluid: 150,
  generalLiquid: 149,
  fatMass: 146,
  growth: 140,
  sizeShoulder: 144,
  sizeThigh: 143,
  sizeWaist: 142,
  pleatsStomach: 157,
  pleatsHips: 158,
  pleatsChest: 156,
  pleatsChestWoman: 159,
  harris: 6440796,
  visceralFat: 154,
};

const values = R.map(item => `${appKey}t12r${item}`, ids);

const units = {
  massBody: 'кг',
  calories: 'ккал',
  caloriesActive: 'г',
  carbohydrates: 'г',
  proteins: 'г',
  fats: 'г',
  activeCellMass: 'кг',
  visceralFat: '',
  skinnyWeight: 'кг',
  muscleMass: 'кг',
  intracellularFluid: 'л',
  extracellularFluid: 'л',
  generalLiquid: 'л',
  fatMass: 'кг',
  growth: 'см',
  sizeShoulder: 'см',
  sizeThigh: 'см',
  sizeWaist: 'см',
  pleatsStomach: 'мм',
  pleatsHips: 'мм',
  pleatsChest: 'мм',
  pleatsChestWoman: 'мм',
  harris: 'ккал',
};

export const getDateToChart = R.uncurryN(3, param => R.compose(
  R.sortBy(R.prop('date')),
  R.map(item => ({
    date: new Date(item.custom_дата),
    weight: item.custom_значение,
    unit: units[param] || 'кг',
  })),
  R.uniqWith(({ custom_дата: left }, { custom_дата: right }) => moment(left).isSame(right, 'day')),
  R.reverse,
  R.filter(R.propEq('custom_показатель', values[param])),
  R.reject(R.propEq('custom_значение', 0)),
  getItemsArrayByParent,
));

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class Triangle extends PureComponent {
  static propTypes = {
    height: PropTypes.number,
    triangles: PropTypes.array,
    x: PropTypes.func,
  }

  static defaultProps = {
    height: 0,
    triangles: [],
    x: () => {
    },
  }

  render() {
    const { height, triangles, x } = this.props;

    return (
      <g>
        {triangles.map((norm, index) => (
          <g
            key={index}
            transform={`translate(${x(norm) + (index === 0 ? 3 : -3)},${height / 2})
              rotate(${index === 0 ? 90 : -90})`}
          >
            {norm !== 0 &&
              <path
                d='M0,-4 L4, 3 -4, 3Z'
                fill='black'
                stroke='black'
              />
            }
          </g>
        ))}
      </g>
    );
  }
}

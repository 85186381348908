import * as R from 'ramda';
import { createSelector } from 'reselect';

import getSupplementWithStatus from '../getSupplementWithStatus';

export default createSelector(
  getSupplementWithStatus,

  R.groupBy(R.prop('custom_производитель')),
);

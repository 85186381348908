import * as R from 'ramda';
import { createSelector } from 'reselect';
import { appKey } from 'config';

import getElementsVisible from '../getElementsVisible';

const baseElements = [
  `${appKey}t28r219`,
  `${appKey}t28r221`,
  `${appKey}t28r222`,
  `${appKey}t28r223`,
];

export default createSelector(
  getElementsVisible,

  R.reject(item => R.contains(item.key, baseElements)),
);

import { combineReducers } from 'redux';
import { all, call } from 'redux-saga/effects';

import allergen from './modules/allergen';
import correction from './modules/correction';
import ingestion from './modules/ingestion';
import mode from './modules/mode';
import ui from './modules/ui';

import daysSaga from './modules/day/saga';
import rationSaga from './modules/ration/saga';
import patientsElementsSaga from './modules/patientsElements/saga';

export default combineReducers({
  allergen,
  correction,
  ingestion,
  mode,
  ui,
});

export function* saga() {
  yield all([
    call(daysSaga),
    call(rationSaga),
    call(patientsElementsSaga),
  ]);
}

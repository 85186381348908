import generator from 'redux/hor/module';

import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,
  fetch,
} = generator({
  name: 'PatientActivitySport',
  tableId: 16,
});

export const fetchList = fetchWithView(fetch, 'ts5c16cs9r29', {
  useLS: true,
});

export {
  fetch,
};

export {
  getItems,
} from './selectors';

export default reducer;

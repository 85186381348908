import { attr, Model } from 'redux-orm';

import generator from 'redux/hor/orm-module';

const {
  reducer,
  fetch,
} = generator({
  name: 'Webinar',
  tableId: 206,
});

export default class Webinar extends Model {
  static modelName = 'Webinar';

  static options = {
    idAttribute: 'key',
  };

  static fields = {
    custom_дата: attr(),
    custom_заголовок: attr(),
    custom_ссылка_на_запись: attr(),
    custom_ссылка_на_онлайн	: attr(),
  }

  static reducer = reducer;

  toString() {
    return `Webinar: ${this.key}`;
  }
}

export const fetchList = () => fetch();

export {
  fetch,
};

import * as R from 'ramda';
import { createSelector } from 'reselect';

import getInstitution from '../getInstitution';

export default createSelector(
  getInstitution,

  R.compose(
    Boolean,
    R.prop('custom_recurringinvoiceid'),
  ),
) as () => boolean;

import * as R from 'ramda';
import { createSelector } from 'reselect';

import getUser from '../getUser';

export default createSelector(
  getUser,

  R.propOr(null, 'custom_login'),
);

import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import styles from './Grid.module.css';

const cx = classNames.bind(styles);

const maxSize = {
  small: 4,
  medium: 6,
  big: 8,
};


export default class Column extends Component {
  static propTypes = {
    big: PropTypes.oneOf(R.range(0, maxSize.big + 1)),
    children: PropTypes.node,
    indent: PropTypes.bool,
    borderRight: PropTypes.bool,
    medium: PropTypes.oneOf(R.range(0, maxSize.medium + 1)),
    offsetBig: PropTypes.oneOf(R.range(0, maxSize.big + 1)),
    offsetMedium: PropTypes.oneOf(R.range(0, maxSize.medium + 1)),
    offsetSmall: PropTypes.oneOf(R.range(0, maxSize.small + 1)),
    order: PropTypes.number,
    small: PropTypes.oneOf(R.range(0, maxSize.small + 1)),
  };

  static defaultProps = {
    big: undefined,
    children: undefined,
    indent: undefined,
    medium: undefined,
    offsetBig: undefined,
    offsetMedium: undefined,
    offsetSmall: undefined,
    order: undefined,
    small: undefined,
  };

  static contextTypes = {
    level: PropTypes.string,
  }

  getOrderByLevel() {
    const { level } = this.context;

    return this.props[`${level}Order`] || this.props.order;
  }

  render() {
    const {
      small,
      medium,
      big,
      offsetSmall,
      offsetMedium,
      offsetBig,
      indent,
      borderRight,
    } = this.props;
    const order = this.getOrderByLevel();
    const style = order ? {
      order,
      msFlexOrder: order,
    } : {};

    return (
      <div
        className={cx({
          column: true,
          [`column_small_${small}`]: isFinite(small),
          [`column_medium_${medium}`]: isFinite(medium),
          [`column_big_${big}`]: isFinite(big),
          [`column_offsetSmall_${offsetSmall}`]: isFinite(offsetSmall),
          [`column_offsetMedium_${offsetMedium}`]: isFinite(offsetMedium),
          [`column_offsetBig_${offsetBig}`]: isFinite(offsetBig),
          column_indent: indent,
          column_borderRight: borderRight,
        })}
        style={style}
      >
        {this.props.children}
      </div>
    );
  }
}

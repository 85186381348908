import * as R from 'ramda';
import { put, all, takeEvery } from 'redux-saga/effects';

import isLocalKey from 'utils/isLocalKey';

import { save } from './';
import { save as saveDietanamnezes } from '../dietanamnez';

function* saveFlow(action) {
  const key = R.pathOr(null, ['data', '___parent'], action.payload);

  if (isLocalKey(key)) {
    yield put(saveDietanamnezes({
      key,
    }));
  }
}

export default function* saga() {
  yield all([
    takeEvery(save, saveFlow),
  ]);
}

import { all, call } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import activityLife from './activityLife';
import activitySport from './activitySport';
import activityChild from './activityChild';
import disease from './disease';
import illness from './illness';
import notes, { saga as notesSaga } from './notes';
import inbody, { saga as inbodySaga } from './inbody';
import medass, { saga as medassSaga } from './medass';
import notifications from './notifications';
import notificationsExcluded from './notificationsExcluded';

import { saga as patientReceptionsSaga } from './receptions';

export default combineReducers({
  activityLife,
  activitySport,
  activityChild,
  disease,
  illness,
  notes,
  notifications,
  notificationsExcluded,
  inbody,
  medass,
});

export function* saga() {
  yield all([
    call(notesSaga),
    call(inbodySaga),
    call(medassSaga),
    call(patientReceptionsSaga),
  ]);
}

import React from "react";
import ReactDOM from "react-dom";
import { syncHistoryWithStore } from "react-router-redux";
import { browserHistory } from "react-router";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import { sentry, version } from "config";
import moment from "moment";
import "moment/locale/ru";

import Root from "containers/Root";

import { setStore } from "utils/api/request";
import { getTokenInfo } from "utils/cookie/token";
import { initLocalStorage } from "utils/api/utils/localStorage";

import createStore from "./redux/create";
import routes from "./routes";

import "./model/init";
import { updateStore } from "model";

import "./styles/base.css";
import "./styles/variables.css";

if (process.env.REACT_APP_API === "dd") {
  import("./styles/variables.dd.css");
}

moment.locale("ru");

Sentry.init({
  dsn: sentry,
  release: version,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  denyUrls: [/cdn\.carrotquest\.io/],
  beforeSend: (event) => {
    if (process.env.NODE_ENV !== "production") {
      console.log("EVENT", event);
      return null;
    }

    return event;
  },
});

const render = (Component, store, history, rs) => {
  ReactDOM.render(
    <Component store={store} history={history} routes={rs} />,
    document.getElementById("content"),
    () => {
      document.documentElement.classList.add("html");
    }
  );
};

if (window.isSupported) {
  const store = createStore(browserHistory, {
    auth: getTokenInfo(),
  });

  store.subscribe(() => {
    const state = store.getState();
    updateStore(state);
  });

  initLocalStorage();
  setStore(store);
  const history = syncHistoryWithStore(browserHistory, store);

  render(Root, store, history, routes);
}

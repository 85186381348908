import * as R from 'ramda';
import { createSelector } from 'reselect';

import getMealsList from '../getMealsList';

export default createSelector(
  getMealsList,

  R.indexBy(R.prop('key')),
);

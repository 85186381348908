import * as R from 'ramda';
import { createSelector } from 'reselect';

import getDietanamnez from '../getDietanamnez';

export default createSelector(
  getDietanamnez,

  R.propOr(false, 'isAssigning'),
);

import * as R from 'ramda';
import memoize from 'memoizee';
import moment from 'moment';

const formatItem = (item) => {
  const [lastname, firstname, midname] = item.custom_диетолог.label.split(' ');

  return {
    author: item.custom_диетолог.key,
    user: {
      lastname,
      firstname,
      midname,
    },
    patients: {
      all: R.defaultTo(0, item.custom_пациенты),
      added: R.defaultTo(0, item.custom_пациенты_за_день),
    },
    reception: {
      all: R.defaultTo(0, item.custom_приемов_всего),
      added: R.defaultTo(0, item.custom_приемов_за_день),
    },
    analysis: {
      all: R.defaultTo(0, item.custom_анализы_всего),
      added: item.custom_анализы_за_день___добавлено - item.custom_анализы_за_день___удалено,
    },
    anamnesis: {
      all: R.defaultTo(0, item.custom_диетанамнезы_всего),
      added: R.defaultTo(0, item.custom_диетанамнезы_за_день),
    },
    rations: {
      all: R.defaultTo(0, item.custom_рационы_всего),
      added: item.custom_рационы_за_день___добавлено - item.custom_рационы_за_день___удалено,
    },
    print: {
      all: item.custom_печать_всего,
      added: item.custom_печать_за_день,
    },
  };
};

const dayFields = [
  'custom_пациенты_за_день',
  'custom_приемов_за_день',
  'custom_анализы_за_день___добавлено',
  'custom_анализы_за_день___удалено',
  'custom_диетанамнезы_за_день',
  'custom_рационы_за_день___добавлено',
  'custom_рационы_за_день___удалено',
  'custom_печать_за_день',
];
const evolveConfig = R.reduce((acc, item) => ({
  ...acc,
  [item]: R.always(0),
}), {}, dayFields);
const getPrevData = item => R.evolve({
  custom_пациенты: R.subtract(R.__, item.custom_пациенты_за_день),
  custom_приемов_всего: R.subtract(R.__, item.custom_приемов_за_день),
  custom_диетанамнезы_всего: R.subtract(R.__, item.custom_диетанамнезы_за_день),
  custom_печать_всего: R.subtract(R.__, item.custom_печать_за_день),
  custom_анализы_всего: R.subtract(
    R.__,
    item.custom_анализы_за_день___добавлено - item.custom_анализы_за_день___удалено,
  ),
  custom_рационы_всего: R.subtract(
    R.__,
    item.custom_рационы_за_день___добавлено - item.custom_рационы_за_день___удалено,
  ),
}, item);
const initialGroup = {
  all: 0,
  added: 0,
};

const getDayStatistic = memoize(
  (state, date, institution) => R.compose(
    R.map(formatItem),
    R.map((item) => {
      const isCurrent = moment(item.custom_data, moment.ISO_8601).format('DD.MM.YYYY') === date;

      if (isCurrent) {
        return item;
      }

      return R.evolve(evolveConfig, item);
    }),
    R.map(R.path(['max', 'fields'])),
    R.filter(R.pathEq(['max', 'fields', 'custom_учреждение_оригинальное', 'key'], institution)),
    R.propOr([], 'items'),
  )(state),
  { max: 1 },
);

const mergeByUser = (start, end) =>
  R.compose(
    R.merge(end),
    R.mapObjIndexed((val, key) => ({
      all: val.all,
      added: val.all - R.pathOr(0, [key, 'all'], start),
    })),
    R.pick([
      'patients',
      'reception',
      'analysis',
      'anamnesis',
      'rations',
      'print',
    ]),
  )(end);

const getMonthStatistic = memoize(
  (state, institution) => R.compose(
    R.reject(R.isNil),
    R.map(({ max, min }) => {
      if (!min && !max) {
        return null;
      }

      const prevData = getPrevData(min ? min.fields : max.fields);

      return mergeByUser(formatItem(prevData), formatItem(max.fields));
    }),
    R.filter(R.pathEq(['max', 'fields', 'custom_учреждение_оригинальное', 'key'], institution)),
    R.propOr([], 'items'),
  )(state),
  { max: 1 },
);

export const getEndDate = (date) => {
  const startOfMonth = moment(date, 'MM.YYYY');
  const endOfMonth = startOfMonth.endOf('month');
  const yesterday = moment().add(-1, 'day');
  return endOfMonth.isBefore(yesterday) ? endOfMonth : yesterday;
};

export const getStatisticByInstitution = (state, date, institution) => {
  if (date.length !== 7) {
    return getDayStatistic(state, date, institution);
  }

  const startOfMonth = moment(date, 'MM.YYYY');
  const endOfMonth = startOfMonth.endOf('month');
  const yesterday = moment().add(-1, 'day');

  if (endOfMonth.isAfter(yesterday) && !endOfMonth.isSame(yesterday, 'month')) {
    return null;
  }

  return getMonthStatistic(state, institution);
};

const mergeTotal = R.reduce((resultSum, tableTrValue) => R.mapObjIndexed((value, key) => {
  return {
    all: value.all + R.pathOr(0, [key, 'all'], tableTrValue),
    added: value.added + R.pathOr(0, [key, 'added'], tableTrValue),
  }
}, resultSum), {
  patients: initialGroup,
  reception: initialGroup,
  analysis: initialGroup,
  anamnesis: initialGroup,
  rations: initialGroup,
  print: initialGroup,
});

export const getStatisticTotal = memoize(
  table => R.isEmpty(table) ? {} : ({
    user: 'Всего',
    ...mergeTotal(table),
  }),
  { max: 1 },
);

export const getStatisticData = (state, date, institution) => {
  if (institution !== 'all') {
    return getStatisticByInstitution(state, date, institution);
  }

  return R.compose(
    R.reject(R.isNil),
    R.map(({ key, label, inviteKey }) => {
      const data = R.compose(
        getStatisticTotal,
        getStatisticByInstitution,
      )(state, date, key);
      if (R.isEmpty(data)) {
        return null;
      }
      return {
        ...data,
        user: {
          firstname: label.replace(/Lite /g, ''), // TODO костыль пока нет бэка. По идее ничего не нужно
          inviteKey
        },
      };
    }),
    R.uniqBy(R.prop('key')),
    R.reject(R.isNil),
    R.map(R.path(['max', 'fields', 'custom_учреждение_оригинальное'])),
    R.propOr([], 'items'),
  )(state);
};

export const getIsUserCanSee = R.compose(
  R.lt(1),
  R.length,
  R.uniqBy(R.prop('key')),
  R.reject(R.isNil),
  R.map(R.path(['max', 'fields', 'custom_учреждение_оригинальное'])),
  R.propOr([], 'items'),
);

import * as R from 'ramda';
import createOrmSelector from 'redux/utils/createOrmSelector';

import getPatientNorm from 'redux/selectors/getPatientNorm';
import { getVisibleList } from 'redux/modules/elements/selectors';

import getDietanamnezKey from '../getDietanamnezKey';

const merge = R.mergeWith(R.merge);

export default createOrmSelector(
  getDietanamnezKey,
  getPatientNorm,
  getVisibleList,

  (session, dietanamnezKey, patientNorm, elements) => {
    if (!dietanamnezKey) {
      return [];
    }

    const { Dietanamnez } = session;

    const items = (Dietanamnez
      .withId(dietanamnezKey)
      .custom_профиль_потребления_веществ || [])
      .map((item) => {
        try {
          const element = elements.find(element => element.key === item.элемент_состава);

          return {
            key: item.key,
            element: element.key,
            data: item.среднее_за_день,
            dataWithoutSupplement: item.среднее_за_день_без_бад,
            label: element.custom_наименование,
            sources: element.custom_источники,
            influence: element.custom_влияние,
          };
        } catch (e) {
          return null;
        }
      })
      .filter(item => !!item);
    const chartItems = R.indexBy(R.prop('element'), items);

    return merge(chartItems, patientNorm);
  },
);

import * as R from 'ramda';
import { createSelector } from 'reselect';

import getPatientIllness from '../getPatientIllness';
import getDiseaseList from '../getDiseaseList';
import getIllnessList from '../getIllnessList';
import getPatientSpecificConditions from '../getPatientSpecificConditions';

export default createSelector(
  getPatientSpecificConditions,
  getPatientIllness,
  getIllnessList,
  getDiseaseList,

  (specificConditions, patientIllness, illnessList, diseaseList) => {
    const illness = R.compose(
      R.pluck('label'),
      R.map(item => R.find(R.propEq('value', item), illnessList)),
    )(patientIllness);
    const disease = R.compose(
      R.pluck('label'),
      R.map(item => R.find(R.propEq('value', item), diseaseList)),
    )(specificConditions);

    return [...illness, ...disease];
  },
);

import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import Field from 'components/Field';
import Icon from 'components/Icon';

import styles from './FieldPassword.module.css';

const cx = classNames.bind(styles);

export default class FieldPassword extends PureComponent {
  static propTypes = {
    value: PropTypes.string,
  }

  state = {
    type: 'password',
  }

  handleTypeChange = () => {
    this.setState(({ type }) => ({
      type: type === 'password' ? 'text' : 'password',
    }));
  }

  render() {
    return (
      <div className={cx('field')}>
        <Field
          {...this.props}
          name={null}
          type={this.state.type}
        />
        {this.props.value &&
          <button type='button' className={cx('icon')} onClick={this.handleTypeChange}>
            <Icon icon={this.state.type !== 'password' ? 'eye' : 'eye-crossed'} width={24} height={24} />
          </button>
        }
      </div>
    );
  }
}

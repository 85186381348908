import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import RowsTable from '../../components/RowsTable';

const tableTitles = ['Витамин', 'Оценка', 'Рекомендации'];

export default class VitaminsAnalys extends PureComponent {
  static propTypes = {
    data: PropTypes.array,
    itemSelectDictionary: PropTypes.array,
  }

  render() {
    const {
      data,
      itemSelectDictionary,
    } = this.props;

    return (
      <RowsTable
        rows={data}
        titles={tableTitles}
        selectList={itemSelectDictionary}
        vitaminsAnalys
      />
    );
  }
}

import * as R from 'ramda';
import moment from 'moment';
import { isValidPhoneNumber } from "react-phone-number-input"

import { timeToMinutes } from 'utils/time';

import simplePasswords from '../simplePasswords';

const isEmpty = value => value === undefined || value === null || value === '';

function email() {
  return value => isEmpty(value) || /^[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(value);
}

function date() {
  return value => isEmpty(value) || moment(value).isValid();
}

function minDate(config) {
  const minValue = R.propOr(null, 'limiter', config);

  return value => moment(value).isSameOrAfter(minValue);
}
function maxDate(config) {
  const maxValue = R.propOr(null, 'limiter', config);

  return value => moment(value).isSameOrBefore(maxValue);
}
function time() {
  return value => isEmpty(value) || timeToMinutes(value) <= 1440;
}

function timeFilled() {
  return value => isEmpty(value) || /\d{2}:\d{2}/.test(value);
}

function locate() {
  return value => /^[A-ZА-ЯЁа-яё-]*$/i.test(R.trim(value || ''));
}

function locateNum() {
  return value => /^[0-9A-ZА-ЯЁа-яё]*$/i.test(R.trim(value || ''));
}

function createNumberValidator(type) {
  return (value) => {
    const parsedValue = typeof value === 'string' ? value.replace(',', '.') : value;
    const number = parseFloat(parsedValue);

    let comparator = number >= 0;
    switch (type) {
      case 'positive':
        comparator = number > 0;
        break;
      case 'natural':
        comparator = number >= 0;
        break;
      default:
        comparator = true;
    }

    return isEmpty(value) || (/^[0-9.]*$/i.test(parsedValue) && isFinite(number) && comparator);
  };
}

function numeric() {
  return createNumberValidator('natural');
}

function positiveNumber() {
  return createNumberValidator('positive');
}

function required() {
  return value => !isEmpty(R.trim(!R.isNil(value) ? value.toString() : ''));
}

function nonRemovable() {
  return (value, _, initialValue) => !initialValue || value;
}

function min(config) {
  const limiter = R.propOr(null, 'limiter', config);
  return value => isEmpty(value) || !limiter || value.length >= limiter;
}

function max(config) {
  const limiter = R.propOr(null, 'limiter', config);
  return value => isEmpty(value) || !limiter || value.length <= limiter;
}

function phone() {
  return value => {
    return isEmpty(value) || isValidPhoneNumber(value);
  };
}

function pass() {
  return value => /^[0-9A-Z$@$!%*#?&]+$/i.test(value);
}

function simplePass() {
  return value => !R.contains(value, simplePasswords);
}

function typeFiles(config) {
  const correctTypes = R.propOr(null, 'correctTypes', config);

  return (file = {}) => {
    const mime = R.propOr(null, 'mime', file);

    return !correctTypes || !mime || R.contains(mime, correctTypes);
  };
}

function sizeFiles(config) {
  const correctSize = R.propOr(null, 'correctSize', config);

  return file => !correctSize || correctSize > R.pathOr(0, ['base64', 'length'], file);
}

function custom(config) {
  return (value, values) => config.validator(value, values);
}

function unique(config) {
  const formatValue = R.compose(
    R.replace(/["«», .\-—]/g, ''),
    R.toLower,
    R.defaultTo(''),
  );

  const list = R.compose(
    R.map(formatValue),
    R.propOr([], 'list'),
  )(config);

  return value => !R.includes(formatValue(value), list);
}

const validators = {
  email,
  date,
  minDate,
  maxDate,
  locate,
  numeric,
  positiveNumber,
  min,
  max,
  required,
  nonRemovable,
  phone,
  locateNum,
  pass,
  time,
  timeFilled,
  simplePass,
  typeFiles,
  sizeFiles,
  custom,
  unique,
};

export default validators
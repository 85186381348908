import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';

import styles from './Icon.module.css';

const cx = classNames.bind(styles);

export default class Icon extends PureComponent {
  static propTypes = {
    height: PropTypes.number,
    icon: PropTypes.string,
    iconContent: PropTypes.string,
    width: PropTypes.number,
  };

  static defaultProps = {
    height: undefined,
    icon: undefined,
    iconContent: undefined,
    width: undefined,
  };

  render() {
    const { width, height, iconContent } = this.props;
    let icon;
    if (iconContent) {
      icon = iconContent;
    } else {
      try {
        icon = require(`!raw-loader!./icons/${this.props.icon}.svg`); // eslint-disable-line global-require
      } catch (err) {
        return null;
      }
    }

    return (
      <span
        style={{ width, height }}
        className={cx('icon')}
        dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
          __html: icon,
        }}
      />
    );
  }
}

import * as R from 'ramda';
import { createSelector } from 'reselect';

import getSubscriptionPromocode from '../getSubscriptionPromocode';

export default createSelector(
  getSubscriptionPromocode,

  R.compose(
    R.defaultTo(0),
    R.propOr(0, 'discount'),
  ),
);

import * as R from 'ramda';

export default function capitalize(string = '') {
  if (R.isEmpty(string)) {
    return string;
  }

  const [first, ...rest] = string;

  return R.compose(
    val => val + rest.join(''),
    R.toUpper,
  )(first);
}

import * as R from 'ramda';

import generator from 'redux/hor/module';

const {
  reducer,

  fetchSuccess,
  create,
} = generator({
  name: 'patientParams',
  tableId: 13,
  parseItem: R.evolve({
    ___parent: R.propOr(null, 'key'),
    custom_показатель: R.propOr(null, 'key'),
  }),
});

export {
  fetchSuccess,
  create,
};

export {
  getDateToChart,
} from './selectors';

export default reducer;

import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getItems } from 'modules/Patient/redux/notificationsExcluded';
import getApplicationUser from '../getApplicationUser';

export default createSelector(
  getApplicationUser,
  R.path(['Patient', 'notificationsExcluded']),

  getItems,
);

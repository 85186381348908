import * as R from 'ramda';
import { attr, fk, Model } from 'redux-orm';

import generator from 'redux/hor/orm-module';
import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,
  fetch,
} = generator({
  name: 'DietanamnezTiles',
  tableId: 49,
  parseItem: (el => {
    const categoryName = el.___parent ? el.___parent.name : "";
    return R.assoc("categoryName", categoryName, R.evolve({
      ___parent: R.propOr(null, 'key'),
      custom_специфические_исключения: R.compose(
        R.pluck('key'),
        R.defaultTo([]),
      ),
      custom_ед_изм: R.propOr(null, 'label'),
      custom_формат: R.propOr(null, 'value'),
      custom_версия_приложения: R.propOr(null, 'value'),
    })(el))
  }
  )
})

export const fetchList = fetchWithView(fetch, 'ts5c49cs9r104', {
  useLS: true,
});

export default class DietanamnezTiles extends Model {
  static modelName = 'DietanamnezTiles';

  static reducer = reducer;

  static options = {
    idAttribute: 'key',
  }

  static fields = {
    ___parent: fk('DietanamnezTiles', 'items'),
    categoryName: attr(),
    custom_наименование: attr(),
    custom_иконка_url: attr(),
    custom_специфические_исключения: attr(),
    custom_ед_изм: attr(),
    custom_частота_употребления: attr(),
    custom_количество: attr(),
    custom_добавить_исключить: attr(),
    custom_картинка_с_порциями_url: attr(),
    custom_light: attr(),
    custom_формат: attr(),
    custom_версия_приложения: attr(),
  }
}

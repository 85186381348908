import * as R from 'ramda';
import { take, race } from 'redux-saga/effects';

export default function* waitFor(action) {
  const successType = `${action.type}_SUCCESS`;
  const failureType = `${action.type}_FAILURE`;

  while (true) {
    const [success, failure] = yield race([
      take(successType),
      take(failureType),
    ]);

    const isSameKey = R.eqBy(R.path(['payload', 'key']));
    const isSameView = R.eqBy(R.path(['payload', 'view']));
    if (!R.pathOr(false, ['payload', 'isLocal'], success)) {
      if (isSameKey(action, success) && isSameView(action, success)) {
        return success;
      }

      if (isSameKey(failure) && isSameView(action, failure)) {
        throw failure;
      }
    }
  }
}

import * as R from 'ramda';
import { createSelector } from 'reselect';

import getUserJournal from '../getUserJournal';
import getUserPatients from '../getUserPatients';

export default createSelector(
  getUserJournal,
  getUserPatients,

  (journal, patients) =>
    R.compose(
      patientKey => R.find(R.propEq('key', patientKey), patients),
      R.propOr(null, 'patient'),
      R.head,
    )(journal),
);

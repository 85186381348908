import * as R from "ramda";

import generator from "redux/hor/module";
import fetchWithView from "redux/utils/fetchWithView";

const { reducer, fetch } = generator({
  name: "normPregnant",
  tableId: 121,
  parseItem: R.evolve({
    ___parent: R.propOr(null, "key"),
    custom_страна: R.propOr(null, "key"),
    custom_период: R.propOr(null, "label"),
    custom_группа_физической_активности: R.propOr(null, "label"),
  }),
});

export const fetchList = fetchWithView(fetch, "ts5c121cs9r396");

export { fetch };

export default reducer;

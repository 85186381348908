import { createSelector } from 'reselect';

import getInstitutionRationTemplates from '../getInstitutionRationTemplates';
import getRationSystemTemplates from '../getRationSystemTemplates';
import getRationGeneratorTemplates from '../getRationGeneratorTemplates';
import getUserRationTemplates from '../getUserRationTemplates';

export default createSelector(
  (state, props) => props.type,
  getInstitutionRationTemplates,
  getRationSystemTemplates,
  getUserRationTemplates,
  getRationGeneratorTemplates,

  (type, institutionTemplates, commonTemplates, personalTemplates, generatorTemplates) => {
    const templatesMap = {
      common: commonTemplates,
      personal: personalTemplates,
      institution: institutionTemplates,
      generator: generatorTemplates,
    };

    return templatesMap[type];
  },
);

import * as R from 'ramda';

import { takeEvery, all, put, select } from 'redux-saga/effects';

import {
  getItemByKey,
} from 'redux/selectors';

import {
  startVisit,
  switchArchive,
  logPrint,
} from 'redux/modules/patients';

import {
  save as saveDietanamnezes,
} from 'modules/Dietanamnez/redux/modules/dietanamnez';

import {
  assign as assignRation,
} from 'modules/Rations/redux/modules/ration';

import {
  create,
} from './index';

function* startVisitFlow(action) {
  const { meta } = action;

  yield put(create({
    data: {
      ___parent: meta.userKey,
      custom_пациент: action.payload.key,
      custom_дата: Date.now(),
      custom_действие: 'Прием',
    },
  }));
}

function* switchArchiveFlow(action) {
  const { meta } = action;
  const patient = yield select(state => getItemByKey(action.payload.key, state));
  const isInArchive = R.propOr(false, 'custom_в_архив_1', patient);

  yield put(create({
    data: {
      ___parent: meta.userKey,
      custom_пациент: action.payload.key,
      custom_дата: Date.now(),
      custom_действие: isInArchive ? 'Возвращен из архива' : 'Перенесен в архив',
    },
  }));
}

function* logPrintFlow(action) {
  const { meta } = action;

  yield put(create({
    data: {
      ___parent: meta.userKey,
      custom_пациент: action.payload.key,
      custom_дата: Date.now(),
      custom_действие: 'Печать',
    },
  }));
}

function* saveDietanamnezesFlow(action) {
  const { meta } = action;
  const isAssign = !!R.pathOr(null, ['data', 'custom_дата_назначения'], action.payload);

  if (isAssign) {
    return;
  }

  yield put(create({
    data: {
      ___parent: meta.userKey,
      custom_пациент: meta.patientKey,
      custom_дата: Date.now(),
      custom_действие: 'Диетанамнез',
    },
  }));
}

function* assignRationFlow(action) {
  const { meta } = action;

  yield put(create({
    data: {
      ___parent: meta.userKey,
      custom_пациент: meta.patientKey,
      custom_дата: Date.now(),
      custom_действие: 'Рацион',
    },
  }));
}

export default function* saga() {
  yield all([
    takeEvery(startVisit, startVisitFlow),
    takeEvery(switchArchive, switchArchiveFlow),
    takeEvery(logPrint, logPrintFlow),
    takeEvery(saveDietanamnezes, saveDietanamnezesFlow),
    takeEvery(assignRation, assignRationFlow),
  ]);
}

import * as R from 'ramda';
import { attr, Model } from 'redux-orm';

import generator from 'redux/hor/orm-module';
import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,

  fetch,
} = generator({
  name: 'SubscriptionDay',
  tableId: 119,
  parseItem: R.evolve({
    ___parent: R.prop('key'),
  }),
});

export default class SubscriptionDay extends Model {
  static modelName = 'SubscriptionDay';

  static options = {
    idAttribute: 'key',
  }

  static fields = {
    ___parent: attr(),
    custom_название: attr(),
    custom_стоимость: attr(),
    custom_стоимость_исходная: attr(),
    custom_количество_месяцев_подписки: attr(),
  }

  static reducer = reducer;
}

export const fetchList = fetchWithView(fetch, 'ts5c119cs9r401', {
  useLS: true,
});

export {
  fetch,
};

import createOrmSelector from 'redux/utils/createOrmSelector';

import getDietanamnezKey from '../getDietanamnezKey';

export default createOrmSelector(
  getDietanamnezKey,

  (session, dietanamnezKey) => {
    const { Dietanamnez } = session;

    try {
      const dietamanez = Dietanamnez.withId(dietanamnezKey);

      return dietamanez.custom_профиль_потребления_продуктов.map(item => ([
        item.наименование,
        Math.round(item.среднее * 100) / 100,
      ]));
    } catch (error) {
      return [];
    }
  },
);

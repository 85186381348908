import * as R from 'ramda';
import { createSelector } from 'reselect';

import getRationColumns from '../getRationColumns';

export default createSelector(
  getRationColumns,

  R.compose(
    R.sum,
    R.pluck('width'),
  ),
);

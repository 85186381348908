import * as R from 'ramda';
import moment from 'moment';
import { all, takeEvery, put, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { reset } from 'redux-form';

import {
  getPatientId,
  getUserPatientReceptions,
} from 'redux/selectors';
import { setNotification } from 'redux/modules/notification';
import {
  saveSuccess,
} from './index';

function* nextReceptionNotificationFlow() {
  const hasNotification = yield select(R.pathOr('false', ['notification', 'isNextReception']));
  const receptionList = yield select(state =>
    getUserPatientReceptions(state, {
      startDate: moment().toISOString(),
      endDate: moment().add(1, 'day').toISOString(),
      custom_отключено: false,
    }));
  if (hasNotification && !receptionList.length) {
    yield put(setNotification({
      type: 'global',
      isNextReception: false,
    }));
  } else if (receptionList.length) {
    yield put(setNotification({
      type: 'global',
      isNextReception: {
        receptionKey: receptionList[0].key,
      },
    }));
  }
}

function* saveSuccessFlow({ payload: { form } }) {
  // There are two forms, one on patient info page and one on calendar
  // Currently only the former uses URL to open modal,
  // so redirect to "parent" page after save success
  yield put(reset(form));

  if (form === 'profile_patient_reception_form') {
    const patientId = yield select(getPatientId);
    yield put(push(`/patients/${patientId}`));
  }
  // After closing a notification next one should appear if exists
  if (form === 'patient_reception_notification_form') {
    yield nextReceptionNotificationFlow();
  }
}

export default function* saga() {
  yield all([
    takeEvery('LOADING_FINISH', nextReceptionNotificationFlow),
    takeEvery(saveSuccess, saveSuccessFlow),
  ]);
}

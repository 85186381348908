import React, { PureComponent } from 'react';
import { Field } from 'redux-form';
import { withReduxForm } from 'redux-form/es/ReduxFormContext';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import Editor from './Editor';

class EditorConnected extends PureComponent {
  static contextTypes = {
    onFormChange: PropTypes.func,
  };

  static propTypes = {
    name: PropTypes.string,
    onBlur: PropTypes.func,
  };

  static defaultProps = {
    name: undefined,
    onBlur: () => { },
  };

  handleBlur = onBlur => (event) => {
    onBlur(event);

    this.props.onBlur();

    if (this.context.onFormChange) {
      this.context.onFormChange();
    }
  };

  renderField = (field = {}) => (
    <Editor
      {...this.props}
      {...field.input}
      {...field.meta}
      onBlur={this.handleBlur(field.input.onBlur)}
    />
  );

  render() {
    const { _reduxForm: reduxForm } = this.props;

    if (!reduxForm || !R.includes(this.props.name, reduxForm.fields)) {
      return (
        <Editor {...this.props} />
      );
    }

    return (
      <Field
        component={this.renderField}
        {...this.props}
      />
    );
  }
}

export default withReduxForm(EditorConnected);

import * as R from 'ramda';
import moment from 'moment';

import generator from 'redux/hor/module';

const {
  reducer,

  fetchSuccess,
  create,
} = generator({
  name: 'Journal',
  tableId: 57,
  parseItem: R.evolve({
    ___parent: R.propOr(null, 'key'),
    custom_дата: val => moment(val).toDate(),
    custom_пациент: R.propOr(null, 'key'),
  }),
});

export {
  fetchSuccess,
  create,
};

export {
  getItemsArray,
} from 'redux/hor/module/selectors';

export {
  getUserJournal,
  getUserActiveDays,
  getPatientJournal,
} from './selectors';

export { default as saga } from './saga';

export default reducer;

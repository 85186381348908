const authErrors = {
  401: 'Неверная почта или пароль',
  500: 'Ошибка на сервере (или обслуживания)',
  400: 'Проблемы с запросом',
  confirmed: () => 'Вы еще не подтвердили адрес электронной почты. Пожалуйста, подтвердите адрес электронной почты, перейдя по ссылке в отправленном вам письме',
  deactivated: name => {
    return `
    К сожалению ваш аккаунт деактивирован администратором учреждения
    ${name}.
    Обратитесь к нему, если считаете что деактивация была сделана по ошибке.
    `

  },
  subscription: 'К сожалению время действия подписки истекло. Пожалуйста, обратитесь к администратору учреждения для продления подписки и продолжения работы в сервисе.',
  unknown: 'Неизвестный статус ответа от сервера',
};

export default authErrors;

import { auth, identify } from './carrot';

export const authorizeUserInCarrot = (email, hash, name) => {
  auth(email, hash);
  identify({
    $email: email,
    ...name,
  });
};

export const authorizeUserInCarrotByUserData = (data) => {
  const email = data.custom_login;
  const hash = data.custom_хеш_пользователя;

  authorizeUserInCarrot(email, hash, {
    $name: data.custom_firstname,
    Фамилия: data.custom_lastname,
    Отчество: data.custom_midname,
    версия: data.custom_партнер ? 'schoold' : data.isLight ? 'lite' : 'pro',
  });
};

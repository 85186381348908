import { createReducer, createAction } from 'redux-act';

export const setStep = createAction('SIGNUP__SET_STEP');
export const setUser = createAction('SIGNUP__SET_USER');
export const setInstitution = createAction('SIGNUP__SET_INSTITUTION');
export const setUTM = createAction('SIGNUP_UTM');
const initialState = {
  step: 'user',
  utm: null
};

const reducer = createReducer({
  [setUTM]: (state, payload) => ({
    ...state,
    utm: payload
  }),
  [setStep]: (state, payload) => ({
    ...state,
    step: payload,
  }),
  [setUser]: (state, payload) => ({
    ...state,
    user: payload.data,
  }),
  [setInstitution]: (state, payload) => ({
    ...state,
    institution: {
      ...payload.data,
      custom_название_на_печати: payload.data.custom_название,
    },
  }),
}, initialState);


export { default as saga } from './saga.js';

export default reducer;

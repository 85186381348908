
import createSelector from 'redux/utils/createOrmSelector';

import getRationDayNumber from '../getRationDayNumber';
import getRationKey from '../getRationKey';

export default createSelector(
  getRationDayNumber,
  getRationKey,

  (session, number, rationKey) => {
    const { Ration, Day } = session;

    if (!Ration.idExists(rationKey)) {
      return false;
    }

    const removingDays = Day
      .filter({ custom_рацион_пациента: rationKey })
      .exclude(day => !day.isDeleting);

    return !removingDays.count();
  },
);

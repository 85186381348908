import * as R from 'ramda';

const formatValue = (i) => {
  if (R.isNil(i)) {
    return '';
  } else if (R.is(Number, i)) {
    return String(i);
  } else if (i === '') {
    return i;
  }

  return String(Number.parseFloat(R.replace(',', '.', i)));
};

export default formatValue;

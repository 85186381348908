import createCachedSelector from 're-reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';

import orm from 'redux/orm';

const createCachedOrmSelector = (...args) => {
  const resultFunc = args.pop();

  return createCachedSelector(
    state => state.orm,
    ...args,
    ormCreateSelector(orm, resultFunc),
  )(args[0]);
};

export default createCachedOrmSelector;
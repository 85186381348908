import generator from 'redux/hor/module';
import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,
  fetch,
} = generator({
  name: 'Rights',
  tableId: 58,
});

export const fetchList = fetchWithView(fetch, 'ts5c58cs9r127', {
  useLS: true,
});

export {
  fetch,
};

export {
  getKeyById,
} from 'redux/hor/module/selectors';

export {
  getRightsList,
} from './seletors';

export default reducer;

import { combineReducers } from 'redux';
import { all, call } from 'redux-saga/effects';

import patientInWork, { saga as patientInWorkSaga } from './patientInWork';

export default combineReducers({
  patientInWork,
});

export function* saga() {
  yield all([
    call(patientInWorkSaga),
  ]);
}

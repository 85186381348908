import * as R from 'ramda';
import createOrmSelector from 'redux/utils/createOrmSelector';

import { getKeyById } from 'modules/Patient/redux/receptions/selectors';
import getPatientReceptionId from '../getPatientReceptionId';

export default createOrmSelector(
  getPatientReceptionId,
  (_, params) => R.propOr(null, 'receptionId', params),
  (_, params) => R.propOr(null, 'receptionKey', params),

  /* eslint-disable complexity */
  (session, routeReceptionId, paramReceptionId, paramReceptionKey) => {
    const receptionId = paramReceptionId || routeReceptionId;
    if (paramReceptionKey || (receptionId && receptionId !== 'new')) {
      const receptionKey = paramReceptionKey || getKeyById(receptionId);
      const { PatientReceptions } = session;
      try {
        const reception = PatientReceptions.withId(receptionKey);
        return reception && !reception.isDeleting && !reception.isDeleted
          ? reception.ref : null;
      } catch (err) {
        return null;
      }
    }
    return null;
  },
);

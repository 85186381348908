import * as R from 'ramda';
import { createSelector } from 'reselect';

import getAnalysisItem from '../getAnalysisItem';

export default createSelector(
  getAnalysisItem,

  R.prop('caption'),
);

import { all, takeLatest, put } from "redux-saga/effects";

import { fetch, saveSuccess } from "./index";

function* saveSuccessFlow(action) {
  const { payload } = action;
  const { response } = payload;

  if (payload.key !== response.data.key) {
    yield put(
      fetch({
        key: response.data.key,
      })
    );
  }
}

export default function* saga() {
  yield all([takeLatest(saveSuccess, saveSuccessFlow)]);
}

import * as R from 'ramda';
import { createSelector } from 'reselect';

import getPatientIllness from '../getPatientIllness';
import getIllnessList from '../getIllnessList';
import getPatientSpecificConditions from '../getPatientSpecificConditions';

export default createSelector(
  getPatientSpecificConditions,
  getPatientIllness,
  getIllnessList,

  (specificConditions, patientIllness, illnessList) => {
    const illness = R.compose(
      R.uniq,
      R.pluck('group'),
      R.filter(R.prop('group')),
      R.filter(item => R.contains(item.value, patientIllness)),
    )(illnessList);

    return [...illness, ...specificConditions];
  },
);

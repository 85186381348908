import * as R from 'ramda';

export default function getNormText(field) {
  const { normMin, normMax } = field;
  const getRoundedValue = value => Math.round(value * 100) / 100;

  if (R.isNil(normMin) && R.isNil(normMax)) {
    return null;
  }

  if (normMin === 0) {
    return `< ${getRoundedValue(normMax)}`;
  }

  if (normMax === Infinity) {
    return `> ${getRoundedValue(normMin)}`;
  }

  return `${getRoundedValue(normMin)} - ${getRoundedValue(normMax)}`;
}

import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import Text from 'components/Text';

import styles from './ServerUnderMaintenance.module.css';

const cx = classNames.bind(styles);

export default class ServerUnderMaintenance extends PureComponent {
  render() {
    return (
      <div className={cx('serverUnderMaintenance')}>
        <Text block size='big' weight='bold'>
          Сервер перешел в режим обслуживания. Приносим извинения за доставленные неудобства
        </Text>
        <Text block size='medium' color='gray'>
          Сайт автоматически продолжит работу, когда обслуживание закончится
        </Text>
      </div>
    );
  }
}

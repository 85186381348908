import * as R from 'ramda';

import createSelector from 'redux/utils/createOrmSelector';
import getRationDays from '../getRationDays';

// while removing the last day from a ration state.routeParams.dayId can contain a reference
// to the removed day; in this case return the new last day
export default createSelector(
  (state, props = {}) => props.dayId || R.path(['routeParams', 'dayId'], state),
  getRationDays,

  (session, dayId, days) => {
    if (!dayId) {
      return dayId;
    }

    const result = Number(dayId);
    const maxDayId = days.length;
    return result <= maxDayId ? result : maxDayId;
  },
);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Field from 'components/Field';

export default class FieldDate extends PureComponent {
  static propTypes = {
    active: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    onBlur: PropTypes.func,
    mobileVersion: PropTypes.bool,
  }

  handleBlur = () => {
    this.props.onBlur(this.getValue(this.props.value));
  }

  getValue(value) {
    const date = moment(value, 'DD.MM.YYYY');

    if (!date.isValid()) {
      return value;
    }

    return date;
  }

  getFormattedValue(value) {
    const { active } = this.props;
    const date = moment(value);

    if (!date.isValid() || typeof value === 'string') {
      return value;
    }

    if (active) {
      return date.format('DD.MM.YYYY');
    }

    return date.format('DD MMMM YYYY');
  }

  render() {
    const { active, value } = this.props;

    return (
      <Field
        mask={active ? '99.99.9999' : ''}
        {...this.props}
        onBlur={this.handleBlur}
        value={this.getFormattedValue(value)}
        name={null}
      />
    );
  }
}

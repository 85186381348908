import createSelector from 'redux/utils/createOrmSelector';

import { appKey } from 'config';

import getRationKey from '../getRationKey';

export default createSelector(
  getRationKey,

  (session, rationKey) => {
    const { Ration } = session;

    const ration = Ration.withId(rationKey);

    if (!ration) {
      return false;
    }

    return ration[`${appKey}ts9c39c3c1r77`];
  },
);

import * as R from 'ramda';

const defaultResolver = () => '__default__';

/**
 * Remember the previous computation result (by key - see resolver) and return
 * it (not a current one!) if it is deep equal to the current one.
 *
 * Use this if you always acquire new data from a selector, but new data is deep
 * equal to the previous selector result. (e.g. redux-orm model data when some
 * related model is changed).
 *
 * This is a hackish part of optimization strategy. Think twice before using it.
 *
 * @param {Function} fn A function which result to remember
 * @param {Function} resolver A function returning a cache key; default one always
 *    returns default key
 * @returns {Function} Memoized function.
 */
const memoizeByResult = (fn, resolver = defaultResolver) => {
  const cache = new Map();
  return (...args) => {
    const newResult = fn(...args);
    const key = resolver(...args);
    const result = cache.get(key);
    if (newResult !== result && !R.equals(newResult, result)) {
      cache.set(key, newResult);
      return newResult;
    }
    return result;
  };
};

export default memoizeByResult;

import * as R from 'ramda';
import { createSelector } from 'reselect';

import getInstitutionTariffKey from '../getInstitutionTariffKey';
import getTariff from '../getTariff';

export default createSelector(
  R.identity,
  getInstitutionTariffKey,

  (state, tariffKey) => getTariff(state, { tariffKey }),
);

import * as R from 'ramda';
import createOrmSelector from 'redux/utils/createOrmSelector';

import { getItem } from 'redux/hor/module/selectors';

import orm from 'redux/orm';

const getOrmItem = createOrmSelector(
  (state, key) => key,

  (session, key) => {
    let result;

    const models = R.pluck('modelName', orm.registry);
    const items = R.compose(
      R.values,
      R.pick(models),
    )(session);

    R.forEach((Model) => {
      if (Model.idExists(key)) {
        result = Model.withId(key).ref;
      }
    }, items);

    return result;
  },
);

function getItemByKey(key, state) {
  let result;

  R.forEachObjIndexed((val, prop) => {
    let item;

    if (prop[0].toUpperCase() === prop[0]) {
      item = getItemByKey(key, val);
    } else {
      item = getItem(key, val);
    }

    if (item) {
      result = item;
    }
  }, state);

  return result;
}

export default R.curry((key, state) => {
  const data = getItemByKey(key, state);
  const ormItem = getOrmItem(state, key);

  return data || ormItem;
});

import * as R from "ramda";
import memoizee from "memoizee";

import { appKey } from "config";

import { getItem, getItemsArray } from "redux/hor/module/selectors";
import getKeyInfo from "utils/getKeyInfo";

export const getItemsList = (state, country) =>
  R.compose(
    memoizee(
      R.map((item) => ({
        ...item,
        value: item.key,
        label: item.custom_наименование,
      }))
    ),
    R.sortWith([
      R.descend((item) => (R.isEmpty(item.custom_from_bad) ? 0 : 1)),
      R.ascend(R.prop("custom_наименование")),
    ]),
    R.filter((item) => {
      return !country || item.custom_страны.includes(country);
    }),
    getItemsArray
  )(state);

export const getProductsByGroups = R.compose(
  memoizee(R.groupBy(R.prop("custom_производитель"))),
  getItemsList
);

export const getIsCompositionCalcing = R.compose(
  R.propOr(false, `${appKey}ts9c4c3c1r8`),
  getItem
);

export const getSetTechcard = memoizee(
  (key, state) => {
    return R.compose(
      R.map((key) => {
        const item = getItem(key, state);

        if (item) {
          const id = getKeyInfo(item.key).record;
          return {
            id,
            name: item.custom_наименование,
            href: `/catalog/supplement/${id}`,
          };
        }

        return null;
      }),
      R.propOr([], "custom_from_bad"),
      getItem
    )(key, state);
  },
  { length: 4 }
);

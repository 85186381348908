import * as R from 'ramda';
import createOrmSelector from 'redux/utils/createOrmSelector';

import getDayKey from '../getDayKey';

export default createOrmSelector(
  getDayKey,
  R.path(['productComposition']),

  (session, key, state) => {
    const { Day } = session;

    return Day
      .withId(key)
      .getComposition(state);
  },
);

import * as R from 'ramda';
import { createAction } from 'redux-act';
import { attr, Model } from 'redux-orm';

import generator from 'redux/hor/orm-module';

const {
  reducer,
  fetch,
  save,
} = generator({
  name: 'staffFlags',
  tableId: 188,
  parseItem: R.evolve({
    custom_пользователь: R.propOr(null, 'key'),
  }),
});

export default class WorkerFlags extends Model {
  static modelName = 'WorkerFlags';

  static options = {
    idAttribute: 'key',
  };

  static fields = {
    custom_пользователь: attr(),
    custom_показана_подсказка_по_списку: attr(),
    custom_показана_подсказка_по_диаграмме: attr(),
  }

  static reducer = reducer;

  toString() {
    return `WorkerFlags: ${this.key}`;
  }
}

export const saveFlag = createAction('STAFFFLAGS__SAVE_FLAG')

export {
  fetch,
  save,
};

import { applyContainerQuery } from 'react-container-query'; // eslint-disable-line
import * as R from 'ramda';

const getWidth = R.compose(
  Number,
  R.propOr(undefined, 1),
);

const containerQuery =(styles = {}, query = {}) => (component) => {
  const calculatedQuery = R.compose(
    R.merge(query),
    R.reduce((acc, item) => ({
      ...acc,
      [item]: {
        minWidth: (getWidth(item.match(/gt_(\d+)/)) || -1) + 1,
        maxWidth: (getWidth(item.match(/lt_(\d+)/)) || Infinity) - 1,
      },
    }), {}),
    R.filter(key => R.contains('width_gt', key) || R.contains('width_lt', key)),
    R.keys,
  )(styles);

  return applyContainerQuery(component, calculatedQuery);
};

export default containerQuery;
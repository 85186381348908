import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Field } from 'redux-form';
import { withReduxForm } from 'redux-form/es/ReduxFormContext';
import ImagePicker from './ImagePicker';

class ImagePickerConnected extends PureComponent {
  static contextTypes = {
    onFormChange: PropTypes.func,
  };

  static propTypes = {
    name: PropTypes.string,
  };

  static defaultProps = {
    name: undefined,
  };

  handleChange = onChange => (event) => {
    onChange(event);
    setTimeout(this.context.onFormChange, 0);
  }

  renderField = (field = {}) => (
    <ImagePicker
      {...this.props}
      {...field.input}
      {...field.meta}
      onChange={this.handleChange(field.input.onChange)}
    />
  );

  render() {
    if (!this.props._reduxForm) { // eslint-disable-line no-underscore-dangle
      return (
        <ImagePicker
          {...this.props}
        />
      );
    }

    return (
      <Field
        component={this.renderField}
        name={this.props.name}
      />
    );
  }
}

export default withReduxForm(ImagePickerConnected)
import generator from 'redux/hor/module';
import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,
  fetch,
} = generator({
  name: 'Illness',
  tableId: 151,
  parseItem: (item) => {
    item.custom_заболевание = item.custom_заболевание_ключ; // eslint-disable-line

    return item;
  },
});

export const fetchList = fetchWithView(fetch, 'ts5c151cs9r537', {
  useLS: true,
});

export {
  getItem,
} from 'redux/hor/module/selectors';

export {
  getItems,
} from './selectors';

export default reducer;

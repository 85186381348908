import * as R from 'ramda';
import { createSelector } from 'reselect';

import getPatient from '../getPatient';

export default createSelector(
  getPatient,

  R.propOr(0, 'custom_спк_энергия'),
);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class VerticalLine extends PureComponent {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    y: PropTypes.func,
  }

  static defaultProps = {
    width: 0,
    height: 0,
    y: () => {},
  }

  render() {
    const { height, width, y } = this.props;
    const getX = (index, list) => width * (index / (list.length - 1));
    const line = [0, 1, 2];

    return (
      <g>
        {line.map((item, _, list) => (
          <line
            key={item}
            y1={0}
            y2={y(height)}
            stroke='#a2b3c2'
            strokeWidth={1}
            x1={getX(item, list)}
            x2={getX(item, list)}
          />
        ))}
      </g>
    );
  }
}

import createOrmSelector from 'redux/utils/createOrmSelector';

import getPatientKey from 'redux/selectors/getPatientKey';

export default createOrmSelector(
  getPatientKey,

  (session, patientKey) => {
    const { PatientsElements } = session;

    return PatientsElements
      .filter({
        ___parent: patientKey,
      })
      .toRefArray();
  },
);

import * as R from 'ramda';
import { createSelector } from 'reselect';

import getInstitution from '../getInstitution';
import getUserIsLight from '../getUserIsLight';

export default createSelector(
  getInstitution,
  getUserIsLight,

  (institution, isLight) => {
    return R.compose(
      R.not,
      R.propOr(true, 'custom_информирован_о_триальной_подписке'),
    )(institution);
  },
);

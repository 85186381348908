import generator from 'redux/hor/module';

import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,
  fetch,
} = generator({
  name: 'PatientActivityChild',
  tableId: 15,
});

export const fetchList = fetchWithView(fetch, 'ts5c15cs9r1837', {
  useLS: true,
});

export {
  fetch,
};

export {
  getItems,
} from './selectors';

export default reducer;
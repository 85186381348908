import generator from "redux/hor/module";
import fetchWithView from "redux/utils/fetchWithView";

const { reducer, fetch } = generator({
  name: "Countries",
  tableId: 104,
});

export const fetchList = fetchWithView(fetch, "ts5c104cs9r301", {
  useLS: true,
});

export { getItems } from "./selectors";

export default reducer;

import * as R from 'ramda';
import createOrmSelector from 'redux/utils/createOrmSelector';

import getUserType from 'redux/selectors/getUserType';

import getDietanamnezKindsFood from '../getDietanamnezKindsFood';
import getDietanamnezTilesVisible from '../getDietanamnezTilesVisible';
import getDietanamnezType from '../getDietanamnezType';

export default createOrmSelector(
  getDietanamnezKindsFood,
  getDietanamnezTilesVisible,
  getDietanamnezType,
  getUserType,

  (session, kindsFood, items, daType, userType, supplements) => {
    const { DietanamnezTiles } = session;

    const result =  DietanamnezTiles
      .filter({
        ___parent: null,
      })
      .filter(({ custom_формат }) => !custom_формат || custom_формат === daType)
      .filter(({ custom_версия_приложения }) => !custom_версия_приложения || custom_версия_приложения === userType)
      .filter((item) => {
        const { custom_специфические_исключения } = item;

        return R.compose(
          R.isEmpty,
          R.intersection(custom_специфические_исключения),
        )(kindsFood);
      })
      .filter((item) => {
        const groupItems = items[item.key];

        return !!groupItems;
      })
      .toRefArray()
      .concat(userType === 1 ? {
        key: 'SUPPLEMENT',
        custom_наименование: 'БАД',
      } : []);

    return result;
  },
);

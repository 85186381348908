import axios from 'axios';
import { apiUrl } from 'config';

import {
  queueRequest,
  queueResponse,
  queueResponseError,
} from './utils/queue';

import interceptor, { interceptorError } from './utils/interceptor';
import {
  resetCache,
  requestCache,
  responseCache,
  responseCacheError,
} from './utils/cache';
import {
  handleServerMaintenance,
  serverMaintenanceInterceptor,
} from './utils/serverMaintenance';

let store;
export function setStore(_store) {
  store = _store;
  store.subscribe(resetCache(store));
  store.subscribe(handleServerMaintenance(store));
}

const instance = axios.create({
  baseURL: apiUrl,
});

instance.interceptors.request.use(queueRequest);
instance.interceptors.request.use(requestCache);
instance.interceptors.request.use(param => interceptor(store, param));

instance.interceptors.response.use(null, response => serverMaintenanceInterceptor(store, response));
instance.interceptors.response.use(responseCache, responseCacheError);
instance.interceptors.response.use(queueResponse, queueResponseError);
instance.interceptors.response.use(null, response => interceptorError(store, response));

export default instance;

import * as R from 'ramda';
import createOrmSelector from 'redux/utils/createOrmSelector';

import { appKey } from 'config';

const typeOrder = [
  `${appKey}t8r192`,
  `${appKey}t8r194`,
  `${appKey}t8r195`,
  `${appKey}t8r193`,
  `${appKey}t8r196`,
];

export default createOrmSelector(
  (session) => {
    const { AnalysisType } = session;

    return AnalysisType
      .all()
      .filter(item => R.contains(item.key, typeOrder))
      .orderBy(item => R.indexOf(item.key, typeOrder))
      .toModelArray()
      .map(i => ({
        value: i.key,
        label: i.custom_наименование,
      }));
  },
);

import { createAction, createReducer } from 'redux-act';

export const fetchData = createAction('STATISTIC__FETCH_DATA');
export const fetchDataSuccess = createAction('STATISTIC__FETCH_DATA_SUCCESS');
export const fetchDataFailure = createAction('STATISTIC__FETCH_DATA_FAILURE');
export const deleteDataRow = createAction('STATISTIC__DELETE_DATA');
export const toggleDelete = createAction('STATISTIC__DELETING_DATA')

const reducer = createReducer({
  [toggleDelete]: (state, payload) => {

    return {
      ...state,
      deleting: payload
    }
  },
  [fetchDataSuccess]: (state, payload) => {
    const { response, invites, me } = payload;
    const mergeDAta = response.data.data.map(item => {
      const { max } = item;
      invites.forEach(invite => {
        if (max.fields.custom_диетолог.label === invite.fields.custom_емейл_приглашенного && me !== invite.fields.___owner.key) {
          item.max.fields.custom_учреждение_оригинальное.inviteKey = invite.key;
        }
      })
      return item
    })
    return {
      ...state,
      items: mergeDAta,
    };
  },
}, {
  items: [],
  deleting: null
});

export {
  getEndDate,
  getStatisticData,
  getStatisticTotal,
  getIsUserCanSee,
} from './selectors';

export default reducer;

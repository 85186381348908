import * as R from 'ramda';
import createOrmSelector from 'redux/utils/createOrmSelector';

import getPatientKey from '../getPatientKey';

export default createOrmSelector(
  getPatientKey,
  (_, params) => R.propOr(null, 'patientKey', params),

  (session, statePatientKey, propsPatientKey) => {
    const { PatientReceptions } = session;
    const patientKey = statePatientKey || propsPatientKey;

    return PatientReceptions
      .filter({
        custom_planned_patient: patientKey,
      })
      .exclude({
        isDeleting: true,
      })
      .exclude({
        isDeleted: true,
      })
      .orderBy('custom_vremya')
      .toModelArray()
      .map(item => ({
        ...item.ref,
        id: item.id,
      }));
  },
);

import createOrmSelector from 'redux/utils/createOrmSelector';

export default createOrmSelector(
  (session) => {
    const { Vitamin } = session;

    return Vitamin
      .all()
      .toRefArray()
      .map(item => ({
        key: item.key,
        label: item.custom_наименование,
      }));
  },
);

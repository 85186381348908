import * as R from 'ramda';
import uuid from 'uuid/v4';
import { createAction as createAct } from 'redux-act';

import { appKey } from 'config';
import isLocalKey from 'utils/isLocalKey';

import { login } from 'redux/modules/auth';

import parseResult from '../../utils/parseResult';
import createReducer from '../../utils/createReducer';

export default function ormGenerator(config = {}) {
  const {
    name = 'Module__',
    tableId,
    parseItem = R.identity,
    parentField = '___parent',
  } = config;
  const tableKey = `${appKey}t${tableId}r`;
  const initialState = {
    tableKey,
    parentField,
    itemsByKey: {},
  };

  const createAction = (action, payload) => createAct(`${name.toUpperCase()}__${action}`, payload);
  const fetch = createAction('FETCH', args => ({
    key: tableKey,
    ...args,
  }));
  const fetchSuccess = createAction('FETCH_SUCCESS');
  const fetchFailure = createAction('FETCH_FAILURE');

  const create = createAction('CREATE', args => ({
    key: uuid(),
    ...args,
  }));

  const save = createAction('SAVE', args => ({
    ...args,
    tableKey,
    key: args.key || uuid(),
  }));
  const saveSuccess = createAction('SAVE_SUCCESS');
  const saveFailure = createAction('SAVE_FAILURE');

  const remove = createAction('REMOVE');
  const removeSuccess = createAction('REMOVE_SUCCESS');
  const removeFailure = createAction('REMOVE_FAILURE');

  const removeWithUndo = createAction('REMOVE_WITH_UNDO', args => ({
    ...args,
    tableKey,
  }));
  const removeWithUndoSuccess = createAction('REMOVE_WITH_UNDO_SUCCESS');
  const removeWithUndoFailure = createAction('REMOVE_WITH_UNDO_FAILURE');

  const undoRemove = createAction('UNDO_REMOVE');
  const undoRemoveSuccess = createAction('UNDO_REMOVE_SUCCESS');
  const undoRemoveFailure = createAction('UNDO_REMOVE_FAILURE');

  const handleFetchSuccess = (Model, session, payload) => {
    const { response, parents, isParsed } = payload;

    if (response.fromCache) {
      return;
    }

    const items = R.values(parseResult(response, [], isParsed ? R.identity : parseItem));
    const itemsKey = R.pluck('key', items);

    if (parents) {
      Model
        .all()
        .filter(item => R.contains(item[parentField], parents))
        .filter(item => !R.contains(item.key, itemsKey))
        .filter(item => !isLocalKey(item.key))
        .delete();
    }

    const fields = ['key', ...R.keys(Model.fields)];
    items.forEach((item) => {
      const notDeclared = R.omit(fields, item);

      if (!R.isEmpty(notDeclared) && process.env.NODE_ENV !== 'production') {
        // console.warn(`Not declared fiields in ${Model.modelName}(${item.key}):`, notDeclared); // eslint-disable-line no-console
      }

      Model.upsert(R.pick(fields, item));
    });
  };

  const handleFetchFailure = (Model, session, payload) => {
    console.error(payload.error);
  };

  const handleCreate = (Model, session, payload) => {
    const { data, key } = payload;

    Model.create({
      key,
      ...data,
    });
  };

  const handleSave = (Model, session, payload) => {
    const { key, data = {}, isLocal, isSilent } = payload;

    if (isSilent) {
      return;
    }

    if (Model.idExists(key) && isLocalKey(key) && Model.withId(key).isSaving && !isLocal) {
      Model.upsert({
        key,
        ...data,
        isShouldSaved: true,
      });
    } else {
      Model.upsert({
        key,
        ...data,
        isSaving: true,
        isShouldSaved: false,
      });
    }
  };

  const handleSaveSuccess = (Model, session, { key, response, isSilent }) => {
    const newKey = R.pathOr(key, ['data', 'key'], response);
    const isCreation = newKey && key !== newKey;

    if (!Model.idExists(key) || isSilent) {
      return;
    }

    if (isCreation) {
      const item = Model.withId(key);
      const data = item.ref;
      const virtualFields = item.getClass().virtualFields;

      R.forEachObjIndexed((val, fieldKey) => {
        const relatedData = item[fieldKey];

        R.forEach(relatedItem => relatedItem.update({
          [val.relatedName]: newKey,
        }), relatedData.toModelArray());
      }, virtualFields);

      item.delete();
      Model.create({
        ...data,
        isSaving: false,
        isSaved: true,
        oldKey: key,
        key: newKey,
      });
    } else {
      Model.withId(key).update({
        isSaving: false,
        isSaved: true,
      });
    }
  };

  const handleSaveFailure = (Model, session, payload) => {
    const { key } = payload;

    if (Model.idExists(key)) {
      Model.withId(key).update({
        isSaving: false,
        isSaved: false,
      });
    }
  };

  const handleRemove = (Model, session, payload) => {
    const { key } = payload;

    if (Model.idExists(key)) {
      Model.withId(key).update({
        isDeleting: true,
      });
    }
  };

  const handleRemoveSuccess = (Model, session, payload) => {
    const { key } = payload;
    const item = Model.withId(key);

    if (!item) {
      return;
    }

    const isLocalItem = isLocalKey(item.key);

    item.update({
      isDeleted: true,
      isDeleting: false,
      markToDelete: isLocalItem && item.isSaving,
    });
  };

  const handleRemoveFailure = (Model, session, payload) => {
    const { key } = payload;

    if (Model.idExists(key)) {
      Model.withId(key).update({
        isDeleting: false,
      });
    }
  };

  const handleUndoRemove = (Model, session, payload) => {
    const { key } = payload;

    Model.withId(key).update({
      isDeleted: false,
      isDeleting: false,
      markToDelete: false,
    });
  };

  const handleUndoRemoveFailure = (Model, session, payload) => {
    const { key } = payload;

    Model.withId(key).update({
      isDeleted: true,
    });
  };

  const reducer = createReducer({
    [fetchSuccess]: handleFetchSuccess,
    [fetchFailure]: handleFetchFailure,

    [create]: handleCreate,

    [save]: handleSave,
    [saveSuccess]: handleSaveSuccess,
    [saveFailure]: handleSaveFailure,

    [remove]: handleRemove,
    [removeSuccess]: handleRemoveSuccess,
    [removeFailure]: handleRemoveFailure,

    [removeWithUndo]: handleRemove,
    [removeWithUndoSuccess]: handleRemoveSuccess,
    [removeWithUndoFailure]: handleRemoveFailure,

    [undoRemove]: handleUndoRemove,
    [undoRemoveFailure]: handleUndoRemoveFailure,

    [login]: (Model) => {
      Model.all().delete();
    },
  }, initialState);

  return {
    fetch,
    fetchSuccess,
    fetchFailure,

    create,

    save,
    saveSuccess,
    saveFailure,

    remove,
    removeSuccess,
    removeFailure,

    removeWithUndo,
    removeWithUndoSuccess,
    removeWithUndoFailure,

    undoRemove,
    undoRemoveSuccess,
    undoRemoveFailure,

    reducer,
  };
}

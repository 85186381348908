import generator from 'redux/hor/module';
import fetchWithView from 'redux/utils/fetchWithView';

const {
  fetch,
  reducer,
} = generator({
  name: 'statisticDates',
  tableId: 131,
});

export const fetchStatisticDates = fetchWithView(fetch, 'ts5c131cs9r443');

export {
  getStatisticDates,
} from './selectors';

export default reducer;

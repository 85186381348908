import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import * as R from 'ramda';

import {
  gradientByColor,
  getColorStatus,
  CHART_PADDING_TOP,
} from '../../helpers';

export default class OneRect extends PureComponent {
  static propTypes = {
    index: PropTypes.number,
    onMouseEnter: PropTypes.func,
    onMouseMove: PropTypes.func,
    onMouseLeave: PropTypes.func,
    height: PropTypes.number,
    normMin: PropTypes.number,
    normMax: PropTypes.number,
    id: PropTypes.string,
    data: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.array,
    ]),
    x: PropTypes.func,
    width: PropTypes.number,
    color: PropTypes.string,
  }

  state = {
    withAnimation: false,
  }

  componentWillReceiveProps(nextProps) {
    if (this.getSum(nextProps.data) !== this.getSum(this.props.data)) {
      this.setState({
        withAnimation: true,
      });
    }
  }

  getSum(data) {
    if (Array.isArray(data)) {
      return R.sum(data);
    }

    return data;
  }

  getPercent = () => {
    const { data, normMin, normMax } = this.props;
    const sum = R.sum([].concat(data));

    if (sum < normMin) {
      return Math.floor(((sum - normMin) * (100 / normMin)));
    } else if (sum > normMax && normMax) {
      return Math.ceil(sum * (100 / normMax));
    }
    return 100;
  }

  getColor(color) {
    const { id } = this.props;

    const gradientColor = gradientByColor[color] ? gradientByColor[color](id) : `url(#redGradient${id})`;

    if (this.props.index === 0) {
      return gradientColor;
    }

    return color;
  }

  render() {
    const {
      index,
      onMouseEnter,
      onMouseMove,
      onMouseLeave,
      height,
      x,
      width,
    } = this.props;

    const valueInPercent = this.getPercent();
    const color = getColorStatus(valueInPercent, this.props.color);

    return (
      <rect
        key={index}
        x={x}
        height={height}
        width={width}
        y={CHART_PADDING_TOP}
        fill={this.getColor(color)}
        onMouseEnter={onMouseEnter(valueInPercent, color)}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        style={{
          transition: this.state.withAnimation ? 'all 0.3s ease' : null,
        }}
      />
    );
  }
}

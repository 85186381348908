import * as R from 'ramda';

import generator from 'redux/hor/module';

const {
  reducer,
  fetchSuccess,
  save,
} = generator({
  name: 'Ingestion',
  tableId: 95,
  parseItem: R.evolve({
    ___parent: R.propOr(null, 'key'),
    custom_схема_питания: R.propOr(null, 'key'),
  }),
});

export {
  fetchSuccess,
  save,
};

export {
  getItemsArrayByParent,
} from 'redux/hor/module/selectors';

export {
  getFirstIngestionTime,
} from './selector';

export default reducer;

const userInfoField = {
  locate: true,
  min: { limiter: 2 },
  max: { limiter: 50 },
};

const defaultOptions = {
  /**
   * 1. Email
   */
  email: {
    required: true,
    email: true,
  },
  /**
   * 2. Pass
   */
  password: {
    required: true,
    simplePass: { errorText: 'Слишком простой пароль' },
    min: { limiter: 6, errorText: 'Пароль не может быть короче 6-ти символов' },
  },
  /**
   * 3. username
   */
  username: userInfoField,
  /**
   * 4. surname
   */
  surname: userInfoField,
  /**
   * 5. secondname
   */
  secondname: userInfoField,
  /**
   * 6. job
   */
  job: {
    max: { limiter: 200 },
  },
  /**
   * 7. city
   */
  city: {
    max: { limiter: 50 },
  },
  /**
   * 8. street
   */
  street: {
    max: { limiter: 200 },
  },
  /**
   * 9. house
   */
  house: {
    locateNum: true,
    max: { limiter: 4 },
  },
  /**
   * 10. casehouse
   */
  casehouse: {
    locateNum: true,
    max: { limiter: 4 },
  },
  /**
   * 11. phone
   */
  phone: {
    phone: true,
  },
  /**
   * 12. casehouse
   */
  agency: {
    max: { limiter: 200 },
  },
  /**
   * 13. role
   */
  role: {
    max: { limiter: 200 },
  },

  npa: {
    required: true,
    max: { limiter: 15 },
  },

  logo: {
    typeFiles: {
      correctTypes: ['image/png', 'image/jpg', 'image/jpeg'],
      errorText: 'Неверный тип файла',
    },
    sizeFiles: {
      correctSize: 2e6,
      errorText: 'Превышен размер файла',
    },
  },

  date: {
    date: true,
  },

  time: {
    time: true,
  },
};

export default defaultOptions;

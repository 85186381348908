import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withReduxForm } from 'redux-form/es/ReduxFormContext';
import * as R from 'ramda';
import { Field } from 'redux-form';
import Checkbox from './Checkbox';

class CheckboxConnected extends PureComponent {
  static contextTypes = {
    onFormChange: PropTypes.func,
  };

  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    name: undefined,
    value: undefined,
    onChange: () => {},
  };

  renderField = (field = {}) => {
    const handleChange = (checked) => {
      const value = field.input.value || [];
      const fieldValue = this.props.value;

      setTimeout(this.context.onFormChange, 0);

      if (!fieldValue) {
        this.props.onChange(checked);
        field.input.onChange(checked);
        return;
      }

      const newValue = checked ?
        R.uniq(R.concat(value, [this.props.value])) :
        R.without([this.props.value], value);

      this.props.onChange(checked);
      field.input.onChange(newValue);
    };

    const checked = this.props.value ?
      R.contains(this.props.value, field.input.value) :
      field.input.checked;

    return (
      <Checkbox
        {...this.props}
        {...field.input}
        {...field.meta}
        onChange={handleChange}
        value={this.props.value}
        checked={checked}
      />
    );
  }

  render() {
    const { _reduxForm: reduxForm } = this.props;

    if (!reduxForm || !R.contains(this.props.name, reduxForm.fields)) {
      return (
        <Checkbox {...this.props} />
      );
    }

    return (
      <Field
        component={this.renderField}
        name={this.props.name}
        type='checkbox'
      />
    );
  }
}

export default withReduxForm(CheckboxConnected)
import * as R from 'ramda';
import generator from 'redux/hor/module';

const {
  reducer,
  fetch,
  save,
  remove,
} = generator({
  name: 'UserNotificationsExcluded',
  tableId: 142,
  parseItem: R.evolve({
    ___parent: R.propOr(null, 'key'),
    ___owner: R.propOr(null, 'key'),
  }),
});

export {
  fetch,
  save,
  remove,
};

export {
  getItems,
} from './selectors';

export default reducer;

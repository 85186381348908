import * as R from 'ramda';
import { captureException } from '@sentry/browser';
import { all, call } from 'redux-saga/effects';

import { saga as AnalysesSaga } from 'modules/Analyses/redux';
import { saga as CatalogSaga } from 'modules/Catalog/redux';
import { saga as DietanamnezSaga } from 'modules/Dietanamnez/redux';
import { saga as RationsSaga } from 'modules/Rations/redux';
import { saga as SupplementSaga } from 'modules/Supplement/redux';
import { saga as PublicSaga } from 'modules/Public/redux';
import { saga as PatientSaga } from 'modules/Patient/redux';
import { saga as UserSaga } from 'modules/User/redux';

import activationSaga from '../modules/activation/saga';
import authSaga from '../modules/auth/saga';
import ingredientsSaga from '../modules/ingredients/saga';
import institutionsSaga from '../modules/institutions/saga';
import journalSaga from '../modules/journal/saga';
import mealsSaga from '../modules/meals/saga';
import notificationSaga from '../modules/notification/saga';
import patientsSaga from '../modules/patients/saga';
import pingSaga from '../modules/ping/saga';
import productCompositionSaga from '../modules/productComposition/saga';
import staffSaga from '../modules/staff/saga';
import staffFlagsSaga from '../modules/staffFlags/saga';
import registrationLinksSaga from '../modules/registrationLinks/saga';
import subscriptionOperationsSaga from '../modules/subscriptionOperations/saga';
import subscriptionPromocodeSaga from '../modules/subscriptionPromocode/saga';
import supplementSaga from '../modules/supplement/saga';
import routeParamsSaga from '../modules/routeParams/saga';
import userSaga from '../modules/user/saga';

import crudSaga from './crud';
import routesSaga from './routes';

function* modulesSaga() {
  try {
    yield all([
      call(AnalysesSaga),
      call(CatalogSaga),
      call(DietanamnezSaga),
      call(RationsSaga),
      call(SupplementSaga),
      call(PublicSaga),
      call(PatientSaga),
      call(UserSaga),

      call(activationSaga),
      call(authSaga),
      call(ingredientsSaga),
      call(institutionsSaga),
      call(journalSaga),
      call(mealsSaga),
      call(notificationSaga),
      call(patientsSaga),
      call(pingSaga),
      call(productCompositionSaga),
      call(staffSaga),
      call(staffFlagsSaga),
      call(subscriptionOperationsSaga),
      call(subscriptionPromocodeSaga),
      call(routeParamsSaga),
      call(supplementSaga),
      call(userSaga),
      call(registrationLinksSaga),
    ]);
  } catch(error) {
    const status = R.pathOr(null, ['response', 'status'], error)

    if (status !== 401) {
      captureException(error);

      if (process.env.NODE_ENV !== 'production') {
        console.error(error)
      }
    }


    yield call(modulesSaga);
  }
}

export default function* rootSaga() {
  try {
    yield all([
      call(modulesSaga),
      call(crudSaga),
      call(routesSaga),
    ]);
  } catch(error) {
    const status = R.pathOr(null, ['response', 'status'], error)

    if (status !== 401) {
      captureException(error);

      if (process.env.NODE_ENV !== 'production') {
        console.error(error)
      }
    }
  }
}

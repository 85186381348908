import * as R from 'ramda';
import { all, takeEvery, select } from 'redux-saga/effects';
import { trackAddPatientNote } from 'utils/carrot';

import {
  getItemByKey,
} from 'redux/selectors';
import {
  saveSuccess,
} from './';

function* saveSuccessFlow(action) {
  const patientKey = R.pathOr(null, ['payload', 'data', '___parent'], action);
  const patient = yield select(getItemByKey(patientKey));
  // User can add notes for a patient while adding a patient; track only if a patient exist
  if (patient) {
    yield trackAddPatientNote(patientKey);
  }
}

export default function* saga() {
  yield all([
    takeEvery(saveSuccess, saveSuccessFlow),
  ]);
}

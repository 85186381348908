import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getItems } from 'redux/modules/patientsAllergen';

import getPatientKey from '../getPatientKey';

export default createSelector(
  getPatientKey,
  R.path(['patientsAllergen']),

  getItems,
);

import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getItemsArrayByParent as getIngestion } from 'modules/Rations/redux/modules/ingestion';

import getPatientKey from '../getPatientKey';

export default createSelector(
  getPatientKey,
  R.path(['Rations', 'ingestion']),

  getIngestion,
);

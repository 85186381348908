import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import containerQuery from 'decorators/containerQuery';

import constructFile from 'utils/constructFile';

import styles from './AvatarPicker.module.css';

const cx = classNames.bind(styles);

class AvatarPicker extends PureComponent {
  static propTypes = {
    containerQuery: PropTypes.object.isRequired,
    error: PropTypes.string,
    fileTitle: PropTypes.string,
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    title: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]).isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    square: PropTypes.bool,
  }

  static defaultProps = {
    containerQuery: {},
    error: undefined,
    fileTitle: 'Изменить фото',
    id: undefined,
    title: 'Фото',
    value: undefined,
    onChange: () => {},
    disabled: false,
    square: false,
  }

  handleChange = (e) => {
    const reader = new FileReader();
    const name = R.last(e.target.value.split('\\'));
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    reader.onload = (event) => {
      const result = event.target.result.split(/[:;,]/);
      const mime = result[1];
      const base64 = result[3];

      this.props.onChange({
        mime,
        base64,
        name,
      });
    };

    reader.readAsDataURL(e.target.files[0]);
  }


  render() {
    const { id, title, fileTitle, value, disabled, square } = this.props;
    const withFile = !R.isEmpty(value);

    const styleImg = {
      backgroundImage: `url(${constructFile(value)})`,
    };

    return (
      <div
        className={cx({
          avatarPicker: true,
          avatarPicker_square: square,
          avatarPicker_type_value: withFile,
        }, this.props.containerQuery)}
      >
        <input
          className={cx('input')}
          type='file'
          accept='image/*'
          id={id}
          onChange={this.handleChange}
          disabled={disabled}
        />

        <label htmlFor={id} className={cx('label')}>
          <div className={cx('avatar')}>
            {withFile &&
              <div
                style={styleImg}
                className={cx('image')}
                alt='presentation'
              />
            }
          </div>
          {!disabled &&
            <div className={cx('text-wrap')}>
              <div className={cx('title')}>
                {withFile && fileTitle ? fileTitle : title}
              </div>

              <div className={cx('error')}>
                {this.props.error}
              </div>
            </div>
          }
        </label>
      </div>
    );
  }
}

export default R.compose(
  containerQuery(styles),
)(AvatarPicker);

import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import containerQuery from 'decorators/containerQuery';

import Link from 'components/Link';
import Icon from 'components/Icon';

import styles from './RegistrationLayout.module.css';

const cx = classNames.bind(styles);

class PublicRegistrationLayout extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    containerQuery: PropTypes.object,
    isLite: PropTypes.bool,
  };

  static defaultProps = {
    children: undefined,
    containerQuery: {},
    isLite: false,
  };

  render() {
    const { isLite } = this.props;

    return (
      <div className={cx('layout', this.props.containerQuery)}>
        <div className={cx('content')}>
          {isLite ||
            <div className={cx('sidebar')}>
              <div className={cx('link')}>
                <Link
                  to='/login'
                  color='blue'
                  hover='sapphirine'
                  large
                  bold
                >
                  <div className={cx('icon')}>
                    <Icon icon='arrow-login' width={28} height={28} />
                  </div>
                  Вход
                </Link>
              </div>
              <div className={cx('note')}>
                Войдите, если вы
                зарегистрированы
                в системе
              </div>
            </div>
          }
          <div className={cx('page')}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default R.compose(
  containerQuery(styles),
)(PublicRegistrationLayout);

import * as R from 'ramda';
import createOrmSelector from 'redux/utils/createOrmSelector';
import moment from 'moment';

import { appKey } from 'config';

import isLocalKey from 'utils/isLocalKey';

import {
  getPatientKey,
} from 'redux/selectors';

const icons = {
  192: {
    icon: 'biochemical',
    height: 24,
    width: 16,
  },
  196: {
    icon: 'genetic',
    height: 30,
    width: 30,
  },
  193: {
    icon: 'vitamins',
    height: 28,
    width: 22,
  },
  194: {
    icon: 'microelements',
    height: 30,
    width: 24,
  },
  195: {
    icon: 'urine',
    height: 26,
    width: 22,
  },
};

export default createOrmSelector(
  (state, props = {}) => props.filter || 'all',
  getPatientKey,

  (session, filter, patientKey) => {
    const { PatientAnalysis } = session;

    return PatientAnalysis
      .filter({
        ___parent: patientKey,
      })
      .exclude({
        isDeleted: true,
      })
      .exclude(item => isLocalKey(item.key))
      .orderBy(item => moment(item.custom_дата).valueOf(), 'desc')
      .filter(item => item.custom_вид_анализа !== `${appKey}t8r6440794`)
      .filter(item => filter === 'all' || filter === item.custom_вид_анализа)
      .toModelArray()
      .map(item => {
        if (!item.custom_вид_анализа) {
          return null;
        }

        return ({
          id: item.id,
          key: item.key,
          iconProps: R.prop(item.custom_вид_анализа.id, icons),
          time: item.custom_дата_текстом,
          type: item.custom_вид_анализа.key,
          caption: item.custom_вид_анализа.custom_наименование,
          date: item.custom_дата,
          isDeleting: item.isDeleting,
        })
      })
      .filter(Boolean)
  },
);

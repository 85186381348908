import * as R from 'ramda';
import { createSelector } from 'reselect';

import isLocalKey from 'utils/isLocalKey';

import getDietanamnezKey from '../getDietanamnezKey';
import getDietanamnezItems from '../getDietanamnezItems';
import getDietanamnezKindsFood from '../getDietanamnezKindsFood';

export default createSelector(
  getDietanamnezKey,
  getDietanamnezItems,
  getDietanamnezKindsFood,

  (key, items, kindsFood) => {
    const isLocalItem = isLocalKey(key);
    const isEmptyItems = R.isEmpty(items);
    const isEmptyKindsFood = R.isEmpty(kindsFood);

    return isLocalItem || (isEmptyItems && isEmptyKindsFood);
  },
);

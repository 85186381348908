import * as R from "ramda";
import { createSelector } from "reselect";

import getInstitution from "../getInstitution";

export enum SubscriptionState {
  "IAP_ACTIVE" = 10,
  "IAP_RECURRING" = 12,
  "DEFAULT" = 20,
  "DEFAULT_RECURRING" = 22,
  "ERROR" = 30,
}

export default createSelector(
  getInstitution,

  (state) => state.custom_текущий_рекуррент || SubscriptionState.ERROR
) as () => SubscriptionState;

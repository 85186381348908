import * as R from 'ramda';

const getItem = (val) => {
  const result = {
    key: val.key,
    ...val.fields,
  };

  if (val.subitems) {
    result.subitems = R.map(getItem, val.subitems);
  }

  return result;
};

export default function parseResult(response, items, parseItem) {
  const data = response.list || R.pick(['fields', 'key'], response);
  const array = [].concat(data);


  let isChanged = false;
  const newItems = R.reduce((itemsByKey, item) => {
    const key = item.key;
    const parsedItem = parseItem(getItem(item));
    const current = itemsByKey[key];

    if (!current) {
      itemsByKey[key] = parsedItem; // eslint-disable-line
      isChanged = true;
    } else {
      const newItem = {
        ...current,
        ...parsedItem,
      };
      const isEquals = R.equals(newItem, current);

      if (!isEquals) {
        isChanged = true;
        itemsByKey[key] = newItem; // eslint-disable-line
      }
    }

    return itemsByKey;
  }, { ...items }, array);

  if (!isChanged) {
    return items;
  }

  return newItems;
}

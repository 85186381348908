import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import IconDotLoader from 'components/IconDotLoader';
import Modal from 'components/Modal';
import Subscription from 'components/Subscription';

export default class PublicActivationStatus extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool,
    activationStatus: PropTypes.string,
    onCancel: PropTypes.func,
  }

  static defaultProps = {
    isOpen: false,
    activationStatus: undefined,
    onCancel: () => {},
  }

  render() {
    const { activationStatus, isOpen, onCancel } = this.props;

    const textByStatus = {
      ALREADY_ACTIVE: 'Этот пользователь уже активирован',
      ACTIVATION_ERROR: 'Ссылка активации не действующая или время отведенное на активацию истекло. Попробуйте зарегистрироваться заново',
      IMMEDIATELY_DEACTIVATED: 'Ваш аккаунт был автоматически деактивирован, так как все оплаченные аккаунты заняты. Обратитесь к администратору учреждения.',
      ACTIVATED: 'Ваш email успешно подтвержден, спасибо. Для входа в сервис введите ваш пароль.',
      ACTIVATED_INVITE: 'Вы успешно приняли приглашение в группу',
      ACTIVATION_INVITE_ERROR: 'Вы уже приняли приглашение'
    };

    return (
      <Modal
        ariaHideApp={false}
        contentLabel='Активация'
        isOpen={isOpen}
      >
        <Subscription
          submitBtn={null}
          cancelBtn={activationStatus ? 'Ок' : null}
          onCancel={onCancel}
        >
          {textByStatus[activationStatus] ||
            <span>
              Подождите, проверяем ссылку активации
              <IconDotLoader inline />
            </span>
          }
        </Subscription>
      </Modal>
    );
  }
}

import * as R from 'ramda';
import extend from 'deep-extend';
import validators from './validators';
import getErrorText from './getErrorText';
import defaultOptions from './defaultOptions';
import defaultConfig from './defaultConfig';

export default function createValidate(options, evolve = R.nthArg(0)) {
  return (valuesFields, props = {}) => {
    const realOptions = typeof options === 'function' ? options(valuesFields, props) : options;
    const config = extend({}, defaultOptions, realOptions);
    const rules = R.map(R.pickBy(Boolean), config);

    const values = evolve(R.pickAll(props.fields, valuesFields));
    const initialValues = props.initialValues ? evolve(props.initialValues) : {};

    return R.compose(
      // Получаем текст ошибки
      getErrorText(rules),

      // Отфильтровываем поля, у которых ошибки нет
      R.pickBy(Boolean),

      // Получаем первую ошибку
      R.mapObjIndexed(R.compose(
        R.head,
        R.keys,
        R.pickBy(R.complement(Boolean)),
      )),

      // Вызываем все валидаторы для всех полей
      R.mapObjIndexed((value, fieldName) => {
        const validatorList = rules[fieldName];

        return R.mapObjIndexed((validatorConfig, validatorName) => {
          if (!validators[validatorName]) {
            return true;
          }

          const validator = validators[validatorName]({
            ...defaultConfig[validatorName],
            ...validatorConfig,
          });

          return validator(value, values, initialValues[value]);
        }, validatorList);
      }),
    )(values);
  };
}

import { appKey } from 'config';
import { createSelector } from 'reselect';

import getPatientEnergyMax from 'redux/selectors/getPatientEnergyMax';
import getRationGrade from '../getRationGrade';

const getValue = (value, grade, deviationMin, deviationMax = 2 * deviationMin) => {
  if (grade > 0) {
    return {
      norm: value,
      min: value - deviationMin,
      max: value + deviationMax,
    };
  }

  if (grade < 0) {
    return {
      norm: value,
      min: value - deviationMax,
      max: value + deviationMin,
    };
  }

  return {
    norm: value,
  };
};

const getEnergy = (grade, energy) => {
  const diff = grade > 0 ? 133 : 128;
  const value = energy + (diff * grade);
  return getValue(value, grade, 100);
};

const getProtein = (grade, energy) => getValue(energy * 0.0375, grade, 4);
const getFats = (grade, energy) => getValue(energy * 0.0333, grade, 3, 7);
const getCarbohydrate = (grade, energy) => getValue(energy * 0.1375, grade, 14);

export default createSelector(
  getRationGrade,
  getPatientEnergyMax,

  (grade, energy) => {
    const normEnergy = getEnergy(grade, energy);

    if (!energy) {
      return {};
    }

    console.log({
      [`${appKey}t28r219`]: normEnergy,
      [`${appKey}t28r221`]: getProtein(grade, normEnergy.norm),
      [`${appKey}t28r222`]: getFats(grade, normEnergy.norm),
      [`${appKey}t28r223`]: getCarbohydrate(grade, normEnergy.norm),
    });

    return {
      [`${appKey}t28r219`]: normEnergy,
      [`${appKey}t28r221`]: getProtein(grade, normEnergy.norm),
      [`${appKey}t28r222`]: getFats(grade, normEnergy.norm),
      [`${appKey}t28r223`]: getCarbohydrate(grade, normEnergy.norm),
    };
  },
);

import { createAction, createReducer } from 'redux-act';
import * as R from 'ramda';
import { LOCATION_CHANGE } from 'react-router-redux';

const initialState = {
  width: 0,
  isFixed: false,
  activeItem: null,
  activeGroup: null,
  isDisplayBzhuInRelativePercents: false,
};

export const setWidth = createAction('RATIONUI__SET_WIDTH');
export const setFixed = createAction('RATIONUI__SET_IS_FIXED');
export const setActiveItem = createAction('RATIONUI__SET_ACTIVE_ITEM');
export const setActiveGroup = createAction('RATIONUI__SET_ACTIVE_GROUP');
export const setMenuItem = createAction('RATIONUI__SET_MENU_ITEM');
export const toggleDisplayBzhuInRelativePercents =
  createAction('RATIONUI__TOGGLE_DISPLAY_BZHU_IN_RELATIVE_PERCENT');

const handleAction = (prop, mergeState = R.assoc) => (state, payload) => {
  if (state[prop] === payload) {
    return state;
  }

  return mergeState(prop, payload, state);
};

const handleSetWidth = handleAction('width');
const handleSetFixed = handleAction('isFixed');
const handleSetActiveItem = handleAction('activeItem', (prop, payload, state) => ({
  ...state,
  activeItem: payload,
  activeGroup: null,
}));
const handleSetActiveGroup = handleAction('activeGroup', (prop, payload, state) => ({
  ...state,
  activeItem: null,
  activeGroup: payload,
}));
const handleSetMenuItem = (state, payload) => ({
  ...state,
  activeMenu: state.activeMenu === payload ? undefined : payload,
});
const handleChangeRoute = state => ({
  ...state,
  isFixed: false,
  activeItem: null,
  activeGroup: null,
});
const handleToggleDisplayBzhuInRelativePercents = state => ({
  ...state,
  isDisplayBzhuInRelativePercents: !state.isDisplayBzhuInRelativePercents,
});

export default createReducer({
  [setWidth]: handleSetWidth,
  [setFixed]: handleSetFixed,
  [setActiveItem]: handleSetActiveItem,
  [setActiveGroup]: handleSetActiveGroup,
  [setMenuItem]: handleSetMenuItem,
  [LOCATION_CHANGE]: handleChangeRoute,
  [toggleDisplayBzhuInRelativePercents]: handleToggleDisplayBzhuInRelativePercents,
}, initialState);

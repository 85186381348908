import { put, all, takeEvery, call } from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';
import { appKey } from 'config';

import { request } from 'utils/api';
import {getPublicToken} from 'redux/utils/getPublicToken';

import {
  resetPassword,
} from './';

function* resetPasswordFlow(action) {
  const { payload } = action;
  const { form, email } = payload;
  yield put(startSubmit(form));
  const token = yield call(getPublicToken);

  try {
    yield call(() => request.post(`/${appKey}t179r/json/v2`, {
      parameters: {
        action: 40,
        staff_mail: email,
      }
    }, {
      headers: {
        token,
        Function: 'staffActions',
      },
    }));

    yield put(stopSubmit(action.payload.form));
  } catch (error) {
    yield put(stopSubmit(action.payload.form, {
      email: true,
      _error: 'Такой пользователь не зарегистрирован в системе',
    }));
  }
}

export default function* saga() {
  yield all([
    takeEvery(resetPassword, resetPasswordFlow),
  ]);
}

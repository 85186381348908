import * as R from 'ramda';
import { createAction } from 'redux-act';

import generator from 'redux/hor/module';
import fetchWithView from 'redux/utils/fetchWithView';

const setKeyField = R.curry((main, secondary, item) => {
  if (item[main] || item[secondary]) {
    item[main] = item[main] || item[secondary]; // eslint-disable-line no-param-reassign
  }

  return item;
});

const setKeysField = R.curry((main, secondary, item) => {
  if (item[main] || item[secondary]) {
    item[main] = item[main] || item[secondary].replace(/"/g, '').split(','); // eslint-disable-line no-param-reassign
  }

  return item;
});

const {
  reducer,

  fetch,
  fetchSuccess,
  create,
  save,
  saveSuccess,
  remove,
  removeSuccess,
  removeWithUndo,
} = generator({
  name: 'Meals',
  tableId: 4,
  parseItem: R.compose(
    R.compose(
      setKeyField('custom_группа', 'custom_группа_ключ'),
      setKeysField('custom_эталонные_блюда', 'custom_эталонные_блюда_ключи'),
      setKeysField('custom_запрещено_к_употреблению_при', 'custom_запрещено_к_употреблению_при_ключи'),
      setKeysField('custom_не_применяется_в', 'custom_не_применяется_в_ключи'),
    ),
    R.evolve({
      ___owner: R.propOr(null, 'key'),
      ___territory: R.propOr(null, 'key'),
      custom_группа: R.propOr(null, 'key'),
      custom_ед_изм: R.propOr(null, 'key'),
      custom_порция: R.propOr(null, 'key'),
      custom_способ_приготовления: R.propOr(null, 'key'),
      custom_возрастнаягруппа: R.defaultTo([]),
      custom_эталонные_блюда: R.compose(
        R.pluck('key'),
        R.defaultTo([]),
      ),
      custom_ед_изм_если_единица: R.propOr(null, 'key'),
      custom_применимость_в_схеме_питания: R.compose(
        R.pluck('key'),
        R.defaultTo([]),
      ),
      custom_не_применяется_в: R.compose(
        R.pluck('key'),
        R.defaultTo([]),
      ),
      custom_запрещено_к_употреблению_при: R.compose(
        R.pluck('key'),
        R.defaultTo([]),
      ),
      custom_продукт_повышает: R.compose(
        R.pluck('key'),
        R.defaultTo([]),
      ),
    }),
  ),
});

export const fetchItem = fetchWithView(fetch, 'ts5c4cs6r347');
export const fetchComposition = fetchWithView(fetch, 'ts5c4cs6r364', {
  cache: true,
});
export const fetchSystemMeals = fetchWithView(fetch, 'ts5c4cs9r343', {
  useLS: true,
});
export const fetchUserMeals = fetchWithView(fetch, 'ts5c4cs9r344');
export const fetchCatalogList = createAction('MEALS__FETCH_CATALOG_LIST');
export const fetchCatalogListSuccess = createAction('MEALS__FETCH_CATALOG_LIST_SUCCESS');
export const calcComposition = createAction('MEALS__CALC_COMPOSITION');
export const copyComposition = createAction('MEALS__COPY_COMPOSITION');

export {
  fetch,
  fetchSuccess,

  create,
  save,
  saveSuccess,
  remove,
  removeSuccess,
  removeWithUndo,
};

export {
  getItemById,
  getKeyById,
  getItems,
  getItem,
  getItemsArray,
} from 'redux/hor/module/selectors';

export {
  getItemsList,
  getProductsByGroups,
  getIsCompositionCalcing,
} from './selectors';

export { default as saga } from './saga';

export default reducer;

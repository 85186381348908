import { all, call } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import statistic from './statistic';
import statisticDates from './statisticDates';

import statistictSaga from './statistic/saga';

export default combineReducers({
  statistic,
  statisticDates,
});

export function* saga() {
  yield all([
    call(statistictSaga),
  ]);
}

import { createAction, createReducer } from 'redux-act';

const initialState = {
  status: undefined,
  user: undefined,
  isSingleStep: true,
  isLight: false,
};

export const checkInvite = createAction('ACTIVATION__CHECK_INVITE');
export const checkInviteSuccess = createAction('ACTIVATION__CHECK_INVITE_SUCCESS');
export const activate = createAction('ACTIVATION__ACTIVATE');
export const activateInvite = createAction('ACTIVATION__ACTIVATE_INVITE');
export const activateFinish = createAction('ACTIVATION__ACTIVATE_FINISH');

const handleCheckInviteSuccess = (state, payload) => ({
  ...state,
  isSingleStep: payload.isSingleStep,
  isLight: payload.isLight,
})

const handleActivateFinish = (state, payload) => {
  const { status } = payload;

  return {
    ...state,
    status,
  };
};

export default createReducer({
  [checkInviteSuccess]: handleCheckInviteSuccess,
  [activateFinish]: handleActivateFinish,
}, initialState);

import * as R from "ramda";

import { getItemsArray } from "redux/hor/module/selectors";

export const getNorm = (state, country) =>
  R.compose(
    R.map(
      R.compose(
        R.last,
        R.sortBy(R.prop("custom_страна")),
        R.filter(
          (item) => !item.custom_страна || item.custom_страна === country
        )
      )
    ),
    R.groupBy(R.prop("element")),
    R.map(
      ({
        ___parent: parent,
        custom_значение_от: min,
        custom_значение_до: max,
        custom_страна,
      }) => ({
        element: parent,
        normMin: min,
        normMax: max,
        norm: isFinite(max) ? (min + max) / 2 : min,
        custom_страна,
      })
    ),
    getItemsArray
  )(state);

import * as R from "ramda";
import cookie from "react-cookie";
import { startSubmit, stopSubmit } from "redux-form";
import {
  select,
  fork,
  call,
  put,
  all,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { push } from "react-router-redux";
import { appKey, contextKey, liteApiUrl } from "config";
import { parse } from "query-string";

import {
  superuserRoleKey,
  liteTrialRoleKey,
  subscriptionExpiredProfile,
} from "helpers/constants";
import { getPublicToken } from "redux/utils/getPublicToken";

import { request } from "utils/api";
import { authorizeUserInCarrotByUserData, trackAuthorize } from "utils/carrot";
import { saveTokenInfo } from "utils/cookie/token";

import { fetchSuccess as fetchWorkerSuccess } from "redux/modules/staff";
import { getUser, getUserIsLight, getPartner } from "redux/selectors";
import authErrors from "./errors";
import {
  login,
  loginSuccess,
  switchUser,
  switchUserSuccess,
  logout,
  hideModal,
  resendEmail,
  setProfile,
  setInviteByToken,
} from "./reducer";

import { activateFinish } from "../activation/reducer";

const getModalError = (status) => {
  if (status.deactevated) {
    return "deactivated";
  }

  if (status.unconfirmed) {
    return "confirmed";
  }

  return null;
};

function b64EncodeUnicode(str) {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) =>
      String.fromCharCode(`0x${p1}`)
    )
  );
}

const authorize = async (params) => {
  const {
    email,
    password,
    remember,
    Context = contextKey,
    ContactArea,
    anonymous,
    isContact,
  } = params;
  const rememberHeader = remember || anonymous ? { Remember: "true" } : {};
  const auth = b64EncodeUnicode(`${email}:${password}`);
  const token = await getPublicToken();

  const response = isContact
    ? await request.post("/authenticate/json/v2", undefined, {
        headers: R.pickBy(Boolean, {
          Context,
          ContactArea,
          ...rememberHeader,
          Authorization: `Basic ${auth}`,
        }),
      })
    : await request.post(
        `/${appKey}t187r/json/v2`,
        {
          parameters: {
            Authorization: `Basic ${auth}`,
          },
        },
        {
          headers: {
            token,
            ...rememberHeader,
            Function: "getToken",
          },
        }
      );

  if (response.data.errors) {
    return Promise.reject({
      response: {
        ...response,
        status: 401,
      },
    });
  }

  return response;
};

const isCanLogin = (response) => {
  const profileKey = R.pathOr(null, ["data", "ProfileKey"], response);
  const roleKey = R.pathOr(null, ["data", "RoleKey"], response);

  return (
    profileKey !== subscriptionExpiredProfile ||
    roleKey === superuserRoleKey ||
    roleKey === liteTrialRoleKey
  );
};

function* setInviteByTokenFlow(action) {
  try {
    const token = yield call(getPublicToken);
    yield call(
      request.post,
      `/${appKey}t197r/json/v2`,
      {
        parameters: {
          secret: action.payload,
        },
      },
      {
        headers: {
          token,
          Function: "acceptGroupInvitation",
        },
      }
    );
    yield put(
      activateFinish({
        status: "ACTIVATED_INVITE",
      })
    );
  } catch (e) {
    yield put(
      activateFinish({
        status: "ACTIVATION_INVITE_ERROR",
      })
    );
  }
}

function* requestResendEmail(email) {
  const token = yield call(getPublicToken);

  yield call(() =>
    request.post(
      `/${appKey}t179r/json/v2`,
      {
        parameters: {
          action: 20,
          staff_mail: email,
        },
      },
      {
        headers: {
          token,
          Function: "staffActions",
        },
      }
    )
  );
}

function* loginErrorFlow(action) {
  const token = yield call(getPublicToken);
  const { data: status } = yield call(() =>
    request.post(
      `/${appKey}t155r/json/v2`,
      {
        parameters: {
          email: action.payload.email,
        },
      },
      {
        headers: {
          token,
          Function: "GetUserStatus",
        },
      }
    )
  );

  const modalError = getModalError(status);
  const text = authErrors[modalError];

  if (modalError) {
    yield put(
      stopSubmit("Login", {
        _error: {
          submitBtn: modalError === "confirmed" ? "Выслать еще раз" : null,
          text: text(status.initiator),
        },
      })
    );
    yield take(resendEmail);
    yield call(requestResendEmail, action.payload.email);
    yield put(stopSubmit("Login"));
  } else {
    yield put(
      stopSubmit("Login", {
        _error: authErrors[401],
      })
    );
  }
}

function* loginFlow(action) {
  yield put(startSubmit("Login"));

  try {
    const response = yield call(authorize, action.payload);

    var minSupportSize = 800;
    var notSupportMobile = document.getElementById(
      liteApiUrl.includes(response.data.domain)
        ? "notSupportMobileLite"
        : "notSupportMobile"
    );
    var content = document.getElementById("content");
    var isMobile =
      /Android|Mobile|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    const width = window.outerWidth || window.innerWidth;
    const height = window.outerHeight || window.innerHeight;

    if (width < minSupportSize && height < minSupportSize && isMobile) {
      notSupportMobile.style.display = "block";
      content.style.display = "none";
      return;
    }

    if (isCanLogin(response)) {
      const domain = response.data.domain;

      yield put(
        loginSuccess({
          ...action.payload,
          response,
        })
      );
      const search = parse(window.location.search.slice(1));
      const path = search.path ? search.path : "/patients";

      if (!action.payload.anonymous) {
        if (liteApiUrl.includes(domain)) {
          saveTokenInfo({
            auth: {
              ...response.data,
              remember: action.payload.remember,
              domain: response.data.domain
                ? `https://${response.data.domain}`
                : undefined,
            },
          });
          cookie.save("APP", "LITE", {
            expires: new Date(Date.now() + 9999 * 60 * 60 * 1000),
            path: "/",
          });
          window.location.href = path;
        } else {
          yield put(push(path));
        }
      }
    } else {
      yield put(
        stopSubmit("Login", {
          _error: {
            text: authErrors.subscription,
          },
        })
      );
    }
  } catch ({ response }) {
    const { status } = response;

    if (status === 401) {
      yield call(loginErrorFlow, action);
    } else {
      const errorText = R.propOr(authErrors.unknown, status, authErrors);

      yield put(
        stopSubmit("Login", {
          _error: errorText,
        })
      );
    }
  }
}

function* hideModalFlow() {
  yield put(stopSubmit("Login"));
}

function* switchUserFlow(action) {
  try {
    const checkAccessResponse = yield call(() =>
      request.post(
        `/${appKey}t47r/json/v2`,
        {
          parameters: {
            email: action.payload.login,
          },
        },
        {
          token: action.payload.token,
          headers: {
            Function: "CheckAllowToLoginAs",
          },
        }
      )
    );
    const { allow_to_login_as: AllowToLoginAs, кey: Key } =
      checkAccessResponse.data;
    const allowToLoginAs = AllowToLoginAs === "True";

    if (!Key) {
      throw new Error("Такого пользователя не существует");
    }

    if (!allowToLoginAs && !action.payload.isChosen) {
      throw new Error(
        "Пользователь еще не дал доступ в свой аккаунт, либо время доступа истекло"
      );
    }

    const response = yield call(request.post, "/authenticate/json/v2", null, {
      headers: {
        AsUser: Key,
        Token: action.meta.token,
      },
    });

    yield put(
      switchUserSuccess({
        userAKey: Key,
        token: response.data.Token,
      })
    );
    const state = yield select((s) => s);
    saveTokenInfo(state);

    cookie.save("isSupport", 1, {
      expires: new Date(Date.now() + 240 * 60 * 60 * 1000),
      path: "/",
    });

    window.location = "/patients";
  } catch (error) {
    yield put(
      stopSubmit(action.payload.form, {
        login: error.message,
      })
    );
  }
}

function* logoutFlow(action) {
  const { isSessionOver } = action.payload;
  const partner = yield select(getPartner);

  let logoutRedirect = partner ? "/sd" : "/login";

  if (isSessionOver) {
    logoutRedirect += `?logout=true&path=${window.location.pathname}`;
  }

  if (window.location.pathname !== "/login") {
    yield put(push(logoutRedirect));
  }

  const state = yield select((s) => s);
  saveTokenInfo(state);
}

function* carrotLoginTrackFlow() {
  while (true) {
    const loginSuccessSaga = yield fork(function* loginSuccessFlow() {
      yield take(loginSuccess);
    });
    const action = yield take(fetchWorkerSuccess);

    if (action.payload.key) {
      const user = yield select(getUser);
      const isLight = yield select(getUserIsLight);
      authorizeUserInCarrotByUserData({
        ...user,
        isLight,
      });
    }

    if (!loginSuccessSaga.isRunning()) {
      yield trackAuthorize();
    }
  }
}

function* setProfileFlow(action) {
  const state = yield select((s) => s);
  saveTokenInfo(state);
}

export default function* saga() {
  yield all([
    takeLatest(login, loginFlow),
    takeEvery(hideModal, hideModalFlow),
    takeEvery(switchUser, switchUserFlow),
    takeEvery(logout, logoutFlow),
    takeEvery(setProfile, setProfileFlow),
    takeEvery(setInviteByToken, setInviteByTokenFlow),
    call(carrotLoginTrackFlow),
  ]);
}

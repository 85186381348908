import * as R from 'ramda';
import { appKey } from 'config';

const fetchWithView = R.curry((fetch, view, paramsCommon = {}) => params => fetch({
  ...paramsCommon,
  ...params,
  view: R.includes('ts5c', view) ? `${appKey}${view}` : view,
}));

export default fetchWithView;

import * as R from 'ramda';
import { createSelector } from 'reselect';

import createOrmSelector from 'redux/utils/createOrmSelector';

import { getItemsList as getProductsItems } from 'redux/modules/meals';
import getUserType from 'redux/selectors/getUserType';
import getSupplementAll from 'redux/selectors/getSupplementAll';

import getDietanamnezItems from '../getDietanamnezItems';
import getDietanamnezKindsFood from '../getDietanamnezKindsFood';
import getDietanamnezType from '../getDietanamnezType';

const getPatientTiles = createOrmSelector(
  getDietanamnezKindsFood,
  getDietanamnezType,
  getUserType,

  (session, kindsFood, daType, userType) => {
    const { DietanamnezTiles } = session;

    return DietanamnezTiles
      .exclude({
        ___parent: null,
      })
      .filter(({ custom_формат }) => !custom_формат || custom_формат === daType)
      .filter(({ custom_версия_приложения }) => !custom_версия_приложения || custom_версия_приложения === userType)
      .filter((item) => {
        const { custom_специфические_исключения } = item;

        return R.compose(
          R.isEmpty,
          R.intersection(custom_специфические_исключения),
        )(kindsFood);
      })
      .toRefArray();
  },
);

export default createSelector(
  getDietanamnezItems,
  getPatientTiles,
  state => getProductsItems(state.meals),
  getSupplementAll,

  (dietanamnez, tiles, products, supplementsList) => {
    const dishes = R.compose(
      R.unnest,
      R.map((item) => {
        const items = R.isEmpty(item.custom_список_блюд) ?
          [item.custom_блюдо] :
          item.custom_список_блюд;

        return R.compose(
          R.uniqBy(R.prop('___parent')),
          R.map((productKey) => {
            const product = R.find(R.propEq('value', productKey), products) || {};
            const key = R.head(product.custom_эталонные_блюда || []) ||
              item.custom_эталонное_блюдо_для_фронта;
            const tile = R.find(R.propEq('key', key), tiles);
            const parent = R.propOr(null, '___parent', tile);

            return {
              ___parent: parent || key,
              key: item.custom_блюдо,
              custom_наименование: item.custom_строка_поиска || product.custom_название,
              custom_ед_изм: product.custom_ед_изм_наименование,
              isProduct: true,
            };
          }),
        )(items);
      }),
      R.uniqBy(R.prop('custom_блюдо')),
      R.filter(item => R.contains('t4r', item.custom_блюдо)),
      R.values,
    )(dietanamnez);

    const supplements = supplementsList.map(item => ({
      ___parent: 'SUPPLEMENT',
      key: item.key,
      custom_иконка_url: item.custom_картинка_url,
      custom_наименование: item.custom_наименование,
      custom_количество: item.custom_макс_доз_в_день,
      custom_ед_изм: item.custom_лекарственная_форма,
    }));

    return R.groupBy(R.prop('___parent'), [...dishes, ...tiles, ...supplements]);
  },
);

import { createSelector } from 'reselect';

import { getRealFullName } from 'utils/getFullName';
import getUser from '../getUser';

export default createSelector(
  getUser,

  getRealFullName,
);

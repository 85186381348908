import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import PageNotFound from 'components/PageNotFound';

const textByStatus = {
  404: <span>
    Неправильно набран адрес
    <br />
    или такой страницы
    <br />
    не существует
  </span>,
  403: <span>
    У вас нет доступа
  </span>,
};

export default class ErrorPage extends PureComponent {
  static propTypes = {
    route: PropTypes.object,
    router: PropTypes.object,
  }

  static defaultProps = {
    route: {},
    router: {},
  }

  handleClick = () => {
    const { router } = this.props;

    if (router.location.action === 'PUSH') {
      router.goBack();
    } else {
      router.push('/patients');
    }
  }

  render() {
    const { status } = this.props.route;

    return (
      <PageNotFound
        status={status}
        onClick={this.handleClick}
      >
        {textByStatus[status]}
      </PageNotFound>
    );
  }
}

import * as R from 'ramda';
import { createSelector } from 'reselect';

import getRation from '../getRation';

export default createSelector(
  getRation,

  R.propOr(0, 'custom_продолжительность'),
);

import { takeEvery, all } from 'redux-saga/effects';

import { appKey } from 'config';
import { request } from 'utils/api';

import getKeyInfo from 'utils/getKeyInfo';

import {
  saveSuccess,
} from './index';

function saveSuccessFlow(action) {
  try {
    const productKey = action.payload.data.___parent;
    const isMeal = getKeyInfo(productKey).table === 4;

    if (isMeal) {
      request.post(`/${appKey}t179r/json/v2`, {
        parameters: {
          dish_key: productKey,
        },
      }, {
        headers: {
          Function: 'createDishExtraData',
        },
      })
    }
  } catch (error) {
    console.error(error);
  }
}


export default function* saga() {
  yield all([
    takeEvery(saveSuccess, saveSuccessFlow),
  ]);
}

import { attr, Model } from 'redux-orm';

import generator from 'redux/hor/orm-module';
import fetchWithView from 'redux/utils/fetchWithView';

import getKeyInfo from 'utils/getKeyInfo';

const {
  reducer,
  fetch,
} = generator({
  name: 'AnalysisType',
  tableId: 8,
});

export default class AnalysisType extends Model {
  static modelName = 'AnalysisType';

  static reducer = reducer;

  static options = {
    idAttribute: 'key',
  }

  static fields = {
    custom_наименование: attr(),
  }

  get id() {
    return getKeyInfo(this.key).record;
  }
}

export const fetchList = fetchWithView(fetch, 'ts5c8cs9r17', {
  useLS: true,
});

export {
  fetch,
};

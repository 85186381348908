import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import Icon from 'components/Icon';
import Title from 'components/Title';

import styles from './Success.module.css';

const cx = classNames.bind(styles);

export default class Success extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    noPadding: PropTypes.bool,
  }

  static defaultProps = {
    text: undefined,
    noPadding: false,
  }

  render() {
    const { children, noPadding } = this.props;

    return (
      <div
        className={cx({
          success: true,
          success_no_paddings: noPadding,
        })}
      >
        <div className={cx('circle')}>
          <Icon icon='tick' width={28} height={20} />
        </div>
        <Title size='medium'>
          {children}
        </Title>
      </div>
    );
  }
}

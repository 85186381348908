import React, { PureComponent } from 'react';
import { Field as FormField } from 'redux-form';
import { withReduxForm } from 'redux-form/es/ReduxFormContext';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import FieldPassword from './FieldPassword';

class FieldPasswordConnected extends PureComponent {
  static contextTypes = {
    onFormChange: PropTypes.func,
  };

  static propTypes = {
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
  };

  static defaultProps = {
    name: undefined,
    onBlur: () => {},
    onChange: () => {},
    onFocus: () => {},
  };

  handleFocus = onFocus => (event) => {
    this.value = event.target.value;
    onFocus(event);
    this.props.onFocus(event);
  };

  handleBlur = onBlur => (eventOrValue) => {
    onBlur(eventOrValue);

    this.props.onBlur(eventOrValue);

    if ('target' in eventOrValue && this.value !== eventOrValue.target.value && this.context.onFormChange) {
      this.context.onFormChange();
    }
  };

  handleChange = onChange => (value, event) => {
    onChange(event);

    this.props.onChange(value, event);
  }

  renderField = (field = {}) => (
    <FieldPassword
      {...this.props}
      {...field.input}
      {...field.meta}
      onChange={this.handleChange(field.input.onChange)}
      onFocus={this.handleFocus(field.input.onFocus)}
      onBlur={this.handleBlur(field.input.onBlur)}
    />
  );

  render() {
    const { _reduxForm: reduxForm } = this.props;
    const isFieldArray = R.test(/\[\d+\]/, this.props.name || '');

    if (!reduxForm || (!R.contains(this.props.name, reduxForm.fields) && !isFieldArray)) {
      return (
        <FieldPassword {...this.props} />
      );
    }

    return (
      <FormField
        component={this.renderField}
        {...this.props}
      />
    );
  }
}

export default withReduxForm(FieldPasswordConnected)
import * as R from 'ramda';
import generator from 'redux/hor/module';
import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,
  fetch,
} = generator({
  name: 'Roles',
  tableId: 102,
  parseItem: R.evolve({
    custom_роль: R.evolve({
      label: R.ifElse(R.equals('Суперпользователь'), R.always('Администратор'), R.identity),
    }),
  }),
});

export const fetchList = fetchWithView(fetch, 'ts5c102cs9r273', {
  useLS: true,
});

export {
  getRoles,
  getRolesSelect,
  getRole,
  getRoleRights,
} from './seletors';

export {
  fetch,
};

export default reducer;

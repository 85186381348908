import { createSelector } from 'reselect';

import { appKey } from 'config';

import getSupplementId from '../getSupplementId';

export default createSelector(
  getSupplementId,

  id => id ? `${appKey}t158r${id}` : id,
);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import Triangle from './Triangle';

export default class NormRangeLine extends PureComponent {
  static propTypes = {
    norm: PropTypes.number,
    normMin: PropTypes.number,
    normMax: PropTypes.number,
    height: PropTypes.number,
    multiplier: PropTypes.number,
    x: PropTypes.func,
    y: PropTypes.func,
  }

  static defaultProps = {
    norm: undefined,
    normMin: undefined,
    normMax: undefined,
    multiplier: 0,
    height: 0,
    triangles: [],
    x: () => {},
    y: () => {},
  }

  getNorms() {
    const { norm, multiplier } = this.props;
    const normMaxValue = isFinite(this.props.normMax) ? this.props.normMax : norm * 3;
    const normMin = isFinite(this.props.normMin) ? this.props.normMin / multiplier : null;
    const normMax = normMaxValue ? normMaxValue / multiplier : null;

    return [normMin, normMax];
  }

  render() {
    const { height, y, x } = this.props;
    const [normMin, normMax] = this.getNorms();
    const triangles = this.getNorms();

    if (R.isNil(normMin) || R.isNil(normMax)) {
      return null;
    }

    return (
      <g>
        <line
          y1={y(height / 2)}
          x1={x(normMin)}
          x2={x(normMax)}
          y2={y(height / 2)}
          stroke='black'
          strokeWidth={1}
        />
        <Triangle
          height={height}
          triangles={triangles}
          x={x}
        />
      </g>
    );
  }
}

import * as R from 'ramda';
import { createSelector } from 'reselect';

export default createSelector(
  R.path(['Rations', 'ui', 'activeGroup']),
  R.path(['Rations', 'ui', 'activeItem']),
  (state, props) => props.groupKey,

  (activeGroup, activeItem, groupKey) => {
    if (groupKey) {
      const isInactiveByGroup = activeGroup && activeGroup !== groupKey;

      return isInactiveByGroup || activeItem;
    }

    return !!activeItem || !!activeGroup;
  },
);

import createSelector from 'redux/utils/createOrmSelector';

import getRationKey from '../getRationKey';

export default createSelector(
  getRationKey,

  (session, rationKey) => {
    const { Ration } = session;

    if (!Ration.idExists(rationKey)) {
      return null;
    }

    return Ration.withId(rationKey).days.toRefArray();
  },
);

import * as R from 'ramda';

const getLoader = R.propOr(false);

export const getLoaderByType = (type, state) => {
  if (type !== 'global') {
    return getLoader(type, state);
  }

  return getLoader(type, state) && !getLoader('page', state);
};

const config = {
  min: {
    limiter: 10,
  },
  max: {
    limiter: 100,
  },
  typeFiles: {
    correctTypes: ['image/png', 'image/jpg', 'image/jpeg'],
  },
  sizeFiles: {
    correctSize: 2e6,
  },
};

export default config;

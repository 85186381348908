import * as R from 'ramda';
import createOrmSelector from 'redux/utils/createOrmSelector';

import getRationKey from '../getRationKey';

export default createOrmSelector(
  getRationKey,
  R.path(['productComposition']),

  (session, key, state) => {
    const { Ration } = session;

    return Ration
      .withId(key)
      .getComposition(state);
  },
);

import * as R from 'ramda';
import { all, takeLatest, put, select } from 'redux-saga/effects';
import delay from 'utils/delay';

import {
  setNotification,
} from './reducer';

import {
  getCurrentNotification,
} from './selectors';

function* setNotificationFlow() {
  yield delay(1000);

  const current = yield select(getCurrentNotification);

  if (!current) {
    return;
  }

  if (R.includes(current.type, ['isDone', 'isInvalid'])) {
    yield delay(2000);

    yield put(setNotification({
      [current.type]: false,
    }));
  }

  if (current.type === 'isRemove') {
    const time = current.content.duration;
    const nextTime = time - 1;

    if (nextTime > 0) {
      yield put(setNotification({
        isRemove: {
          ...current.content,
          duration: nextTime,
        },
      }));
    } else {
      yield put(setNotification({
        isRemove: false,
      }));
    }
  }
}

export default function* saga() {
  yield all([
    takeLatest(setNotification, setNotificationFlow),
  ]);
}

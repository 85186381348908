import * as R from 'ramda';
import memoizee from 'memoizee';

import { getItemsArray } from 'redux/hor/module/selectors';

export const getSelectData = memoizee(
  R.compose(
    R.map(i => ({
      id: i.key,
      text: i.custom_наименование,
      value: i.key,
    })),
    R.sortBy(R.prop('custom_сортировка')),
    getItemsArray,
  ),
  { max: 1 },
);

import * as R from 'ramda';
import createOrmSelector from 'redux/utils/createOrmSelector';

export default createOrmSelector(
  (session) => {
    const { DietanamnezPortion } = session;
    const items = DietanamnezPortion.all().toRefArray();

    return R.compose(
      R.map(R.map(item => ({
        label: item.custom_мера,
        value: item.custom_мера,
      }))),
      R.groupBy(R.prop('___parent')),
    )(items);
  },
);

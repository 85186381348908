import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { getToken } from 'redux/modules/auth';

import pagePermission from 'decorators/pagePermission';

class PublicLoginPage extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
  }

  static defaultProps = {
    children: undefined,
  }

  render() {
    return React.Children.only(this.props.children);
  }
}

export default R.compose(
  pagePermission({
    rule: (state) => {
      const { auth, routing: { locationBeforeTransitions } } = state;
      return locationBeforeTransitions.pathname === '/g' || !getToken(auth);
    },
    url: '/patients',
  }),
)(PublicLoginPage);

import * as R from 'ramda';
import validators from './validators';
import defaultText from './defaultText';

const getErrorText = rules => (error) => {
  if (!error) {
    return undefined;
  }

  const validatorConfig = R.pathOr({}, [error], rules);
  const getText = R.propOr(defaultText[error], 'errorText', validatorConfig);
  const text = typeof getText === 'function' ? getText(validatorConfig) : getText;

  return text || 'Неизвестная ошибка';
};

export default function createFieldValidate(rules) {
  return (value) => {
    const getError = R.compose(
      getErrorText(rules),
      R.head,
      R.keys,
      R.pickBy(R.complement(Boolean)),
    );

    if (!rules) {
      return undefined;
    }

    const { required, ...rest } = rules;

    const isValid = validators.required(required)(value);

    if (!isValid) {
      if (required) {
        return getError({
          required: false,
        });
      }

      return getError({});
    }

    return R.compose(
      getError,
      R.mapObjIndexed((validatorConfig, validatorName) => {
        if (!validators[validatorName]) {
          return true;
        }

        const validator = validators[validatorName](validatorConfig);

        return validator(value);
      }),
    )(rest);
  };
}

import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getPatientJournal } from 'redux/modules/journal';

import getStaff from '../getStaff';
import getPatientKey from '../getPatientKey';

export default createSelector(
  getStaff,
  getPatientKey,
  R.path(['journal']),

  (staff, patientKey, journal) => R.compose(
    R.map((item) => {
      const doctor = R.find(R.propEq('key', item.doctor), staff);

      return {
        ...item,
        doctorName: R.compose(
          R.join(' '),
          R.values,
          R.pick(['custom_firstname', 'custom_midname', 'custom_lastname']),
          R.defaultTo({}),
        )(doctor),
      };
    }),
    getPatientJournal,
  )(patientKey, journal),
);

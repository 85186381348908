import * as R from 'ramda';
import { appKey } from 'config';
import getKeyInfo from 'utils/getKeyInfo';

const parsers = {
  98: {
    fields: {
      custom_элемент_рациона: R.prop('custom_элемент_состава'),
      custom_значение: R.prop('custom_количество'),
      custom_продукт: R.prop('custom_блюдо'),
      ___parent: R.prop('custom_блюдо'),
    },
    key: R.path(['fields', 'custom_source']),
  },
  113: {
    fields: {
      custom_элемент_рациона: R.compose(
        R.objOf('key'),
        R.prop('custom_элемент_состава'),
      ),
      custom_значение: R.compose(
        Number,
        R.replace(',', '.'),
        R.prop('custom_количество'),
      ),
      custom_продукт: R.prop('custom_блюдо'),
      ___parent: R.compose(
        R.objOf('key'),
        R.prop('custom_блюдо'),
      ),
    },
    key: R.path(['fields', 'custom_source']),
  },
  114: {
    fields: {
      custom_блюдо: R.prop('custom_блюдо'),
      custom_время: R.prop('custom_время'),
      custom_номер: R.prop('custom_номер'),
      custom_количество_план: R.compose(
        parseInt,
        R.prop('custom_план'),
      ),
      ___parent: R.prop('custom_дневной_рацион_пациента'),
      custom_ссылка_на_активное_блюдо: R.prop('custom_ссылка_на_активное_блюдо'),
    },
    key: R.path(['fields', 'custom_блюдо_рациона', 'key']),
  },
  134: {
    fields: {
      custom_рецепт: R.prop('custom_рецепт'),
    },
    key: R.compose(
      R.replace('cXaX', appKey),
      R.path(['fields', 'custom_ключ_блюда'])
    ),
  },
  135: {
    fields: {
      ___parent: R.compose(
        R.objOf('key'),
        R.replace('cXaX', appKey),
        R.prop('custom_ключ_блюда'),
      ),
      custom_ингредиент: fields => ({
        key: fields.custom_ключ_ингредиента,
        label: fields.custom_ингредиент,
      }),
      custom_единица_измерения__гр_мл: R.compose(
        R.objOf('label'),
        R.prop('custom_ед__изм'),
      ),
      custom_вес: R.prop('custom_вес'),
    },
    key: R.path(['fields', 'custom_ключ_тех_карты']),
  },
  139: {
    fields: {
      custom_элемент_рациона: R.prop('custom_элемент_состава'),
      custom_значение: R.prop('custom_количество'),
      custom_продукт: R.prop('custom_блюдо'),
      ___parent: R.prop('custom_блюдо'),
    },
    key: R.path(['fields', 'custom_source']),
  },
  166: {
    fields: {
      ___parent: R.compose(
        R.objOf('key'),
        R.prop('custom_бад_ключ'),
      ),
      custom_элемент: R.compose(
        R.objOf('key'),
        R.prop('custom_элемент_состава_бад'),
      ),
      custom_значение_на_порцию: R.prop('custom_количество_в_бад'),
    },
    key: R.path(['fields', 'custom_источник_ключ']),
  },
};

export default function transformVirtualCategory(group) {
  const key = R.pathOr(null, ['list', 0, 'key'], group);
  const { table } = getKeyInfo(key);

  if (!parsers[table]) {
    return [group];
  }

  const list = group.list;
  const groupParsers = [].concat(parsers[table]);

  return R.map((parser) => {
    const transformedList = R.map((item) => {
      if (typeof parser === 'function') {
        return parser(item);
      }

      return {
        fields: R.map(val => val(item.fields), parser.fields),
        key: parser.key(item),
      };
    }, list);

    return {
      ...group,
      list: R.reject(R.isNil, transformedList),
    };
  }, groupParsers);
}

import {
  getAnalysisItem,
} from '../../selectors';

import getAnalysisViewBlood from './getAnalysisViewBlood';
import getAnalysisViewGenes from './getAnalysisViewGenes';
import getAnalysisViewHair from './getAnalysisViewHair';
import getAnalysisViewSalt from './getAnalysisViewSalt';
import getAnalysisViewVitamin from './getAnalysisViewVitamin';

export const getAnalysisStatefulConfig = (state, type) => {
  const analysisTypeToStateMap = {
    194: getAnalysisViewBlood,
    196: getAnalysisViewGenes,
    193: getAnalysisViewVitamin,
    192: getAnalysisViewHair,
    195: getAnalysisViewSalt,
  };

  return analysisTypeToStateMap[type] || (() => undefined);
};

const getAnalysisViewData = (state) => {
  const analysis = getAnalysisItem(state);
  const { typeId } = analysis;
  const transformData = getAnalysisStatefulConfig(state, typeId);

  if (!transformData) {
    return null;
  }

  return transformData(state);
};

export default getAnalysisViewData
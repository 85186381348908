import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';

import styles from './IconLoader.module.css';

const cx = classNames.bind(styles);

export default class IconLoader extends PureComponent {
  static propTypes = {
    size: PropTypes.oneOf([
      'small',
    ]),
  }

  static defaultProps = {
    size: null,
  }

  render() {
    const { size } = this.props;

    return (
      <div className={cx('iconLoader')}>
        <div
          className={cx({
            inner: true,
            [`inner_size_${size}`]: size,
          })}
        />
      </div>
    );
  }
}

import * as R from 'ramda';
import { createAction } from 'redux-act';
import { attr, Model as ORMModel } from 'redux-orm';

import generator from 'redux/hor/orm-module';
import fetchWithView from 'redux/utils/fetchWithView';
import convertFromServerTimeZoneToCurrentTimeZone from 'utils/convertFromServerTimeZoneToCurrentTimeZone';
import removeRelated from 'utils/orm/removeRelated';

const {
  reducer,
  fetch,
  fetchSuccess,
  create,
  save,
  saveSuccess,
  removeWithUndo,
} = generator({
  name: 'DiaryRation',
  tableId: 39,
  parseItem: R.evolve({
    custom_продолжительность: Number,
    custom_степень: R.propOr(null, 'key'),
    custom_цель: R.propOr(null, 'value'),
    custom_тип_шаблона: R.propOr(null, 'value'),
    custom_режим_питания: R.propOr(null, 'key'),
    custom_прописано_при: R.compose(
      R.pluck('key'),
      R.defaultTo([]),
    ),
    custom_спец_виды_питания: R.compose(
      R.pluck('key'),
      R.defaultTo([]),
    ),
    custom_начало_диеты: convertFromServerTimeZoneToCurrentTimeZone,
    ___parent: R.propOr(null, 'key'),
    ___owner: R.propOr(null, 'key'),
    ___createduser: R.propOr(null, 'key'),
  }),
});

export default class Ration extends ORMModel {
  static modelName = 'Ration';

  static options = {
    idAttribute: 'key',
  }

  static fields = {
    custom_степень: attr(),
    custom_цель: attr(),
    custom_тип_шаблона: attr(),
    custom_режим_питания: attr(),
    custom_прописано_при: attr(),
    custom_спец_виды_питания: attr(),
    ___parent: attr(),
    ___owner: attr(),
    ___createduser: attr(),
    custom_не_назначен: attr(),
    custom_удалено: attr(),
    custom_название: attr(),
    custom_начало_диеты: attr(),
    custom_спк__до: attr(),
    custom_спк__от: attr(),
    custom_debuginfo: attr(),
    custom_степень_за_месяц: attr(),
    custom_текущая_длительность: attr(),
    custom_рекомендации: attr(),

    // custom_продолжительность: attr(), Write-Only
    // custom_создать_по_шаблону: attr(), Write-Only
    // custom_автогенерация: attr(), Write-Only
  }

  static reducer = reducer;

  get custom_продолжительность() {
    if (this.days.count()) {
      return this.days.count();
    }

    return this.custom_текущая_длительность;
  }

  get days() {
    return this // eslint-disable-line no-underscore-dangle
      ._days
      .exclude({
        isDeleted: true,
      })
      .exclude({
        isDeleting: true,
      })
      .orderBy('custom_номер');
  }

  getGroupsComposition(state) {
    const compositions = this.days.toModelArray().map(day => day.getGroupsComposition(state));

    return R.compose(
      R.map(R.map(R.multiply(1 / this.custom_продолжительность))),
      R.map(R.reduce(R.mergeWith(R.add), {})),
      R.transpose,
    )(compositions);
  }

  getComposition(state) {
    return R.reduce(R.mergeWith(R.add), {}, this.getGroupsComposition(state));
  }

  getIsFilled() {
    return R.all(day => day.getIsFilled(), this.days.toModelArray());
  }
}

export const fetchRationComposition = createAction('DIARYRATION__FETCH_COMPOSITION');
export const fetchRationCompositionSuccess = createAction('DIARYRATION__FETCH_COMPOSITION_SUCCESS');
export const fetchRationCompositionFailure = createAction('DIARYRATION__FETCH_COMPOSITION_FAILURE');
export const fetchBase = fetchWithView(fetch, 'ts5c39cs6r434');
export const fetchPrint = fetchWithView(fetch, 'for_print');
export const fetchList = fetchWithView(fetch, 'ts5c39cs9r373');
export const fetchUnassigned = fetchWithView(fetch, 'ts5c39cs9r376');
export const fetchUnassignedTemplates = fetchWithView(fetch, '_unassigned_template');
export const fetchTemplates = fetchWithView(fetch, 'ts5c39cs9r374', {
  lazyLoad: true,
});
export const fetchGeneratorTemplates = fetchWithView(fetch, 'ts5c39cs7r523', {
  useLS: true,
});
export const fetchSystemTemplates = fetchWithView(fetch, 'ts5c39cs9r375', {
  useLS: true,
});
export const fetchInstitutesTemplates = fetchWithView(fetch, 'ts5c39cs9r425', {
  lazyLoad: true,
});

export const createTemplate = createAction('DIARYRATION__CREATE_TEMPLATE');
export const assign = createAction('DIARYRATION__ASSIGN');
export const manual = createAction('DIARYRATION__MANUAL');
export const autogenerate = createAction('DIARYRATION__AUTOGENERATE');
export const remove = createAction('DIARYRATION__REMOVE_CUSTOM');
export const addDay = createAction('DIARYRATION__ADD_DAY');
export const cleanupRelated = createAction('DIARYRATION__CLEANUP_RELATED');
export const checkDiet = createAction('DIARYRATION__CHECK_DIET');
export const checkDietSuccess = createAction('DIARYRATION__CHECK_DIET_SUCCESS');
export const checkDietFailure = createAction('DIARYRATION__CHECK_DIET_FAILURE');

reducer.on(cleanupRelated, (Model, session, { key }) => {
  // HACK: remove related from second-level depth, because Days referenced
  // by foreign key _days are required for computing ration days length
  const item = Model.withId(key);
  R.forEachObjIndexed(
    (_, fieldKey) => R.forEach(
      relatedItem => removeRelated(relatedItem),
      item[fieldKey].toModelArray(),
    ),
    item.getClass().virtualFields,
  );
});

export {
  fetch,
  fetchSuccess,

  create,
  save,
  saveSuccess,
  removeWithUndo,
};

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import styles from './Legend.module.css';

const cx = classNames.bind(styles);

export default class Legend extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    required: PropTypes.bool,
  }

  static defaultProps = {
    children: undefined,
    required: false,
  }

  render() {
    const { required } = this.props;

    return (
      <span className={cx('legend')}>
        {this.props.children}
        {required && <span className={cx('required')}>*</span>}
      </span>
    );
  }
}

import React, { PureComponent } from 'react';
import { connect } from 'utils/react-redux';
import { withRouter } from 'react-router';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import withFetcher from 'decorators/withFetcher';

import { checkInvite, activateInvite } from 'redux/modules/activation';

import ActivationStatus from '../../components/ActivationStatus';
import InviteUserForm from '../../containers/InviteUserForm';
import InstituteForm from '../../containers/InstituteForm';

const mapStateToProps = state => ({
  activationStatus: state.activation.status,
  isSingleStep: state.activation.isSingleStep,
  isLight: state.activation.isLight
});

const mapDispatchToProps = {
  activateInvite,
};

class PublicSignupPage extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    handleSubmit: PropTypes.func,
    valid: PropTypes.bool,
    router: PropTypes.object,
    submitting: PropTypes.bool,
    form: PropTypes.string,
    activationStatus: PropTypes.string,
    isSingleStep: PropTypes.bool,
  }

  static defaultProps = {
    activate: () => {},
    handleSubmit: () => {},
  }

  state = {
    step: 'USER',
  }

  handleCloseModal = () => {
    this.props.router.push('/login');
  }

  handleUserSubmit = (values, form) => {
    const { isSingleStep } = this.props;

    if (isSingleStep) {
      this.handleSubmit(values, null, form);
    } else {
      this.setState({
        step: 'INSTITUTION',
      });
      this.staff = values;
    }
  }

  handleInstituteSubmit = (values, form) => {
    this.handleSubmit(this.staff, values, form)
  }

  handleSubmit = (staff, institution, form) => {
    const { router } = this.props;
    const query = R.pathOr({}, ['location', 'query'], router);

    this.props.activateInvite({
      key: query.key,
      token: query.token,
      email: query.email,
      staff,
      institution,
      form,
    });
  }

  renderModal() {
    const { activationStatus } = this.props;

    return (
      <ActivationStatus
        isOpen={!!activationStatus}
        activationStatus={activationStatus}
        onCancel={this.handleCloseModal}
      />
    );
  }

  render() {
    const steps = {
      USER: (
        <InviteUserForm
          onSubmit={this.handleUserSubmit}
          isLight={this.props.isLight}
        />
      ),
      INSTITUTION: (
        <InstituteForm
          onSubmit={this.handleInstituteSubmit}
        />
      ),
    }

    return (
      <div>
        <Helmet title='Приглашение' />
        {steps[this.state.step]}
        {this.renderModal()}
      </div>
    );
  }
}

export default R.compose(
  withFetcher([
    (_, __, { location }) => checkInvite({ query: location.query }),
  ]),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PublicSignupPage);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  getPercent,
  gradientByColor,
  groupedColors,
  baseColors,
  CHART_PADDING_TOP,
} from '../../helpers';

export default class OneRect extends PureComponent {
  static propTypes = {
    rectStart: PropTypes.number,
    rectEnd: PropTypes.number,
    index: PropTypes.number,
    onMouseEnter: PropTypes.func,
    onMouseMove: PropTypes.func,
    onMouseLeave: PropTypes.func,
    height: PropTypes.number,
    norm: PropTypes.number,
    normMin: PropTypes.number,
    normMax: PropTypes.number,
    id: PropTypes.string,
    data: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.array,
    ]),
    dataInCenter: PropTypes.number,
    x: PropTypes.func,
    width: PropTypes.func,
  }

  getIsValid(isGrouped) {
    const {
      normMin,
      normMax,
      rectEnd,
    } = this.props;

    return isGrouped || (rectEnd >= normMin && rectEnd <= normMax) || !this.props.norm;
  }

  render() {
    const {
      rectStart,
      rectEnd,
      index,
      onMouseEnter,
      onMouseMove,
      onMouseLeave,
      height,
      id,
      data,
      dataInCenter,
      x,
      width,
    } = this.props;

    const isGrouped = Array.isArray(data);
    const percent = getPercent(dataInCenter, rectEnd - rectStart);
    const colorMap = isGrouped ? groupedColors : baseColors;
    const isValid = this.getIsValid(isGrouped);
    const color = R.pathOr('', [index, isValid ? 'valid' : 'invalid'], colorMap);
    const gradientColor = gradientByColor[color] ? gradientByColor[color](id) : color;

    return (
      <rect
        key={index}
        x={x}
        height={height}
        width={width}
        y={CHART_PADDING_TOP}
        fill={gradientColor}
        onMouseEnter={onMouseEnter(percent, color)}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      />
    );
  }
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';

import styles from './Text.module.css';

const cx = classNames.bind(styles);

export default class Text extends PureComponent {
  static propTypes = {
    block: PropTypes.bool,
    inline: PropTypes.bool,
    children: PropTypes.node,
    color: PropTypes.oneOf([
      'inherit',
      'white',
      'gray',
      'red',
      'red-dark',
      'blue',
    ]),
    component: PropTypes.string,
    ellipsis: PropTypes.bool,
    size: PropTypes.oneOf([
      'inherit',
      'small',
      'mini',
      'medium',
      'big',
      'xbig'
    ]),
    uppercase: PropTypes.bool,
    title: PropTypes.string,
    weight: PropTypes.oneOf([
      'bold',
      'medium',
    ]),
    whiteSpace: PropTypes.oneOf([
      'nowrap',
    ]),
  };

  static defaultProps = {
    block: false,
    inline: false,
    children: undefined,
    component: 'span',
    color: undefined,
    ellipsis: false,
    uppercase: undefined,
    title: undefined,
    size: undefined,
    weight: undefined,
    whiteSpace: undefined,
  };

  render() {
    const {
      block,
      inline,
      color,
      component,
      ellipsis,
      size,
      uppercase,
      weight,
      whiteSpace,
      inherit,
    } = this.props;
    const Tag = component;

    return (
      <Tag
        title={ellipsis ? (this.props.title !== undefined ? this.props.title : this.props.children) : null}
        className={cx({
          text: true,
          text_block: block,
          text_inline: inline,
          text_uppercase: uppercase,
          text_ellipsis: ellipsis,
          text_inherit: inherit,
          [`text_color_${color}`]: color,
          [`text_size_${size}`]: size,
          [`text_weight_${weight}`]: weight,
          [`text_white-space_${whiteSpace}`]: whiteSpace,
        })}
      >
        {this.props.children}
      </Tag>
    );
  }
}

import moment from 'moment';
import { createSelector } from 'reselect';

import getInstitutionSubscriptionEnd from '../getInstitutionSubscriptionEnd';

export default createSelector(
  getInstitutionSubscriptionEnd,

  endDate => moment(endDate).diff(moment().startOf('day'), 'days'),
) as () => number;

import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getItemsArrayByParent } from 'redux/hor/module/selectors';

export const getNotesByPatient = createSelector(
  getItemsArrayByParent,

  R.identity,
);

import * as R from 'ramda';
import { createSelector } from 'reselect';

import getRation from '../getRation';

export default createSelector(
  getRation,

  (ration) => {
    const target = R.propOr(null, 'custom_цель', ration);
    const grade = R.propOr(null, 'custom_степень_за_месяц', ration);

    return target * grade;
  },
);

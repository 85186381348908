import * as R from 'ramda';
import { createSelector } from 'reselect';

import getUserIsLight from '../getUserIsLight';
import getUserIsPartner from '../getUserIsPartner';

export default createSelector(
  getUserIsLight,
  getUserIsPartner,

  (isLight, isPartner) => {
    const items = [
      {
        to: '/catalog',
        title: isLight ? 'Справочник биологически активных добавок' : 'Справочник пищевых продуктов',
      },
      {
        to: '/glossary/rations',
        title: 'Справочник шаблонов рационов',
        inMenu: !isLight,
      },
      {
        to: '/glossary/daily-rate',
        title: 'Суточные нормы потребления',
      },
      {
        to: '/glossary/anthropometry-norm',
        title: 'Антропометрические нормы',
      },
      {
        href: "https://help.nutrilogic.ru/",
        target: "_blank",
        title: 'База знаний',
      },
      {
        href: '/files/help.pdf',
        target: '_blank',
        title: 'Помощь',
        inMenu: !isLight && !isPartner,
      },
    ];

    return R.reject(R.propEq('inMenu', false), items);
  },
);

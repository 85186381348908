import generator from 'redux/hor/module';
import fetchWithView from 'redux/utils/fetchWithView';

const {
  reducer,
  fetch,
} = generator({
  name: 'Units',
  tableId: 2,
});

export const fetchList = fetchWithView(fetch, 'ts5c2cs9r352', {
  useLS: true,
});

export {
  getUnits,
} from './selectors';

export default reducer;

import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import debounce from 'lodash.debounce';
import { appKey } from 'config';
import { createStructuredSelector } from 'reselect';

import roundNumber from 'utils/roundNumber';

import Text from 'components/Text';
import Info from 'components/Info';
import Icon from 'components/Icon';
import Link from 'components/Link';
import Barchart from 'components/Barchart';

import getNormText from './utils/getNormText';

import MiddleColumnHeadDefault from './default/MiddleColumnHead';
import MiddleColumnHeadChemical from './chemical/MiddleColumnHead';

import styles from './BarchartTable.module.css';
import getLocalization from 'redux/selectors/getLocalization';
import { connect } from 'utils/react-redux';

const cx = classNames.bind(styles);

const noInfo = {
  [`${appKey}t28r219`]: (field, patientId) => (
    <div className={cx('noNorm')}>
      <Icon icon='info' width={20} height={20} />
      <Text>
        Для норм ЭЦ и<br />
        БЖУ посчитайте
        <br />
        <Link
          color='blue'
          to={`/patients/${patientId}/energy`}
        >
          суточ. потр-ние
        </Link>
      </Text>
    </div>
  ),
};

const mapStateToProps = createStructuredSelector({
  tooltip: state => getLocalization(state).tooltip_header_ind_norm,
});

class BarchartTable extends PureComponent {
  static propTypes = {
    type: PropTypes.oneOf(['chemical']),
    data: PropTypes.array,
    patientId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    fieldRenderer: PropTypes.func,
    valueName: PropTypes.string,
    noNormHint: PropTypes.bool,
    disabled: PropTypes.bool,
    multiplier: PropTypes.number,
    color: PropTypes.string,
    onToggle: PropTypes.func,
    isOpen: PropTypes.bool,
    noNorm: PropTypes.bool,
    noFact: PropTypes.bool,
    size: PropTypes.string,
    Tooltip: PropTypes.func,
    TooltipElement: PropTypes.func,
  };

  static defaultProps = {
    type: undefined,
    data: [],
    patientId: undefined,
    fieldRenderer: undefined,
    valueName: 'Факт',
    noNormHint: false,
    disabled: false,
    multiplier: 1,
    color: undefined,
    onToggle: () => { },
    isOpen: undefined,
  };

  state = {
    active: [],
    toolTipField: null,
    toolTipX: 0,
    toolTipY: 0,
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    setTimeout(() => this.handleResize(), 0);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = debounce(() => this.handleMiddleColumn(this.middleColumn), 300)

  handleMiddleColumn = (node) => {
    if (!node) {
      return;
    }

    const nodeRect = node.getBoundingClientRect();
    const widthMiddleColumn = nodeRect.width - 40;
    const heightMiddleColumn = nodeRect.height;

    this.setState({
      widthMiddleColumn,
      heightMiddleColumn,
    });

    this.middleColumn = node;
  }

  handlePositionMouse = ({ x, y }) => {
    if (this.state.toolTipField) {
      this.setState({
        toolTipX: x,
        toolTipY: y,
      });
    }
  };

  handleMouseMove = (event) => {
    const x = event.clientX;
    const y = event.clientY;

    this.handlePositionMouse({ x, y });
  };

  handleMouseLeave = () => {
    this.setState({
      toolTipField: null,
    });
  };

  handleMouseEnter = (field, event) => {
    this.setState({
      toolTipField: field,
      toolTipX: event.clientX,
      toolTipY: event.clientY,
    });
  };

  handleActiveHiddenRow = R.memoizeWith(R.identity, key => () => {
    const { active } = this.state;
    const isRemove = R.includes(key, active);
    const result = isRemove ? R.without(key, active) : R.concat(active, [key]);

    this.setState({
      active: result,
    });
  })

  renderHead() {
    const infoNorm = (
      <Text
        size='medium'
        color='inherit'
        weight='bold'
      >
        Инд. норма
      </Text>
    );

    const { type } = this.props;
    const columnHeadByType = {
      chemical: MiddleColumnHeadChemical,
    };
    const MiddleColumnHead = columnHeadByType[type] || MiddleColumnHeadDefault;

    return (
      <div className={cx('row')}>
        <div className={cx('cell', 'cell_element')} />
        {this.props.noFact ||
          <div className={cx('cell', 'cell_value')}>
            <Text
              size='medium'
              weight='bold'
            >
              {this.props.valueName}
            </Text>
          </div>
        }
        <div
          className={cx('cell', 'cell_chart')}
          ref={this.handleMiddleColumn}
        >
          <MiddleColumnHead
            multiplier={this.props.multiplier}
            className={cx('middleColumn')}
          />
        </div>
        {this.props.noNorm ||
          <div className={cx('cell', 'cell_norm')}>
            <div className={cx('info')}>
              <Info toggler={infoNorm}>
                {this.props.tooltip}
              </Info>
            </div>
          </div>
        }
      </div>
    );
  }

  renderBody() {
    const { data, isOpen } = this.props;
    const zeroedLength = data.length - 1;
    const isCanBeHidden = isOpen !== undefined;

    return data.map((group, index) => {
      const isHidden = isCanBeHidden && index !== 0 && !isOpen;
      const isShouldRenderToggler = isCanBeHidden && index === 0;

      return (
        <div key={group.element}>
          {this.renderGroup(group, 1, isHidden)}
          {isShouldRenderToggler && this.renderToggler()}
          {!isShouldRenderToggler && !isHidden && index !== zeroedLength &&
            <div className={cx('group')}>
              {this.renderRow({})}
            </div>
          }
        </div>
      );
    });
  }

  renderToggler() {
    const { isOpen } = this.props;

    return (
      <div className={cx('row', 'row_empty')}>
        <button className={cx('toggler')} type='button' onClick={this.props.onToggle}>
          Нутриентный состав

          <span
            className={cx({
              arrow: true,
              arrow_up: isOpen,
            })}
          />
        </button>
      </div>
    );
  }

  renderGroup(group, level, isHidden = false) {
    if (!group.subitems || !group.subitems.length) {
      return [];
    }

    return group.subitems.map(field => (
      <div
        key={field.element}
        className={cx({
          group: true,
          [`group_level_${level}`]: level,
          group_hidden: isHidden || (level > 1 && !R.contains(group.element, this.state.active)),
        })}
      >
        {this.renderRow(field, level)}
        {this.renderGroup(field, level + 1)}
      </div>
    ));
  }

  renderRow(field, level) {
    const { TooltipElement } = this.props;
    const fontColor = field.color || (level === 1 ? null : 'gray');
    const sumValue = !R.isNil(field.data) ? R.sum([].concat(field.data)) : null;

    return (
      <div
        className={cx({
          row: true,
          row_hidden: field.isHidden,
          row_empty: R.isEmpty(field),
          row_bright: field.isBright,
        })}
      >
        <div
          className={cx('cell', 'cell_element')}
          onMouseEnter={(event) => this.handleMouseEnter(field, event)}
          onMouseMove={this.handleMouseMove}
          onMouseLeave={this.handleMouseLeave}
        >
          {!R.isEmpty(field) &&
            this.renderElementCell(field, fontColor)
          }
          {this.state.toolTipField === field && TooltipElement &&
            <TooltipElement
              elementKey={this.state.toolTipField.element}
              toolTipX={this.state.toolTipX}
              toolTipY={this.state.toolTipY}
            />
          }
        </div>
        {this.props.noFact ||
          <div className={cx('cell', 'cell_value')}>
            {!R.isEmpty(field) &&
              <div
                key='value'
                className={cx('value', {
                  value_disabled: this.props.disabled,
                })}
              >
                {this.renderValueCell(sumValue, fontColor, field)}
              </div>
            }
          </div>
        }
        <div className={cx('cell', 'cell_chart')}>
          <Barchart
            isEmpty={R.isEmpty(field)}
            size={this.props.size}
            type={this.props.type}
            multiplier={this.props.multiplier}
            data={field.data}
            metrick={field.size}
            norm={field.norm}
            normMin={field.normMin}
            normMax={field.normMax}
            id={field.element}
            width={this.state.widthMiddleColumn}
            height={this.state.heightMiddleColumn}
            color={this.props.color}
            Tooltip={this.props.Tooltip}
            noNorm={this.props.noNorm}
          />
        </div>
        {this.props.noNorm ||
          <div className={cx('cell', 'cell_norm')}>
            {this.renderNormCell(field, fontColor)}
          </div>
        }
      </div>
    );
  }

  renderElementCell(field, fontColor) {
    const { active } = this.state;
    const children = field.subitems || [];
    const withChildren = children.length > 0;
    const isActiveChild = R.includes(field.element, active);

    const text = `${field.label} ${(this.props.noFact || !field.size) ? '' : `, ${field.size}`}`;

    return (
      <button
        key='button'
        onClick={this.handleActiveHiddenRow(field.element)}
        disabled={!withChildren}
        className={cx('element', {
          element_active: withChildren,
        })}
      >
        <Text title={null} block ellipsis color={fontColor} size={this.props.size}>
          {text}
        </Text>
        {withChildren &&
          <span
            className={cx({
              arrow: true,
              arrow_up: isActiveChild,
            })}
          />
        }
      </button>
    );
  }

  renderValueCell(value, fontColor, field) {
    const { fieldRenderer } = this.props;
    const formattedValue = typeof value === 'number' ? roundNumber(2, value) : '-';
    if (R.is(Function, fieldRenderer)) {
      return fieldRenderer({
        ...field,
        name: field.element,
        value,
      });
    }

    if (field.element === "c105a902t28r219") {
      return (
        <Text
          block
          color={fontColor}
        >
          {Math.round(value)}
        </Text>
      )
    }

    return (
      <Text
        block
        color={fontColor}
      >
        {typeof formattedValue === "number" ? formattedValue.toFixed(2) : formattedValue}
      </Text>
    );
  }

  renderNormCell(field, fontColor) {
    if (!field.normMin && !field.normMax && noInfo[field.element] && !this.props.noNormHint) {
      return noInfo[field.element](field, this.props.patientId);
    }

    return (
      <Text color={fontColor}>
        {getNormText(field)}
      </Text>
    );
  }

  render() {
    const { size } = this.props;

    return (
      <div
        className={cx('barchartTable', {
          [`barchartTable_size_${size}`]: size,
        })}
      >
        {this.renderHead()}
        {this.renderBody()}
      </div>
    );
  }
}

export default R.compose(
  connect(mapStateToProps),
)(BarchartTable)
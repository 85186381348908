import * as R from "ramda";
import { createSelector } from "reselect";

import { getItems as getCountries } from "redux/modules/countries";

import getUserCountry from "../getUserCountry";

const defaultCountryKey = "c105a902t104r5087852";

export default createSelector(
  getUserCountry,
  (state) => getCountries(state.countries),

  (userCountryKey, list) => {
    const defaultCountry = R.find(
      (item) => item.value === defaultCountryKey,
      list
    )?.localization;
    const userCountry =
      R.find((item) => item.value === userCountryKey, list)?.localization || {};

    return {
      ...defaultCountry,
      ...userCountry,
    };
  }
);

import * as R from 'ramda';

import { getItemsArray } from 'redux/hor/module/selectors';

export const getSelectValues = R.compose(
  R.sortBy(R.prop('label')),
  R.map(item => ({
    list: R.propOr([], 'custom_схема_питания', item),
    label: R.propOr(null, 'custom_наименование', item),
    value: R.propOr(null, 'key', item),
  })),
  getItemsArray,
);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';

import styles from './Radio.module.css';

const cx = classNames.bind(styles);

export default class Radio extends PureComponent {
  static propTypes = {
    checked: PropTypes.bool.isRequired,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    theme: PropTypes.oneOf([
      'right',
    ]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    mobileVersion: PropTypes.bool,
  }

  static defaultProps = {
    checked: false,
    children: undefined,
    disabled: false,
    id: undefined,
    name: undefined,
    value: undefined,
    theme: undefined,
    onBlur: () => { },
    onChange: () => { },
    onFocus: () => { },
  };

  handleChange = (event) => {
    this.props.onChange(event.target.value);
  };

  render() {
    const {
      id,
      name,
      value,
      checked,
      children,
      disabled,
      theme,
      onFocus,
      onBlur,
      mobileVersion
    } = this.props;

    return (
      <label
        className={cx('radio', {
          radio_mobile: mobileVersion
        })}
        htmlFor={id}
      >
        <input
          className={cx('input', {
            input_mobile: mobileVersion
          })}
          type='radio'
          value={value}
          name={name}
          checked={checked}
          onChange={this.handleChange}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <div className={cx({
          label_mobile: mobileVersion,
          label: true,
          [`label_theme_${theme}`]: theme,
        })}
        >
          {children}
        </div>
      </label>
    );
  }
}

import * as R from 'ramda';

import { setItem } from 'utils/localStorage';

const supportedModules = ['MEALS', 'INGREDIENTS', 'PRODUCTCOMPOSITION'];
const supportedActions = ['FETCH_SUCCESS', 'SAVE', 'SAVE_SUCCESS', 'SAVE_FAILURE', 'REMOVE_SUCCESS', 'REMOVE_FAILURE'];

function getActionSpec(action) {
  const { type } = action;
  const [module, name] = type.split('__');

  return { module, name };
}

function actionIsSyncable(action) {
  const { module, name } = getActionSpec(action);
  const isSupportedModule = R.contains(module, supportedModules);
  const isSupportedAction = R.contains(name, supportedActions);

  return isSupportedModule && isSupportedAction && !action.payload.useLS && !action.payload.fromLS;
}

const lsSyncMiddleware = (store) => {
  window.addEventListener('storage', (event) => {
    const { key, newValue } = event;

    if (key !== 'action') {
      return;
    }

    try {
      const action = JSON.parse(newValue);
      store.dispatch(R.compose(
        R.assocPath(['payload', 'fromLS'], true),
        R.assocPath(['payload', 'isLocal'], true),
      )(action));
    } catch (e) {
      // ошибка при парсинге экшена. Значит LS поломался, просто не синкаем.
    }
  });

  return next => (action) => {
    if (actionIsSyncable(action)) {
      setItem('action', action);
    }

    return next(action);
  };
};

export default lsSyncMiddleware;
import * as R from 'ramda';
import createOrmSelector from 'redux/utils/createOrmSelector';

import getDayKey from '../getDayKey';

export default createOrmSelector(
  getDayKey,
  R.path(['Rations', 'ui', 'activeGroup']),
  R.path(['Rations', 'ui', 'activeItem']),
  (state, props) => props.groupKey,
  (state, props) => props.itemKey,

  (session, dayKey, activeGroup, activeItem, groupKey, itemKey) => {
    const isInactiveByGroup = activeGroup && activeGroup !== groupKey;
    const isInactiveByItem = activeItem && (activeItem[0] !== groupKey || (itemKey && activeItem[1] !== itemKey));

    return isInactiveByGroup || isInactiveByItem;
  },
);

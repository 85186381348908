import { createSelector } from 'reselect';
import * as R from 'ramda';

export default createSelector(
  R.compose(
    Number,
    R.path(['routeParams', 'supplementId']),
  ),
  (state, props = {}) => props.supplementId,

  (routeId, propsId) => propsId || routeId,
);

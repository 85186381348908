import React, { PureComponent } from 'react';
import { connect } from 'utils/react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { activate } from 'redux/modules/activation';

import ActivationStatus from '../../components/ActivationStatus';

const mapStateToProps = state => ({
  activationStatus: state.activation.status,
});
const mapDispatchToProps = {
  activate,
};

class PublicActivationContainer extends PureComponent {
  static propTypes = {
    activate: PropTypes.func,
    activationStatus: PropTypes.string,
    router: PropTypes.object,
  }

  static defaultProps = {
    activate: () => {},
    activationStatus: undefined,
    router: {},
  }

  componentWillMount() {
    const { activationStatus } = this.props;

    if (this.getIsActivationUrl() && !activationStatus) {
      this.handleActivate();
    }
  }

  handleHideAfterSignup = () => {
    this.props.router.push('/login');
  }

  handleActivate = () => {
    const { router } = this.props;
    const query = R.pathOr({}, ['location', 'query'], router);

    if (router.location.pathname !== '/g') {
      this.props.activate({
        key: query.key,
        token: query.token,
      });
    }
  }

  getIsActivationUrl() {
    const { router } = this.props;
    const query = R.pathOr({}, ['location', 'query'], router);

    return (query.token && query.key) || query.i;
  }

  render() {
    const isActivationUrl = this.getIsActivationUrl();
    const { activationStatus } = this.props;

    return (
      <ActivationStatus
        isOpen={isActivationUrl}
        activationStatus={activationStatus}
        onCancel={this.handleHideAfterSignup}
      />
    );
  }
}

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PublicActivationContainer);

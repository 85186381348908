import { ORM } from 'redux-orm';
import * as R from 'ramda';

import Elements from 'redux/modules/elements';
import Worker from 'redux/modules/staff';
import WorkerFlags from 'redux/modules/staffFlags';
import InstitutionSubscription from 'redux/modules/institutionSubscription';
import SubscriptionDay from 'redux/modules/subscriptionDays';
import SubscriptionOperation from 'redux/modules/subscriptionOperations';
import Partner from 'redux/modules/partner';
import Webinar from 'redux/modules/webinar';
import RegistrationLink from 'redux/modules/registrationLinks';

import AnalysisBlood from 'modules/Analyses/redux/modules/analysisBlood';
import AnalysisGenes from 'modules/Analyses/redux/modules/analysisGenes';
import AnalysisHair from 'modules/Analyses/redux/modules/analysisHair';
import AnalysisSalt from 'modules/Analyses/redux/modules/analysisSalt';
import AnalysisType from 'modules/Analyses/redux/modules/analysisType';
import AnalysisVitamin from 'modules/Analyses/redux/modules/analysisVitamin';
import ChemicalElement from 'modules/Analyses/redux/modules/chemicalElement';
import Gene from 'modules/Analyses/redux/modules/gene';
import Genotype from 'modules/Analyses/redux/modules/genotypes';
import PatientAnalysis from 'modules/Analyses/redux/modules/patientAnalysis';
import Vitamin from 'modules/Analyses/redux/modules/vitamin';

import Dietanamnez from 'modules/Dietanamnez/redux/modules/dietanamnez';
import DietanamnezFrequency from 'modules/Dietanamnez/redux/modules/dietanamnezFrequency';
import DietanamnezItem from 'modules/Dietanamnez/redux/modules/dietanamnezItem';
import DietanamnezPortion from 'modules/Dietanamnez/redux/modules/dietanamnezPortion';
import DietanamnezTiles from 'modules/Dietanamnez/redux/modules/dietanamnezTiles';
import TileImageMap from 'modules/Dietanamnez/redux/modules/tileImageMap';

import Ration from 'modules/Rations/redux/modules/ration';
import PatientsElements from 'modules/Rations/redux/modules/patientsElements';
import Day from 'modules/Rations/redux/modules/day';
import Supplement from 'modules/Supplement/redux/modules/supplement';
import SupplementDish from 'modules/Supplement/redux/modules/supplementDish';

import PatientReception from 'modules/Patient/redux/receptions';

const models = {
  3: Gene,
  6: Vitamin,
  8: AnalysisType,
  10: Genotype,
  19: PatientAnalysis,
  21: AnalysisGenes,
  22: AnalysisVitamin,
  23: ChemicalElement,
  24: AnalysisBlood,
  25: AnalysisHair,
  27: AnalysisSalt,
  28: Elements,
  29: Day,
  39: Ration,
  44: DietanamnezFrequency,
  49: DietanamnezTiles,
  47: Worker,
  65: DietanamnezItem,
  66: DietanamnezPortion,
  96: PatientsElements,
  115: InstitutionSubscription,
  119: SubscriptionDay,
  123: SubscriptionOperation,
  140: Dietanamnez,
  158: Supplement,
  160: SupplementDish,
  171: TileImageMap,
  172: PatientReception,
  188: WorkerFlags,
  192: Partner,
  206: Webinar,
  212: RegistrationLink,
};

const orm = new ORM();
orm.register(...R.values(models));

window.orm = orm;

export default orm;

import * as R from 'ramda';
import createCachedOrmSelector from 'redux/utils/createCachedOrmSelector';
import { appKey } from 'config';

import getDayKey from '../getDayKey';
import getMealsWithStatus from 'redux/selectors/getMealsWithStatus';

export default createCachedOrmSelector(
  getDayKey,
  (state, props) => props.groupKey,
  (state, props) => props.itemKey,
  getMealsWithStatus,

  (session, dayKey, groupKey, itemKey, mealsArray) => {
    const { Day } = session;
    const meals = R.indexBy(R.prop('key'), mealsArray);

    const group = Day.withId(dayKey).groups[groupKey];

    if (!group) {
      return [];
    }

    const dishGroup = Day.withId(dayKey).groups[groupKey].группы_альтернатив[itemKey];

    return Object.keys(dishGroup.блюда)
      .map((baseKey) => {
        const key = baseKey.replace('cXaX', appKey)
        const amount = dishGroup.блюда[baseKey]
        const meal = meals[key] || {};

        return {
          ...meal,
          value: key,
          amount,
          label: meal.custom_название || baseKey,
        };
      });
  },
);

import * as R from 'ramda';
import memoizee from 'memoizee';

import { getItemsArray } from 'redux/hor/module/selectors';

export const getPortions = memoizee(
  R.compose(
    R.sortBy(R.prop('label')),
    R.map(i => ({
      name: i.custom_наименование,
      label: i.custom_название,
      value: i.key,
      amount: i.custom_мера,
      unit: R.pathOr(null, ['custom_ед_изм', 'label'], i),
    })),
    getItemsArray,
  ),
  { max: 1 },
);

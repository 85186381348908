import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';

import styles from './Content.module.css';

const cx = classNames.bind(styles);

export default class Content extends PureComponent {
  static propTypes = {
    borderTop: PropTypes.bool,
    children: PropTypes.node.isRequired,
    maximize: PropTypes.bool,
    indentTop: PropTypes.bool,
    indentBottom: PropTypes.bool,
    paddingXOff: PropTypes.bool,
  };

  static defaultProps = {
    borderTop: false,
    children: undefined,
    maximize: false,
    indentTop: true,
    indentBottom: true,
    paddingXOff: false,
  };

  render() {
    return (
      <section
        className={cx('content', {
          content_borderTop: this.props.borderTop,
          content_indentTop: this.props.indentTop,
          content_indentBottom: this.props.indentBottom,
          content_paddingXOff: this.props.paddingXOff,
          content_maximize: this.props.maximize,
        })}
      >
        {this.props.children}
      </section>
    );
  }
}

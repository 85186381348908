import * as R from 'ramda';
import { createSelector } from 'reselect';

import {
  getPromo,
} from 'redux/modules/subscriptionPromocode';

export default createSelector(
  R.path(['subscriptionPromocode']),

  getPromo,
);

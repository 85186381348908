import { createSelector } from 'reselect';

import { getValues } from 'redux/modules/elements/selectors';

import getDietanamnezChemistryData from '../getDietanamnezChemistryData';

export default createSelector(
  state => state,
  getDietanamnezChemistryData,

  (state, data) => data ? getValues(state, {
    chartId: 'dietanamnez',
    data,
  }) : [],
);

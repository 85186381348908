import * as R from 'ramda';
import { stopSubmit } from 'redux-form';
import { put } from 'redux-saga/effects';
import { setNotification } from 'redux/modules/notification';
import validateFromResponse from 'utils/validateFromResponse';

import getErrorTextFromResponse from '../getErrorTextFromResponse';

const parse = R.compose(
  R.map(put),
  R.reject(R.isNil),
)

export default function getErrorFromResponse(error, form, type = 'global') {
  const { response = {} } = error;
  const formEffect = form ? stopSubmit(form, {
    _error: true,
  }) : undefined;

  if (error.message === 'Network Error') {
    return parse([
      setNotification({
        type,
        isProcess: false,
        isFailedNetwork: {
          exception: 'Error',
        },
      }),
      formEffect,
    ]);
  }

  const validationError = validateFromResponse(response);

  if (form && validationError) {
    return parse([stopSubmit(form, validationError)]);
  }

  const exception = getErrorTextFromResponse(error);

  return parse([
    setNotification({
      type,
      isProcess: false,
      isFailed: {
        exception,
      },
    }),
    formEffect,
  ]);
}

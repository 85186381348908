import * as R from 'ramda';
import memoizee from 'memoizee';

import { getItemsArray } from 'redux/hor/module/selectors';

export const getFilterOptions = memoizee(
  R.compose(
    R.map(R.pluck('custom_исключить')),
    R.groupBy(R.prop('custom_поиск')),
    getItemsArray,
  ),
  { max: 1 },
);

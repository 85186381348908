import createCachedOrmSelector from 'redux/utils/createCachedOrmSelector';
import memoizeByResult from 'utils/memoizeByResult';

import getDayKey from '../getDayKey';

export default createCachedOrmSelector(
  getDayKey,
  (state, props) => props.groupKey,

  memoizeByResult(
    (session, dayKey, time) => {
      const { Day } = session;
      const ration = Day.withId(dayKey).custom_рацион_пациента;

      return ration
        .days
        .exclude({
          key: dayKey,
        })
        .toModelArray()
        .filter(day => day.groups[time])
        .map(day => day.custom_номер);
    },
    (session, key) => key,
  ),
);

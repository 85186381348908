import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension'

import raven from './middlewares/raven';
import params from './middlewares/params';
import lsSync from './middlewares/ls-sync';
import createdUser from './middlewares/createdUser';

import reducer from './modules/reducer';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

export default function create(history, data) {
  const reduxRouterMiddleware = routerMiddleware(history);
  const middlewares = [reduxRouterMiddleware, lsSync, params, sagaMiddleware, createdUser, raven];
  const composeEnhancers = composeWithDevTools({
    trace: true,
    traceLimit: 25,
  })
  const store = createStore(reducer, data, composeEnhancers(
    applyMiddleware(...middlewares)
  ));

  sagaMiddleware.run(sagas);

  return store;
}

import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getCatalogGroups as getProductGroups } from 'redux/modules/productGroups';
import { getCatalogGroups as getSupplementGroups } from 'redux/modules/supplementGroups';

import getUserIsLight from '../getUserIsLight';
import getUserSupplementGroups from '../getUserSupplementGroups';

export default createSelector(
  getUserIsLight,
  getUserSupplementGroups,
  R.path(['productGroups']),
  R.path(['supplementGroups']),

  (isLight, userGroups, productGroups, supplementGroups) => {
    if (isLight) {
      return getSupplementGroups(supplementGroups, userGroups);
    }

    return getProductGroups(productGroups);
  },
);

import { createDictionaryModel } from "../createDictionaryModel";

export type SupplementComposition = {
  key: string;
  ___parent: string;
  custom_элемент: string;
  custom_значение_на_порцию: number;
};

export const [$supplementsCompositionAll, fetchSupplementsComposition] =
  createDictionaryModel<SupplementComposition>({
    tableId: 161,
    view: "c105a902ts5c161cs9r575",
    parseItem: (item: any) =>
      ({
        key: item.key,
        ...item.fields,
        ___parent: item.fields.___parent.key,
        custom_элемент: item.fields.custom_элемент.key,
      } as SupplementComposition),
  });

type CompositionMap = {
  [key: string]: {
    [key: string]: number;
  };
};

export const $supplementsCompositionByParent = $supplementsCompositionAll.map(
  (state) => {
    if (!state) {
      return null;
    }

    return state.reduce((acc, item) => {
      acc[item.___parent] = acc[item.___parent] || {};

      acc[item.___parent][item.custom_элемент] = item.custom_значение_на_порцию;

      return acc;
    }, {} as CompositionMap);
  }
);

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import InputMask from 'react-input-mask';
import * as R from 'ramda';

import FieldDate from './FieldDate';
import styles from './Field.module.css';
import PhoneInput from 'react-phone-number-input'
import "./PhoneInput.css";

const cx = classNames.bind(styles);

export default class Field extends PureComponent {
  state = {
    phoneValue: this.props.value
  }

  static propTypes = {
    autoFocus: PropTypes.bool,
    active: PropTypes.bool,
    center: PropTypes.bool,
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    hint: PropTypes.string,
    id: PropTypes.string,
    indent: PropTypes.bool,
    interval: PropTypes.string,
    label: PropTypes.string,
    mask: PropTypes.string,
    mimicSelect: PropTypes.bool,
    name: PropTypes.string,
    noOverflow: PropTypes.bool,
    note: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    symbol: PropTypes.string,
    tooltipError: PropTypes.bool,
    touched: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    noBorder: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyPress: PropTypes.func,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    maxLength: PropTypes.number,
    submitFailed: PropTypes.bool,
    phone: PropTypes.bool,
    mobileVersion: PropTypes.bool
  }

  static defaultProps = {
    autoFocus: false,
    active: false,
    center: false,
    error: undefined,
    hint: undefined,
    id: undefined,
    indent: false,
    interval: undefined,
    label: undefined,
    mask: undefined,
    mimicSelect: false,
    name: undefined,
    noOverflow: false,
    note: undefined,
    placeholder: undefined,
    required: false,
    symbol: undefined,
    tooltipError: false,
    touched: true,
    type: 'text',
    value: undefined,
    noBorder: false,
    onChange: () => { },
    onBlur: () => { },
    onFocus: () => { },
    disabled: false,
    readOnly: false,
    phone: false,
    mobileVersion: false
  }

  handleChange = (event) => {
    this.props.onChange(event.target.value, event);


  }

  phoneHandleChange = (value) => {
    this.setState({ phoneValue: value || "" }, () => {
      const trueEvent = new Event("input", { bubbles: true });
      this.truePhone.dispatchEvent(trueEvent);
    });
  }

  componentDidUpdate() {
    if (this.props.phone) {
      this.setState({ phoneValue: this.props.value });
    }
  }


  render() {
    const {
      autoFocus,
      id,
      touched,
      active,
      label,
      symbol,
      interval,
      value,
      placeholder,
      required,
      error,
      tooltipError,
      note,
      hint,
      center,
      indent,
      mimicSelect,
      noOverflow,
      onBlur,
      onFocus,
      onClick,
      type,
      disabled,
      readOnly,
      mask,
      maxLength,
      submitFailed,
      noBorder,
      phone,
      mobileVersion
    } = this.props;
    const withError = error && (touched && (!active || submitFailed)); // || (error && phone && touched)

    const InputElement = InputMask.default || InputMask;
    const Input = !R.isNil(mask) && InputElement ? InputElement : 'input';

    return (
      <label
        className={cx({
          field: true,
          field_mobile: mobileVersion,
          field_state_error: withError,
          field_state_isText: value,
          field_type_noOverflow: noOverflow,
          field_type_placeholder: placeholder,
          field_type_interval: interval,
          field_type_center: center,
          field_type_free: !label && !note,
          field_indent: indent,
          field_mimicSelect: mimicSelect,
        })}
        htmlFor={id}
        onClick={onClick}
      >
        {interval &&
          <div className={cx('interval')}>
            {interval}
          </div>
        }
        {!(phone && mask) ? <Input
          className={cx({
            input: true,
            input_no_border: noBorder,
            input_mobile: mobileVersion,
          })}
          id={id}
          placeholder={placeholder}
          value={value}
          onBlur={onBlur}
          onFocus={onFocus}
          type={type}
          onChange={this.handleChange}
          onKeyPress={this.props.onKeyPress}
          autoFocus={autoFocus}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
          mask={mask}
        /> :
          <>
            <input
              disabled={disabled}
              name={this.props.name}
              style={{ height: "0px", width: "0px", padding: "0px", border: "none", display: "none" }}
              value={this.state.phoneValue}
              onInput={this.handleChange}
              onBlur={onBlur}
              ref={el => this.truePhone = el}
            >
            </input>
            <PhoneInput
              numberInputProps={{
                className: cx({
                  input: true,
                  input_no_border: noBorder,
                  input_mobile: mobileVersion,
                })
              }}
              autoFocus={autoFocus}
              autoComplete=""
              onFocus={onFocus}
              onBlur={() => {
                this.truePhone.dispatchEvent(new Event("blur", { bubbles: true }));

              }}
              id={id}
              onKeyPress={this.props.onKeyPress}
              value={value}
              disabled={disabled}
              onChange={this.phoneHandleChange}
            />

          </>
        }



        {symbol &&
          <div className={cx('symbol')}>
            {symbol}
          </div>
        }

        <div className={cx('border')} style={phone ? { top: "unset" } : undefined} />
        {label &&
          <span
            className={cx({
              label: true,
              label_type_hint: !!hint,
              label_mobile: mobileVersion,
            })}
          >
            <span className={cx('label-text')}>{label}</span>
            {required && <span className={cx('required')}>*</span>}
          </span>
        }
        {hint &&
          <div className={cx('hint')}>
            {hint}
          </div>
        }
        <span className={cx('note')}>
          {note || <FieldDate name={this.props.name} />}
        </span>
        {withError &&
          <span
            htmlFor={id}
            className={cx({
              note_type_error_mobile: mobileVersion,
              note: true,
              note_type_error: true,
              note_state_tooltip_error: tooltipError,
            })}
          >
            {error}
          </span>
        }
      </label>
    );
  }
}

import createCachedOrmSelector from 'redux/utils/createCachedOrmSelector';

import getAnalysisKey from '../../getAnalysisKey';

export default createCachedOrmSelector(
  getAnalysisKey,

  (session, key) => {
    const { AnalysisGenes } = session;

    return AnalysisGenes
      .filter({
        ___parent: key,
      })
      .toModelArray()
      .map(item => ({
        key: item.key,
        itemKey: item.custom_ген.key,
        label: item.custom_ген.custom_название,
        value: item.custom_генотип.custom_наименование,
      }));
  },
);

import * as R from 'ramda';
import moment from 'moment';

export const dateFieldMap = {
  custom_масса_тела: 'custom_масса_тела_1',
  custom_рост: 'custom_рост_1',
  custom_объем_талии: 'custom_объем_талии_1',
  custom_объем_бедер: 'custom_объем_бедер_1',
  custom_объем_плеча: 'custom_объем_плеча_1',
  custom_жировая_масса: 'custom_жировая_масса_1',
  custom_мышечная_масса: 'custom_мышечная_масса_1',
  custom_общая_жидкость: 'custom_общая_жидкость_1',
  custom_вунутриклеточная_жидкость: 'custom_внутриклеточная_жидкость',
  custom_внеклеточная_жидкость: 'custom_внеклеточная_жидкость_1',
  custom_тощая_масса_тела: 'custom_тощая_масса_тела_1',
  custom_активная_клеточная_масса: 'custom_активная_клеточная_масса_1',
  custom_висцеральный_жир: 'custom_висцеральный_жир_1',
  custom_складка_грудь_подмышка: 'custom_складка_грудь_подмышка_1',
  custom_складка_живот: 'custom_складка_живот_1',
  custom_складка_бедро: 'custom_складка_бедро_1',
  custom_дата_рождения: 'custom_дата_рождения_1',
  custom_число_калорий: 'custom_число_калорий_1',
  custom_окисление_белка: 'custom_окисление_белка_3',
  custom_окисление_жиров: 'custom_окисление_жиров_2',
  custom_окисление_углеводов: 'custom_окисление_углеводов_1',
  custom_число_калорий_при_физической_активности: 'custom_число_калорий_при_физической_активности_3',
};

export const addDateField = (values = {}) =>
  R.compose(
    R.reduce((acc, field) => {

      if (!dateFieldMap[field] || !R.has(field, values)) {
        return acc;
      }
      return {
        ...acc,
        [dateFieldMap[field]]: values[field] === null ? null : moment().format('DD.MM.YYYY'),
      };
    }, {}),
    R.keys,
  )(dateFieldMap);

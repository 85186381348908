import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import * as R from 'ramda';
import moment from 'moment';

import createValidate from 'utils/validation';
import { userFormPreset } from 'utils/validation/presets';
import options from 'utils/validation/defaultOptions';

import UserForm from 'components/UserForm';

const form = {
  form: 'inviteUser',
  validate: createValidate({
    ...userFormPreset.validation,
    custom_мобильный_телефон: {
      ...userFormPreset.validation.custom_мобильный_телефон,
      required: false,
    },
    custom_password: options.password,
    eula: {
      custom: {
        validator: R.equals(true),
      },
    },
  }),
  fields: [
    'custom_фото',
    'custom_firstname',
    'custom_lastname',
    'custom_midname',
    'custom_sexint',
    'custom_dob',
    'custom_мобильный_телефон',
    'custom_должность',
    'custom_город',
    'custom_password',
    'eula',
  ],
  initialValues: {
    eula: true,
  },
};

class InviteUserFormContainer extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    handleSubmit: PropTypes.func,
    valid: PropTypes.bool,
    router: PropTypes.object,
    submitting: PropTypes.bool,
    form: PropTypes.string,
  }

  static defaultProps = {
    activate: () => {},
    handleSubmit: () => {},
  }

  handleSubmit = this.props.handleSubmit(({ eula, ...values }) => {
    const data = R.evolve({
      custom_dob: item => moment(item).isValid() ? moment(item).format('DD.MM.YYYY') : null,
      custom_sexint: Number,
      custom_firstname: R.trim,
      custom_lastname: R.trim,
      custom_midname: R.trim,
      custom_мобильный_телефон: value => value ? R.replace(/[ )()-]/g, '', value) : value,
    }, values);

    this.props.onSubmit(data, this.props.form)
  })

  render() {
    return (
      <UserForm
        step='invite'
        isLight={this.props.isLight}
        onSubmit={this.handleSubmit}
        valid={this.props.valid}
        submitting={this.props.submitting}
      />
    );
  }
}

export default R.compose(
  reduxForm(form),
)(InviteUserFormContainer);

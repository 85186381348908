import * as R from 'ramda';
import { apiUrl, proApiUrl } from 'config';
import { logout } from 'redux/modules/auth';
import { saveTokenInfo } from 'utils/cookie/token';

import request from '../../request';
import { loadingFinish } from "../../../../redux/modules/loading";
import { hideLoader } from "../../../../redux/modules/loader";

export default function interceptor(store, config) {
  const { auth = {} } = store.getState();

  let domain = apiUrl;

  if (!config.hasStrictDomain) {
    const pathname = window.location.pathname.toLowerCase();

    if (pathname === '/signup' || pathname === '/pro' || pathname === '/signup/' || pathname === '/invite' || (pathname === '/login' && window.location.search.toLowerCase().includes('lite=false'))) {
      domain = proApiUrl;
    } else if (auth.domain) {
      domain = auth.domain;
    }

    config.baseURL = domain;
  }


  const isPing = R.pathEq(['url'], '/ping/json/v2', config);
  const isAuth = R.pathEq(['url'], '/authenticate/json/v2', config);

  if (isAuth) {
    return config;
  }

  if (!auth.token && !R.pathOr(false, ['headers', 'token'], config) && !isPing) {
    store.dispatch(logout({ isSessionOver: true }));
    store.dispatch(loadingFinish());
    store.dispatch(hideLoader('page'));
    store.dispatch(hideLoader('application'));

    return Promise.reject({
      response: {
        status: 401,
        config,
      },
    });
  }

  saveTokenInfo(store.getState());

  const { token, remember } = auth;

  return R.compose(
    R.over(R.lensPath(['headers', 'token']), R.defaultTo(token)),
    c => remember ? R.set(R.lensPath(['headers', 'Remember']), remember, c) : config,
  )(config);
}

export function interceptorError(store, error) {
  const { config, message, response = {} } = error;

  if (message === 'Network Error' && (config.attempts < 5 || !config.attempts)) {
    return request.request({
      ...config,
      attempts: (config.attempts || 0) + 1,
    });
  }

  if (response.status === 401 && !R.endsWith('/authenticate/json/v2', response.config.url)) {
    store.dispatch(logout({ isSessionOver: true }));
  }

  return Promise.reject(error);
}

import * as R from "ramda";
import { createSelector } from "reselect";

import { getItemById, Patient } from "redux/modules/patients";

import getPatientId from "../getPatientId";

export default createSelector(
  getPatientId,
  R.path(["patients"]),

  getItemById
) as unknown as () => Patient;

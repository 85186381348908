import request from "./request";

import formatHeaders from "./utils/formatHeaders";

type Params = {
  key: string;
  data: Record<string, any>;
  token?: string;
  button?: string;
};

export function post<T>({ key, data, token, button }: Params) {
  return request.post<T>(
    `/${key}/json/v2`,
    { data },
    {
      headers: formatHeaders({
        button,
        token,
      }),
    }
  );
}

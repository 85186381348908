import * as R from 'ramda';

const getFirstName = R.compose(
  R.defaultTo(''),
  R.propOr('', 'custom_firstname'),
);
const getMidName = R.compose(
  R.defaultTo(''),
  R.propOr('', 'custom_midname'),
);
const getLastName = R.compose(
  R.defaultTo(''),
  R.propOr('', 'custom_lastname'),
);
const getFullName = patient => `${getFirstName(patient)} ${getLastName(patient)}`;
const getRealFullName = R.compose(
  R.join(' '),
  R.values,
  R.pick(['custom_firstname', 'custom_midname', 'custom_lastname']),
  R.defaultTo({}),
);

export default getFullName;

export {
  getFirstName,
  getMidName,
  getLastName,
  getRealFullName,
};

import { createAction, createReducer } from 'redux-act';

const initialState = {
  active: 'profession',
};

export const toggleActivity = createAction('TOGGLE__ACTIVITY')

const handleToggleActivity = (state, payload) => {

  return {
    ...state,
    active: payload
  }
}

export default createReducer({
  [toggleActivity]: handleToggleActivity
}, initialState)
import { AxiosRequestConfig } from "axios";
import request from "./request";
import formatHeaders from "./utils/formatHeaders";

type Params = {
  key: string;
  view?: string;
  filter?: string;
  cache?: boolean;
  token?: string;
  limit?: number;
  useLS?: boolean;
};

export function get<T>({
  key,
  view,
  filter,
  cache,
  token,
  limit = 100000,
  useLS = false,
}: Params) {
  return request
    .get<T>(`/${key}/json/v2`, {
      cache,
      useLS,
      headers: formatHeaders({
        view,
        filter,
        limit,
        token,
      }),
    } as AxiosRequestConfig)
    .then((response) => ({
      ...response.data,
      fromCache: (response as any).fromCache,
    }));
}

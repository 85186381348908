import createSelector from 'redux/utils/createOrmSelector';

import getRationKey from '../getRationKey';

export default createSelector(
  getRationKey,
  getRationKey,

  (session, rationKey) => {
    const { Ration } = session;

    return Ration.withId(rationKey).getIsFilled();
  },
);

import createSelector from 'redux/utils/createOrmSelector';

import getApplicationUser from 'redux/selectors/getApplicationUser';
import getPatientKey from 'redux/selectors/getPatientKey';

export default createSelector(
  getApplicationUser,
  getPatientKey,

  (session, appUser, patientKey) => {
    const { Ration } = session;

    const lastRation = Ration
      .filter({
        ___parent: patientKey,
        ___owner: appUser,
        custom_не_назначен: true,
        custom_удалено: false,
      })
      .last();

    if (!lastRation) {
      return null;
    }

    return lastRation.key;
  },
);

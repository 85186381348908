import memoizee from 'memoizee';
import * as R from 'ramda';

import { getItemsArray } from 'redux/hor/module/selectors';

export const getItemsList = memoizee(R.compose(
  R.map(item => ({
    ...item,
    value: item.key,
    label: item.custom_наименование,
  })),
  getItemsArray,
));

export const getSelectData = getItemsList;

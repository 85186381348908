import * as R from 'ramda';
import { createAction } from 'redux-act';
import { attr, Model, fk } from 'redux-orm';

import generator from 'redux/hor/orm-module';
import convertFromServerTimeZoneToCurrentTimeZone
  from 'utils/convertFromServerTimeZoneToCurrentTimeZone';

const {
  reducer,

  fetch,
  save,
  saveSuccess,
} = generator({
  name: 'SubscriptionOperation',
  tableId: 123,
  parseItem: R.evolve({
    ___parent: R.prop('key'),
    custom_счет: R.propOr(null, 'url'),
    custom_операция: R.propOr(null, 'key'),
    custom_тарифный_план: R.propOr(null, 'key'),
    custom_дата_для_однодневной: convertFromServerTimeZoneToCurrentTimeZone,
  }),
});

export default class SubscriptionOperation extends Model {
  static modelName = 'SubscriptionOperation';

  static options = {
    idAttribute: 'key',
  }

  static fields = {
    ___parent: attr(),
    custom_currentstatus: attr(),
    custom_ordersumamount: attr(),
    custom_ordersumrequired: attr(),
    custom_дата_для_однодневной: attr(),
    custom_дата_операции: attr(),
    custom_значение_операции: attr(),
    custom_операция: attr(),
    custom_промокод: attr(),
    custom_счет: attr(),
    custom_ordernumber: attr(),
    custom_customernumber: attr(),
    custom_signaturevalue: attr(),
    custom_тарифный_план: fk('SubscriptionDay', 'operations'),
  }

  static reducer = reducer;
}

export {
  fetch,
  save,
  saveSuccess,
};

export const renewal = createAction('SUBSCRIPTIONOPERATIONS__RENEWAL');
export const cancel = createAction('SUBSCRIPTIONOPERATIONS__CANCEL');
export const changeFutureOneDayDate =
  createAction('SUBSCRIPTIONOPERATIONS__CHANGE_FUTURE_ONE_DAY_DATE');
export { default as saga } from './saga';

import * as R from 'ramda';
import { put, call, select, all } from 'redux-saga/effects';

import {
  getItemByKey,
} from 'redux/selectors';

import getErrorFromResponse from 'redux/utils/getErrorFromResponse';
import dispatchAndWait from 'redux/utils/dispatchAndWait';
import waitFor from 'redux/utils/waitFor';
import * as api from 'utils/api';
import isLocalKey from 'utils/isLocalKey';

export default function runButtonCreator({
  button,
  fetch,
  save,
  data = {},
  showError = true,
}) {
  return function* runButton(action) {
    const { payload } = action;
    let { key } = payload;
    const currentItem = yield select(getItemByKey(key));
    const currentFields = R.pickAll(R.keys(data), currentItem);

    try {
      yield put(save({
        key,
        data: {
          ...data,
          [button]: true,
        },
        isLocal: true,
      }));

      if (isLocalKey(key)) {
        const success = yield waitFor(save({
          key,
        }));
        key = success.payload.response.data.key;
      }

      const response = yield call(api.post, {
        key,
        button,
      });

      const success = {
        type: `${action.type}_SUCCESS`,
        payload: {
          ...action.payload,
          response,
        },
      };

      const item = yield select(getItemByKey(key))

      if (fetch && item && !item.isDeleted) {
        yield dispatchAndWait(fetch({
          key,
        }));
      }

      yield put(save({
        key,
        data: {
          [button]: false,
        },
        isLocal: true,
      }));
      yield put(success);

      return success;
    } catch (error) {
      const status = R.pathOr(null, ['response', 'status'], error);
      const failure = {
        type: `${action.type}_FAILURE`,
        payload: {
          ...action.payload,
          error,
        },
      };

      if (showError) {
        const errorEffect = getErrorFromResponse(error, payload.form);
        yield all(errorEffect);
      }

      yield put(failure);
      yield put(save({
        key: action.payload.key,
        data: {
          ...currentFields,
          [button]: false,
        },
        isLocal: true,
      }));

      if (status !== 401) {
        throw error;
      }

      return null;
    }
  };
}

import React, { PureComponent } from 'react';
import { Field } from 'redux-form';
import { withReduxForm } from 'redux-form/es/ReduxFormContext';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import Radio from './Radio';

class RadioConnected extends PureComponent {
  static contextTypes = {
    onFormChange: PropTypes.func,
  };

  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    mobileVersion: PropTypes.bool,
  };

  static defaultProps = {
    name: undefined,
    value: undefined,
  };

  handleChange = onChange => (event) => {
    onChange(event);
    setTimeout(this.context.onFormChange, 0);
  }

  renderField = (field = {}) => (
    <Radio
      {...this.props}
      {...field.input}
      {...field.meta}
      onChange={this.handleChange(field.input.onChange)}
      value={this.props.value}
    />
  );

  render() {
    const { _reduxForm: reduxForm } = this.props;

    if (!reduxForm || !R.contains(this.props.name, reduxForm.fields)) {
      return (
        <Radio {...this.props} />
      );
    }

    return (
      <Field
        {...this.props}
        component={this.renderField}
        type='radio'
      />
    );
  }
}

export default withReduxForm(RadioConnected)
import * as R from 'ramda';
import generator from 'redux/hor/module';

const {
  reducer,
  fetch,
  fetchSuccess,

  create,
  save,
  remove,
} = generator({
  name: 'SupplementTechCard',
  tableId: 164,
  parseItem: R.evolve({
    ___parent: R.prop('key'),
    custom_ингредиент: R.prop('key'),
  }),
});

export {
  fetch,
  fetchSuccess,
  create,
  save,
  remove,
};

export {
  getItemsArrayByParent,
} from 'redux/hor/module/selectors';

export {
  getTechcard,
} from './selectors';

export default reducer;

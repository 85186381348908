import * as R from 'ramda';
import { createSelector } from 'reselect';

import getPatient from '../getPatient';
import getStaff from '../getStaff';

export default createSelector(
  R.compose(
    R.propOr(null, '___createduser'),
    getPatient,
  ),
  getStaff,

  (authorKey, staff) => {
    if (!authorKey) {
      return null;
    }

    return R.compose(
      R.join(" "),
      R.filter(Boolean),
      ({ custom_firstname, custom_lastname }) => [custom_lastname, custom_firstname],
      R.defaultTo({}),
      R.find(R.propEq('custom_ключ_системного_пользователяold', authorKey)),
    )(staff);
  },
);

import * as R from 'ramda';
import { createSelector } from 'reselect';

import getKeyInfo from 'utils/getKeyInfo';

import { getItemsArray } from 'redux/hor/module/selectors';

export const getItems = createSelector(
  getItemsArray,

  R.compose(
    R.map(item => ({
      ...item,
      key: item.key,
      title: item.custom_заголовок,
      text: (item.custom_содержимое || '').replace(/<a href=/, '<a target="_blank" href='),
      withIgnore: true,
    })),
    R.reverse,
    R.sortBy(R.compose(
      R.prop('record'),
      getKeyInfo,
      R.prop('key'),
    )),
    R.filter(R.prop('custom_активное')),
  ),
);

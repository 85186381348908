import * as R from 'ramda';
import { createSelector } from 'reselect';

import getInstitution from '../getInstitution';

export default createSelector(
  getInstitution,

  R.compose(
    R.multiply(0.01),
    R.defaultTo(0),
    R.propOr(0, 'custom_постоянная_скидка'),
  ),
);

import * as R from 'ramda';
import createCachedSelector from 're-reselect';

import getAnalysisItem from '../getAnalysisItem';
import getAnalysisDataBlood from './getAnalysisDataBlood';
import getAnalysisDataGenes from './getAnalysisDataGenes';
import getAnalysisDataVitamin from './getAnalysisDataVitamin';
import getAnalysisDataHair from './getAnalysisDataHair';
import getAnalysisDataSalt from './getAnalysisDataSalt';

const types = {
  194: getAnalysisDataBlood,
  196: getAnalysisDataGenes,
  193: getAnalysisDataVitamin,
  192: getAnalysisDataHair,
  195: getAnalysisDataSalt,
};

export default createCachedSelector(
  (state, props) => R.compose(
    type => types[type](state, props),
    R.prop('typeId'),
    getAnalysisItem,
  )(state, props),

  R.identity,
)((state, props) => props.analysisId);

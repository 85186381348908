import * as R from 'ramda';
import { fk, attr, Model } from 'redux-orm';

import generator from 'redux/hor/orm-module';

const {
  reducer,
  fetchSuccess,
  save,
} = generator({
  name: 'analysisHair',
  tableId: 25,
  parseItem: R.evolve({
    ___parent: R.propOr(null, 'key'),
    custom_химический_элемент: R.propOr(null, 'key'),
    custom_ед__изм: R.propOr(null, 'key'),
  }),
});

export default class AnalysisHair extends Model {
  static modelName = 'AnalysisHair';

  static reducer = reducer;

  static options = {
    idAttribute: 'key',
  }

  static fields = {
    ___parent: fk('PatientAnalysis'),
    custom_химический_элемент: fk('ChemicalElement'),
    custom_ед__изм: attr(),
    custom_факт: attr(),
  }
}

export {
  fetchSuccess,
  save,
};

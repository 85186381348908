import * as R from 'ramda';
import { createSelector } from 'reselect';

import { setNotification } from './reducer';

const errorsState = ['isFailed', 'isFailedNetwork'];
const types = ['isProcess', 'isDone', 'isRemove', 'isNextReception', ...errorsState, 'isNba',  'isInfo'];

export const getCurrentNotification = createSelector(
  state => state.notification,

  (state) => {
    const notification = R.reduce((acc, status) => {
      return {
        ...acc,
        [status]: R.propOr(false, status, state),
      }
    }, {}, types);

    const item = R.compose(
      R.head,
      R.keys,
      R.pickBy(Boolean),
    )(notification);

    if (R.isNil(item)) {
      return null;
    }

    const common = {
      onClose: setNotification({
        [item]: false,
      }),
    };

    return {
      type: item,
      content: R.is(Object, notification[item]) ? {
        ...common,
        ...notification[item],
      } : common,
    };
  },
);

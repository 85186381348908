import * as R from 'ramda';
import { all, takeEvery, put, select } from 'redux-saga/effects';

import { hideLoader } from 'redux/modules/loader';
import { loadingStart, loadingFinish } from 'redux/modules/loading';

import dispatchAndWait from 'redux/utils/dispatchAndWait';

function* loadingFlow(action) {
  const { fetcher, params, state = {}, callback, loader = true } = action.payload;
  const lazyLoadList = [];

  function* processAction(ac) {
    try {
      if (ac.then) {
        yield ac;
        return true;
      }

      if (ac) {
        const lazyLoad = R.pathOr(false, ['payload', 'lazyLoad'], ac);

        if (lazyLoad) {
          lazyLoadList.push(ac);
        } else {
          const result = yield dispatchAndWait(ac);
          return !!result;
        }
      }
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  function* itemLoader(item) {
    const storeState = yield select(s => s);
    const getState = () => ({
      ...storeState,
      routeParams: params,
    });

    const ac = typeof item === 'function' ? item(params, getState(), state) : item;

    if (ac) {
      if (ac.action) {
        const isSuccess = yield itemLoader(ac.action);

        if (isSuccess) {
          return yield itemLoader(ac.after);
        }
      } else {
        if (Array.isArray(ac)) {
          return yield all(ac.map(i => itemLoader(i)));
        }

        return yield processAction(ac);
      }
    }
  }

  const waiter = R.compose(
    R.unnest,
    R.map(itemLoader),
    R.defaultTo([]),
  )(fetcher);

  yield all(waiter);

  yield put(loadingFinish());

  callback();

  yield all(lazyLoadList.map(i => put(i)));

  if (loader) {
    yield put(hideLoader('global'));
    yield put(hideLoader('page'));
  }
}

export default function* saga() {
  yield all([
    takeEvery(loadingStart, loadingFlow),
  ]);
}

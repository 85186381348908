import * as R from 'ramda';
import { createSelector } from 'reselect';

import getDietanamnez from '../getDietanamnez';

export default createSelector(
  getDietanamnez,

  R.propOr(2, 'custom_тип_да'),
);

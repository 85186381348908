import * as R from 'ramda';

import { getErrorText } from 'utils/validation';

const apiNameMap = {
  EmptyRequiredFieldException: 'required',
  Cat_ObjectName_DuplicateValueException: 'unique',
};

const fieldNameMap = {
  custom_login_save: 'custom_login',
};

const defaultRules = {
  custom_login: {
    unique: {
      errorText: 'Пользователь с таким логином уже зарегистрирован',
    },
  },
};

export default function validateFromResponse(response = {}, map = fieldNameMap, rules = defaultRules) {
  const { data = {} } = response;
  let { exception = {} } = data;


  if (R.contains('Пароль подтверждения неверен', exception.message || '') || R.contains('Пароль не правильный', exception.message || '')) {
    exception = {
      fieldName: 'custom.requared_password',
      fieldMessage: 'Введен неверный пароль',
    };
  }

  const { type, fieldMessage = '', fieldName = '' } = exception || {};
  const field = R.compose(
    item => R.propOr(item, item, map),
    R.replace(/\./g, '_'),
    R.defaultTo(''),
  )(fieldName);

  if (!field) {
    return null;
  }

  return getErrorText(rules)({
    [field]: apiNameMap[type] || fieldMessage,
  });
}

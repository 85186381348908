import * as R from 'ramda';
import { createSelector } from 'reselect';

import {
  getItems as getDiseaseItems,
} from 'modules/Patient/redux/disease';

export default createSelector(
  R.path(['Patient', 'disease']),

  getDiseaseItems,
);

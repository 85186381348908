import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';

import styles from './IconDotLoader.module.css';

const cx = classNames.bind(styles);

export default class IconDotLoader extends PureComponent {
  static propTypes = {
    inline: PropTypes.bool,
  }

  static defaultProps = {
    inline: false,
  }

  render() {
    return (
      <span
        className={cx({
          iconDotLoader: true,
          iconDotLoader_inline: this.props.inline,
        })}
      >
        <span className={cx('dot')} />
        <span className={cx('dot')} />
        <span className={cx('dot')} />
      </span>
    );
  }
}

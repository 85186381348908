import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import * as R from 'ramda'

import Content from 'components/Content';
import Page from 'containers/Page';

import RegistrationLayout from '../../components/RegistrationLayout';

export default class PublicSignupLayout extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  }

  static defaultProps = {
    children: undefined,
  }

  render() {
    return (
      <RegistrationLayout isLite={R.contains(this.props.location.pathname, ['/lite', '/invite'])}>
        <Page>
          <Content>
            {this.props.children}
          </Content>
        </Page>
      </RegistrationLayout>
    );
  }
}

import createOrmSelector from 'redux/utils/createOrmSelector';

import getRationDayNumber from '../getRationDayNumber';
import getRationKey from '../getRationKey';

export default createOrmSelector(
  getRationDayNumber,
  getRationKey,

  (session, number, rationKey) => {
    const { Ration, Day } = session;


    if (!Ration.idExists(rationKey)) {
      return null;
    }

    try {
      return Ration
        .withId(rationKey)
        .days
        .filter({
          custom_номер: number,
        })
        .first()
        .ref;
    } catch (error) {
      const day = Day
      .filter({
        custom_номер: number,
        custom_рацион_пациента: rationKey,
      })
      .first()

      return day && day.ref;
    }
  },
);

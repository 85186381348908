import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { routerReducer } from 'react-router-redux';
import { createReducer } from 'redux-orm';

import Catalog from 'modules/Catalog/redux';
import Glossary from 'modules/Glossary/redux';
import Patient from 'modules/Patient/redux';
import Public from 'modules/Public/redux';
import Rations from 'modules/Rations/redux';
import Supplement from 'modules/Supplement/redux';
import User from 'modules/User/redux';

import activation from './activation';
import auth from './auth';
import cities from './cities';
import cookingTechniques from './cookingTechniques';
import countries from './countries';
import elementNorms from './elementNorms';
import filterOptions from './filterOptions';
import ingredients from './ingredients';
import institutions from './institutions';
import journal from './journal';
import loader from './loader';
import loading from './loading';
import meals from './meals';
import mealTypes from './mealTypes';
import notification from './notification';
import patientParams from './patientParams';
import patients from './patients';
import patientsAllergen from './patientsAllergen';
import productComposition from './productComposition';
import productGroups from './productGroups';
import productTechCard from './productTechCard';
import pingReducer from './ping';
import portions from './portions';
import rights from './rights';
import roles from './roles';
import specificEatingTypes from './specificEatingTypes';
import subscriptionPromocode from './subscriptionPromocode';
import subscriptionTariffs from './subscriptionTariffs';
import supplement from './supplement';
import supplementComposition from './supplementComposition';
import supplementGroups from './supplementGroups';
import supplementTechCard from './supplementTechCard';
import units from './units';
import user from './user';
import windowsOpen from './windowsOpen';
import patientActivity from './patientActivity';
import routeParams from './routeParams';

import orm from '../orm';

export default combineReducers({
  activation,
  patientActivity,
  auth,
  cities,
  cookingTechniques,
  countries,
  elementNorms,
  filterOptions,
  ingredients,
  institutions,
  journal,
  loader,
  loading,
  meals,
  mealTypes,
  notification,
  patientParams,
  patients,
  patientsAllergen,
  portions,
  productGroups,
  productComposition,
  productTechCard,
  rights,
  roles,
  specificEatingTypes,
  subscriptionPromocode,
  subscriptionTariffs,
  supplement,
  supplementComposition,
  supplementGroups,
  supplementTechCard,
  units,
  user,
  ping: pingReducer,
  windowsOpen,

  // custom
  routeParams,

  // modules
  Catalog,
  Glossary,
  Patient,
  Public,
  Rations,
  Supplement,
  User,

  // 3rd-party
  form,
  routing: routerReducer,
  orm: createReducer(orm),
});

import * as R from 'ramda';
import createCachedOrmSelector from 'redux/utils/createCachedOrmSelector';

import getApplicationUser from 'redux/selectors/getApplicationUser';
import getStaff from 'redux/selectors/getStaff';
import getUserCanEditSystemTemplates from 'redux/selectors/getUserCanEditSystemTemplates';
import { getUserRight } from 'redux/selectors/getUserRights';

import getRationKey from '../getRationKey';

// ration key is both temporary (uuid) and normal id;
// both are accessed at the same time, so cache orm selector by a ration key
export default createCachedOrmSelector(
  getRationKey,
  getApplicationUser,
  getUserCanEditSystemTemplates,
  state => getUserRight('INSTITUTION_ADMINISTER', state),
  getStaff,

  (session, rationKey, userKey, canEditSystemTemplates, isAdmin, staff) => {
    const { Ration } = session;
    if (!Ration.idExists(rationKey)) {
      return null;
    }

    const ration = Ration.withId(rationKey);
    const item = ration.ref;
    const type = R.propOr(null, 'custom_тип_шаблона', item);
    const owner = R.propOr(null, '___owner', item);
    const isCurrentUserCanEdit = userKey === owner || isAdmin;
    const author = R.find(R.propEq('custom_ключ_системного_пользователяold', ration.___createduser), staff);

    return {
      ...item,
      specialist: R.compose(
        R.join(' '),
        R.values,
        R.pick(['custom_firstname', 'custom_midname', 'custom_lastname']),
        R.defaultTo({}),
      )(author),
      custom_продолжительность: ration.custom_продолжительность,
      withEdit: type === 3 ? canEditSystemTemplates : isCurrentUserCanEdit,
      withDelete: type === 3 ? false : isCurrentUserCanEdit,
    };
  },
);

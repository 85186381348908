import { createSelector } from 'reselect';

import { getInstituteTemplate } from 'modules/Rations/redux/modules/ration/selectors';
import { getUserRight } from 'redux/selectors/getUserRights';
import getApplicationUser from 'redux/selectors/getApplicationUser';

export default createSelector(
  state => state,
  state => getUserRight('INSTITUTION_ADMINISTER', state),
  getApplicationUser,

  getInstituteTemplate,
);

import * as R from 'ramda';
import { createSelector } from 'reselect';

import getDietanamnez from '../getDietanamnez';

export default createSelector(
  getDietanamnez,

  R.compose(
    R.not,
    R.isNil,
    R.propOr(null, 'custom_дата_назначения'),
  ),
);

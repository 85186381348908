import * as R from 'ramda';
import { fk, Model } from 'redux-orm';

import generator from 'redux/hor/orm-module';

const {
  reducer,
  fetchSuccess,
  save,
} = generator({
  name: 'analysisGenes',
  tableId: 21,
  parseItem: R.evolve({
    ___parent: R.propOr(null, 'key'),
    custom_ген: R.propOr(null, 'key'),
    custom_генотип: R.propOr(null, 'key'),
  }),
});

export default class AnalysisGenes extends Model {
  static modelName = 'AnalysisGenes';

  static reducer = reducer;

  static options = {
    idAttribute: 'key',
  }

  static fields = {
    ___parent: fk('PatientAnalysis'),
    custom_ген: fk('Gene'),
    custom_генотип: fk('Genotype'),
  }
}

export {
  fetchSuccess,
  save,
};

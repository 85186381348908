import React, { PureComponent } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Title from 'components/Title';
import Field from 'components/Field';
import Success from 'components/Success';
import Error from 'components/Error';

import styles from './PasswordRecovery.module.css';

const cx = classNames.bind(styles);

export default class PublicPasswordRecovery extends PureComponent {
  static propTypes = {
    email: PropTypes.string,
    error: PropTypes.string,
    submitting: PropTypes.bool,
    success: PropTypes.bool,
    valid: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
  }

  static defaultProps = {
    email: undefined,
    error: undefined,
    submitting: false,
    success: false,
    valid: false,
    onCancel: () => {},
    onSubmit: () => {},
  }

  getTitle = R.memoizeWith(R.identity, email => (
    <span>
      Пароль выслан
      <br />
      на <b>{email}</b>
    </span>
  ));

  renderForm() {
    const { onSubmit, onCancel, error, submitting } = this.props;

    return (
      <form onSubmit={onSubmit} className={cx('form')} noValidate>
        <div className={cx('circle')}>
          <Icon icon='recovery' width={50} height={40} />
        </div>

        <div className={cx('title')}>
          <Title>Восстановление <br /> пароля</Title>
        </div>

        <div className={cx('field')}>
          <Field
            name='email'
            type='email'
            placeholder='Адрес почты, на которую зарегистрирован аккаунт'
            center
          />
        </div>

        {error && <Error error={error} />}

        <div className={cx('buttons')}>
          <Button
            type='submit'
            isLoading={submitting}
            disabled={!this.props.valid || submitting}
            upper
          >
            Восстановить пароль
          </Button>

          <Button
            onClick={onCancel}
            bordered
            upper
          >
            Отмена
          </Button>
        </div>
      </form>
    );
  }

  renderSuccess() {
    const { email } = this.props;

    return (
      <div>
        <Success>
          {this.getTitle(email)}
        </Success>
        <div className={cx('successButton')}>
          <Button
            onClick={this.props.onCancel}
            bordered
            upper
            block
          >
            ОК
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { success } = this.props;

    return (
      <div className={cx('passwordRecovery')}>
        {success ? this.renderSuccess() : this.renderForm()}
      </div>
    );
  }
}
